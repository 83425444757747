import React, {ReactNode, useEffect, useMemo} from 'react';
import {
    Form, Row, Col,
} from 'antd';
import {getAirportOptions} from "../../FlightSearch/helper";
import {FormFieldsWrapper} from "./styles";
import translations from "../../../screens/Search/translations";
import StyledDatePicker from "../../CustomAntdComponents/StyledDatePicker";
import {getDisabledDateRange, getExtraData, getStartOfDay} from "../../../utils/helpers";
import PassengerCountSelector from "../../PassegerSelector";
import {useI18nContext} from "../../../utils/I18n";
import {useStore} from "../../../store/global/appStore";
import {RangePickerProps} from "antd/es/date-picker";
import {FlightSearchStore} from "../../../store/flightSearch/types";
import StyledRadioGroup from "../../CustomAntdComponents/StyledRadioGroup";
import {tripModesMultiCity} from "../../../screens/Search/constant";
import {ReactComponent as DirectionIcon} from "../../../assets/images/direction.svg";
import {DepartureWrapper, SwitcherWrapper} from "../../FlightSearch/styles";
import styled from "styled-components";
import {MulticityFlightSearchForm} from "../../FlightSearch/MultiCity/mobileModify";
import ModifyRouteSelector from "./MobileRouteModifySelector";

type FlightSearchProps = {
    onSubmit: (arg?: any) => void;
    payload: FlightSearchStore;
    onChange: (arg) => void;
    form: any;
}

const FlightSearchMobile = ({ onSubmit, onChange, payload, form }: FlightSearchProps) => {
    const {
        state: { t },
    } = useI18nContext();
    const { airports } = useStore();

    const {
        FlightClass,
        DepartureDate,
        ReturnDate,
        numberOfAdults,
        DestinationLocationCode,
        OriginLocationCode,
        numberOfInfants,
        numberOfChildren,
        tripType,
        multiCity
    } = payload;

    const tripTypeWatch = Form.useWatch('tripType', form);
    const DepartureDateWatch = Form.useWatch('DepartureDate', form);

    const isRoundTrip = tripTypeWatch === 'round_trip';
    const isMultiCity = tripTypeWatch === 'multi_city';

    const formInitialValues = {
        FlightClass,
        DepartureDate,
        ReturnDate,
        tripType,
        numberOfAdults,
        DestinationLocationCode,
        OriginLocationCode,
        numberOfInfants,
        numberOfChildren,
        multiCity
    };

    const allAirports = [...airports];
    const airportOptions = useMemo(() => getAirportOptions(allAirports), [airports]);

    const validateDestination = (_: any, value: any) => {
        if (!value) {
            return Promise.reject(new Error(t(translations).validations.destination));
        }

        if (form.getFieldValue('OriginLocationCode') === value) {
            return Promise.reject(new Error(t(translations).validations.invalidDestination));
        }

        return Promise.resolve();
    };

    const checkReturningDate = (_: any, value: any) => {
        const tripType = form.getFieldValue('tripType');
        if(tripType === 'one_way') return Promise.resolve();

        const departureDate = form.getFieldValue('DepartureDate');

        if (!value) {
            return Promise.reject(new Error(t(translations).validations.destinationDate));
        }

        if (value.isBefore(departureDate)) {
            return Promise.reject(new Error(t(translations).validations.invalidDestinationDate));
        }
        return Promise.resolve();
    };

    const onFormInputChange = (value: any, name: string) => {
        onChange({
            [name]: value,
        });
    };

    const disabledReturnDate: RangePickerProps['disabledDate'] = (current) => getDisabledDateRange(current, getStartOfDay(DepartureDateWatch));
    const flightModes = tripModesMultiCity(t(translations));

    const OriginLocationCodeWatch = Form.useWatch('OriginLocationCode', form);
    const DestinationLocationCodeWatch = Form.useWatch('DestinationLocationCode', form);

    const handleSwitchAirports = () => {
        onChange({
            OriginLocationCode: DestinationLocationCodeWatch,
            DestinationLocationCode: OriginLocationCodeWatch,
        });

        form.setFieldsValue({
            OriginLocationCode: DestinationLocationCodeWatch,
            DestinationLocationCode: OriginLocationCodeWatch,
        });
    }

    useEffect(() => {
        const origin = allAirports.find((airport) => airport.Code === OriginLocationCodeWatch)
        const destination = allAirports.find((airport) => airport.Code === DestinationLocationCodeWatch)

        if(!origin || !destination) return;
        const isDomestic =  origin?.CountryCode === destination?.CountryCode;

        onChange({
            isDomestic,
        });

    }, [DestinationLocationCodeWatch, OriginLocationCodeWatch]);

    const onFinish = (values) => {
        onSubmit(values.trips);
    };

    const extraData = getExtraData();
    const MultiCityMaxRoute = extraData.MultiCityMaxRoute ? extraData.MultiCityMaxRoute : 5;

    return (
        <Form
            wrapperCol={{ span: 24 }}
            layout="horizontal"
            initialValues={formInitialValues}
            form={form}
            size="large"
            onFinish={onFinish}
        >
            <FormFieldsWrapper>
                <Row gutter={[8, 4]}>
                    <Col xs={24}>
                        <Form.Item
                            name="tripType"
                            rules={[{ required: true, message: t(translations).validations.departure }]}
                        >
                            <StyledRadioGroup options={flightModes} value={tripType} onChange={(e) => onFormInputChange(e.target.value, 'tripType')} />
                        </Form.Item>
                    </Col>

                    {
                        isMultiCity ? (
                            <Col span={24}>
                                <MulticityFlightSearchForm
                                    airportOptions={airportOptions}
                                    MultiCityMaxRoute={MultiCityMaxRoute}
                                    form={form}
                                />
                            </Col>
                        ) : (
                            <>
                                <Col xs={24}>
                                    <HeaderDepartureWrapper>
                                        <Form.Item
                                            name="OriginLocationCode"
                                            rules={[{ required: true, message: t(translations).validations.departure }]}
                                        >
                                           <ModifyRouteSelector
                                               label={t(translations).inputs.fromLabel}
                                               value={OriginLocationCode}
                                               onChange={(value) => onFormInputChange(value, 'OriginLocationCode')}
                                               icon="takeoff"
                                               tripType="flight"
                                           />
                                        </Form.Item>
                                        <HeaderSwitcherWrapper onClick={handleSwitchAirports}>
                                            <DirectionIcon />
                                        </HeaderSwitcherWrapper>
                                    </HeaderDepartureWrapper>

                                </Col>
                                <Col xs={24}>
                                    <div>
                                        <Form.Item
                                            name="DestinationLocationCode"
                                            rules={[
                                                {
                                                    validator: validateDestination,
                                                },
                                            ]}
                                        >
                                            <ModifyRouteSelector
                                                label={t(translations).inputs.toLabel}
                                                value={DestinationLocationCode}
                                                onChange={(value) => onFormInputChange(value, 'DestinationLocationCode')}
                                                icon="land"
                                                tripType="flight"
                                            />
                                        </Form.Item>
                                    </div>
                                </Col>

                                <Col xs={24}>
                                    <Form.Item
                                        name="DepartureDate"
                                        rules={[{ required: true, message: t(translations).validations.departureDate }]}
                                    >
                                        <StyledDatePicker
                                            placeholder={t(translations).inputs.departureDate}
                                            value={DepartureDate}
                                            disabledDate={getDisabledDateRange}
                                            onChange={(value) => onFormInputChange(value, 'DepartureDate')}
                                        />
                                    </Form.Item>
                                </Col>

                                {
                                    isRoundTrip && (
                                        <Col xs={24}>
                                            <Form.Item
                                                name="ReturnDate"
                                                rules={[
                                                    {
                                                        validator: checkReturningDate,
                                                    },
                                                ]}
                                                dependencies={['tripType']}
                                            >
                                                <StyledDatePicker
                                                    placeholder={t(translations).inputs.arrivalDate}
                                                    value={ReturnDate}
                                                    disabledDate={disabledReturnDate}
                                                    onChange={(value) => onFormInputChange(value, 'ReturnDate')}
                                                />
                                            </Form.Item>
                                        </Col>
                                    )
                                }
                            </>
                        )
                    }


                    <Col xs={24} lg={0}>
                        <Form.Item>
                            <PassengerCountSelector onChange={onChange} payload={payload} />
                        </Form.Item>
                    </Col>
                </Row>
            </FormFieldsWrapper>
        </Form>
    );
};

const HeaderSwitcherWrapper = styled(SwitcherWrapper)`
    bottom: -25px;
`;

const HeaderDepartureWrapper = styled(DepartureWrapper)`
`;

export default FlightSearchMobile;
