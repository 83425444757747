import React, { useState } from 'react';
import { Typography, Slider } from 'antd';
import type { SliderMarks } from 'antd/es/slider';

import { PillItem, RangeSelectorWrapper } from './styles';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/SearchListings/translations';

const { Paragraph } = Typography;

type Props = {
  handleSortByPrice: (arg0: string | null) => void,
}
function PriceRange({ handleSortByPrice }: Props) {
  const {
    state: { t },
  } = useI18nContext();
  const [activePriceFilter, setActivepriceFilter] = useState('');
  const marks: SliderMarks = {
    20000: 'N20,000',
    200000: 'N200,000',
  };

  const handlePriceFilter = (order: string) => {
    setActivepriceFilter(order);
    handleSortByPrice(order);
  };

  return (
    <>
      <Paragraph strong>
        {t(translations).price}
      </Paragraph>
      <RangeSelectorWrapper>
        <Slider
          range
          marks={marks}
          defaultValue={[20000, 50000]}
          min={20000}
          max={200000}
        />

      </RangeSelectorWrapper>

      <PillItem
        active={activePriceFilter === 'asc'}
        onClick={() => handlePriceFilter('asc')}
      >
        {t(translations).cheapest}
      </PillItem>
      <PillItem
        active={activePriceFilter === 'desc'}
        onClick={() => handlePriceFilter('desc')}
      >
        {t(translations).expensive}
      </PillItem>
    </>
  );
}

export default PriceRange;
