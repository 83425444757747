import React, { useMemo } from 'react';
import StyledCheckboxGroup from "../../CustomAntdComponents/StyledCheckboxGroup";
import {ClearWrapper} from "../styles";
import {StyledText} from "../../CustomAntdComponents/Typography";

type Props = {
    airlines: AirlineOption[]
    onChange: (arg: string[]) => void,
    selectedAirlines: string[]
}

function AirlinesCheckbox({ airlines, selectedAirlines, onChange }: Props) {
    const handleChange = (list) => {
        onChange(list);
    }

    const airlineOptions = useMemo(() => {
        return airlines.map((airline) => ({
            label: airline.name,
            value: airline.code,
        }))
    }, [airlines]);

    return (
        <>
            <ClearWrapper>
                <StyledText onClick={() =>  handleChange([])} color="primary">
                    Clear all
                </StyledText>
            </ClearWrapper>
            <StyledCheckboxGroup options={airlineOptions} values={selectedAirlines} onChange={handleChange} />
        </>
    );
}

export default AirlinesCheckbox;
