import { create } from 'zustand';
import { RailSearchStore } from './types';

interface RailSearchStoreState {
  formData: RailSearchStore;
  selectedTrain: AvailableTrain | null,
  selectedResult: RailResultItem | null,
  loading: boolean;
  updateFormData: (data: any) => void,
  updateStoreData: (data: any) => void,
  resetFormData: () => void,
}

export const initialFormData: RailSearchStore = {
  TripType: 'one_way',
  numberOfAdults: 1,
  numberOfChildren: 0,
  numberOfInfants: 0,
  OrderbyDepartureTime: 'ASC',
  OrderByPrice: 'ASC',
  Currency: 'NGN',
  PageSize: 5000,
  From: 0,
  selectedSeats: [],
};

export const useRailSearchStore = create<RailSearchStoreState>((set) => ({
  // initial state
  formData: initialFormData,
  loading: false,
  selectedTrain: null,
  selectedResult: null,
  updateFormData: (data: any) => {
    set((state) => ({
      ...state,
      formData: {
        ...state.formData,
        ...data,
      },
    }));
  },
  updateStoreData: (data: any) => {
    set((state) => ({
      ...state,
      ...data,
    }));
  },
  resetFormData: () => {
    set((state) => ({
      ...state,
      formData: {
        ...initialFormData,
      },
    }));
  },
}));
