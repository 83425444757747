import styled, { css } from 'styled-components';
import { GREY_10 } from '../../utils/colors';

export const StyledHR = styled.div<{ size?: string }>`
  border-top: solid 1px ${GREY_10};
  margin: 10px 0;
  width: 100%;

  ${({ size }) => size === 'large' && css`margin: 25px 0`}
`;

export const CentralizedContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
`;

export const VerticalAlignContent = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
`;

export const HorizontalAlignContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 5px;
`;

export const SpaceBetweenAlignContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
`;

export const GiveMeSpace = styled.div<{ size?: string}>`
  display: block;
  height: 15px;
  
  ${({ size }) => size === 'large' && css` height: 30px`}
  ${({ size }) => size === 'small' && css` height: 5px`}
`;
