export default {
  en: {
    search: 'Search',
    flight: 'Flight',
    road: 'Road',
    water: 'Water',
    rail: 'Rail',
    passengerSelector: {
      title: 'Passengers',
      adult: 'Adult',
      adults: 'Adults',
      children: 'Children',
      child: 'Child',
      infant: 'Infant',
      infants: 'Infants',
      adultValidationText: 'Passenger must have at least one',
      maxValidationText: 'Max of 9 Passengers is allowed!',
      unmatchValidationText: 'Number of infants does not match number of adults',
      placeholder: {
        adults: 'Adults (12yrs+)',
        children: 'Children (2 - 11yrs)',
        infants: 'Infants (<2yrs)',
      },
    },
    readiness: 'Coming Soon',
  },
  fr: {
    search: 'Recherche',
    flight: 'Vol',
    road: 'Route',
    water: 'Eau',
    rail: 'Rail',
    passengerSelector: {
      title: 'Passagers',
      adult: 'Adulte',
      adults: 'Adultes',
      children: 'Enfant',
      child: 'Enfants',
      infant: 'Nourrisson',
      infants: 'Nourrissons',
      adultValidationText: 'Le passager doit avoir au moins un',
      maxValidationText: 'Un maximum de 9 passagers est autorisé !',
      unmatchValidationText: 'Le nombre d\'enfants en bas âge ne correspond pas au nombre d\'adultes.',
      placeholder: {
        adults: 'Adultes (12yrs+)',
        children: 'Enfant (2 - 11yrs)',
        infants: 'Nourrissons (<2yrs)',
      },
    },
    readiness: 'À Venir',
  },
  sp: {
    search: 'Busque en',
    flight: 'Vuelo',
    road: 'Carretera',
    water: 'Agua',
    rail: 'Ferrocarril',
    ype: 'Tipo de viaje',
    passengerSelector: {
      title: 'Pasajeros',
      adult: 'Adulto',
      adults: 'Adultos',
      children: 'Niños',
      child: 'Niño',
      infant: 'Bebé',
      infants: 'Bebés',
      adultValidationText: 'El pasajero debe tener al menos una',
      maxValidationText: 'Se permite un máximo de 9 pasajeros',
      unmatchValidationText: 'El número de bebés no coincide con el número de adultos',
      placeholder: {
        adults: 'Adultos (12yrs+)',
        children: 'Niños (2 - 11yrs)',
        infants: 'Bebés (<2yrs)',
      },
    },
    readiness: 'Muy Pronto',
  },
  sw: {
    search: 'Tafuta',
    flight: 'Ndege',
    road: 'Barabara',
    water: 'Maji',
    rail: 'Reli',
    tripType: 'Aina ya safari',
    passengerSelector: {
      title: 'Abiria',
      adult: 'Mtu mzima',
      adults: 'Watu wazima',
      children: 'Mtoto',
      child: 'Watoto',
      infant: 'Mtoto mchanga',
      infants: 'Watoto wachanga',
      adultValidationText: 'Abiria lazima awe na angalau moja',
      maxValidationText: 'Upeo wa Abiria 9 unaruhusiwa!',
      unmatchValidationText: 'Idadi ya watoto wachanga hailingani na idadi ya watu wazima',
      placeholder: {
        adults: 'Watu wazima (12yrs+)',
        children: 'Mtoto (2 - 11yrs)',
        infants: 'Watoto wachanga (<2yrs)',
      },
    },
    readiness: 'Inakuja Hivi Karibuni',
  },
};
