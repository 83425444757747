import React from 'react';
import {
    CircleIcon,
    RoadDetails,
    RoadDetailsContainer,
    RoadDetailsWrapper,
    RoadLogoWrapper,
    ProgressIcon,
    RowItem,
    TimeWrapper
} from "./styles";
import {StyledParagraph, StyledText, StyledTitle} from "../CustomAntdComponents/Typography";
import {ReactComponent as BusIcon} from "../../assets/images/bus-grey.svg";
import {getRoadTransportLogo} from "../AvailableBuses/helper";

type Props = {
    operator: BusResultItem,
    bus: AvailableBus,
}

const RoadCard = ({ operator, bus }: Props) => {

    return (
        <RoadDetailsContainer>
            <RoadDetails>
                <RowItem>
                    <TimeWrapper>
                        <StyledTitle level={5}>
                            {bus.EstimatedDepartureTime}
                        </StyledTitle>
                    </TimeWrapper>

                    <ProgressIcon>
                        <CircleIcon />
                    </ProgressIcon>

                    <RoadDetailsWrapper>
                        <StyledParagraph size="large">
                            {operator.DepartureTerminal}
                        </StyledParagraph>
                        <StyledParagraph>
                            {
                                bus.BoardingDetails && `${bus.BoardingDetails.Name}`
                            }
                        </StyledParagraph>
                    </RoadDetailsWrapper>
                </RowItem>

                <RowItem>
                    <TimeWrapper>
                        <StyledParagraph align="center">
                            {bus.Duration}
                        </StyledParagraph>
                    </TimeWrapper>

                    <ProgressIcon road>
                        <BusIcon width={15} height={15} />
                    </ProgressIcon>

                    <RoadDetailsWrapper>
                        <div>
                            <RoadLogoWrapper>
                                <img
                                    src={getRoadTransportLogo(operator.AgentName)}
                                    alt={operator.AgentName}
                                />
                            </RoadLogoWrapper>

                            <StyledText>
                                {operator.AgentName}
                            </StyledText>
                        </div>
                        <StyledParagraph size="small">
                            {bus.BusType} | {`${bus.Capacity} seater`}
                        </StyledParagraph>
                    </RoadDetailsWrapper>
                </RowItem>

                <RowItem>
                    <TimeWrapper>
                        <StyledTitle level={5}>
                            {bus.EstimatedArrivalTime}
                        </StyledTitle>
                    </TimeWrapper>

                    <ProgressIcon>
                        <CircleIcon />
                    </ProgressIcon>

                    <RoadDetailsWrapper>
                        <StyledParagraph size="large">
                            {operator.ArrivalTerminal}
                        </StyledParagraph>
                        <StyledParagraph>
                            {
                                bus.DropOffDetails && `${bus.DropOffDetails.Name}`
                            }
                        </StyledParagraph>
                    </RoadDetailsWrapper>
                </RowItem>
            </RoadDetails>

        </RoadDetailsContainer>
    );
};

export default RoadCard;
