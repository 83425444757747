import React from 'react';
import {
  Checkbox, Space, Typography,
} from 'antd';
import { AirlineCheckboxWrapper } from './styles';
import { useRoadSearchStore } from '../../store/roadSearch/roadSearchStore';
import translations from '../../screens/SearchRoadListings/translations';
import { useI18nContext } from '../../utils/I18n';

const { Paragraph } = Typography;

type Props = {
  transports: Transport[];
  handleTransportFiltering: (arg0: boolean, arg: number) => void,
}
function TransportCheckbox({ transports, handleTransportFiltering }: Props) {
  const {
    state: { t },
  } = useI18nContext();
  const {
    selectedResult,
  } = useRoadSearchStore();
  return (
    <AirlineCheckboxWrapper>
      <Paragraph strong>
        {t(translations).transports}
      </Paragraph>
      <Space direction="vertical" size="middle">
        {transports.map((transport: Transport) => (
          <Checkbox
            key={transport.code}
            disabled={Boolean(selectedResult)}
            onChange={(e) => {
              handleTransportFiltering(e.target.checked, transport.code);
            }}
          >
            {transport.name}
          </Checkbox>
        ))}
      </Space>
    </AirlineCheckboxWrapper>
  );
}

export default TransportCheckbox;
