import React, { useMemo } from 'react';
import StyledRadioGroup from "../../CustomAntdComponents/StyledRadioGroup";

type Props = {
  onChange: (arg) => void,
    selectedStopOver: number | null,
}

const options = [
    {
      name: 'Any',
      value: "-1",
    },
    {
      name: 'Direct',
      value: "0",
    },
    {
      name: '1 stop',
      value: "1",
    },
    {
      name: '2 stops',
      value: "2",
    }, {
      name: '3 stops',
      value: "3",
    }
];

function StopOvers({ onChange, selectedStopOver }: Props) {
  const handleSetStopOver = (e) => {
    onChange(e.target.value);
  };

  const stopType = useMemo(() => {
    if (selectedStopOver === null) {
      return "-1";
    }
    return selectedStopOver.toString();
  }, [selectedStopOver]);

  return (
      <div>
        <StyledRadioGroup block options={options} value={stopType} onChange={handleSetStopOver} />
      </div>
  );
}

export default StopOvers;
