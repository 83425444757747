import React, {ReactNode, useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFlightSearchStore } from '../store/flightSearch/flightSearchStore';
import { useRoadSearchStore } from '../store/roadSearch/roadSearchStore';
import AuthenticatedRoute from './AuthenticatedRoute';
import { useWaterSearchStore } from '../store/waterSearch/waterSearchStore';
import { useRailSearchStore } from '../store/railSearch/railSearchStore';

type Props = {
  children: ReactNode
}
function DataFilled({ children }: Props) {
  const { tripType } = useParams();
  const [allow, setAllow] = useState(false)

  const navigate = useNavigate();
  const {
    formData: { OriginLocationCode, multiCity },
      selectedFlight
  } = useFlightSearchStore();

  const {
    formData: { DepartureId },
  } = useRoadSearchStore();

  const {
    formData: { DepartureId: WaterDepartureId },
  } = useWaterSearchStore();

  const {
    formData: { DepartureId: TrainDepartureId },
  } = useRailSearchStore();
  const firstOriginLocationCodeInMultiCity = multiCity && multiCity?.length > 0 && multiCity[0]?.OriginLocationCode;

  useEffect(() => {
    if (((!OriginLocationCode && !firstOriginLocationCodeInMultiCity) || !selectedFlight) && tripType === 'flight') {
      navigate('/search/flight');
    } else if (!DepartureId && tripType === 'road') {
      navigate('/search/road');
    }else if (!WaterDepartureId && tripType === 'water') {
      navigate('/search/water');
    }else if (!TrainDepartureId && tripType === 'rail') {
      navigate('/search/rail');
    } else {
      setAllow(true)
    }
  }, [DepartureId, OriginLocationCode, firstOriginLocationCodeInMultiCity, TrainDepartureId, WaterDepartureId, navigate, tripType]);



  return allow ? (
    <AuthenticatedRoute>
      {children}
    </AuthenticatedRoute>
  ) : (
      <></>
  );
}

export default DataFilled;
