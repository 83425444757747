import React from 'react';
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import {
    Content,
    Wrapper,
} from "./styles";
import searchIcon from "../../assets/images/bg/search.svg";
import {StyledButton} from "../CustomAntdComponents/StyledButton";


type Props = {
    onAction: () => void;
    emptyOnFilter?: boolean;
}

const EmptyResult = ({ onAction, emptyOnFilter }: Props) => {
    return (
            <Wrapper>
                <Content>
                    <img src={searchIcon} alt="search" width={130} />

                    {
                        emptyOnFilter ? (
                            <>
                                <StyledTitle level={4} align="center">
                                    Sorry, no trip match the applied filters
                                </StyledTitle>

                                <StyledParagraph align="center" size="normal">
                                    Try  adjusting the filters or reset to see all available trips.
                                </StyledParagraph>
                                <StyledButton type="primary" onClick={onAction}>
                                    Reset filters
                                </StyledButton>
                            </>
                        ) : (
                            <>
                                <StyledTitle level={4} align="center">
                                    Sorry, we couldn't find any trip on that date
                                </StyledTitle>

                                <StyledParagraph align="center" size="normal">
                                    Try  adjusting the date, trip type or destination.
                                </StyledParagraph>
                                <StyledButton type="primary" onClick={onAction}>
                                    Modify search
                                </StyledButton>
                            </>
                        )
                    }



                </Content>
            </Wrapper>
    );
};

export default EmptyResult;
