import styled from 'styled-components';
import {BLACK_200, GREY, GREY_1, GREY_12, GREY_5, GREY_6, LIGHT_GREY, WHITE} from '../../utils/colors';
import device from '../../utils/mediaQuery';
import {Wrapper as NumberWrapper, IconWrapper as NumberIconWrapper } from "../NumberInput";
import {StyledParagraph} from "../CustomAntdComponents/Typography";

export const SelectWrapper = styled.div`
  .ant-select-selector {
    background: ${GREY_5} !important;
  }
`;

export const Wrapper = styled.div`
  width: 100%;

  .as-label {
   display: none;
  }

  @media ${device.large} {
    .as-label {
      display: block;
    }
    min-width: 300px;
  }
`;

export const TitleWrapper = styled.div`
  margin-bottom: 18px;
  display: flex;
  align-items: center;
`;

export const SelectLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  background: #fff;
  padding-left: 20px;
  border-radius: 5px 0 0 5px;
  
  .ant-typography {
    //color: ${GREY};
  }
`;

export const HelperText = styled(StyledParagraph)`
  color: ${GREY};
    font-size: 9px;
`;

export const InputDisplay = styled.div`
  background: ${WHITE};
  padding: 6px 11px;
  font-size: 14px;
  border-radius: 12px;
  overflow: hidden;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.4;
  min-height: 48px;
  color: ${GREY};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 1px ${GREY_6};

  @media ${device.large} {
    display: block;
    cursor: pointer;
  }
`;

export const InputTriggerItem = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;

   svg {
     width: 20px;
     height: 20px;
     
     path {
          fill: ${BLACK_200} !important;
     }
   }
`;

export const CabinWrapper = styled.div`
  min-height: 350px;
  
  .ant-typography {
    margin-bottom: 20px !important;
  }

  @media ${device.large} {
    min-height: unset;
    .ant-typography {
      margin-bottom: 0 !important;
    }
  }
`;

export const PaxCountWrapper = styled.div`
  min-height: 420px;


  @media ${device.large} {
    min-height: unset;
  }
`;

export const PaxCountLabel = styled.div`
  flex: 1;
`;


export const PaxCountItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0 16px;
  border-bottom: solid 1px ${GREY_12};
  
  .ant-form-item {
    margin-bottom: 0;
  }


  ${NumberWrapper} {
    justify-content: right;
  }
  
  @media ${device.large} {
    padding-top: 0;
    border-bottom: none;
    
    ${NumberIconWrapper}{
      width: 30px;
      height: 30px;
      font-size: 14px;
    }
    
    ${NumberWrapper} {
      font-size: 14px;
    }
  }
`;

export const DropDownTitle = styled.div`
  .ant-typography {
    margin-bottom: 20px !important;
  }

  @media ${device.large} {
    border-bottom: solid 1px #EFEFEF;
    margin-bottom: 4px;

    .ant-typography {
      margin-bottom: 5px !important;
    }
  }
`;

export const DropDownSubTitle = styled.div`
  .ant-typography {
    margin-bottom: 25px !important;
  }
`;

export const PaxCountTitle = styled.div`
  .ant-typography {
    margin: 0 !important;
  }

  @media ${device.large} {
    .ant-typography {
      font-size: 12px !important;
    }
  }
`;

export const PaxCountSubTitle = styled.div`
  .ant-typography {
    margin: 0 !important;
    color: ${BLACK_200};
  }

  @media ${device.large} {
    .ant-typography {
      font-size: 10px !important;
    }
  }
`;

export const DropdownContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;


