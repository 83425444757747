import React from 'react';
import {
  Card, Col, Row, Space,
} from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  ContentWrapper,
  IconWrapper, ListItemWrapper, ListWrapper, TextWrapper,
} from '../../screens/PaymentReceipt/styles';
import { ReactComponent as PayIcon } from '../../assets/images/pay.svg';
import {
  StyledParagraph,
  StyledTitle,
} from '../CustomAntdComponents/Typography';
import { formatNumberAsCurrency } from '../../utils/helpers';
import { StyledButton } from '../CustomAntdComponents/StyledButton';
import { calculateTotalFare } from '../AvailableTrains/helper';
import { useRailSearchStore } from '../../store/railSearch/railSearchStore';

function TrainReceipt() {
  const {
    selectedTrain,
    selectedResult,
  } = useRailSearchStore();

  const totalFare = calculateTotalFare(selectedTrain ? selectedTrain.PassengerFares : []);

  return (
    <Row>
      <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 6 }}>
        <Card>
          <ContentWrapper>
            <Space direction="vertical">
              <IconWrapper>
                <PayIcon />
              </IconWrapper>

              <StyledTitle level={3} color="primary" align="center">
                Transaction successful
              </StyledTitle>

              <ListWrapper>
                <ListItemWrapper>
                  <StyledParagraph color="secondary">
                    Transaction Amount
                  </StyledParagraph>
                  <StyledTitle level={5} align="right">
                    {formatNumberAsCurrency(totalFare.totalAmount, selectedResult?.Currency)}
                  </StyledTitle>
                </ListItemWrapper>
                <ListItemWrapper>
                  <StyledParagraph color="secondary">
                    Transaction Ref
                  </StyledParagraph>
                  <StyledTitle level={5} align="right">
                    GJSO10397
                  </StyledTitle>
                </ListItemWrapper>
                <ListItemWrapper>
                  <StyledParagraph color="secondary">
                    Transaction Status
                  </StyledParagraph>
                  <StyledTitle level={5} align="right">
                    Successful
                  </StyledTitle>
                </ListItemWrapper>
                <ListItemWrapper>
                  <StyledParagraph color="secondary">
                    Transaction Date
                  </StyledParagraph>
                  <StyledTitle level={5} align="right">
                    {moment().format('ll')}
                  </StyledTitle>
                </ListItemWrapper>
                <ListItemWrapper>
                  <StyledParagraph color="secondary">
                    Customer
                  </StyledParagraph>
                  <StyledTitle level={5} align="right">
                    Jordan Belson
                  </StyledTitle>
                </ListItemWrapper>
              </ListWrapper>
            </Space>
          </ContentWrapper>
        </Card>

        <TextWrapper>
          <StyledParagraph align="center">
            Your bus ticket will be sent to your email address
          </StyledParagraph>
        </TextWrapper>

        <Space direction="vertical" size="middle">
          <StyledButton block size="large" type="primary" ghost>
            Download Receipt
          </StyledButton>

          <Link to="/">
            <StyledButton block type="primary" size="large">
              Done
            </StyledButton>
          </Link>
        </Space>
      </Col>
    </Row>
  );
}

export default TrainReceipt;
