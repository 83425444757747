import React, {ReactNode, useState, useMemo } from 'react';
import { Drawer }  from "antd";
import {
    DrawerContent,
    InputDisplay,
    InputWrapper,
    ListContentWrapper,
    PrefixIconWrapper
} from "./styles";
import SelectSearches from "./SelectSearches";
import StyledInput from "../CustomAntdComponents/StyledInput";
import {debounce} from "../../utils/helpers";
import SelectRoadSearches from "./SelectRoadSearches";
import {useStore} from "../../store/global/appStore";
import {Label} from "../CustomAntdComponents/StyledInput/styles";

type Props = {
    label: string,
    placeholder?: string,
    defaultValue?: string,
    options: SelectOption[],
    onChange?: (value: any) => void;
    suffixIcon?: ReactNode,
    value?: string | number,
    renderIcon: () => ReactNode
    tripType: string
}

const MobileRouteSelector = ({ label, tripType, placeholder, value, options, onChange, renderIcon }: Props) => {
    const [isOpen, setIOpen] = useState(false);
    const [text, setText] = useState("");
    const [searchText, setSearchText] = useState("");
    const { roadRoutes, airports} = useStore();

    const handleSelect = (value: any) => {
        onChange(value);
        setIOpen(false);
        setText("");
        setSearchText("")
    }

    const debouncedSetSearchText = debounce(setSearchText, 300);

    const displayValue = useMemo(() => {
        if(tripType === "road"){
            return options.find((option) => option.value === value)?.label;
        }

        const airport = airports.find((option) => option.Code === value);if(airport){
            return `${airport.Code}, ${airport.StateName}`;
        }

        if(value) {
            return value.toString();
        }

        return '';
    }, [value, airports, roadRoutes]);

    return (
        <div>
            <InputWrapper onClick={() => setIOpen(true)}>
                <PrefixIconWrapper>
                    {renderIcon()}
                    <Label>
                        {`${label}: `}
                    </Label>
                </PrefixIconWrapper>
                <InputDisplay>
                    {displayValue}
                </InputDisplay>
            </InputWrapper>

            <Drawer
                open={isOpen}
                placement="bottom"
                onClose={() => {
                    setText("");
                    setIOpen(false);
                }}
                height="100%"
            >
                <DrawerContent>
                    {
                        isOpen && <StyledInput
                            name="text"
                            value={text}
                            placeholder={placeholder}
                            onChange={(val) => {
                                setText(val);
                                debouncedSetSearchText(val);
                            }}
                        />
                    }

                    <ListContentWrapper>
                        {
                            tripType === 'road' ? (
                                <SelectRoadSearches
                                    onSelect={handleSelect}
                                    searchText={searchText}
                                />

                            ) : (
                                <SelectSearches
                                    onSelect={handleSelect}
                                    searchText={searchText}
                                />
                            )
                        }
                    </ListContentWrapper>
                </DrawerContent>
            </Drawer>
        </div>
    );
};

MobileRouteSelector.defaultProps = {
    placeholder: undefined,
    defaultValue: undefined,
    suffixIcon: undefined,
    value: undefined,
    onChange: undefined,
};

export default MobileRouteSelector;
