import styled, { css } from "styled-components";
import {BLACK_400, GREY_12} from "../../../utils/colors";

export const RadioItem = styled.div`
  display: inline-block;
`;

export const Wrapper = styled.div<{ bordered?: boolean, block?: boolean }>`
  .ant-radio-wrapper {
    line-height: 30px;
    font-size: 14px;
    color: ${BLACK_400}
  }
  
  ${({ block }) => block && css`
    .ant-radio-wrapper {
      display: block;
      height: 50px;
    }
    
    .ant-radio {
      position: absolute;
      top: 15px;
      right: 0;
    }

    .ant-radio-group {
      display: block;
    }
    
    ${RadioItem} {
      display: block;
      width: 100%;
    }
  `}


  ${({ bordered }) => bordered && css`
      ${RadioItem} {
        border-bottom: solid 1px ${GREY_12};
      }
  `}
`;
