import React, {ReactNode, useState, useMemo, useRef, useEffect } from 'react';
import {Popover} from "antd";
import {
    DesktopPopoverWrapper,
} from "./styles";
import SelectSearches from "./SelectSearches";
import {debounce} from "../../utils/helpers";
import SelectRoadSearches from "./SelectRoadSearches";
import {useStore} from "../../store/global/appStore";
import StyledInput from "../CustomAntdComponents/StyledInput/Input";

type Props = {
    label: string,
    placeholder?: string,
    defaultValue?: string,
    onChange?: (value: any) => void;
    suffixIcon?: ReactNode,
    value?: string | number,
    renderIcon: () => ReactNode
    tripType: string,
    limitSuggestionHeight?: boolean
}

const DesktopSelector = ({ label, tripType, value, onChange, renderIcon, limitSuggestionHeight }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [text, setText] = useState("");
    const [searchText, setSearchText] = useState("");
    const { roadRoutes, airports} = useStore();
    const [popOverHeight, setPopOverHeight] = useState(300);
    const popoverContainerRef = useRef(null);

    const debouncedSetSearchText = debounce(setSearchText, 300);

    const displayValue = useMemo(() => {
        if(tripType === "road"){
            return roadRoutes.find((option) => option.TerminalId.toString() === value)?.TerminalName;
        }

        const airport = airports.find((option) => option.Code === value);
        if(airport){
            return `${airport.Code}, ${airport.StateName}`;
        }

        if(value) {
            return value.toString();
        }

        return '';
    }, [value, airports, roadRoutes]);

    const handleSelect = (val: any) => {
        onChange(val);
        setIsOpen(false);

        if(val === value) {
            setText(displayValue);
        }
    }


    useEffect(() => {
        if(displayValue) {
            setText(displayValue);
        }
    }, [displayValue]);

    const desktopContent = (
        <div>
            {
                tripType === 'road' ? (
                    <SelectRoadSearches
                        onSelect={handleSelect}
                        searchText={searchText}
                        height={popOverHeight}
                        limitSuggestionHeight={limitSuggestionHeight}
                    />
                ) : (
                    <SelectSearches
                        onSelect={handleSelect}
                        searchText={searchText}
                        height={popOverHeight}
                        limitSuggestionHeight={limitSuggestionHeight}
                    />
                )
            }
        </div>
    );

    const calculateDistance = () => {
        const element = popoverContainerRef.current as HTMLElement;
        const rect = element.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
        const distanceToBottom = viewportHeight - rect.bottom;

        let appliedPopoverHeight = popOverHeight;
        if(distanceToBottom >= 300){
            appliedPopoverHeight = 300;
        } else {
            appliedPopoverHeight = distanceToBottom - 100;
        }

        appliedPopoverHeight = appliedPopoverHeight < 100 ? 100 : appliedPopoverHeight;

        setPopOverHeight(appliedPopoverHeight);
    }

    useEffect(() => {
        window.addEventListener('resize', calculateDistance);
        window.addEventListener('scroll', calculateDistance);

        return () => {
            window.removeEventListener('resize', calculateDistance);
            window.removeEventListener('scroll', calculateDistance);
        };
    }, []);

    useEffect(() => {
        calculateDistance();
    }, []);

    return (
        <div>
            <DesktopPopoverWrapper ref={popoverContainerRef}>
                <Popover
                    placement="bottomLeft"
                    content={desktopContent}
                    overlayStyle={{ minWidth: '450px' }}
                    open={isOpen}
                    getPopupContainer={() => popoverContainerRef.current as HTMLElement}
                    onOpenChange={(open) => setIsOpen(open)}
                >
                    <div>
                        <StyledInput
                            icon={renderIcon()}
                            label={label}
                            value={text}
                            onChange={(val) => {
                                setText(val);
                                debouncedSetSearchText(val);
                            }}
                            defaultValue={value}
                        />
                    </div>
                </Popover>
            </DesktopPopoverWrapper>
        </div>
    );
};

DesktopSelector.defaultProps = {
    placeholder: undefined,
    defaultValue: undefined,
    suffixIcon: undefined,
    value: undefined,
    onChange: undefined,
};

export default DesktopSelector;
