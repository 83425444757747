import React from 'react';
import {
    AirportFieldsWrapper,
    DepartureWrapper,
    FormCard,
    FormWrapper, SwitcherWrapper,
    TripRadioGroupWrapper
} from "../../FlightSearch/styles";
import {Button, Col, Form, Row} from "antd";
import StyledRadioGroup from "../../CustomAntdComponents/StyledRadioGroup";
import translations from "../../../screens/Search/translations";
import LocationSelector from "../../RouteSelector";
import {ReactComponent as DirectionIcon} from "../../../assets/images/direction-hr.svg";
import {validateRoundTripDates} from "../../FlightSearch/helper";
import StyledDateRangePicker from "../../CustomAntdComponents/StyledDateRangePicker/DateRangePicker";
import {getDisabledDateRange} from "../../../utils/helpers";
import StyledDatePicker from "../../CustomAntdComponents/StyledDatePicker/DatePicker";
import {useI18nContext} from "../../../utils/I18n";
import {tripModes} from "../../../screens/Search/constant";
import {Moment} from "moment";
import {useRoadSearchStore} from "../../../store/roadSearch/roadSearchStore";
import {getSelectOptions} from "../helper";
import {useStore} from "../../../store/global/appStore";

type Props = {
    form: any;
}
const RoadSearchForm = ({ form }: Props) => {
    const {
        state: { t },
    } = useI18nContext();

    const {
        formData: {
            TripType, DepartureId, ArrivalId, DepartureDate, ArrivalDate,
        }, updateFormData,
    } = useRoadSearchStore();
    const {
        roadRoutes,
    } = useStore();

    const tripsMode =  tripModes(t(translations));

    const onFormInputChange = (value: any, name: string) => {
        updateFormData({ [name]: value });
    };

    const onDateRangeChange = (values: [Moment, Moment]) => {
        onFormInputChange(values[0], 'DepartureDate');
        onFormInputChange(values[1], 'ArrivalDate');
    };

    const handleSwitchLocation = () => {
        const temp = DepartureId;
        onFormInputChange(ArrivalId, 'DepartureId');
        onFormInputChange(temp, 'ArrivalId');

        updateFormData({
            DepartureId: ArrivalId,
            ArrivalId: temp,
        });

        form.setFieldsValue({
            DepartureId: ArrivalId,
            ArrivalId: DepartureId,
        });
    }

    const isRoundTrip = TripType === 'round_trip';
    const departureRouteOptions = getSelectOptions(roadRoutes.filter((route) => route.IsDeparture));
    const arrivalRouteOptions = getSelectOptions(roadRoutes.filter((route) => route.IsArrival));

    const validateDestination = (_: any, value: any) => {
        if (!value) {
            return Promise.reject(new Error(t(translations).validations.destination));
        }

        if (DepartureId === value) {
            return Promise.reject(new Error(t(translations).validations.invalidDestination));
        }

        return Promise.resolve();
    };


    return (
        <>
            <FormWrapper>
                <FormCard bustrip>
                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <TripRadioGroupWrapper>
                                <StyledRadioGroup options={tripsMode} value={TripType} onChange={(e) => onFormInputChange(e.target.value, 'TripType')} />
                            </TripRadioGroupWrapper>
                        </Col>

                        <Col span={16}>
                                <AirportFieldsWrapper>
                                    <Row gutter={[16, 0]}>
                                        <Col span={12}>
                                            <DepartureWrapper>
                                                <Form.Item
                                                    name="DepartureId"
                                                    rules={[{ required: true, message: t(translations).validations.departure }]}
                                                >
                                                    <LocationSelector
                                                        label={t(translations).inputs.fromLabel}
                                                        placeholder={t(translations).inputs.fromPlaceholder}
                                                        value={DepartureId}
                                                        options={departureRouteOptions}
                                                        icon="circle"
                                                        onChange={(value) => onFormInputChange(value, 'DepartureId')}
                                                        tripType="road"
                                                    />
                                                </Form.Item>
                                                <SwitcherWrapper onClick={handleSwitchLocation}>
                                                    <DirectionIcon />
                                                </SwitcherWrapper>
                                            </DepartureWrapper>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name="ArrivalId"
                                                rules={[
                                                    {
                                                        validator: validateDestination,
                                                    },
                                                ]}
                                            >
                                                <LocationSelector
                                                    label={t(translations).inputs.toLabel}
                                                    placeholder={t(translations).inputs.toPlaceholder}
                                                    value={ArrivalId}
                                                    options={arrivalRouteOptions}
                                                    icon="location"
                                                    onChange={(value) => onFormInputChange(value, 'ArrivalId')}
                                                    tripType="road"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </AirportFieldsWrapper>
                            </Col>

                        <Col span={8}>
                                {
                                    isRoundTrip ? (
                                        <Form.Item
                                            name="BusDateRange"
                                            rules={[
                                                { validator: validateRoundTripDates }
                                            ]}
                                        >
                                            <StyledDateRangePicker
                                                value={[DepartureDate, ArrivalDate]}
                                                onChange={onDateRangeChange}
                                                disabledDate={getDisabledDateRange}
                                                placeholder={[t(translations).inputs.departureDate, t(translations).inputs.arrivalDate]}
                                            />
                                        </Form.Item>
                                    ) : (
                                        <Form.Item
                                            name="DepartureDate"
                                            rules={[{ required: true, message: t(translations).validations.departureDate }]}
                                        >
                                            <StyledDatePicker
                                                label={t(translations).inputs.departureDate}
                                                value={DepartureDate}
                                                disabledDate={getDisabledDateRange}
                                                onChange={(value) => onFormInputChange(value, 'DepartureDate')}/>
                                        </Form.Item>
                                    )
                                }
                        </Col>

                        <Col
                            span={4}
                            offset={20}
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                block
                            >
                                {t(translations).searchRoadButton}
                            </Button>
                        </Col>
                    </Row>
                </FormCard>
            </FormWrapper>
        </>
    );
};

export default RoadSearchForm;
