import React, {useEffect, useState} from 'react';
import {
    BreakdownWrapper,
} from "./styles";
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import {formatNumberAsCurrency} from "../../utils/helpers";
import {SpaceBetweenAlignContent} from "../CustomAntdComponents/Utitlities";
import {useBookingStore} from "../../store/booking/bookingStore";
import {useRoadSearchStore} from "../../store/roadSearch/roadSearchStore";
import {calculateRoadFareBreakdown, calculateTotalFare} from "../AvailableBuses/helper";
import {calculateTicketTotal} from "../AvailableFlights/helper";

type Props = {
    hideBreakdown: boolean
}
const RoadFareBreakdown = ({ hideBreakdown } : Props) => {
    const {
        formData: {
            numberOfAdults,
        },
        selectedBus,
    } = useRoadSearchStore();

    const {
        bookingPayload,
    } = useBookingStore();

    const { totalAmount } = calculateTotalFare(selectedBus, numberOfAdults);
    const paxFares = calculateRoadFareBreakdown(selectedBus)
    const bookingAmount = bookingPayload ? calculateTicketTotal(bookingPayload.TicketingDetails) : totalAmount;
    const isRoundTripFares = paxFares.length > 1;

    return <div>
        {
            !hideBreakdown && (
                <BreakdownWrapper>
                    {
                        isRoundTripFares ? (
                            paxFares.map((f, key) => (
                                <div style={{ marginBottom: "5px"}}>
                                    <StyledParagraph size="small">
                                        {key === 0 ? "Departure" : "Return"}
                                    </StyledParagraph>
                                    <SpaceBetweenAlignContent key={key}>
                                        <StyledParagraph style={{ fontSize: "14px" }}>
                                            Passenger &nbsp;x&nbsp; {numberOfAdults}
                                        </StyledParagraph>

                                        <StyledTitle level={5} style={{ color: "#434455" }}>
                                            {formatNumberAsCurrency((f.TotalFare * numberOfAdults) || 0, selectedBus[0]?.Currency)}
                                        </StyledTitle>
                                    </SpaceBetweenAlignContent>
                                </div>
                            ))
                        ) : (
                            paxFares.map((f, key) => (
                                <SpaceBetweenAlignContent key={key}>
                                    <StyledParagraph style={{ fontSize: "14px" }}>
                                        Passenger &nbsp;x&nbsp; {numberOfAdults}
                                    </StyledParagraph>

                                    <StyledTitle level={5} style={{ color: "#434455" }}>
                                        {formatNumberAsCurrency((f.TotalFare * numberOfAdults) || 0, selectedBus[0]?.Currency)}
                                    </StyledTitle>
                                </SpaceBetweenAlignContent>
                            ))
                        )
                    }
                </BreakdownWrapper>
            )
        }

        <SpaceBetweenAlignContent>
            <StyledParagraph size="default">
                Total for all passengers
            </StyledParagraph>

            <StyledTitle level={3}>
                {formatNumberAsCurrency(bookingAmount || 0, selectedBus[0]?.Currency)}
            </StyledTitle>
        </SpaceBetweenAlignContent>
    </div>
};

export default RoadFareBreakdown;
