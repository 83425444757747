import React, {ReactNode} from 'react';
import useIsMobile from "../../hooks/useIsMobile";
import { ReactComponent as FlightIcon } from "../../assets/images/flight-takeoff.svg";
import { ReactComponent as FlightLandIcon } from "../../assets/images/flight-land.svg";
import { ReactComponent as LocationIcon } from '../../assets/images/location.svg';
import { ReactComponent as CircleIcon } from '../../assets/images/circle.svg';
import DesktopSelector from "./DesktopSelector";
import MobileRouteSelector from "./MobileSelector";

export type SuggestionType = {
    name: string;
    label: string;
    code?: string;
    value: string;
}

type Props = {
    label: string,
    placeholder?: string,
    defaultValue?: string,
    options: SelectOption[],
    onChange?: (value: any) => void;
    suffixIcon?: ReactNode,
    value?: string | number,
    icon: 'takeoff' | 'land' | 'circle' | 'location'
    tripType: 'flight' | 'road',
    limitSuggestionHeight?: boolean
}

const RouteSelector = ({ label, placeholder, value, options, onChange, icon, tripType, limitSuggestionHeight }: Props) => {
    const { isMobile } = useIsMobile();

    const renderIcon = (): ReactNode => {
        if (icon === 'takeoff') {
            return <FlightIcon />;
        } else if (icon === 'land') {
            return <FlightLandIcon />;
        }  else if (icon === 'location') {
            return <LocationIcon />;
        }  else if (icon === 'circle') {
            return <CircleIcon />;
        } else {
            return <FlightIcon />;
        }
    }

    return isMobile ? (
        <MobileRouteSelector
            label={label}
            placeholder={placeholder}
            value={value}
            options={options}
            onChange={onChange}
            renderIcon={renderIcon}
            tripType={tripType}
        />
        ) : (
        <DesktopSelector
            label={label}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            renderIcon={renderIcon}
            tripType={tripType}
            limitSuggestionHeight={limitSuggestionHeight}
        />
    )
};

RouteSelector.defaultProps = {
    placeholder: undefined,
    defaultValue: undefined,
    suffixIcon: undefined,
    value: undefined,
    onChange: undefined,
};

export default RouteSelector;
