import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled, { css } from "styled-components";
import flightIcon from '../../assets/images/flight-takeoff-grey.svg';
import busIcon from '../../assets/images/bus-grey.svg';
import { GREY_3, GREY_6, GREY_7, WHITE } from "../../utils/colors";
import { getExtraData } from '../../utils/helpers';

function FlightSearchMobileNav() {
    const { tripType } = useParams();
    const extraData = getExtraData();
    const [visibleTripMode, setVisibleTripsMode] = useState({
        isAirVisible: false,
        isRoadVisible: false,
        isRailVisible: false,
        isWaterVisible: false,
    });


    const getVisibleTransportModes = useCallback(() => {
        const isAirVisible = extraData && extraData?.TransportMode?.find((item: TransportMode) => item.TransportModeName === 'Air')?.Status;
        const isRoadVisible = extraData && extraData?.TransportMode?.find((item: TransportMode) => item.TransportModeName === 'Road')?.Status;
        const isRailVisible = extraData && extraData?.TransportMode?.find((item: TransportMode) => item.TransportModeName === 'Rail')?.Status;
        const isWaterVisible = extraData && extraData?.TransportMode?.find((item: TransportMode) => item.TransportModeName === 'Water')?.Status;

        

        setVisibleTripsMode({
            ...visibleTripMode,
            isAirVisible,
            isRoadVisible,
            isRailVisible,
            isWaterVisible,
        });

    }, [setVisibleTripsMode]);

    useEffect(() => {
        getVisibleTransportModes();
    }, [getVisibleTransportModes]);

    return (
        <Wrapper>
            {visibleTripMode.isAirVisible && (
                <IconContainer active={tripType !== 'road'}>
                    <Link to="/search/flight">
                        <IconWrapper>
                            <img src={flightIcon} alt="flight-icon" />
                        </IconWrapper>
                    </Link>
                </IconContainer>
            )}
            {visibleTripMode.isRoadVisible && (
                <IconContainer active={tripType === 'road'}>
                    <Link to="/search/road">
                        <IconWrapper>
                            <img src={busIcon} alt="bus-icon" />
                        </IconWrapper>
                    </Link>
                </IconContainer>
            )}
        </Wrapper>
    );
}

export default FlightSearchMobileNav;

const Wrapper = styled.div`
  background: #ffffff;
  display: flex;
  column-gap: 10px;
  margin-top: 10px;
`;

const IconContainer = styled.div<{ active?: boolean }>`
   padding: 12px;

    ${({ active }) => active && css`
        background: ${GREY_3};
        border-radius: 12px 12px 0 0;
      border-left: solid 1px ${GREY_7};
      border-right: solid 1px ${GREY_7};
      border-top: solid 1px ${GREY_7};
      margin-bottom: -1px;
    `}
`;

const IconWrapper = styled.div`
  width: 52px;
  height: 52px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${WHITE};
  border: 1px solid ${GREY_6};
`;
