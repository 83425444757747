import React, {useEffect, useRef, useState} from 'react';
import {
  Button, Col, Drawer, Row, Checkbox
} from 'antd';
import {
  ActionWrapper,
  ActionContentWrapper, DesktopContentWrapper, MobileContentWrapper,
  TermsWrapper, MobileContentContainer,
} from './styles';
import {StyledParagraph, StyledTitle} from '../../components/CustomAntdComponents/Typography';
import {
  useFlightSearchStore,
} from '../../store/flightSearch/flightSearchStore';
import {
  formatNumberAsCurrency,
} from '../../utils/helpers';
import {
  calculateFlightTotalFare, calculateTicketTotal,
} from '../../components/AvailableFlights/helper';
import { useBookingStore } from '../../store/booking/bookingStore';
import FlightPaymentBreakdown from "../../components/FlightItinerary/FlightPaymentBreakdown";
import {SectionCard, SectionCardBody, SectionCardHeading} from "../PassengerDetails/styles";
import {ReactComponent as ArrowRightIcon} from "../../assets/images/caret-right.svg";
import FlightItinerarySummary from "../../components/FlightItinerary/FlightItinerarySummary";
import useIsMobile from "../../hooks/useIsMobile";
import PassengerList from "../../components/PassengerList";
import BookingContact from "../../components/BookingContact";
import {ActionButtonWrapper} from "../../components/FlightItinerary/styles";
import FlightBookingSummary from "../../components/FlightItinerary/FlightBookingSummary";
import FareRule from "../../components/FareRule";

type Props = {
  handlePayment: () => void;
}

function FlightPayment({ handlePayment }: Props) {
  const {
    selectedFlight,
    formData: {
      isDomestic,
      fareRuleAccepted
    },
  } = useFlightSearchStore();
  const [accept, setAccept] = useState(Boolean(fareRuleAccepted));
  const { isMobile } = useIsMobile();
  const drawerContainerRef = useRef(null);

  const {
    bookingPayload
  } = useBookingStore();

  useEffect(() => {
    setAccept(fareRuleAccepted);
  }, [fareRuleAccepted]);

  const handleChange = (e) => {
    setAccept(e.target.checked)
  }
  const { totalTicketAmount } = calculateFlightTotalFare(selectedFlight, isDomestic);
  // Use booking ticket amount to cater for promo discount
  const bookingAmount = bookingPayload ? calculateTicketTotal(bookingPayload.TicketingDetails) : totalTicketAmount

  return (
      <div ref={drawerContainerRef}>

        {
          isMobile ? (
              <Row>
                <Col xs={24} lg={0}>
                  <MobileContentContainer>
                    <MobileContentWrapper>
                      <div style={{ marginBottom: "20px" }}>
                        <StyledParagraph size="default">
                          Booking summary
                        </StyledParagraph>
                      </div>

                      <FlightBookingSummary />
                    </MobileContentWrapper>

                    <ActionWrapper>
                      <TermsWrapper>
                          <Checkbox onChange={handleChange} checked={accept}>
                              I have read and agree to the terms and condition within the <FareRule inline />
                          </Checkbox>
                      </TermsWrapper>
                      <ActionContentWrapper>
                        <div>
                          <StyledParagraph>
                            Total fare
                          </StyledParagraph>
                          <StyledTitle level={3}>
                            {formatNumberAsCurrency(bookingAmount || 0, selectedFlight[0]?.Currency)}
                            &nbsp;
                          </StyledTitle>
                        </div>
                        <ActionButtonWrapper>
                          <Button block size="large" type="primary" htmlType="submit" disabled={!accept} onClick={handlePayment}>
                            Make payment
                          </Button>
                        </ActionButtonWrapper>
                      </ActionContentWrapper>
                    </ActionWrapper>
                  </MobileContentContainer>
                </Col>
              </Row>
          ) : (
              <Row>
                <Col xs={0} lg={24}>
                  <DesktopContentWrapper className="container">
                    <div style={{ marginBottom: "30px" }}>
                      <StyledTitle level={3}>
                        Booking summary
                      </StyledTitle>
                    </div>
                    <Row>
                      <Col span={12}>
                        <SectionCard>
                          <SectionCardHeading bordered>
                            <StyledTitle level={4}>
                              Trip Itinerary
                            </StyledTitle>
                          </SectionCardHeading>
                          <SectionCardBody>
                            <FlightItinerarySummary />
                          </SectionCardBody>
                        </SectionCard>

                        <SectionCard>
                          <SectionCardHeading>
                            <StyledTitle level={4}>
                              Passenger information
                            </StyledTitle>
                          </SectionCardHeading>
                          <SectionCardBody>
                            <PassengerList />
                          </SectionCardBody>
                        </SectionCard>

                        <SectionCard>
                          <SectionCardHeading>
                            <StyledTitle level={4}>
                              Contact details
                            </StyledTitle>
                          </SectionCardHeading>
                          <SectionCardBody>
                            <BookingContact />
                          </SectionCardBody>
                        </SectionCard>
                      </Col>

                      <Col offset={4} span={8}>
                        <FlightPaymentBreakdown proceedToPayment={handlePayment} />
                      </Col>
                    </Row>
                  </DesktopContentWrapper>
                </Col>
              </Row>
          )
        }
      </div>
  );
}

export default FlightPayment;
