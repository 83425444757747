import React from 'react';
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import {
    CommentInputWrapper,
    ContentWrapper,
    HeadingWrapper,
    Option, OptionGroupWrapper,
    OptionsWrapper,
    ReactionItem,
    ReactionsWrapper,
    Wrapper
} from "./styles";
import NeutralFace from "../../../src/assets/images/reactions/neutral-face.svg";
import SmilingFace  from "../../../src/assets/images/reactions/smiling-face.svg";
import GrinningFace from "../../../src/assets/images/reactions/grinning-face-with-smiling-eyes.svg";
import {EXCELLENT_REACTION, FAIR_REACTION, GOOD_REACTION} from "../BookingFeedback/constant";
import StyledTextarea from "../CustomAntdComponents/StyledTextarea";

type Props = {
    reaction: string;
}

const options = [
    {
        label: 'Prices were fair',
        value: 'price'
    },
    {
        label: 'No hidden fees',
        value: 'hiddenFees'
    },
    {
        label: 'It was easy to book',
        value: 'easyToBook'
    },
    {
        label: 'Fast loading time',
        value: 'fastLoadingTime'
    }
]

const improvementOptions = [
    {
        label: 'Loading time',
        value: 'loadingTime'
    },
    {
        label: 'Available inventory',
        value: 'inventory'
    },
    {
        label: 'Difficult to book',
        value: 'difficultToBook'
    },
    {
        label: 'Unclear policy',
        value: 'unclearPolicy'
    }
];

const FeedbackForm = ({ reaction }: Props) => {
    const [selectedOption, setSelectedOption] = React.useState<string>("price");
    const [selectedImprovement, setSelectedImprovement] = React.useState<string>("loadingTime");

    const renderReactionIcon = () => {
       switch (reaction) {
              case GOOD_REACTION:
                    return <img src={SmilingFace} alt={reaction} />;
           case FAIR_REACTION:
               return <img src={NeutralFace} alt={reaction} />;
           case EXCELLENT_REACTION:
               return <img src={GrinningFace} alt={reaction} />;
           default:
               return <img src={NeutralFace} alt={reaction} />;

       }
    }

    return (
        <Wrapper>
            <HeadingWrapper>
                <StyledTitle level={4} align="center">
                    Your experience with Trips
                </StyledTitle>
                <ReactionsWrapper>
                    <ReactionItem>
                        {renderReactionIcon()}
                        <StyledParagraph size="normal">
                            {reaction}
                        </StyledParagraph>
                    </ReactionItem>
                </ReactionsWrapper>
            </HeadingWrapper>

            <ContentWrapper>
                <OptionGroupWrapper>
                    <StyledTitle level={4}>
                        What did Trips do right?
                    </StyledTitle>

                    <OptionsWrapper>
                        {
                            options.map((option, index) => (
                                <Option active={selectedOption === option.value} onClick={() => setSelectedOption(option.value)} key={index}>
                                    <StyledParagraph>
                                        {option.label}
                                    </StyledParagraph>
                                </Option>
                            ))
                        }
                    </OptionsWrapper>
                </OptionGroupWrapper>
                <OptionGroupWrapper>
                    <StyledTitle level={4}>
                        What could Trips improve on?
                    </StyledTitle>

                    <OptionsWrapper>
                        {
                            improvementOptions.map((option, index) => (
                                <Option active={selectedImprovement === option.value} onClick={() => setSelectedImprovement(option.value)} key={index}>
                                    <StyledParagraph>
                                        {option.label}
                                    </StyledParagraph>
                                </Option>
                            ))
                        }
                    </OptionsWrapper>
                </OptionGroupWrapper>

                <CommentInputWrapper>
                    <StyledTitle level={4}>
                        Anything else to add? (optional)
                    </StyledTitle>

                    <StyledTextarea rows={4} placeholder="Enter your feedback here"/>

                    <StyledParagraph size="normal">
                        Your feedback means the world to us. It helps us to improve how we serve you.
                    </StyledParagraph>
                </CommentInputWrapper>
            </ContentWrapper>
        </Wrapper>
    );
};

export default FeedbackForm;
