import React, { ReactNode } from 'react';
import {
  IconWrapper, StyledParagraph, StyledTitle, Wrapper,
} from './styles';

type Props = {
  bordered?: boolean,
  Icon: ReactNode,
  title: string,
  messages?: {
    key: number,
    node: ReactNode
  }[],
  children?: ReactNode
}
function ModalContent({
  Icon, title, messages, bordered, children,
}: Props) {
  return (
    <Wrapper>
      <IconWrapper bordered={bordered}>
        {Icon}
      </IconWrapper>

      <StyledTitle level={3}>
        {title}
      </StyledTitle>

      {
        messages && messages.map((message) => (
          <StyledParagraph key={message.key}>
            {message.node}
          </StyledParagraph>
        ))
      }

      {children && children}
    </Wrapper>
  );
}

ModalContent.defaultProps = {
  bordered: false,
  messages: undefined,
  children: undefined,
};
export default ModalContent;
