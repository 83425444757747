export default {
  en: {
    success: 'Transaction successful',
    amount: 'Transaction Amount',
    ref: 'Transaction Ref',
    status: 'Transaction Status',
    date: 'Transaction Date',
    successful: 'Successful',
    successText: 'Your ticket will be sent to your email address',
    downloadText: 'Download Receipt',
    done: 'Done',
    noData: 'No Data',
  },
  fr: {
    success: 'Transaction réussie',
    amount: 'Montant de la transaction',
    ref: 'Réf. de la transaction',
    status: 'Statut de la transaction',
    date: 'Date de la transaction',
    successful: 'Succès',
    successText: 'Votre ticket sera envoyé à votre adresse électronique',
    downloadText: 'Télécharger le reçu',
    done: 'Terminé',
    noData: 'Pas de données',
  },
  sp: {
    success: 'Transacción realizada con éxito',
    amount: 'Importe de la transacción',
    ref: 'Referencia de la transacción',
    status: 'Estado de la transacción',
    date: 'Fecha de la transacción',
    successful: 'Realizado con éxito',
    successText: 'Su ticket será enviado a su dirección de correo electrónico',
    downloadText: 'Descargar el recibo',
    done: 'Hecho',
    noData: 'No hay datos',
  },
  sw: {
    success: 'Muamala umefanikiwa',
    amount: 'Malipo ya Muamala',
    ref: 'Ref ya Muamala',
    status: 'Hali ya Muamala',
    date: 'Tarehe ya muamala',
    successful: 'Imefanikiwa',
    successText: 'Tikiti yako itatumwa kwa barua pepe yako',
    downloadText: 'Pakua Risiti',
    done: 'Imekamilika',
    noData: 'Hakuna Data',
  },
};
