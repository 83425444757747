import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Form,
} from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import { useStore } from '../../store/global/appStore';
import {generateMultiCityFlightRoutes, getAirportOptions} from './helper';
import {
  useFlightSearchStore,
} from '../../store/flightSearch/flightSearchStore';
import { getAPI } from '../../utils/api';
import {
  GET_AIRPORTS_API_URL,
  GET_INTERNATIONAL_AIRPORTS_API_URL,
} from '../../utils/constants';
import { tripModes, tripModesMultiCity } from '../../screens/Search/constant';
import translations from '../../screens/Search/translations';
import { useI18nContext } from '../../utils/I18n';
import { getDisabledDateRange, getExtraData, getStartOfDay } from '../../utils/helpers';
import FlightSearchMobileView from './FlighSearchMobileView';
import FlightSearchWebView from './FlightSearchWebView';
import useIsMobile from "../../hooks/useIsMobile";
import {FlightLocalStoreKey} from "../ContinueSearch/constant";
import {SAVED_SEARCH_LIMIT} from "./constant";

function FlightSearch() {
  const {
    state: { t },
  } = useI18nContext();
  const [checkReturnDate, setCheckReturnDate] = useState(false);
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();
  const { airports, setAirports } = useStore();
  const {
    formData, updateFormData,
  } = useFlightSearchStore();

  const [form] = Form.useForm();
  const extraData = getExtraData();
  const MultiCityMaxRoute = extraData.MultiCityMaxRoute ? extraData.MultiCityMaxRoute : 5;
  const allAirports = [...airports]
  const allAirportOptions = useMemo(() => getAirportOptions([...allAirports]),
      [airports]);

  const isRoundTrip = formData.tripType === 'round_trip';
  const isMultiCity = formData.tripType === 'multi_city';

  const getAirports = useCallback(async () => {
    const airportsApi = await getAPI(GET_AIRPORTS_API_URL);
    if(airportsApi.status === 200) {
      localStorage.setItem('airports', JSON.stringify(airportsApi.data));
      setAirports(airportsApi.data, 'airports');
    }
  }, []);

  const {
    FlightClass,
    DepartureDate,
    ReturnDate,
    tripType,
    numberOfAdults,
    DestinationLocationCode,
    OriginLocationCode,
    numberOfInfants,
    numberOfChildren,
    sameAirline,
    multiCity
  } = formData;

  const formInitialValues = {
    FlightClass,
    DepartureDate,
    ReturnDate,
    FlightDateRange: [DepartureDate, ReturnDate],
    tripType,
    numberOfAdults,
    DestinationLocationCode,
    OriginLocationCode,
    numberOfInfants,
    numberOfChildren,
    sameAirline,
    multiCity
  };

  const onFinish = (values) => {
    let flightSearchData = JSON.parse(localStorage.getItem(FlightLocalStoreKey) || '[]');
    if(flightSearchData.length >= SAVED_SEARCH_LIMIT) {
      flightSearchData = flightSearchData.slice(0, SAVED_SEARCH_LIMIT - 1);
    }


    if (isMultiCity) {
      const multiCityData = generateMultiCityFlightRoutes(values.trips);
      updateFormData({ multiCity: multiCityData, isDomestic: false });
      flightSearchData.unshift({ ...formData, multiCity: multiCityData, isDomestic: false });
    } else {
      flightSearchData.unshift(formData);
    }

    // Save search data to local storage
    localStorage.setItem(FlightLocalStoreKey, JSON.stringify(flightSearchData));

    navigate('/search-listings');
  };

  useEffect(() => {
    getAirports();
  }, []);

  useEffect(() => {
    const origin = allAirports.find((airport) => airport.Code === formData.OriginLocationCode)
    const destination = allAirports.find((airport) => airport.Code === formData.DestinationLocationCode)

    if(!origin || !destination) return;
    const isDomestic =  origin?.CountryCode === destination?.CountryCode;

    updateFormData({ isDomestic });
  }, [airports, formData.OriginLocationCode, formData.DestinationLocationCode]);

  const onFormInputChange = (value: any, name: string) => {
    updateFormData({ [name]: value });
  };

  const onDateInputChange = (value: any, name: string) => {
    updateFormData({ [name]: value });
  };

  const checkReturningDate = (_: any, value: any) => {
    if (!isRoundTrip || !formData.DepartureDate) {
      return Promise.resolve();
    }

    if (!value) {
      return Promise.reject(new Error(t(translations).validations.destinationDate));
    }

    if (value.isBefore(formData.DepartureDate)) {
      return Promise.reject(new Error(t(translations).validations.invalidDestinationDate));
    }
    return Promise.resolve();
  };

  const validateDestination = (_: any, value: any) => {
    if (!value) {
      return Promise.reject(new Error(t(translations).validations.destination));
    }

    if (formData.OriginLocationCode === value) {
      return Promise.reject(new Error(t(translations).validations.invalidDestination));
    }

    return Promise.resolve();
  };

  const validateDeparture = (_: any, value: any) => {
    if (!value) {
      return Promise.reject(new Error(t(translations).validations.departure));
    }

    if (!formData.DestinationLocationCode) {
        return Promise.resolve();
    }

    if (formData.DestinationLocationCode === value) {
      return Promise.reject(new Error(t(translations).validations.invalidDeparture));
    }

    return Promise.resolve();
  };

  const disabledReturnDate: RangePickerProps['disabledDate'] = (current) => getDisabledDateRange(current, getStartOfDay(formData.DepartureDate));

  useEffect(() => {
    setCheckReturnDate(isRoundTrip);
  }, [isRoundTrip, setCheckReturnDate]);

  useEffect(() => {
    form.validateFields(['ReturnDate']);
  }, [checkReturnDate, form]);

  const flightModes = formData.isDomestic ? tripModes(t(translations)) : tripModesMultiCity(t(translations));
  const handleSwitchAirports = () => {
    const temp = formData.OriginLocationCode;
    onFormInputChange(formData.DestinationLocationCode, 'OriginLocationCode');
    onFormInputChange(temp, 'DestinationLocationCode');

    form.setFieldsValue({
      OriginLocationCode: formData.DestinationLocationCode,
      DestinationLocationCode: formData.OriginLocationCode,
    });
  }

  return (
    <div>
      <Form
          wrapperCol={{ span: 24 }}
          layout="horizontal"
          initialValues={formInitialValues}
          form={form}
          onFinish={onFinish}
          size="large"
      >
      {isMobile ? (
        <FlightSearchMobileView
          flightModes={flightModes}
          onFinish={onFinish}
          onFormInputChange={onFormInputChange}
          isMultiCity={isMultiCity}
          isRoundTrip={isRoundTrip}
          airportOptions={allAirportOptions}
          checkReturningDate={checkReturningDate}
          disabledReturnDate={disabledReturnDate}
          validateDestination={validateDestination}
          validateDeparture={validateDeparture}
          onDateInputChange={onDateInputChange}
          formData={formData}
          MultiCityMaxRoute={MultiCityMaxRoute}
          handleSwitchAirports={handleSwitchAirports}
          form={form}
        />
      ) : (
          <FlightSearchWebView
              onFinish={onFinish}
              isMultiCity={isMultiCity}
              isRoundTrip={isRoundTrip}
              airportOptions={allAirportOptions}
              checkReturningDate={checkReturningDate}
              disabledReturnDate={disabledReturnDate}
              validateDestination={validateDestination}
              validateDeparture={validateDeparture}
              MultiCityMaxRoute={MultiCityMaxRoute}
              handleSwitchAirports={handleSwitchAirports}
              initialValues={formInitialValues}
              form={form}
          />
      )}
      </Form>
    </div>
  );
}

export default FlightSearch;
