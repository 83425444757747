/* eslint-disable no-tabs */
import React from 'react';
import {
  Form, Row, Col,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import { generateFlightSearchInputs } from '../helper';
import translations from '../../../screens/Search/translations';
import { useI18nContext } from '../../../utils/I18n';
import { FlightSearchForm } from './MulticityFlightSearchForm';
import {AddItem, MultiCityFormWrapper} from "./styles";

type MulticityFlightSearchFormProps = {
    airportOptions: SelectOption[];
    MultiCityMaxRoute: number;
    form: any;
    limitSuggestionHeight?: boolean;
}
export function MulticityFlightSearchForm(props: MulticityFlightSearchFormProps) {
  const {
    airportOptions,
    MultiCityMaxRoute,
    form,
      limitSuggestionHeight
  } = props;

  const {
    state: { t },
  } = useI18nContext();

  const multiCities = form.getFieldValue('multiCity');
  const initialValue = multiCities?.length > 0
    ? multiCities.map((item) => ({ ...item, DepartureDate: moment(item.DepartureDate) })) : generateFlightSearchInputs(2);

  return (
    <Col span={24}>
        <Row gutter={8}>
          <Form.List name="trips" initialValue={initialValue}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Col xs={24} lg={24} key={`itemWrapper${field.key}`}>
                    <Row>
                      {field.name > 1 && (
                        <Col>
                          {' '}
                          <CloseOutlined onClick={() => remove(field.name)} key={`outline${field.key}`} />
                        </Col>
                      ) }

                    </Row>
                    <MultiCityFormWrapper>
                      <FlightSearchForm
                          airportOptions={airportOptions}
                          field={field}
                          key={field.key}
                          form={form}
                          limitSuggestionHeight={limitSuggestionHeight}
                      />
                    </MultiCityFormWrapper>
                  </Col>
                ))}

                <Col xs={24} lg={24}>
                  <Form.Item>
                    <AddItem
                        onClick={add}
                        disabled={fields.length >= MultiCityMaxRoute}
                    >
                      +
                      {' '}
                      {t(translations).addNewButton}
                    </AddItem>
                  </Form.Item>
                </Col>
              </>
            )}
          </Form.List>
        </Row>
    </Col>
  );
}
