import React, { useRef } from 'react';
import {
  Drawer, DrawerProps,
} from 'antd';
import { Wrapper } from './style';
import { CloseIconDrawer } from '../../../screens/Home/styles';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';

type Props = {
  children: React.ReactNode,
  onClose: () => void,
  fullHeight?: boolean,
} & DrawerProps

function StyledDrawer({
  children, open, footer, onClose, height, title, fullHeight
}: Props) {
  const popoverContainerRef = useRef(null);

  const CloseIconComponent = (
    <CloseIconDrawer>
      {' '}
      <CloseIcon />
      {' '}
    </CloseIconDrawer>
  );

  const getContainer = () => popoverContainerRef.current as unknown as HTMLElement;

  return (
    <Wrapper ref={popoverContainerRef} fullHeight={fullHeight}>
      <Drawer
        title={title}
        placement="bottom"
        closable
        onClose={onClose}
        open={open}
        closeIcon={CloseIconComponent}
        key="bottom"
        className="trips-drawer"
        getContainer={getContainer()}
        footer={footer}
        height={height}
      >
        {children}
      </Drawer>
    </Wrapper>
  );
}

StyledDrawer.defaultProps = {
  height: 378,
  footer: null,
};

export default StyledDrawer;
