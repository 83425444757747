import styled from 'styled-components';
import device from '../../utils/mediaQuery';

export const ContentWrapper = styled.div`
  padding: 0 20px;

  @media ${device.large} {
    padding: 0 0 20px;
  }
`;
