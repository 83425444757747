import React, {useState, useEffect, useMemo} from 'react';
import { Row, Col } from 'antd';
import StyledSelect from "../CustomAntdComponents/StyledSelect";
import {StyledParagraph} from "../CustomAntdComponents/Typography";
import {getDayOptions, getMonthOptions, getYearOptions} from "./helper";
import moment from "moment";
import type { Moment } from 'moment';
import styled, { css  } from "styled-components";

type Props = {
    label: string,
    onChange: (date: Moment) => void,
    value?: Moment,
    startYearIndex?: number,
}
const CustomDatePicker = ({ onChange, value, label, startYearIndex }: Props) => {
    const [year, setYear] = useState<string>();
    const [month, setMonth] = useState<string>();
    const [day, setDay] = useState<string>();
    const [hasError, setHasError] = useState<boolean>(false);

    const yearOptions = getYearOptions(startYearIndex);
    const monthOptions = getMonthOptions();
    const dayOptions = getDayOptions();

    useEffect(() => {
        if(year && month && day){
            const date = moment(`${year}-${month}-${day}`);
            if(date.isValid()){
                onChange(date);
                setHasError(false)
            } else {
                setHasError(true);
            }
        }
    }, [year, month, day]);

    useEffect(() => {
        if(value){
            setYear(value.year().toString());
            setMonth((value.month() + 1).toString().padStart(2, '0'));
            setDay(value.date().toString().padStart(2, '0'));
        }
    }, [value]);

    const defaultYear = useMemo(() => {
        return value ? value.year() : undefined;
    }, [value])

    const defaultMonth = useMemo(() => {
        return value ? value.month().toString().padStart(2, '0') : undefined;
    }, [value])

    const defaultDay = useMemo(() => {
        return value ? value.day().toString().padStart(2, '0') : undefined;
    }, [value])

    return (
        <Wrapper error={hasError}>
            <Row gutter={[16, 12]}>
                <Col span={24}>
                    <StyledParagraph>
                        {label}
                    </StyledParagraph>
                </Col>
                <Col span={8}>
                    <StyledSelect
                        options={dayOptions}
                        defaultValue={defaultDay}
                        value={day}
                        placeholder="Day"
                        showSearch
                        onChange={setDay}/>
                </Col>
                <Col span={8}>
                    <StyledSelect
                        options={monthOptions}
                        value={month}
                        defaultValue={defaultMonth}
                        placeholder="Month"
                        showSearch
                        onChange={setMonth} />
                </Col>
                <Col span={8}>
                    <StyledSelect
                        options={yearOptions}
                        value={year}
                        defaultValue={defaultYear}
                        placeholder="Year"
                        showSearch onChange={setYear} />
                </Col>
            </Row>
        </Wrapper>
    );
};

export const Wrapper = styled.div<{ error?: boolean }>`
  ${({ error }) => error && css`
      .ant-select {
          .ant-select-selector {
              border-color: #FF3B30 !important;
          }
      }
  `}
`;

export default CustomDatePicker;
