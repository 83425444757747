export const tripModes = (t: any) : DataOption[] => ([
  {
    id: 1,
    name: t.oneWay,
    value: 'one_way',
  },
  {
    id: 2,
    name: t.roundTrip,
    value: 'round_trip',
  },
]);
export const tripModesMultiCity = (t: any) : DataOption[] => ([
  {
    id: 1,
    name: t.oneWay,
    value: 'one_way',
  },
  {
    id: 2,
    name: t.roundTrip,
    value: 'round_trip',
  },
  {
    id: 3,
    name: t.multiCity,
    value: 'multi_city',
  },
]);

export const flightClassOptions = (t: any) : DataOption[] => ([
  {
    id: 1,
    name: t.economy,
    value: 'Economy',
  },
  {
    id: 2,
    name: t.businessClass,
    value: 'Business',
  },
  {
    id: 3,
    name: t.firstClass,
    value: 'First Class',
  },
]);
