import styled from 'styled-components';
import device from '../../utils/mediaQuery';
import {GREY, WHITE} from '../../utils/colors';

export const Wrapper = styled.section`
  padding: 20px;
  min-height: 68px;
  background: ${WHITE};


  @media ${device.large} {
    min-height: 78px;
    padding: 0;
  }
`;

export const BannerWrapper = styled.div<{ image: string }>`
  height: 500px;
  background-image: url(${({ image }) => image});
  background-color: ${GREY};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 15px;
  
  h1 {
    font-size: 55px;
  }
  .ant-typography {
    color: #fff;
  }
  
  .ant-btn {
    margin-top: 15px;
  }
`;

export const AvatarWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: solid 2px #EF8833;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.large} {
    justify-content: flex-end;
    column-gap: 20px;
  }
`;

export const UserDetails = styled.div`
  display: flex;
  align-items: center;
  
  h4 {
    margin: 0;
  }
`;

export const FormWrapper = styled.div`
  margin-top: 40px;
`;
