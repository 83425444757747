import React, {useMemo} from 'react';
import { Card, Space, Button } from 'antd';
import moment from 'moment';
import {
  StyledParagraph, StyledText,
} from '../CustomAntdComponents/Typography';
import {
  BillingDetailsWrapper,
  DetailsWrapper,
  HighlightDetailItem,
  TitleWrapper,
} from './styles';
import {
  SpaceBetweenAlignContent,
  VerticalAlignContent,
} from '../CustomAntdComponents/Utitlities';
import { ReactComponent as ClockIcon } from '../../assets/images/clock.svg';
import { formatNumberAsCurrency, pluralizeText } from '../../utils/helpers';
import { useRoadSearchStore } from '../../store/roadSearch/roadSearchStore';
import {
  calculateTotalFare,
  getRoadTransportLogo,
} from '../AvailableBuses/helper';
import RoadTripSummary from '../TripSummary/RoadTripSummary';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/SearchRoadListings/translations';

type Props = {
  showSubmitButton?: boolean,
}
function BusItinerary({ showSubmitButton }: Props) {
  const {
    state: { t },
  } = useI18nContext();
  const {
    selectedBus,
    selectedResult,
    formData,
  } = useRoadSearchStore();

  const oneWayTrip = formData.TripType === 'one_way';
  const totalFare = calculateTotalFare(selectedBus, formData.numberOfAdults);
  const isReturning = selectedBus?.length === 2;
  const activeBus = useMemo(() => {
    return isReturning ? selectedBus[1] : selectedBus[0];
  }, [selectedBus, isReturning]);


  return (
      <h2>
        Bus Itinerary
      </h2>
  );

  // const renderTripDetails = (returning = false) => ((selectedResult && selectedBus) ? (
  //   <>
  //     <Space direction="vertical" size="middle">
  //       <VerticalAlignContent>
  //         <img src={getRoadTransportLogo(selectedResult.AgentName)} alt="" />
  //         <StyledText color="secondary" size="small">
  //           {selectedResult.AgentName}
  //         </StyledText>
  //       </VerticalAlignContent>
  //
  //       <RoadTripSummary showChangeButton={false} returning={returning} itinerary />
  //     </Space>
  //
  //     <DetailsWrapper>
  //       <HighlightDetailItem>
  //         <SpaceBetweenAlignContent>
  //           <StyledText size="small">
  //             {
  //                 activeBus.EstimatedDepartureDate
  //               && moment(activeBus.EstimatedDepartureDate).format('hh:mm a')
  //             }
  //           </StyledText>
  //           <StyledText size="small" strong>
  //             {
  //                 activeBus.EstimatedArrivalDate && moment(activeBus.EstimatedArrivalDate).format('hh:mm a')
  //             }
  //           </StyledText>
  //         </SpaceBetweenAlignContent>
  //       </HighlightDetailItem>
  //
  //       <VerticalAlignContent>
  //         {selectedResult.Stops !== 0 && (
  //         <StyledText size="small">
  //           {pluralizeText(selectedResult.Stops, t(translations).stop, t(translations).stops)}
  //         </StyledText>
  //         ) }
  //
  //         {activeBus.Duration && (
  //         <VerticalAlignContent>
  //           <ClockIcon />
  //           <StyledText size="small">
  //             {activeBus.Duration}
  //           </StyledText>
  //         </VerticalAlignContent>
  //         )}
  //
  //       </VerticalAlignContent>
  //     </DetailsWrapper>
  //   </>
  // ) : (<div>&nbsp;</div>));
  //
  // return (
  //   <Space direction="vertical" size="middle">
  //     <Card size="default">
  //       <TitleWrapper>
  //         <StyledParagraph strong color="secondary">
  //           {t(translations).departure}
  //         </StyledParagraph>
  //       </TitleWrapper>
  //       {
  //         selectedResult && renderTripDetails()
  //       }
  //     </Card>
  //
  //     {/* Return Info not ready yet  */}
  //     {
  //       !oneWayTrip && selectedBus && (
  //       <Card size="default">
  //         <TitleWrapper>
  //           <StyledParagraph strong color="secondary">
  //             {t(translations).return}
  //           </StyledParagraph>
  //         </TitleWrapper>
  //
  //         {
  //           selectedBus && renderTripDetails(
  //             true,
  //           )
  //         }
  //       </Card>
  //       )
  //     }
  //
  //     {
  //       selectedBus && (
  //       <Card size="default">
  //         <TitleWrapper>
  //           <StyledParagraph strong color="secondary">
  //             {t(translations).billingBreakdown}
  //           </StyledParagraph>
  //         </TitleWrapper>
  //
  //         {/* TODO Differentiate departure and return fee */}
  //         <BillingDetailsWrapper>
  //           <Space direction="vertical" style={{ width: '100%' }}>
  //             <SpaceBetweenAlignContent>
  //               <StyledText size="small" color="secondary">
  //                 {t(translations).departureFare}
  //               </StyledText>
  //               <StyledText strong>
  //                 {formatNumberAsCurrency(totalFare.ticketAmount, activeBus.Currency)}
  //               </StyledText>
  //             </SpaceBetweenAlignContent>
  //
  //             {!oneWayTrip && (
  //             <SpaceBetweenAlignContent>
  //               <StyledText size="small" color="secondary">
  //                 {t(translations).returnFare}
  //               </StyledText>
  //               <StyledText strong>
  //                 {formatNumberAsCurrency(totalFare.ticketAmount, activeBus.Currency)}
  //               </StyledText>
  //             </SpaceBetweenAlignContent>
  //             )}
  //
  //             <SpaceBetweenAlignContent>
  //               <StyledText size="small" color="secondary">
  //                 {t(translations).serviceFee}
  //               </StyledText>
  //               <StyledText strong>
  //                 {formatNumberAsCurrency(totalFare.totalCharges, activeBus.Currency)}
  //               </StyledText>
  //             </SpaceBetweenAlignContent>
  //           </Space>
  //         </BillingDetailsWrapper>
  //
  //         <HighlightDetailItem>
  //           <SpaceBetweenAlignContent>
  //             <StyledText size="small" color="secondary">
  //               {t(translations).totalAmount}
  //             </StyledText>
  //             <StyledText strong>
  //               {formatNumberAsCurrency(totalFare.totalAmount, activeBus.Currency)}
  //             </StyledText>
  //           </SpaceBetweenAlignContent>
  //         </HighlightDetailItem>
  //         { showSubmitButton && (
  //           <Button type="primary" htmlType="submit" block>
  //             {t(translations).book}
  //           </Button>
  //         )}
  //
  //       </Card>
  //       )
  //     }
  //
  //   </Space>
  // );
}

BusItinerary.defaultProps = {
  showSubmitButton: true,
};
export default BusItinerary;
