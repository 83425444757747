import React, {useMemo, useEffect, useState} from 'react';
import { Progress } from 'antd';
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import {
    Content,
    InfoTextWrapper,
    ImageWrapper,
    InfoWrapper,
    TextWrapper,
    Wrapper,
    AirportInfo,
    AirportItem, BoxesWrapper, BoxItem
} from "./styles";
import { ReactComponent as LoadingIcon} from "../../assets/images/loader.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/images/arrow-right.svg";
import { ReactComponent as DirectionIcon } from "../../assets/images/direction-hr.svg";
import {useFlightSearchStore} from "../../store/flightSearch/flightSearchStore";
import {pluralizeText} from "../../utils/helpers";
import {useStore} from "../../store/global/appStore";
import {getAirportStateName} from "../TripSummary/helper";
import {PRIMARY} from "../../utils/colors";
import useIsMobile from "../../hooks/useIsMobile";

type Props = {
    booking?: boolean
}

const FlightRequestLoading = ({ booking }: Props) => {
    const [percent, setPercent] = useState<number>(5);
    const { isMobile } = useIsMobile();

    const { airports } = useStore();
    const {
        formData: {
            numberOfAdults, numberOfInfants, numberOfChildren, tripType,
            ReturnDate, DepartureDate, DestinationLocationCode, OriginLocationCode, FlightClass,
            multiCity
        },
    } = useFlightSearchStore();

    const numberOfPassengers = useMemo(() => {
        return numberOfAdults + (numberOfChildren || 0) + (numberOfInfants || 0);
    }, [numberOfAdults, numberOfChildren, numberOfInfants]);

    const isRoundTrip = tripType === 'round_trip';
    const isMultiCity = tripType === 'multi_city' && multiCity?.length > 0;
    const departure = isMultiCity ? multiCity[0].OriginLocationCode : OriginLocationCode;
    const arrival = isMultiCity ? multiCity[multiCity.length - 1].DestinationLocationCode : DestinationLocationCode;
    const departureDate = isMultiCity ? multiCity[0].DepartureDate : DepartureDate;
    const arrivalDate = isMultiCity ? multiCity[multiCity.length - 1].DepartureDate : ReturnDate;


    const dynamicProgress = () => {
        const newPercent = percent + 1;

        if(newPercent > 100){
            setPercent(60);
        } else {
            setPercent(newPercent)
        }
    }

    useEffect(() => {
        const intervalId =  setInterval(dynamicProgress, 1000)

        return () => clearInterval(intervalId);
    })

    return (
        <>
            {
                !isMobile && <Progress strokeColor={PRIMARY} percent={percent} type="line" showInfo={false} size="small" />
            }
            <Wrapper>
                <Content>
                    <InfoWrapper>
                        <AirportInfo>
                            <AirportItem>
                                <StyledParagraph size="small" align="left">
                                    {getAirportStateName(airports, departure)}
                                </StyledParagraph>
                                <StyledTitle>
                                    {departure}
                                </StyledTitle>
                            </AirportItem>

                            {
                                isRoundTrip ? <DirectionIcon /> : <RightArrowIcon />
                            }

                            <AirportItem>
                                <StyledParagraph size="small" align="left">
                                    {getAirportStateName(airports, arrival)}
                                </StyledParagraph>
                                <StyledTitle>
                                    {arrival}
                                </StyledTitle>
                            </AirportItem>
                        </AirportInfo>
                        <TextWrapper>
                            {(isRoundTrip || isMultiCity) && arrivalDate ? (
                                <StyledParagraph align="center">
                                    {departureDate.format("ddd, MMM D YYYY") + " - " + arrivalDate.format("ddd, MMM D YYYY")}
                                </StyledParagraph>
                            ) : (
                                <StyledParagraph align="center">
                                    {departureDate.format("ddd, MMM D YYYY")}
                                </StyledParagraph>
                            )}
                        </TextWrapper>

                        <TextWrapper>
                            <StyledParagraph align="center">
                                {`${pluralizeText(numberOfPassengers, "Passenger", "Passengers")}, ${FlightClass}`}
                            </StyledParagraph>
                        </TextWrapper>

                        <BoxesWrapper>
                            {new Array(30).fill(0).map((_, index) => (
                                <BoxItem key={index} index={index}>&nbsp;</BoxItem>
                            ))}
                        </BoxesWrapper>
                    </InfoWrapper>

                    <ImageWrapper>
                        <LoadingIcon />
                    </ImageWrapper>

                    <InfoTextWrapper>
                        <StyledParagraph size="large" align="center">
                            {
                                booking ? "We are confirming your booking..." : "We are searching for the best prices..."
                            }
                        </StyledParagraph>
                    </InfoTextWrapper>
                </Content>
            </Wrapper>
        </>
    );
};

export default FlightRequestLoading;
