import React, {useMemo, useState} from 'react';
import { Slider } from 'antd';
import type { SliderMarks } from 'antd/es/slider';
import { RangeSelectorWrapper } from '../styles';
import {StyledParagraph} from "../../CustomAntdComponents/Typography";

type Props = {
    onChange: (arg) => void,
    farePriceRange: PriceRangeType
}
function PriceRange({ onChange, farePriceRange }: Props) {
    const { min, max } = farePriceRange;
    const [minValue, setMinValue] = useState(min);
    const [maxValue, setMaxValue] = useState(max);
    const marks: SliderMarks = {
        [min]: `N${min.toLocaleString()}`,
        [max]: `N${max.toLocaleString()}`,
  };

  const handlePriceFilter = (value: number | [number, number]) => {
      setMinValue(value[0]);
      setMaxValue(value[1]);
      onChange(value);
  };

  const noRange = useMemo(() => min === max, [min, max]);

  return (
    <>
        <StyledParagraph color='primary' size="normal">
            {noRange ? `NGN ${minValue.toLocaleString()}` : `NGN ${minValue.toLocaleString()} - NGN ${maxValue.toLocaleString()}`}
        </StyledParagraph>
        {
            !noRange && (
                <RangeSelectorWrapper>
                    <Slider
                        range
                        tooltip={{
                            formatter: (value) => `NGN ${value.toLocaleString()}`
                        }}
                        marks={marks}
                        defaultValue={[min, max]}
                        min={min}
                        max={max}
                        onChange={handlePriceFilter}
                    />
                </RangeSelectorWrapper>
            )
        }
    </>
  );
}

export default PriceRange;
