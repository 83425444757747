import React, {useRef, useState} from 'react';
import {CustomWrapper} from "./styles";
import type {Moment} from "moment/moment";
import {DatePicker} from "antd";
import type {RangePickerProps} from "antd/es/date-picker";
import {SuffixWrapper} from "../StyledDatePicker/styles";
import { ReactComponent as CalendarIcon } from '../../../assets/images/calendar-grey.svg';

type Props = {
    onChange: (dates: [Moment, Moment]) => void,
} & RangePickerProps

const { RangePicker } = DatePicker;

const DateRangePicker = ({ placeholder, onChange, disabledDate, value }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const popoverContainerRef = useRef(null);

    const handleDateChange: RangePickerProps['onChange'] = (date, formatString) => {
        if (onChange) onChange(date);
    };

    const getContainer = () => popoverContainerRef.current as unknown as HTMLElement;

    const renderPrefix = () => {
        return <SuffixWrapper onClick={() => setIsOpen(true)}>
            <CalendarIcon />
        </SuffixWrapper>
    }

    return (
        <CustomWrapper ref={popoverContainerRef}>
            {renderPrefix()}
            <RangePicker
                open={isOpen}
                suffixIcon={null}
                disabledDate={disabledDate}
                value={value}
                onChange={handleDateChange}
                inputReadOnly
                getPopupContainer={getContainer}
                onOpenChange={(status) => setIsOpen(status)}
                placeholder={placeholder}
            />
        </CustomWrapper>
    );
};

export default DateRangePicker;
