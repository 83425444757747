import styled, { css } from 'styled-components';
import { Card } from 'antd';
import {
    GREY_3,
    GREY_5, GREY_7, PRIMARY, WHITE,
} from '../../utils/colors';
import device from '../../utils/mediaQuery';

export const TabWrapper = styled.div`
  display: flex;
`;

export const FormFieldsWrapper = styled.div`
  margin-top: 20px;

  .ant-form-item {
    margin-bottom:12px;
  }
  
  @media ${device.large} {
    margin-bottom: 24px;
  }
`;

export const AirportFieldsWrapper = styled.div`
  position: relative;
  .ant-form-item {
    margin-bottom: 4px;
    
    &:last-of-type {
        margin-bottom: 12px;
    }
  }

  @media ${device.large} {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
`;

export const SwitcherWrapper = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: ${GREY_3};
    border: solid 1px ${WHITE};
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 16px;
    top: 40px;
    z-index: 99;
    cursor: pointer;
  
    svg {
      path {
        fill: #15162B !important;
      }
    };
  
    @media ${device.large} {
      right: -23px;
      top: 7px;
    }
`;

export const DepartureWrapper = styled.div`
  position: relative;
`;

export const MobileFormContentWrapper = styled.div`
  border-radius: 24px;

  .as-label {
    background: #EEEEEE;
  }
`;

export const FormWrapper = styled.div``;
export const MobileFormContent = styled.div<{ bustrip?: boolean }>`
  background: ${GREY_3};
  padding: 12px;
  border: solid 1px ${GREY_7};
  border-radius: 0 12px 12px 12px;

   ${({ bustrip }) => bustrip && css`
        border-radius: 20px;
    `}
`;

export const TabItemWrapper = styled.div< { active: boolean }>`
  padding: 8px 10px;
  flex: 1;
  text-align: center;
  border-bottom: 1px solid transparent;
  cursor: pointer;

  transition: all 0.5s ease-in-out;
  
  .ant-typography {
    font-weight: 600;
  }
  
  ${({ active }) => active && css`
    border-bottom: 1px solid ${PRIMARY};
  `}
`;

export const InputPrepend = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-right: none;
  width: 35px;
  
  img {
    height: 18px;
  }

  transition: background 0.2s ease-in;
`;

export const StyledSelect = styled.div`
  .ant-select-selector {
    border-left: none !important;
  }
  
  &:hover {
    ${InputPrepend} {
      border-color: #223E7C;
    }
  }
`;

export const FormCard = styled(Card)<{ bustrip?: boolean }>`
  @media ${device.large} {
    padding: 0 20px 20px;
    border-radius: 0 20px 20px 20px;
    border: none;
    
    ${({ bustrip }) => bustrip && css`
        border-radius: 20px;
    `
  }
`;

export const FlexColumnItem = styled.div`
  flex: 1;
`;

export const FormDivider = styled.div`
  width: 1px;
  margin: 0 auto 0;
  height: 90%;
  background: ${GREY_5};
`;

export const TripRadioGroupWrapper = styled.div`
  margin-bottom: 15px;
`;
