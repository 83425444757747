import React, { useState, useEffect } from 'react';
import {
  Row, Col, Typography,
} from 'antd';
import { HeaderDivider, Wrapper } from './styles';
import PriceRange from './PriceRange';
import {
  FormWrapper,
  TitleWrapper,
} from '../../screens/SearchListings/styles';
// import StopOvers from './StopOvers';
import TransportCheckbox from './TransportCheckbox';
import { StyledHR } from '../CustomAntdComponents/Utitlities';
import StyledSelect from '../CustomAntdComponents/StyledSelect';
import { priceOptions } from './helper';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/SearchRailListings/translations';

const { Paragraph } = Typography;

type Props = {
  results?: RailSearchResult,
  handleTransportFiltering: (arg0: boolean, arg: number) => void,
  handleSortByPrice: (arg0: string | null) => void,
}

function RailListingFilter({
  results,
  handleTransportFiltering,
  handleSortByPrice,
}: Props) {
  const {
    state: { t },
  } = useI18nContext();
  const [transports, setTransports] = useState<Transport[]>([]);

  useEffect(() => {
    let uniqueTransports: Transport[] = [];

    if (results) {
      const tp: Transport[] = results.ResultList.map((br) => ({
        name: br.AgentName,
        code: br.AgentId,
      }));

      uniqueTransports = tp.filter((a, i) => tp.findIndex((s) => a.code === s.code) === i);

      setTransports([...uniqueTransports]);
    }
  }, [results]);

  const transportOptions = transports.map((transport) => ({
    label: transport.name,
    value: transport.code,
  }));

  return (
    <Wrapper>

      <TitleWrapper>
        <Paragraph strong>
          {t(translations).filterTitle}
        </Paragraph>
      </TitleWrapper>

      <Row>
        <Col xs={0} lg={24}>
          <HeaderDivider />
        </Col>
      </Row>

      <FormWrapper>
        <Row>
          <Col xs={24} lg={0}>
            <Row gutter={16}>
              <Col span={12}>
                <StyledSelect size="large" placeholder="Price" onChange={handleSortByPrice} options={priceOptions} />
              </Col>
              <Col span={12}>
                <StyledSelect
                  size="large"
                  placeholder={t(translations).transport}
                  onChange={(value) => handleTransportFiltering(true, value)}
                  options={[{ label: t(translations).transports, value: -1 }, ...transportOptions]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </FormWrapper>

      <Row>
        <Col xs={0} lg={24}>

          <PriceRange handleSortByPrice={handleSortByPrice} />

          <StyledHR size="large" />

          <TransportCheckbox transports={transports} handleTransportFiltering={handleTransportFiltering} />
        </Col>
      </Row>

    </Wrapper>

  );
}

RailListingFilter.defaultProps = {
  results: undefined,
};
export default RailListingFilter;
