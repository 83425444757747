import React from 'react';
import {
  Empty, Card,
} from 'antd';
import { useParams } from 'react-router-dom';
import FlightSearch from '../../components/FlightSearch';
import RoadSearch from '../../components/RoadSearch';
import WaterSearch from '../../components/WaterSearch';
import {
  ContentContainer,
  ContentWrapper, FormContentWrapper
} from './styles';
import Header from '../../components/Header';
import RailSearch from '../../components/RailSearch';
import PopularDestinations from "../../components/PopularDestinations";
import ContinueSearch from "../../components/ContinueSearch";

function Search() {
  let { tripType } = useParams();

  tripType = tripType || 'flight';

  const renderTripForm = () => {
    switch (tripType) {
      case 'road':
        return <RoadSearch />;
      case 'flight':
        return <FlightSearch />;
      case 'water':
        return <WaterSearch />;
      case 'rail':
        return <RailSearch />;
      default:
        return <Card><Empty description="This feature is not available yet! " /></Card>;
    }
  };

  return (
    <>
      <Header />
      <div className="container">
        <ContentContainer>
          <ContentWrapper>
            <FormContentWrapper>
              {renderTripForm()}
            </FormContentWrapper>

            <ContinueSearch tripType={tripType} />

            <PopularDestinations />
          </ContentWrapper>
        </ContentContainer>
      </div>
    </>

  );
}

export default Search;
