import styled, { css } from 'styled-components';
import {BLACK_200, FONT_SIZE, GREY_10, GREY_6} from '../../../utils/colors';
import device from '../../../utils/mediaQuery';


export const Wrapper = styled.div<{ filled?: boolean, hideLabel?: boolean, hasPrefix: boolean }>`
  position: relative;

  .label {
    font-weight: normal;
    position: absolute;
    font-size: 14px !important;
    pointer-events: none;
    left: 12px;
    top: 13px;
    transition: 0.2s ease all;
  }

  .as-placeholder {
    color: gray;
    font-size: 14px !important;
  }

  .as-label {
    top:  calc(-8px * ${FONT_SIZE});
    font-size: ${FONT_SIZE > 1 ? '14px' : '11px'}!important;
    padding: 0 4px;
    margin-left: -4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 98%;
    background: #ffffff;
  }
  
  .ant-select {
    width: 100%;
  }
  
  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
    border-radius: 12px !important;
  }

  .ant-select-selector {
    box-shadow: none !important;
    border: solid 1px ${GREY_6} !important;
    border-radius: 12px !important;
  }
  
  @media ${device.large} {
    .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
      border: solid 1px ${GREY_6} !important;
    }

    .ant-select-selector {
      box-shadow: none !important;
      border: solid 1px ${GREY_6} !important;
    }
  }
  
 .ant-select-focused .ant-select-selector {
    box-shadow: none !important;
    border: solid 1px ${GREY_6} !important;
 }
 

  ${({ filled }) => filled && css`
    .ant-select-selector {
      background: ${GREY_10} !important;
    }

    .as-label {
      
    }
  `}
  
  ${({ hideLabel }) => hideLabel && css`
    .as-label {
      display: none;
    }
  `}

  svg {
    path {
      fill: ${BLACK_200} !important;
    }
  }
  
  ${({ hasPrefix }) => hasPrefix && css`
    .as-placeholder {
      left: 35px;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-search {
      left: 35px;
    }
    
    .ant-select-selector {
        padding-left: 35px !important;
    }
  `}
`;

export const PrefixIconWrapper = styled.div`
    position: absolute;
    top: 13px;
    left: 10px;
  
    svg {
      width: 20px;
      height: 20px;
    }
`;
