/* eslint-disable react/no-array-index-key */
import React, {useEffect} from 'react';
import { useFlightSearchStore } from '../../store/flightSearch/flightSearchStore';
import {useNavigate} from "react-router-dom";
import {useStore} from "../../store/global/appStore";
import useIsMobile from "../../hooks/useIsMobile";
import {Col, Row} from "antd";
import {
  AirportNameWrapper,
  FlightFareWrapper,
  FlightLogoWrapper,
  FlightTimeWrapper, StepItemContent, StepItemWrapper,
  StepProgressLine,
  StepsContainer,
  StepsWrapper
} from "../AvailableFlights/styles";
import {getAirlineLogo} from "../AvailableFlights/helper";
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import moment from "moment";
import {ReactComponent as FlightIcon} from "../../assets/images/flight.svg";
import {pluralizeText} from "../../utils/helpers";
import {getAirportStateName} from "../TripSummary/helper";

type Props = {
  hideOperator?: boolean;
}
function FlightItinerarySummary({ hideOperator }: Props) {
  const { isMobile } = useIsMobile();
  const {
    selectedFlight,
    formData: {
      isDomestic,
      tripType
    },
  } = useFlightSearchStore();
  const { airports } = useStore();


  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedFlight) {
      navigate('/search/flight');
    }
  }, [selectedFlight, navigate]);

  const departureFlight = selectedFlight[0].FlightDetails[0];
  const returningFlight = selectedFlight && selectedFlight.length == 2 ? selectedFlight[1].FlightDetails[0] : null;
  const isRoundTrip = tripType === 'round_trip';
  const isMultiCity = tripType === 'multi_city';

  const numberOfStops = departureFlight.StopOvers;
  const returningNumberOfStops = returningFlight?.StopOvers || 0;

  const consolidatedDetails = isMultiCity || (!isDomestic && isRoundTrip);

  return isMobile ? (
      consolidatedDetails ? (
            <div>
              {selectedFlight[0].FlightDetails.map((flight, index) => (
                  <div key={index}>
                    <FlightTimeWrapper>
                      <div>
                        <StyledTitle level={5}>
                          {moment(flight.DepartureDate).format('hh:mm a')}
                        </StyledTitle>
                        <StyledParagraph>
                          {flight.DepartureAirportCode}
                        </StyledParagraph>
                      </div>

                      <StepsContainer>
                        <StyledParagraph align="center" size="small">
                          {flight.Duration}
                        </StyledParagraph>
                        <StepsWrapper>
                          <StepProgressLine />
                          <StepItemWrapper>
                            <StepItemContent>
                              <FlightIcon />
                            </StepItemContent>
                          </StepItemWrapper>

                          <StepProgressLine />
                        </StepsWrapper>
                        <StyledParagraph align="center" size="small">
                          {flight.StopOvers > 0 ? pluralizeText(flight.StopOvers, 'stop', 'stops') : 'Non-stop'}
                        </StyledParagraph>
                      </StepsContainer>

                      <div>
                        <StyledTitle level={5}>
                          {moment(flight.DepartureDate).format('hh:mm a')}
                        </StyledTitle>
                        <StyledParagraph align="right">
                          {flight.ArrivalAirportCode}
                        </StyledParagraph>
                      </div>
                    </FlightTimeWrapper>

                    {
                      !hideOperator && (
                            <FlightFareWrapper>
                              <FlightLogoWrapper>
                                <img
                                    src={getAirlineLogo(flight.OperatingAirlineCode)}
                                    alt={flight.OperatingAirlineCode}
                                />
                                <StyledParagraph>
                                  {flight.OperatingAirlineName}
                                </StyledParagraph>
                              </FlightLogoWrapper>
                            </FlightFareWrapper>
                        )
                    }
                  </div>
              ))}
            </div>
      ) : (
          <div>
            <div>
              <FlightTimeWrapper>
                <div>
                  <StyledTitle level={5}>
                    {moment(departureFlight.DepartureDate).format('hh:mm a')}
                  </StyledTitle>
                  <StyledParagraph>
                    {departureFlight.DepartureAirportCode}
                  </StyledParagraph>
                </div>

                <StepsContainer>
                  <StyledParagraph align="center" size="small">
                    {departureFlight.Duration}
                  </StyledParagraph>
                  <StepsWrapper>
                    <StepProgressLine />
                    <StepItemWrapper>
                      <StepItemContent>
                        <FlightIcon />
                      </StepItemContent>
                    </StepItemWrapper>

                    <StepProgressLine />
                  </StepsWrapper>
                  <StyledParagraph align="center" size="small">
                    {numberOfStops > 0 ? pluralizeText(numberOfStops, 'stop', 'stops') : 'Non-stop'}
                  </StyledParagraph>
                </StepsContainer>

                <div>
                  <StyledTitle level={5}>
                    {moment(departureFlight.DepartureDate).format('hh:mm a')}
                  </StyledTitle>
                  <StyledParagraph align="right">
                    {departureFlight.ArrivalAirportCode}
                  </StyledParagraph>
                </div>
              </FlightTimeWrapper>

              {
                !hideOperator && (
                      <FlightFareWrapper>
                        <FlightLogoWrapper>
                          <img
                              src={getAirlineLogo(departureFlight.OperatingAirlineCode)}
                              alt={departureFlight.OperatingAirlineCode}
                          />
                          <StyledParagraph>
                            {departureFlight.OperatingAirlineName}
                          </StyledParagraph>
                        </FlightLogoWrapper>
                      </FlightFareWrapper>
                  )
              }
            </div>

            {returningFlight && (
                <div>
                  <FlightTimeWrapper>
                    <div>
                      <StyledTitle level={5}>
                        {moment(returningFlight.DepartureDate).format('hh:mm a')}
                      </StyledTitle>
                      <StyledParagraph>
                        {returningFlight.DepartureAirportCode}
                      </StyledParagraph>
                    </div>

                    <StepsContainer>
                      <StyledParagraph align="center" size="small">
                        {returningFlight.Duration}
                      </StyledParagraph>
                      <StepsWrapper>
                        <StepProgressLine />
                        <StepItemWrapper>
                          <StepItemContent>
                            <FlightIcon />
                          </StepItemContent>
                        </StepItemWrapper>

                        <StepProgressLine />
                      </StepsWrapper>
                      <StyledParagraph align="center" size="small">
                        {returningNumberOfStops > 0 ? pluralizeText(returningNumberOfStops, 'stop', 'stops') : 'Non-stop'}
                      </StyledParagraph>
                    </StepsContainer>

                    <div>
                      <StyledTitle level={5}>
                        {moment(returningFlight.DepartureDate).format('hh:mm a')}
                      </StyledTitle>
                      <StyledParagraph align="right">
                        {returningFlight.ArrivalAirportCode}
                      </StyledParagraph>
                    </div>
                  </FlightTimeWrapper>

                  {
                      !hideOperator && (
                          <FlightFareWrapper>
                            <FlightLogoWrapper>
                              <img
                                  src={getAirlineLogo(returningFlight.OperatingAirlineCode)}
                                  alt={returningFlight.OperatingAirlineCode}
                              />
                              <StyledParagraph>
                                {returningFlight.OperatingAirlineName}
                              </StyledParagraph>
                            </FlightLogoWrapper>
                          </FlightFareWrapper>
                      )
                  }
                </div>
            )}
          </div>
      )
  ) : (
        consolidatedDetails ? (
               <div>
                 {selectedFlight[0].FlightDetails.map((flight, index) => (
                  <div key={index}>
                    <Row gutter={8} align="middle">
                      <Col span={ hideOperator ? 0 : 8}>
                        <FlightLogoWrapper>
                          <img
                              src={getAirlineLogo(flight.OperatingAirlineCode)}
                              alt={flight.OperatingAirlineCode}
                          />
                          <StyledParagraph size="default">
                            {flight.OperatingAirlineName}
                          </StyledParagraph>
                        </FlightLogoWrapper>
                      </Col>
                      <Col span={hideOperator ? 24 : 16}>
                        <FlightTimeWrapper>
                          <div>
                            <StyledParagraph>
                              {moment(flight.DepartureDate).format('ddd, D MMM')}
                            </StyledParagraph>
                            <StyledTitle level={4}>
                              {moment(flight.DepartureDate).format('hh:mm a')}
                            </StyledTitle>
                          </div>

                          <StepsContainer>
                            <StyledParagraph align="center" size="small">
                              {flight.Duration}
                            </StyledParagraph>
                            <StepsWrapper>
                              <StepProgressLine />
                              <StepItemWrapper>
                                <StepItemContent>
                                  <FlightIcon />
                                </StepItemContent>
                              </StepItemWrapper>

                              <StepProgressLine />
                            </StepsWrapper>
                            <StyledParagraph align="center" size="small">
                              {flight.StopOvers > 0 ? pluralizeText(flight.StopOvers, 'stop', 'stops') : 'Non-stop'}
                            </StyledParagraph>
                          </StepsContainer>

                          <div>
                            <StyledParagraph align="right">
                              {moment(flight.ArrivalDate).format('ddd, D MMM')}
                            </StyledParagraph>
                            <StyledTitle level={4} align="right">
                              {moment(flight.ArrivalDate).format('hh:mm a')}
                            </StyledTitle>
                          </div>
                        </FlightTimeWrapper>

                        <AirportNameWrapper>
                          <StyledParagraph>
                            {`${getAirportStateName(airports, flight.DepartureAirportCode)} (${flight.DepartureAirportCode})`}
                          </StyledParagraph>

                          <StyledParagraph align="right">
                            {`${getAirportStateName(airports, flight.ArrivalAirportCode)} (${flight.ArrivalAirportCode})`}
                          </StyledParagraph>
                        </AirportNameWrapper>
                      </Col>
                    </Row>
                  </div>
              ))}
              </div>)
            : (
            <div>
              <Row gutter={8} align="middle">
                <Col span={ hideOperator ? 0 : 8}>
                  <FlightLogoWrapper>
                    <img
                        src={getAirlineLogo(departureFlight.OperatingAirlineCode)}
                        alt={departureFlight.OperatingAirlineCode}
                    />
                    <StyledParagraph size="default">
                      {departureFlight.OperatingAirlineName}
                    </StyledParagraph>
                  </FlightLogoWrapper>
                </Col>
                <Col span={hideOperator ? 24 : 16}>
                  <FlightTimeWrapper>
                    <div>
                      <StyledParagraph>
                        {moment(departureFlight.DepartureDate).format('ddd, D MMM')}
                      </StyledParagraph>
                      <StyledTitle level={4}>
                        {moment(departureFlight.DepartureDate).format('hh:mm a')}
                      </StyledTitle>
                    </div>

                    <StepsContainer>
                      <StyledParagraph align="center" size="small">
                        {departureFlight.Duration}
                      </StyledParagraph>
                      <StepsWrapper>
                        <StepProgressLine />
                        <StepItemWrapper>
                          <StepItemContent>
                            <FlightIcon />
                          </StepItemContent>
                        </StepItemWrapper>

                        <StepProgressLine />
                      </StepsWrapper>
                      <StyledParagraph align="center" size="small">
                        {numberOfStops > 0 ? pluralizeText(numberOfStops, 'stop', 'stops') : 'Non-stop'}
                      </StyledParagraph>
                    </StepsContainer>

                    <div>
                      <StyledParagraph align="right">
                        {moment(departureFlight.ArrivalDate).format('ddd, D MMM')}
                      </StyledParagraph>
                      <StyledTitle level={4} align="right">
                        {moment(departureFlight.ArrivalDate).format('hh:mm a')}
                      </StyledTitle>
                    </div>
                  </FlightTimeWrapper>

                  <AirportNameWrapper>
                    <StyledParagraph>
                      {`${getAirportStateName(airports, departureFlight.DepartureAirportCode)} (${departureFlight.DepartureAirportCode})`}
                    </StyledParagraph>

                    <StyledParagraph align="right">
                      {`${getAirportStateName(airports, departureFlight.ArrivalAirportCode)} (${departureFlight.ArrivalAirportCode})`}
                    </StyledParagraph>
                  </AirportNameWrapper>
                </Col>
              </Row>

              {returningFlight && (
                  <Row gutter={8} align="middle">
                    <Col span={ hideOperator ? 0 : 8}>
                      <FlightLogoWrapper>
                        <img
                            src={getAirlineLogo(returningFlight.OperatingAirlineCode)}
                            alt={returningFlight.OperatingAirlineCode}
                        />
                        <StyledParagraph size="default">
                          {returningFlight.OperatingAirlineName}
                        </StyledParagraph>
                      </FlightLogoWrapper>
                    </Col>
                    <Col span={hideOperator ? 24 : 16}>
                      <FlightTimeWrapper>
                        <div>
                          <StyledParagraph>
                            {moment(returningFlight.DepartureDate).format('ddd, D MMM')}
                          </StyledParagraph>
                          <StyledTitle level={4}>
                            {moment(returningFlight.DepartureDate).format('hh:mm a')}
                          </StyledTitle>
                        </div>

                        <StepsContainer>
                          <StyledParagraph align="center" size="small">
                            {returningFlight.Duration}
                          </StyledParagraph>
                          <StepsWrapper>
                            <StepProgressLine />
                            <StepItemWrapper>
                              <StepItemContent>
                                <FlightIcon />
                              </StepItemContent>
                            </StepItemWrapper>

                            <StepProgressLine />
                          </StepsWrapper>
                          <StyledParagraph align="center" size="small">
                            {returningNumberOfStops > 0 ? pluralizeText(returningNumberOfStops, 'stop', 'stops') : 'Non-stop'}
                          </StyledParagraph>
                        </StepsContainer>

                        <div>
                          <StyledParagraph align="right">
                            {moment(returningFlight.ArrivalDate).format('ddd, D MMM')}
                          </StyledParagraph>
                          <StyledTitle level={4}>
                            {moment(returningFlight.ArrivalDate).format('hh:mm a')}
                          </StyledTitle>
                        </div>
                      </FlightTimeWrapper>

                      <AirportNameWrapper>
                        <StyledParagraph>
                          {`${getAirportStateName(airports, returningFlight.DepartureAirportCode)} (${returningFlight.DepartureAirportCode})`}
                        </StyledParagraph>

                        <StyledParagraph align="right">
                          {`${getAirportStateName(airports, returningFlight.ArrivalAirportCode)} (${returningFlight.ArrivalAirportCode})`}
                        </StyledParagraph>
                      </AirportNameWrapper>
                    </Col>
                  </Row>
              )}
            </div>
        )
  )
}
export default FlightItinerarySummary;
