import React, {ReactNode, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useRoadSearchStore } from '../store/roadSearch/roadSearchStore';
import AuthenticatedRoute from './AuthenticatedRoute';

type Props = {
  children: ReactNode
}
function RoadDataFilled({ children }: Props) {
  const [allow, setAllow] = useState(false)
  const navigate = useNavigate();
  const {
    formData,
  } = useRoadSearchStore();

  useEffect(() => {
    if (!formData.DepartureId) {
      navigate('/search/road');
    } else {
      setAllow(true)
    }
  }, [formData, navigate]);

  return allow ? (
      <AuthenticatedRoute>
        {children}
      </AuthenticatedRoute>
  ) : (
      <></>
  );
}

export default RoadDataFilled;
