import React, { useCallback, useEffect, useState } from 'react';
import {
  Empty, Spin,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { Wrapper } from './styles';
import { getAPICustom } from '../../utils/api';
import {
  APP_NAME,
  VALIDATE_TOKEN_API_URL,
} from '../../utils/constants';
import { StyledTitle } from '../../components/CustomAntdComponents/Typography';

type TokenPayload = {
  MerchantCode: string;
  MerchantHash: string;
}

function Index(): React.ReactElement {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const validateUser = useCallback(async ({
    MerchantCode, MerchantHash,
  }: TokenPayload) => {
    setLoading(true);
    const validateApi = await getAPICustom(VALIDATE_TOKEN_API_URL, {}, {
      MerchantCode,
      MerchantHash,
      AppName: APP_NAME,
    });

    setLoading(false);

    if (validateApi.status === 200) {
      localStorage.setItem('trips_auth', JSON.stringify(validateApi.data));
      window.location.href = '/home';
    } else {
      setError(validateApi.data);
    }
  }, []);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const {
      MerchantCode, MerchantHash, CustomerName, CustomerId,
    } = Object.fromEntries(urlSearchParams.entries());

    if (MerchantCode && MerchantHash) {
      validateUser({
        MerchantCode, MerchantHash,
      });
      localStorage.setItem('trips_user', JSON.stringify({
        CustomerName, CustomerId,
      }));
    } else if (localStorage.getItem('trips_auth')) {
      navigate('/home');
    } else {
      setError('You need authorization to access this app');
    }
  }, [navigate, validateUser]);

  return (
    <Wrapper>
      <Spin spinning={loading} tip="Loading content" />
      {error && <Empty description={<StyledTitle level={4}>{error}</StyledTitle>} />}
    </Wrapper>
  );
}

export default Index;
