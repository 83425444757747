import React from 'react';
import { Radio } from 'antd';
import { RadioItem, Wrapper } from "./styles";

const AntdRadioGroup = Radio.Group;

type Props = {
    options: { name: string, value: string }[],
    value: string,
    bordered?: boolean,
    block?: boolean,
    onChange: (e: any) => void
}


const StyledRadioGroup = ({ options, value, onChange, bordered, block }: Props) => {
    return (
        <Wrapper bordered={bordered} block={block}>
            <AntdRadioGroup onChange={onChange} value={value}>
                {options.map((option) => (
                    <RadioItem key={option.value}>
                        <Radio value={option.value}>{option.name}</Radio>
                    </RadioItem>
                ))}
            </AntdRadioGroup>
        </Wrapper>
    );
};

export default StyledRadioGroup;
