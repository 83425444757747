import React, { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  children: ReactNode
}
function AuthenticatedRoute({ children }: Props) {
  const navigate = useNavigate();

  useEffect(() => {
    const auth = localStorage.getItem('trips_auth');
    if (!auth) {
      navigate('/');
    }
  }, [navigate]);
  return (
    <div>
      {children}
    </div>
  );
}

export default AuthenticatedRoute;
