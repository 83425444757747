export const passengerFares = [
  {
    PassengerType: 'ADT',
    Taxes: 12,
    Fees: 12,
    TotalFare: 10000,
    GdsFare: 123456789,
    BaseFare: 100,
    GdsTotalFare: 10000,
    MarkupFare: 1000,
  },
];

export const availableSeats = () => new Array(15).fill(true).map((a, index) => ({
  IsAvailable: a,
  SeatNumber: index + 1,
  Price: 100,
}));
export const availableTrains = [
  {
    RecommendationId: 120292093,
    BusType: 'Flying boat',
    EstimatedDepartureDate: '2022-10-10',
    EstimatedArrivalDate: '2022-10-15',
    Duration: '5 hours',
    ReservationId: 12345678,
    PassengerFares: passengerFares,
    Currency: 'NGN',
    AvailableSeats: availableSeats(),
  },
];

export const trainResultItems = [
  {
    CombinationId: 236789,
    DepartureTerminal: 'LOS',
    ArrivalTerminal: 'ABJ',
    GdsId: 12345678,
    AgentId: 98765,
    Currency: 'NGN',
    AgentName: 'Agent Name',
    StartingPrice: 10000,
    IsLocal: true,
    Stops: 0,
    TripsSessionId: '123456789',
    AvailableTrains: availableTrains,
  },
];

export const trainResultsResponse = {
  status: 200,
  data: {
    TotalCount: 100,
    DepartureTerminal: 'V/ISL',
    DepartureTerminalId: 1222,
    ArrivalTerminal: 'EKO ATL',
    ArrivalTerminalId: 1222,
    DepartureDate: '2022-10-10',
    ArrivalDate: '2022-10-15',
    TripsSessionId: '123456789',
    Currency: 'NGN',
    IsLocal: true,
    ResultList: trainResultItems,
  },
};

export const trainFareRules = [
  {
    Text: 'This is a fare rule needed to be accepted',
    Code: '12',
  },
  {
    Text: 'This is another fare rule needed to be accepted',
    Code: '123',
  },
];

export const confirmTicketResponse = {
  status: 200,
  data: {
    ConfirmationCode: '1234',
    TicketTimeLimit: '2022-10-10',
    OldPrice: 100,
    NewPrice: 100,
    Errors: [],
    Warnings: [],
  },
};
