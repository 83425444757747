import styled from 'styled-components';
import {BLACK, BLACK_300, GREY} from "../../utils/colors";
import {StyledText} from "../CustomAntdComponents/Typography";

export const Wrapper = styled.div`
  padding-top: 5px;
  
  .ant-select {
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  text-align: right;
  
  .ant-btn {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

export const CollapseWrapper = styled.div`
  .ant-collapse-expand-icon {
    svg {
      path {
        fill: ${BLACK};
      }
    }
  }

  .ant-collapse-content, .ant-collapse {
    border: none;
    background: none;
  }
  .ant-collapse > .ant-collapse-item {
    border: none;
    background: none;
  }
  
  .ant-collapse-content-box {
    padding-top: 15px;
    padding-left: 0;
  }
  
  .ant-collapse-header-text {
    font-weight: 700;
    font-size: 16px;
    color: ${GREY};
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    align-items: center;
    padding-left: 0;
  }
`;


export const ContactFormWrapper = styled.div`
  .ant-form-item-control {
    width: 100% !important;
  }
  
  @media (max-width: 991px) {
    .ant-form-item-label > label  {
      height: 22px;
    }
  }
`;

export const CheckBoxLabel = styled(StyledText)`
  font-size: 15px;
  color: ${BLACK_300};
`;


export const InfoWrapper = styled.div`
  margin-bottom: 25px;
    .ant-typography {
      color: ${BLACK_300};
    }
`;
