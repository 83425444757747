import styled from 'styled-components';
import device from '../../utils/mediaQuery';
import { FONT_SIZE, GREY_10 } from '../../utils/colors';

export const Wrapper = styled.div`
  padding: 0 20px 20px;

  @media ${device.large} {
    padding: 0 0 20px;
  }
`;

export const TitleWrapper = styled.div`
  .ant-typography {
    color: #C4C4C4;
    font-size: calc(12px *  ${FONT_SIZE});

  }
`;

export const FormWrapper = styled.div`
 margin-bottom: 15px;
  
  ${device.large} {
    margin-bottom: 0;
  }

  .ant-select-selection-placeholder,
  .ant-select-selection-search {
    font-size: calc(12px *  ${FONT_SIZE});
  }
  
  .ant-select-selection-item {
    font-size: calc(12px *  ${FONT_SIZE});
  }
`;

export const TripSummaryWrapper = styled.div`
  padding: 0 10px;
  
  ${device.large} {
    padding: 0;
  }
`;

export const HeaderWrapper = styled.div`
  margin: 14px 0 28px;
`;

export const FareRuleTitleWrapper = styled.div`
  margin: 20px auto 10px;
`;

export const FareRuleWrapper = styled.div`
  padding: 20px;
  background: ${GREY_10};
  border-radius: 5px;
  margin: 20px 0;
`;
