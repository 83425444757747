import { create } from 'zustand';
import { FlightSearchStore } from './types';
import { getExtraData } from '../../utils/helpers';
import {AirTraveller, BookingContact} from "../../screens/PassengerDetails/types";

interface FlightSearchStoreState {
  formData: FlightSearchStore;
  selectedFlight: AirlineResultItem[] | null,
  passengers?: AirTraveller[],
  bookingContact?: BookingContact,
  loading: boolean;
  updateFormData: (data: any) => void,
  updateStoreData: (data: any) => void,
  resetFormData: () => void,
}

const extraData = getExtraData();

export const initialFormData: FlightSearchStore = {
  FlightClass: 'Economy',
  Currency: extraData?.CurrencyCode || 'NGN',
  EnsureAgentUnicityForRoutes: false,
  OrderbyFlightTime: 'asc',
  OrderbyPrice: 'asc',
  pageSize: 5000,
  From: 0,
  tripType: 'one_way',
  isDomestic: false,
  numberOfAdults: 1,
  numberOfChildren: 0,
  numberOfInfants: 0,
};

export const defaultStoreData = {
  passengers: null,
  selectedFlight: null,
  loading: false,
  bookingContact: null,
}

export const useFlightSearchStore = create<FlightSearchStoreState>((set) => ({
  // initial state
  formData: initialFormData,
  loading: false,
  selectedFlight: null,
  passengers: null,
  bookingContact: null,
  updateFormData: (data: any) => {
    set((state) => ({
      ...state,
      formData: {
        ...state.formData,
        ...data,
      },
    }));
  },
  updateStoreData: (data: any) => {
    set((state) => ({
      ...state,
      ...data,
    }));
  },
  resetFormData: () => {
    set((state) => ({
      ...state,
      ...defaultStoreData,
      formData: {
        ...initialFormData,
      },
    }));
  },
}));
