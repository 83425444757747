/* eslint-disable no-nested-ternary */
import React, { useMemo } from "react";
import { Row, Col, notification, Button } from "antd";
import moment from "moment";
import {
  FlightCard,
  FlightTimeWrapper,
  FlightFareWrapper,
  CurrencyWrapper,
  FlightLogoWrapper,
  StepItemContent,
  StepItemWrapper,
  StepProgressLine,
  StepsWrapper,
  StepsContainer,
  FlightCardsWrapper,
  FareWrapper,
  CheapestFareTag,
  AirportNameWrapper,
} from "./styles";
import { StyledParagraph, StyledText, StyledTitle } from "../CustomAntdComponents/Typography";
import { getAirlineLogo } from "./helper";
import { formatNumber, formatNumberAsCurrency, pluralizeText } from "../../utils/helpers";
import { ReactComponent as FlightIcon } from "../../assets/images/flight.svg";
import useIsMobile from "../../hooks/useIsMobile";
import { CentralizedContent } from "../CustomAntdComponents/Utitlities";
import { getAirportStateName } from "../TripSummary/helper";

type Props = {
  result: AirlineResultItem;
  handleSelectFlight?: (arg: AirlineResultItem[]) => void;
  onlyDepartureSelected: boolean;
  cheapestFare: number | null;
  airports: Airport[];
};

function FlightDetails({ result, onlyDepartureSelected, handleSelectFlight, cheapestFare, airports }: Props) {
  const { isMobile } = useIsMobile();

  const openNotification = () => {
    notification.open({
      message: "Departure flight selected",
      placement: "bottom",
      type: "success",
    });
  };

  const onFlightClick = () => {
    if (onlyDepartureSelected) {
      openNotification();
    }

    handleSelectFlight([result]);
  };

  return isMobile ? (
    <FlightCard onClick={onFlightClick}>
      {cheapestFare === result.TotalFare && <CheapestFareTag>Cheapest fare</CheapestFareTag>}

      {result.FlightDetails.map((flight, index) => {
        return (
          <div key={index}>
            <FlightTimeWrapper>
              <div>
                <StyledTitle level={5}>{moment(flight.DepartureDate).format("hh:mm a")}</StyledTitle>
                <StyledParagraph>{flight.DepartureAirportCode}</StyledParagraph>
              </div>

              <StepsContainer>
                <StyledParagraph align="center" size="small">
                  {flight.Duration}
                </StyledParagraph>
                <StepsWrapper>
                  <StepProgressLine />
                  <StepItemWrapper>
                    <StepItemContent>
                      <FlightIcon />
                    </StepItemContent>
                  </StepItemWrapper>

                  <StepProgressLine />
                </StepsWrapper>
                <StyledParagraph align="center" size="small">
                  {flight.StopOvers > 0 ? pluralizeText(flight.StopOvers, "stop", "stops") : "Non-stop"}
                </StyledParagraph>
              </StepsContainer>

              <div>
                <StyledTitle level={5} align="right">
                  {moment(flight.ArrivalDate).format("hh:mm a")}
                </StyledTitle>
                <StyledParagraph align="right">{flight.ArrivalAirportCode}</StyledParagraph>
              </div>
            </FlightTimeWrapper>
            <FlightFareWrapper>
              <FlightLogoWrapper>
                <img src={getAirlineLogo(flight.OperatingAirlineCode)} alt={flight.OperatingAirlineCode} />
                <StyledParagraph>{flight.OperatingAirlineName}</StyledParagraph>
              </FlightLogoWrapper>
            </FlightFareWrapper>
          </div>
        );
      })}

      <FlightFareWrapper style={{ justifyContent: "flex-end" }}>
        {result.IsDiscounted && (
          <StyledText style={{ textDecoration: "line-through" }}>
            {formatNumberAsCurrency(result.OriginalTotalFare, result.Currency)}
          </StyledText>
        )}

        <StyledTitle level={5}>
          <CurrencyWrapper>{result.Currency} </CurrencyWrapper>
          {formatNumber(result.TotalFare)}
        </StyledTitle>
      </FlightFareWrapper>
    </FlightCard>
  ) : (
    <FlightCardsWrapper>
      <Row>
        <Col span={17} style={{ display: "flex" }}>
          <FlightCard rightBordered>
            {cheapestFare === result.TotalFare && <CheapestFareTag>Cheapest fare</CheapestFareTag>}

            {result.FlightDetails.map((flight, index) => (
              <Row align="middle" gutter={8} key={index}>
                <Col span={9}>
                  <FlightLogoWrapper>
                    <img src={getAirlineLogo(flight.OperatingAirlineCode)} alt={flight.OperatingAirlineCode} />
                    <StyledParagraph size="default">{flight.OperatingAirlineName}</StyledParagraph>
                  </FlightLogoWrapper>
                </Col>
                <Col span={15}>
                  <FlightTimeWrapper>
                    <div>
                      <StyledTitle level={4}>{moment(flight.DepartureDate).format("hh:mm a")}</StyledTitle>
                    </div>

                    <StepsContainer>
                      <StyledParagraph align="center" size="small">
                        {flight.Duration}
                      </StyledParagraph>
                      <StepsWrapper>
                        <StepProgressLine />
                        <StepItemWrapper>
                          <StepItemContent>
                            <FlightIcon />
                          </StepItemContent>
                        </StepItemWrapper>

                        <StepProgressLine />
                      </StepsWrapper>
                      <StyledParagraph align="center" size="small">
                        {flight.StopOvers > 0 ? pluralizeText(flight.StopOvers, "stop", "stops") : "Non-stop"}
                      </StyledParagraph>
                    </StepsContainer>

                    <div>
                      <StyledTitle level={4} align="right">
                        {moment(flight.ArrivalDate).format("hh:mm a")}
                      </StyledTitle>
                    </div>
                  </FlightTimeWrapper>

                  <AirportNameWrapper padded>
                    <StyledParagraph>{getAirportStateName(airports, flight.DepartureAirportCode)}</StyledParagraph>

                    <StyledParagraph>{getAirportStateName(airports, flight.ArrivalAirportCode)}</StyledParagraph>
                  </AirportNameWrapper>
                </Col>
              </Row>
            ))}
          </FlightCard>
        </Col>
        <Col span={7} style={{ display: "flex" }}>
          <FlightCard leftBordered>
            <CentralizedContent style={{ height: "100%" }}>
              <div>
                {result.IsDiscounted && (
                  <FareWrapper>
                    <StyledTitle level={5} align="center" style={{ textDecoration: "line-through" }}>
                      {formatNumberAsCurrency(result.OriginalTotalFare, result.Currency)}
                    </StyledTitle>
                  </FareWrapper>
                )}
                <FareWrapper>
                  <StyledTitle level={4} align="center">
                    {formatNumberAsCurrency(result.TotalFare, result.Currency)}
                  </StyledTitle>
                </FareWrapper>

                <Button type="primary" block onClick={onFlightClick}>
                  Select fare
                </Button>
              </div>
            </CentralizedContent>
          </FlightCard>
        </Col>
      </Row>
    </FlightCardsWrapper>
  );
}

export default FlightDetails;
