import React from 'react';
import {
    Container,
    StepCounter,
    StepperItem,
    StepperWrapper,
} from "./styles";
import {StyledParagraph} from "../CustomAntdComponents/Typography";
import { ReactComponent as CheckIcon } from "../../assets/images/check.svg";

type Props = {
    currentStep: number;
    steps: string[];
}

const Stepper = ( { currentStep, steps }: Props) => {

    return (
        <Container>
            <StepperWrapper>
                {
                    steps.map((step, index) => {
                        const completed = (index + 1) < currentStep;
                        const active = (index + 1) === currentStep
                        return (
                            <StepperItem key={index} completed={completed} active={active}>
                                <StepCounter>
                                    {
                                        (completed && !active) ? <CheckIcon /> : index + 1
                                    }
                                </StepCounter>
                                <StyledParagraph>{step}</StyledParagraph>
                            </StepperItem>
                        )
                    })
                }
            </StepperWrapper>
        </Container>
    );
};

export default Stepper;
