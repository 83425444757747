import { Button } from 'antd';
import styled, { css } from 'styled-components';
import {PRIMARY, GREY_5, BLACK_200, BLACK} from '../../utils/colors';

type StyledButtonProp = {
  mode?: string
  padded?: string
}

export const StyledButton = styled(Button)<StyledButtonProp>`
  ${({ mode }) => mode === 'secondary' && css`
    color: ${PRIMARY};
    background ${GREY_5};
    
    &:hover {
      color: ${PRIMARY};
      border-color: ${PRIMARY};
    }
  `}
  ${({ mode }) => mode === 'primary' && css`
    background: ${PRIMARY};
    border-color: ${PRIMARY};
    color: #fff;
    
    &:focus,
    &:hover {
      background: ${PRIMARY};
      border-color: ${PRIMARY};
      color: #fff;
    }
  `}
  
  ${({ mode }) => mode === 'default' && css`
    border-color: ${BLACK_200};
    
    &:focus,
    &:hover {
      color: ${BLACK};
      border-color: ${BLACK_200};
    }
  `}
  
  ${({ padded }) => padded === 'large' && css`
    padding-left: 50px;
    padding-right: 50px;
  `}
  `;
