import React, {useRef, useState} from 'react';
import { Drawer } from "antd";
import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/arrow-left.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/pencil.svg';
import { ReactComponent as RightArrowIcon } from "../../../assets/images/arrow-right-grey.svg";
import { ReactComponent as DirectionIcon } from "../../../assets/images/direction-hr.svg";
import {
  DetailsWrapper,
  BackIconWrapper, Wrapper, EditIconWrapper, DrawerContent,
} from './styles';
import { StyledParagraph, StyledTitle } from "../../CustomAntdComponents/Typography";
import RoadSearch from "./RoadSearch";
import {Button, Form} from "antd";
import {useRoadSearchStore} from "../../../store/roadSearch/roadSearchStore";
import {RoadSearchStore} from "../../../store/roadSearch/types";
import {getTerminalName} from "../../TripSummary/helper";
import {useStore} from "../../../store/global/appStore";
import FlightSearchMobile from "../Flight/FlightSearchMobile";
import {MobileDrawerContent} from "../Flight/styles";

type Props = {
  navigateHandler: () => void
  modify: boolean,
  refetchSearch: () => void,
  onModifyChange: (value: boolean) => void
}
function MobileHeader({ navigateHandler, modify, onModifyChange, refetchSearch }: Props) {
  const drawerContainerRef = useRef(null);
  const {
    formData, updateFormData
  } = useRoadSearchStore();
  const { roadRoutes } = useStore();
  const [form] = Form.useForm();

  const {
    DepartureDate,
    ArrivalDate,
    ArrivalId,
    DepartureId,
    TripType,
  } = formData;

  const isRoundTrip = TripType === 'round_trip';
  const departure = getTerminalName(roadRoutes, DepartureId);
  const arrival = getTerminalName(roadRoutes, ArrivalId);
  const [payload, setPayload] = useState<RoadSearchStore>(formData);

  const handleSubmit = () => {
    form.validateFields().then(() => {
      updateFormData(payload);
      onModifyChange(false);
      refetchSearch();
    }).catch(() => {

    });
  }

  const handleChange = (object) => {
    setPayload({ ...payload, ...object });
    form.setFieldsValue(object);
  }


  const DrawerFooter = (
      <Button type="primary" block onClick={handleSubmit}>
        Search Bus
      </Button>
  )

  return (
      <>
        <Wrapper ref={drawerContainerRef}>
          <BackIconWrapper onClick={navigateHandler}>
            <ArrowLeftIcon width={20} height={20} />
          </BackIconWrapper>

          <div>
            <DetailsWrapper>
              <StyledTitle level={4}>
                {departure}
              </StyledTitle>

              {
                (isRoundTrip) ? <DirectionIcon /> : <RightArrowIcon />
              }

              <StyledTitle level={4}>
                {arrival}
              </StyledTitle>
            </DetailsWrapper>

            <StyledParagraph>
                <span>
                  {
                    (isRoundTrip) && arrival ? `${DepartureDate.format("ddd, MMM D")} - ${ArrivalDate.format("ddd, MMM D")}` : DepartureDate.format("ddd, MMM D")
                  }
                </span>
            </StyledParagraph>
          </div>

          <EditIconWrapper>
            <EditIcon width={30} height={30} onClick={() => {
              onModifyChange(true);
            }} />
          </EditIconWrapper>
        </Wrapper>

        <Drawer
            open={modify}
            onClose={() => onModifyChange(false)}
            footer={DrawerFooter}
            title={"Modify search"}
            placement="bottom"
            height="100%"
            getContainer={drawerContainerRef.current as HTMLElement}
        >
          <MobileDrawerContent>
            <RoadSearch onSubmit={handleSubmit} onChange={handleChange} payload={payload} form={form} />
          </MobileDrawerContent>
        </Drawer>
      </>
  );
}

MobileHeader.defaultProps = {
  name: undefined,
  navigateHandler: undefined,
};

export default MobileHeader;
