/* eslint-disable no-nested-ternary */
import React, {useMemo, useRef, useState} from 'react';
import {Row, Col, notification, Button, Drawer} from 'antd';
import {
    StepItemContent,
    StepItemWrapper,
    StepProgressLine,
    StepsWrapper,
    StepsContainer,
    FlightCardsWrapper,
    FareWrapper,
    CheapestFareTag, Tag, TagsWrapper
} from '../AvailableFlights/styles';
import {
  StyledParagraph, StyledTitle,
} from '../CustomAntdComponents/Typography';
import {getBusTypeImage, getRoadTransportLogo} from './helper';
import {formatNumberAsCurrency, pluralizeText} from '../../utils/helpers';
import { ReactComponent as BusIcon } from '../../assets/images/bus-grey.svg';
import { ReactComponent as PassengerIcon } from '../../assets/images/passenger.svg';
import { ReactComponent as ClockIcon } from '../../assets/images/clock.svg';

import useIsMobile from "../../hooks/useIsMobile";
import {CentralizedContent, SpaceBetweenAlignContent} from "../CustomAntdComponents/Utitlities";

import {
    BusCard,
    BusCardContent,
    BusCardImage,
    BusCardPriceContent, BusDetailsWrapper,
    BusImage,
    BusStepWrapper, SeatFooterWrapper,
    SeatsNumberWrapper
} from "./styles";
import translations from "../../screens/SearchRoadListings/translations";
import {useI18nContext} from "../../utils/I18n";
import {useRoadSearchStore} from "../../store/roadSearch/roadSearchStore";
import SeatSelection from "../SeatSelection/SeatSelection";

type Props = {
    result: BusResultItem,
    handleSelectBus: (arg: BusResultItem, arg2: AvailableBus, arg3: AvailableSeat[]) => void,
    onlyDepartureSelected: boolean,
    cheapestFare: number | null,
    isReturning: boolean,
}

function BusDetails ({ result, onlyDepartureSelected, handleSelectBus, cheapestFare, isReturning }: Props) {
    const { state: { t } } = useI18nContext();
    const drawerContainerRef = useRef(null);
    const [selectedSeats, setSelectedSeats] = useState<AvailableSeat[]>([]);
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [activeBus, setActiveBus] = useState<AvailableBus>();
    const { isMobile } = useIsMobile();

    const {
        formData: { numberOfAdults },
        updateFormData
    } = useRoadSearchStore();

    const disableButton = useMemo(() => {
        if(!isReturning) {
            return selectedSeats.length === 0;
        }

        return numberOfAdults > selectedSeats.length;
    }, [numberOfAdults, selectedSeats]);

    const totalFare = useMemo(() => {
        if(!activeBus){
            return 0;
        }

        const numberOfSeats = selectedSeats.length;

        return numberOfSeats * activeBus.TotalFare;
    }, [selectedSeats, activeBus]);

    const openNotification = () => {
    notification.open({
        message: 'Departure bus selected',
        placement: 'bottom',
        type: 'success',
    });
  };

  const selectedSeatNumbers = useMemo(() => {
    return selectedSeats.map(seat => seat.SeatNumber);
  }, [selectedSeats]);

  const onBusClick = (bus: AvailableBus) => {
    setActiveBus(bus);
    setIsOpened(true);
  };

  const onSeatSelect = (seats: AvailableSeat[]) => {
      setSelectedSeats(seats);

      if(!isReturning) {
          const numberOfPAX = seats.length;
          updateFormData({ numberOfAdults: numberOfPAX });
      }
  }

  const onSeatSelectFinish = () => {
      setIsOpened(false);
      if(onlyDepartureSelected) {
          openNotification();
      }

      handleSelectBus(result, activeBus, selectedSeats);
  }

    const Footer = (
        <SeatFooterWrapper>
            <SeatsNumberWrapper>
                <StyledParagraph size="default">
                    Seats selected:

                    {selectedSeatNumbers.length > 0 && <span>
                        &nbsp;Seat No. {selectedSeatNumbers.join(', ')}
                    </span>}
                </StyledParagraph>
            </SeatsNumberWrapper>

            <SpaceBetweenAlignContent>
                <StyledParagraph size="default">
                    Total for all passengers
                </StyledParagraph>

                <StyledTitle level={3}>
                    {formatNumberAsCurrency(totalFare || 0, result?.Currency)}
                </StyledTitle>
            </SpaceBetweenAlignContent>

            <Button block size="large" type="primary" onClick={onSeatSelectFinish} disabled={disableButton}>
                Continue
            </Button>
        </SeatFooterWrapper>
    )

    return (
        <BusDetailsWrapper ref={drawerContainerRef}>
            {
                isMobile ? (
                    <div>
                        {
                            result.AvailableBuses.map((bus, index) => {
                                return (
                                    <BusCard onClick={()=>onBusClick(bus)} key={index} >
                                        <Row>
                                            <Col span={7}>
                                                <BusCardImage>
                                                    <img src={getRoadTransportLogo(result.AgentName)} alt={result.AgentName} width={50} />
                                                    <BusImage src={getBusTypeImage(bus.Capacity)} alt="bus" />
                                                </BusCardImage>
                                            </Col>

                                            <Col span={17}>
                                                <BusCardContent>
                                                    <div>
                                                        {
                                                            (cheapestFare === bus.TotalFare) &&  <CheapestFareTag>
                                                                Cheapest fare
                                                            </CheapestFareTag>
                                                        }
                                                        <StyledTitle level={5}>
                                                            {bus.BusType}
                                                        </StyledTitle>
                                                    </div>

                                                    <BusStepWrapper>
                                                        <div>
                                                            <StyledTitle level={5}>
                                                                {result.DepartureTerminal}
                                                            </StyledTitle>
                                                            <StyledParagraph size="default">
                                                                {
                                                                    bus.BoardingDetails && `${bus.BoardingDetails.Name}`
                                                                }
                                                            </StyledParagraph>
                                                        </div>

                                                        <StepsContainer>
                                                            <StepsWrapper>
                                                                <StepProgressLine />
                                                                <StepItemWrapper>
                                                                    <StepItemContent />
                                                                </StepItemWrapper>

                                                                <StepProgressLine />
                                                            </StepsWrapper>
                                                        </StepsContainer>

                                                        <div>
                                                            <StyledTitle level={5} align="left">
                                                                {result.ArrivalTerminal}
                                                            </StyledTitle>
                                                            <StyledParagraph size="default">
                                                                {
                                                                    bus.DropOffDetails && `${bus.DropOffDetails.Name}`
                                                                }
                                                            </StyledParagraph>
                                                        </div>
                                                    </BusStepWrapper>

                                                    <TagsWrapper>
                                                        <Tag>
                                                            <PassengerIcon />
                                                            {
                                                                pluralizeText(
                                                                    bus.TotalAvailableSeats,
                                                                    t(translations).seatAvailable,
                                                                    t(translations).seatsAvailable,
                                                                )
                                                            }
                                                        </Tag>
                                                        <Tag>
                                                            <ClockIcon />
                                                            {bus.EstimatedDepartureTime}
                                                        </Tag>
                                                    </TagsWrapper>
                                                </BusCardContent>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col span={24}>
                                                <StyledTitle level={4} align="right" style={{ padding: "12px" }}>
                                                    {formatNumberAsCurrency(bus.TotalFare, result.Currency)}
                                                </StyledTitle>
                                            </Col>
                                        </Row>
                                    </BusCard>
                                )
                            })
                        }
                    </div>
                ) : (
                    <FlightCardsWrapper>
                        {
                            result.AvailableBuses.map((bus, index) => {
                                return (
                                    <Row key={index}>
                                        <Col span={17} style={{ display: "flex" }}>
                                            <BusCard rightBordered>
                                                <Row>
                                                    <Col span={7}>
                                                        <BusCardImage>
                                                            <img src={getRoadTransportLogo(result.AgentName)} alt={result.AgentName} width={50} />
                                                            <BusImage src={getBusTypeImage(bus.Capacity)} alt="bus" />
                                                        </BusCardImage>
                                                    </Col>
                                                    <Col span={17}>
                                                        <BusCardContent>
                                                            <SpaceBetweenAlignContent>
                                                                <StyledTitle level={3}>
                                                                    {bus.BusType}
                                                                </StyledTitle>
                                                                {
                                                                    (cheapestFare === bus.TotalFare) &&  <CheapestFareTag>
                                                                        Cheapest fare
                                                                    </CheapestFareTag>
                                                                }
                                                            </SpaceBetweenAlignContent>

                                                            <BusStepWrapper>
                                                                <div>
                                                                    <StyledTitle level={4}>
                                                                        {result.DepartureTerminal}
                                                                    </StyledTitle>
                                                                    <StyledParagraph size="default">
                                                                        {
                                                                            bus.BoardingDetails && `${bus.BoardingDetails.Name}`
                                                                        }
                                                                    </StyledParagraph>
                                                                </div>

                                                                <StepsContainer>
                                                                    <StepsWrapper>
                                                                        <StepProgressLine />
                                                                        <StepItemWrapper>
                                                                            <StepItemContent>
                                                                                <BusIcon />
                                                                            </StepItemContent>
                                                                        </StepItemWrapper>

                                                                        <StepProgressLine />
                                                                    </StepsWrapper>
                                                                </StepsContainer>

                                                                <div>
                                                                    <StyledTitle level={4} align="left">
                                                                        {result.ArrivalTerminal}
                                                                    </StyledTitle>
                                                                    <StyledParagraph size="default">
                                                                        {
                                                                            bus.DropOffDetails && `${bus.DropOffDetails.Name}`
                                                                        }
                                                                    </StyledParagraph>
                                                                </div>
                                                            </BusStepWrapper>

                                                            <TagsWrapper>
                                                                <Tag>
                                                                    <PassengerIcon />
                                                                    {
                                                                        pluralizeText(
                                                                            bus.TotalAvailableSeats,
                                                                            t(translations).seatAvailable,
                                                                            t(translations).seatsAvailable,
                                                                        )
                                                                    }
                                                                </Tag>
                                                                <Tag>
                                                                    <ClockIcon />
                                                                    {bus.EstimatedDepartureTime}
                                                                </Tag>
                                                            </TagsWrapper>
                                                        </BusCardContent>
                                                    </Col>
                                                </Row>
                                            </BusCard>
                                        </Col>

                                        <Col span={7} style={{ display: "flex" }}>
                                            <BusCard leftBordered>
                                                <BusCardPriceContent>
                                                    <CentralizedContent>
                                                        <div>
                                                            <FareWrapper>
                                                                <StyledTitle level={4} align="center">
                                                                    {formatNumberAsCurrency(bus.TotalFare, result.Currency)}
                                                                </StyledTitle>
                                                            </FareWrapper>
                                                            <Button
                                                                type="primary"
                                                                block
                                                                onClick={() => onBusClick(bus)}
                                                            >
                                                                Select seats
                                                            </Button>
                                                        </div>
                                                    </CentralizedContent>
                                                </BusCardPriceContent>
                                            </BusCard>
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </FlightCardsWrapper>
                )
            }

            <Drawer
                title="Seat selection"
                placement={ isMobile ? "bottom" : "right"}
                onClose={() => setIsOpened(false)}
                open={isOpened}
                footer={Footer}
                forceRender={true}
                height="100%"
                getContainer={drawerContainerRef.current as HTMLElement}
            >
                {
                    activeBus && <SeatSelection bus={activeBus} onSeatSelect={onSeatSelect} limitSelection={isReturning} />
                }
            </Drawer>
        </BusDetailsWrapper>
    );
}

export default BusDetails;
