import React, { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthenticatedRoute from './AuthenticatedRoute';
import { useWaterSearchStore } from '../store/waterSearch/waterSearchStore';

type Props = {
  children: ReactNode
}
function WaterDataFilled({ children }: Props) {
  const navigate = useNavigate();
  const {
    formData,
  } = useWaterSearchStore();

  useEffect(() => {
    if (!formData.DepartureId) {
      navigate('/search/water');
    }
  }, [formData, navigate]);
  return (
    <AuthenticatedRoute>
      {children}
    </AuthenticatedRoute>
  );
}

export default WaterDataFilled;
