import React, {useRef, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Col, Row, Modal
} from 'antd';
import {
  Wrapper, BookingFooterWrapper,
} from './styles';
import {
  useFlightSearchStore,
} from '../../store/flightSearch/flightSearchStore';
import {
  postMessage,
} from '../../utils/helpers';
import { useBookingStore } from '../../store/booking/bookingStore';
import { EXTERNAL_PAYMENT_CHANNEL } from '../../utils/constants';
import PaymentHeaderNavigation from "../../components/HeaderNavigation/Payment";
import useIsMobile from "../../hooks/useIsMobile";
import FlightReceipt from "../../components/ReceiptCard/FlightReceipt";
import RoadReceipt from "../../components/ReceiptCard/RoadReceipt";
import WaterReceipt from "../../components/ReceiptCard/WaterReceipt";
import TrainReceipt from "../../components/ReceiptCard/TrainReceipt";
import {StyledButton} from "../../components/CustomAntdComponents/StyledButton";
import BookingFeedback from "../../components/BookingFeedback";
import FeedbackForm from "../../components/FeedbackForm";
import {useRoadSearchStore} from "../../store/roadSearch/roadSearchStore";
import FlightPayment from "./FlightPayment";
import RoadPayment from "./RoadPayment";
import Done from "../../components/FeedbackForm/Done";

function Payment() {
  const { isMobile } = useIsMobile();
  const [openBooking, setOpenBooking] = useState<boolean>(false);
  const [openFeedback, setOpenFeedback] = useState<boolean>(false);
  const [openFeedbackForm, setOpenFeedbackForm] = useState<boolean>(false);
  const [openFeedbackDone, setOpenFeedbackDone] = useState<boolean>(false);
  const [reaction, setReaction] = useState("");
  const navigate =  useNavigate();

  const drawerContainerRef = useRef(null);
  const { tripType } = useParams();
  const {
    resetFormData
  } = useFlightSearchStore();

  const {
    resetFormData: resetRoadSearchData
  } = useRoadSearchStore();

  const {
    bookingPayload, resetBooking
  } = useBookingStore();

  const proceedPayment = () => {
    // Notify App
    // Check payment channel
    const auth: UserAuth = JSON.parse(localStorage.getItem('trips_auth') || '{}');
    if (auth.ExtraData?.PaymentChannel === EXTERNAL_PAYMENT_CHANNEL) {
      postMessage(bookingPayload);
      // this part is commented out to remove the sucessfull screen temporily
      // if(isMobile) {
      //   navigate(`/payment-receipt/${tripType}`)
      // } else {
      //   setOpenBooking(true);
      // }
    }
    else {
      // this part is commented out to remove the sucessfull screen temporily
      // if(isMobile) {
      //   navigate(`/payment-receipt/${tripType}`)
      // } else {
      //   setOpenBooking(true);
      // }
    }
  };


  const renderTripReceipt = () => {
    switch (tripType) {
      case 'flight':
        return <FlightReceipt />;
      case 'road':
        return <RoadReceipt />;
      case 'water':
        return <WaterReceipt />;
      case 'rail':
        return <TrainReceipt />;
        default:
            return null;
    }
  }

  const redirectToSearch = () => {
    resetBooking();

    if(tripType === 'flight') {
      resetFormData();
    }

    if(tripType === 'road') {
      resetRoadSearchData();
    }


    navigate(`/search/${tripType}`);
  }

  const BookingFooter = (
        <StyledButton mode="primary" block onClick={() => {
          setOpenBooking(false);
          setOpenFeedback(true);
        }}>
          Done
        </StyledButton>
  )

  const FeedbackFooter = (
      <StyledButton mode="default" block onClick={redirectToSearch}>
        Not now
      </StyledButton>
  )

  const FeedbackFormFooter = (
      <StyledButton mode="primary" block onClick={() => {
        setOpenFeedbackForm(false);
        setOpenFeedbackDone(true);
      }}>
        Submit feedback
      </StyledButton>
  )

  const FeedbackDoneFooter = (
      <StyledButton mode="primary" block onClick={redirectToSearch}>
        Done
      </StyledButton>
  )

  const renderTripPayment = () => {
    switch (tripType) {
        case 'flight':
            return <FlightPayment handlePayment={proceedPayment} />
        case 'road':
            return <RoadPayment handlePayment={proceedPayment} />
        default:
            return null;
    }
  };

  return (
      <Wrapper ref={drawerContainerRef}>
        <Row>
          <Col span={24}>
            <PaymentHeaderNavigation />
          </Col>
        </Row>

        {
          renderTripPayment()
        }

        {
            !isMobile && (
                <>
                  <Modal
                      centered
                      open={openBooking}
                      footer={BookingFooter}
                      onCancel={() => setOpenBooking(false)}
                      width={650}
                      getContainer={drawerContainerRef.current as HTMLElement}
                  >
                    {renderTripReceipt()}
                  </Modal>
                  <Modal
                      centered
                      open={openFeedback}
                      footer={FeedbackFooter}
                      onCancel={() => setOpenFeedback(false)}
                      width={650}
                      getContainer={drawerContainerRef.current as HTMLElement}
                  >
                    <BookingFeedback openFeedbackForm={(reaction: string) => {
                      setReaction(reaction)
                      setOpenFeedback(false);
                      setOpenFeedbackForm(true);
                    }} hideAction />
                  </Modal>
                  <Modal
                      centered
                      open={openFeedbackForm}
                      footer={FeedbackFormFooter}
                      onCancel={() => setOpenFeedbackForm(false)}
                      width={650}
                      getContainer={drawerContainerRef.current as HTMLElement}
                  >
                    <FeedbackForm reaction={reaction} />
                  </Modal>
                  <Modal
                      centered
                      open={openFeedbackDone}
                      footer={FeedbackDoneFooter}
                      onCancel={redirectToSearch}
                      width={650}
                      getContainer={drawerContainerRef.current as HTMLElement}
                  >
                    <Done />
                  </Modal>
                </>
            )
        }
      </Wrapper>
  );
}

export default Payment;
