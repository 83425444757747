import React, {useMemo} from 'react';
import {NameContent, NameWrapper, SearchItem, SectionTitle} from "./styles";
import {StyledParagraph, StyledText, StyledTitle} from "../CustomAntdComponents/Typography";
import { useStore } from "../../store/global/appStore";
import { FixedSizeList as List } from 'react-window';
import {SuggestionType} from "./index";
import styled from "styled-components";

const suggestedSearches: SuggestionType[] = [
    {
        name: "Lagos",
        label: "Okota",
        value: "56"
    },
    {
        name: "Lagos",
        label: "Ajah",
        value: "64"
    },
    {
        name: "Lagos",
        label: "Jibowu",
        value: "72"
    },
    {
        name: "Abia",
        label: "Aba",
        value: "40"
    },
    {
        name: "Imo",
        label: "Owerri",
        value: "51"
    },
];

const SelectRoadSearches = ({ onSelect, searchText, height = 500, limitSuggestionHeight }: { limitSuggestionHeight?: boolean, height?: number, onSelect: (airport: string) => void; searchText: string; }) => {
    const { roadRoutes} = useStore();

    const filteredOptions = useMemo(() => {
        const textLower = searchText.toLowerCase();
        return roadRoutes.filter((option) => {
            return (option?.TerminalName).toLowerCase().includes(textLower);
        });
    }, [roadRoutes, searchText]);

    const filteredLength = filteredOptions.length;
    const suggestionHeight = limitSuggestionHeight ? `${height}px` : "100%";

    return (
        <div>
            {
                searchText ? (
                    <>
                        {
                            filteredLength === 0 && (
                                <Content height={suggestionHeight}>
                                    <SectionTitle>
                                        <StyledParagraph align="center" size="normal">
                                            No results found.
                                        </StyledParagraph>
                                    </SectionTitle>
                                </Content>
                            )
                        }
                        <List
                            height={height}
                            itemCount={filteredLength}
                            itemSize={50}
                            width={"100%"}
                        >
                            {({ index, style }) => {
                                const option = filteredOptions[index];
                                return (
                                    <div style={{...style, paddingLeft: "16px", paddingRight: "16px"}}>
                                        <SearchItem key={index} onClick={() => onSelect(option.TerminalId.toString())}>
                                            <NameWrapper>
                                                <NameContent>
                                                    <StyledTitle level={5}>{option.TerminalName}</StyledTitle>
                                                </NameContent>
                                            </NameWrapper>
                                        </SearchItem>
                                    </div>
                                )
                            }}
                        </List>
                    </>
                ) : (
                    <Content height={suggestionHeight}>
                        <SectionTitle>
                            <StyledText>
                                Suggested Searches
                            </StyledText>
                        </SectionTitle>
                        {
                            suggestedSearches.map((search, index) => (
                                <SearchItem key={index} onClick={() => onSelect(search.value)}>
                                    <NameWrapper>
                                        <NameContent>
                                            <StyledTitle level={5}>{search.name}</StyledTitle>
                                            <StyledText>{search.label}</StyledText>
                                        </NameContent>
                                    </NameWrapper>
                                    {search?.code && <StyledTitle level={4}>{search?.code}</StyledTitle>}
                                </SearchItem>
                            ))
                        }
                    </Content>
                )}
        </div>
    )
};

const Content = styled.div<{ height: string }>`
    padding: 0 16px;
    max-height: ${({ height }) => `${height}`};
    overflow-y: auto;
`;

export default SelectRoadSearches;
