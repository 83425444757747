import moment from "moment";

export const getYearOptions = (startYearIndex = 0) => {
    const currentYear = moment().add(startYearIndex, 'year').year();

    const years = [];
    for (let i = currentYear; i >= 1900; i--) {
        years.push({ label: i.toString(), value: i });
    }
    return years;
}

export const getMonthOptions = () => {
    const months = [];
    for (let i = 1; i <= 12; i++) {
        months.push({ label: moment().month(i - 1).format('MMMM'), value: i.toString().padStart(2, '0') });
    }
    return months;
}

export const getDayOptions = () => {
    const days = [];
    for (let i = 1; i <= 31; i++) {
        days.push({ label: i.toString(), value: i.toString().padStart(2, '0') });
    }
    return days;
}
