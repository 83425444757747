import React from 'react';
import { Space } from 'antd';
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import moment from "moment";
import {TitleWrapper} from "./styles";
import { ReactComponent as RightArrowIcon } from "../../assets/images/arrow-right.svg";

type Props = {
   operator: BusResultItem,
    bus: AvailableBus,
    label: string
}

const RoadHeading = ({ operator, bus, label }: Props) => {
    return (
        <TitleWrapper>
            <StyledParagraph size="default">
                {label}
            </StyledParagraph>
            <Space>
                <StyledTitle level={4}>
                    {operator.DepartureTerminal}
                </StyledTitle>

                <RightArrowIcon />

                <StyledTitle level={4}>
                    {operator.ArrivalTerminal}
                </StyledTitle>
            </Space>

            <StyledParagraph>
                {moment(bus.EstimatedDepartureDate).format('ddd, MMM DD')}
            </StyledParagraph>
        </TitleWrapper>
    );
};

export default RoadHeading;
