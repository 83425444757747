import styled, { css } from 'styled-components';
import {
  Divider, Card,
} from 'antd';
import device from '../../utils/mediaQuery';

export const Wrapper = styled.div`
  margin-top: 15px;

  .ant-space {
    display: flex;
    margin-top: 15px;
  }
  
  .ant-typography {
    margin-bottom: 0;
  }

  @media ${device.large} {
    margin-top: 0;
  }
`;

export const HeaderWrapper = styled.div`
 margin-bottom: 10px;
`;

export const HeaderBusDetails = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0 10px;
  column-gap: 15px;

  img {
      margin-right: 5px;
      width: 100px;
  }

  @media ${device.large} {
    //display: block;
    text-align: center;
    justify-content: center;
    
    .ant-typography {
      text-align: center;
      margin: 10px 0 !important;
    }
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 0;
`;

export const StyledCard = styled(Card)`
  @media ${device.large} {
    padding: 20px;
  }
`;

export const ColumnItem = styled.div<{ index?: number}>`
  background: #fff;
  flex: 1;
  padding: 0 20px;

  @media ${device.extraSmall} {
    background: none;
  }
  
  @media ${device.large} {
    background: #fff;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    ${({ index }) => index === 1 && css`
      border-radius: 5px 0 0 5px;
    `}
    
    ${({ index }) => index === 2 && css`
      border-radius: 0;
    `}
    
    ${({ index }) => index === 3 && css`
      border-radius: 0 5px 5px 0;
    `}
  }
`;

export const TextWrapper = styled.div`
  margin-bottom: 10px;
`;

export const RowWrapper = styled.section`
  background: #EEEDF9;
  margin-top: 15px;
  border-radius: 5px;

  @media ${device.extraSmall} {
    background: #fff;
  }

  @media ${device.large} {
    background: #EEEDF9;
  }
`;

export const ItemWrapper = styled(Card)`
  margin: 15px 0;
`;

export const DetailsWrapper = styled.div<{ rightAlign?: boolean}>`
    display: flex;
    align-items: center;
    column-gap: 15px;
    
    ${({ rightAlign }) => rightAlign && css`
        justify-content: flex-end;
    `}
`;
