/* eslint-disable react/no-array-index-key */
import React, {useEffect} from 'react';
import { useFlightSearchStore } from '../../store/flightSearch/flightSearchStore';
import {useNavigate} from "react-router-dom";
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import moment from "moment";
import {pluralizeText} from "../../utils/helpers";
import styled from "styled-components";
import {GREY_4, WHITE} from "../../utils/colors";
import { ReactComponent as RightArrowIcon } from "../../assets/images/arrow-right-grey.svg";
import {useRoadSearchStore} from "../../store/roadSearch/roadSearchStore";

function RoadBookingSummary() {
  const {
    selectedResult,
    selectedBus,
      bookingContact,
      passengers,
      formData: {
        TripType,
          numberOfAdults,
      }
  } = useRoadSearchStore();

  const isRoundTrip = TripType === 'round_trip';
  const totalPassengers = numberOfAdults;

  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedResult) {
      navigate('/search/road');
    }
  }, [selectedResult]);

  const departureOperator = selectedResult[0];
  const returningOperator = selectedResult[1];

  const departureBus = selectedBus[0];
    const returningBus = selectedBus[1];

  return (
      <div>
        <>
          <Section>
            <StyledTitle level={5}>
              Departure
            </StyledTitle>

            <FlightCode>
              <StyledTitle level={4}>
                {departureOperator.DepartureTerminal}
              </StyledTitle>
              <RightArrowIcon />
              <StyledTitle level={4}>
                {departureOperator.ArrivalTerminal}
              </StyledTitle>
            </FlightCode>

            <StyledParagraph size="default">
              {moment(departureBus.EstimatedDepartureDate).format('ddd, D MMM')} | {pluralizeText(totalPassengers, 'Passenger', 'Passengers')}
            </StyledParagraph>
          </Section>

          {isRoundTrip && returningOperator && (
              <Section>
                <StyledTitle level={5}>
                  Return
                </StyledTitle>

                <FlightCode>
                  <StyledTitle level={4}>
                    {returningOperator.DepartureTerminal}
                  </StyledTitle>
                  <RightArrowIcon />
                  <StyledTitle level={4}>
                    {returningOperator.ArrivalTerminal}
                  </StyledTitle>
                </FlightCode>


                <StyledParagraph size="default">
                  {moment(returningBus.EstimatedDepartureDate).format('ddd, D MMM')} | {pluralizeText(totalPassengers, 'Passenger', 'Passengers')}
                </StyledParagraph>
              </Section>
          )}
        </>

        {
          passengers && (
              <Section>
                <StyledTitle level={5}>
                  Passengers
                </StyledTitle>

                <Content>
                  {passengers.map((p, key) => (
                      <StyledParagraph size="default" key={key}>
                        {p.FirstName} {p.LastName}
                      </StyledParagraph>
                  ))}
                </Content>
              </Section>
            )
        }

        {
          bookingContact && (
              <Section>
                <StyledTitle level={5}>
                  Contact details (This is where we will send your booking details)
                </StyledTitle>

                <Content>
                  <StyledParagraph size="default">
                    Email: {bookingContact.email}
                  </StyledParagraph>
                  <StyledParagraph size="default">
                    Mobile: {bookingContact.phone}
                  </StyledParagraph>
                </Content>
              </Section>
            )
        }
      </div>
  )
}

const Content = styled.div`
  margin-top: 10px;
`;

const FlightCode = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  margin: 10px 0 5px;
`;

const Section = styled.div`
  background: ${WHITE};
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 20px;
  border: solid 1px ${GREY_4};
`;

export default RoadBookingSummary;
