import React, { useState, ChangeEvent } from 'react';
import {Input, InputProps} from 'antd';
import {PrefixIconWrapper, Wrapper} from './styles';

type Props = {
  onChange?: (value: string) => void;
  filled?: boolean;
  icon?: React.ReactNode
} & InputProps
function StyledInput({
  placeholder, size, filled, onChange, type, icon, value
}: Props) {
  const [focus, setFocus] = useState(false);

  const hasValue = focus || (value);
  const labelClass = hasValue ? 'label as-label' : 'label as-placeholder';

  const handleChange = (input: ChangeEvent<HTMLInputElement>) => {
    input.preventDefault();
    if (onChange) { onChange(input.target.value); }
  };

  return (
    <Wrapper
      hasPrefix={Boolean(icon)}
      filled={filled}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Input size={size} onChange={handleChange} defaultValue={value} value={value} type={type} />
      <span className={labelClass}>
        {hasValue ? placeholder : placeholder}
      </span>
        {icon && <PrefixIconWrapper>
            {icon}
        </PrefixIconWrapper> }
    </Wrapper>
  );
}

StyledInput.defaultProps = {
  size: 'large',
  placeholder: undefined,
  onChange: undefined,
  filled: undefined,
  type: 'text',
};
export default StyledInput;
