import React from 'react';
import {
    Row, Col, Form,
} from 'antd';
import StyledInput from '../CustomAntdComponents/StyledInput';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/PassengerDetails/translations';
import PhoneNumberInput from "../PhoneNumberInput";
import {ContactFormWrapper} from "./styles";
import {validateEmailRequired, validatePhoneRequired} from "../../utils/helpers";

function ContactForm() {
    const {
        state: { t },
    } = useI18nContext();

    return (
        <ContactFormWrapper>
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <Form.Item
                        name="contactEmail"
                        label="Email address"
                        rules={[{ validator: validateEmailRequired, message: t(translations).validations.emailInvalid }]}
                    >
                        <StyledInput placeholder="" />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        name="contactPhone"
                        label="Phone number"
                        required
                        rules={[
                            { validator: validatePhoneRequired, message: t(translations).validations.contactPhone }
                        ]}
                    >
                        <PhoneNumberInput
                            name={"contactPhone"}
                            placeholder=""
                        />
                    </Form.Item>
                </Col>
            </Row>
        </ContactFormWrapper>
    );
}

export default ContactForm;
