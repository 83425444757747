import styled from 'styled-components';
import {BLACK_100, BLACK_300, GREY, GREY_10, GREY_2, PRIMARY, WHITE} from '../../utils/colors';
import device from '../../utils/mediaQuery';

export const Wrapper = styled.section`
  padding: 40px 25px 120px;

  .ant-space {
    display: flex;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  margin: 0 auto;
  border-radius: 50%;
  background: ${GREY_10};
  border: solid 4px ${PRIMARY};
`;

export const NavImageIcon = styled.div`
  padding: 10px 10px 5px;
  box-sizing: content-box;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
  width: auto;
 display: inline-block;
`;

export const NavWrapper = styled.section`
  text-align: right;
  margin: 14px 0 28px;
`;

export const InfoWrapper = styled.div`
   width: 300px;
   display: flex;
   flex-direction: column;
   row-gap: 10px;
 
   @media(${device.large}) {
    width: 400px;
   }
`;

export const TransactionWrapper = styled.div`
  border-top: 1px dashed ${BLACK_100};
    padding-top: 15px;
    margin-top: 20px;
`;

export const TransactionItem = styled.div`
   margin-top: 5px;
   display: flex; 
    justify-content: space-between;
 
   .ant-typography {
     font-size: 14px;
      
    color: ${GREY};
   }
 
   h5.ant-typography {
    color: ${BLACK_300};
   }
 
    @media(${device.large}) {
        .ant-typography {
            font-size: 16px;
        }
    }
`;

export const ItineraryWrapper = styled.div`
     flex: 1;
    background: ${WHITE};
    width: 100%;
    padding: 25px;
   border-radius: 12px;
 
      @media(${device.large}) {
       background: ${GREY_2};
      }
`;

export const ContentWrapper = styled.section`
    display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
   row-gap: 25px;
   width: 100%;
`;

export const ListItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #EFEFEF;
  & * {
    padding: 10px 0;
  }
`;

export const ListWrapper = styled.div`
 ${ListItemWrapper} {
   &:last-of-type {
     border-bottom: none;
   }
 }
`;

export const TextWrapper = styled.div`
  margin: 30px auto;
  width: 60%;
`;

export const ActionWrapper = styled.div`
    position: fixed;
    bottom: 0;
      left: 0;  
      right: 0;
 background: ${WHITE};
  padding: 25px 20px;
`;
