import React, { useState, useEffect, useCallback } from 'react';
import {
  ContentWrapper, IndicatorContent,
  IndicatorWrapper, PadContentWrapper,
  PadWrapper, SeatWrapper,
  Wrapper,
} from './styles';
import { StyledParagraph } from '../CustomAntdComponents/Typography';
import { useRoadSearchStore } from '../../store/roadSearch/roadSearchStore';
import { groupBy } from '../../utils/helpers';
import steering from '../../assets/images/steering.svg';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/PassengerDetails/translations';

type Props = {
  bus: AvailableBus;
  onSeatSelect: (arg: AvailableSeat[]) => void;
  limitSelection: boolean;
}

function SeatSelection({ bus, onSeatSelect, limitSelection }: Props) {
  const {
    state: { t },
  } = useI18nContext();
  const [isCompleted, setIsCompleted] = useState(false);
  const [busSize, setBusSize] = useState('');
  const [column, setColumn] = useState(1);
  const [selectedSeats, setSelectedSeats] = useState<AvailableSeat[]>([]);
  const [sortedMapSeats, setSortedMapSeats] = useState<AvailableSeat[][]>([]);
  const { formData: { numberOfAdults }, selectedBus } = useRoadSearchStore();
  const totalPassengers = numberOfAdults;

  const getSeatStatus = (seat: AvailableSeat) => {
    const findSeat = selectedSeats.find((s) => s.SeatNumber === seat.SeatNumber);
    if (!findSeat) {
      return seat.IsAvailable ? 'available' : 'booked';
    }

    return 'selected';
  };
  const getIsDriverStatus = (seat: AvailableSeat) => seat.Row === 1 && seat.Column === 1;

  const handleSeatSelection = (seat: AvailableSeat) => {
    const findSeat = selectedSeats.find((s) => s.SeatNumber === seat.SeatNumber);

    if (!findSeat && seat.IsAvailable && !isCompleted) {
      setSelectedSeats([...selectedSeats, { ...seat }]);
    } else {
      const newSelections = selectedSeats.filter((s) => s.SeatNumber !== seat.SeatNumber);
      setSelectedSeats([...newSelections]);
    }
  };

  const getSeatWidth = useCallback(() => {
    const numberOfSeats = bus?.Capacity || 0;
    setColumn(bus?.Column || 1);

    let size: string;

    if (numberOfSeats >= 50) {
      size = 'large';
    } else if (numberOfSeats >= 12 && numberOfSeats <= 15) {
      size = 'medium';
    } else if (numberOfSeats >= 8 && numberOfSeats <= 12) {
      size = 'medium-s';
    } else {
      size = 'small';
    }
    setBusSize(size);
  }, [bus]);

  // eslint-disable-next-line prefer-const
  let allSort = [];

  const displaySeatPosition = (sortSeats) => {
    sortSeats.forEach((rows:any, rowIndex) => {

      let mapSortedSeats = [];

      for (let colIndex = 1; colIndex <= column; colIndex++) {

        const checkRow = rows?.find((item :AvailableSeat) => item.Column === colIndex);
        if (checkRow) {
          mapSortedSeats.push(checkRow);
        } else {
          mapSortedSeats.push({
            IsAvailable: false,
            SeatNumber: "0",
            Price: 0,
            Row: rowIndex + 1,
            Column: colIndex,
          });
        }
      }
      allSort.push(mapSortedSeats);
    });
    return allSort;
  };

  useEffect(() => {
    if(!limitSelection) {
      setIsCompleted(false);
    } else {
        setIsCompleted(selectedSeats.length === totalPassengers);
    }
  }, [selectedSeats, totalPassengers, selectedBus]);

  useEffect(() => {
    if (bus && bus.AvailableSeats) {

      const groupedValue = groupBy(bus.AvailableSeats, (seat: AvailableSeat) => seat.Row);
      const groupedSeats = Array.from(groupedValue, ([_, value]) => (
        value));

      getSeatWidth();
      const checkMappedSeat = displaySeatPosition(groupedSeats);
      setSortedMapSeats(checkMappedSeat);
    }
  }, [selectedBus, busSize]);

  useEffect(() => onSeatSelect(selectedSeats), [selectedSeats]);

  return (
    <Wrapper>
      <ContentWrapper size={busSize}>
        <IndicatorWrapper>
          <IndicatorContent>
            <SeatWrapper />
            <StyledParagraph type="secondary" size="small" align="center">
              {t(translations).available}
            </StyledParagraph>
          </IndicatorContent>
          <IndicatorContent>
            <SeatWrapper status="booked" />
            <StyledParagraph type="secondary" size="small" align="center">
              {t(translations).booked}
            </StyledParagraph>
          </IndicatorContent>
          <IndicatorContent>
            <SeatWrapper status="selected" />
            <StyledParagraph type="secondary" size="small" align="center">
              {t(translations).selected}
            </StyledParagraph>
          </IndicatorContent>
        </IndicatorWrapper>

        <PadContentWrapper>
          {sortedMapSeats.map((seatArr) => (
              <PadWrapper column={column}>
                {seatArr.map((seat) => (
                    <SeatWrapper
                        key={seat.SeatNumber}
                        status={getSeatStatus(seat)}
                        onClick={() => handleSeatSelection(seat)}
                        isEmpty={seat.SeatNumber === "0" && !getIsDriverStatus(seat)}
                        isDriver={getIsDriverStatus(seat)}
                    >
                      {getIsDriverStatus(seat) ? <img src={steering} alt="" /> : seat.SeatNumber}
                    </SeatWrapper>
                ))}

              </PadWrapper>
          ))}
        </PadContentWrapper>
      </ContentWrapper>
    </Wrapper>
  );
}

export default SeatSelection;
