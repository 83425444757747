import styled from 'styled-components';
import { FONT_SIZE } from '../../utils/colors';

export const Wrapper = styled.section`
  background: linear-gradient(279.27deg,#dfdfdf 1.45%,#ffffff 87.41%);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:  calc(16px *  ${FONT_SIZE}) !important;
`;
