import React from 'react';
import {
  Row, Col, Space,
} from 'antd';
import {
  ColumnItem,
  Wrapper,
  RowWrapper,
  HeaderBusDetails,
} from './styles';
import {
  StyledParagraph, StyledText, StyledTitle,
} from '../CustomAntdComponents/Typography';
import {
  CentralizedContent, GiveMeSpace, SpaceBetweenAlignContent,
} from '../CustomAntdComponents/Utitlities';
import { formatNumberAsCurrency } from '../../utils/helpers';
import { StyledButton } from '../CustomAntdComponents/StyledButton';
import { HighlightDetailItem } from '../FlightItinerary/styles';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/SearchWaterListings/translations';

type Props = {
  listings: RailResultItem,
  onBookingRedirect: (arg: AvailableTrain) => void
}
function OperatorTrain({ listings, onBookingRedirect }: Props) {
  const {
    state: { t },
  } = useI18nContext();
  return (
    <Wrapper>
      <Space direction="vertical" size="large">
        <StyledTitle level={4}>
          {t(translations).title}
           &nbsp;
        </StyledTitle>
      </Space>

      {listings.AvailableTrains.map((train) => (
        <RowWrapper key={train.RecommendationId}>
          <Row gutter={4}>
            <Col xs={24} lg={8} style={{ display: 'flex' }}>
              <ColumnItem index={1}>
                <Row align="middle" gutter={16}>
                  <Col xs={24} lg={24}>
                    <HeaderBusDetails>
                      <StyledTitle level={3}>15609</StyledTitle>
                      <StyledTitle level={5} color="primary">Abadh Assam Express</StyledTitle>
                    </HeaderBusDetails>
                  </Col>
                  <Col xs={24} lg={0}>
                    <StyledParagraph type="secondary" strong size="large">
                      {formatNumberAsCurrency(listings.StartingPrice, listings.Currency)}
                    </StyledParagraph>
                    <GiveMeSpace />
                  </Col>
                </Row>
              </ColumnItem>
            </Col>
            <Col xs={24} lg={8} style={{ display: 'flex' }}>
              <ColumnItem index={2}>
                <Row>
                  <Col span={24}>
                    <HighlightDetailItem>
                      <SpaceBetweenAlignContent>
                        <StyledText>
                          Sat 12 Mar
                        </StyledText>

                        <StyledText>
                          9:00 am
                        </StyledText>
                      </SpaceBetweenAlignContent>
                    </HighlightDetailItem>
                  </Col>
                </Row>
              </ColumnItem>
            </Col>
            <Col xs={24} lg={8} style={{ display: 'flex' }}>
              <ColumnItem index={3}>
                <Row>
                  {/* >= LG devices */}
                  <Col xs={0} lg={24}>
                    <Space direction="vertical" size="small">
                      <StyledTitle level={4} align="center">
                        {formatNumberAsCurrency(listings.StartingPrice, listings.Currency)}
                      </StyledTitle>
                      <CentralizedContent>
                        <StyledButton padded="large" type="primary" onClick={() => onBookingRedirect(train)}>
                          {t(translations).book}
                        </StyledButton>
                      </CentralizedContent>
                    </Space>
                  </Col>

                  <Col xs={24} lg={0}>
                    <GiveMeSpace size="small" />
                    <StyledButton size="large" type="primary" onClick={() => onBookingRedirect(train)} block style={{ marginBottom: '10px' }}>
                      {t(translations).book}
                    </StyledButton>
                  </Col>
                </Row>
              </ColumnItem>
            </Col>
          </Row>
        </RowWrapper>
      ))}
    </Wrapper>
  );
}

export default OperatorTrain;
