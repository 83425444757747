import boatLogo from '../../assets/images/boat/default-boat.svg';

export const getBoatTypeImage = () => boatLogo;
export const getBoatTransportLogo = () => boatLogo;

export const calculateTotalFare = (passengers: WaterPassengerFare[]) => {
  // TODO cater for other passenger types
  const adultFares = passengers.find((pf) => pf.PassengerType === 'ADT');
  const totalAmount = adultFares ? adultFares.TotalFare : 0;
  const totalCharges = adultFares ? adultFares.Fees : 0;

  return { totalCharges, totalAmount, ticketAmount: (totalAmount - totalCharges) };
};
