import styled, { css } from 'styled-components';
import { Typography } from 'antd';
import { GREY_10, PRIMARY } from '../../utils/colors';

const { Title, Paragraph } = Typography;

export const StyledTitle = styled(Title)``;
export const StyledParagraph = styled(Paragraph)`
  line-height: 159%;
  
  mark {
    color: ${PRIMARY};
    background: none;
  }
`;

export const Wrapper = styled.div`
  text-align: center;
  & * {
    text-align: center;
  }
  
  ${StyledTitle} {
    color: ${PRIMARY};
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  ${StyledParagraph} {
    &:last-of-type {
      margin-bottom: 20px;
    }
  }
`;

export const IconWrapper = styled.div<{ bordered?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  margin: 0 auto;
  border-radius: 50%;
  background: ${GREY_10};

  ${({ bordered }) => bordered && css`
    border: solid 4px ${PRIMARY};
  `};
`;
