import moment, { Moment } from 'moment';
import { SUPPORTED_LANGUAGES } from './constants';
import { parsePhoneNumber } from 'awesome-phonenumber';


interface CustomWindow extends Window {
	FlutterWebviewPlugin?: any; // Adjust type as necessary
  TripsMerchant?:any
}

declare var window: CustomWindow;

export const pluralizeText = (total: number, singularText: string, pluralText: string): string => (total === 1
  ? `1 ${singularText}`
  : `${total} ${pluralText}`);

export const formatNumberAsCurrency = (value: number, currency?: string) => {
  return `${currency || 'NGN'} ${value.toLocaleString('en-US')}`
};

export const formatNumber = (value: number) => value.toLocaleString('en-US');

export const getThemeName = () => {
  const auth = localStorage.getItem('trips_auth');
  if (auth) {
    const obj: UserAuth = JSON.parse(auth);
    return obj.ExtraData?.PrimaryColor;
  }

  return '';
};

export const getUser = () => {
  const user = localStorage.getItem('trips_user');

  if (user) {
    const obj: User = JSON.parse(user);

    return obj;
  }

  return null;
};

export const getExtraData = () => {
  const config = localStorage.getItem('trips_auth');

  if (config) {
    const obj = JSON.parse(config);

    return obj && obj.ExtraData;
  }

  return { MultiCityMaxRoute: null };
};

export const getFontSize = (size?: string) => {
  let fontSize = 1;
  switch (size) {
    case 'SMALL':
      fontSize = 0.8;
      break;
    case 'NORMAL':
      fontSize = 1;
      break;
    case 'MEDIUM':
      fontSize = 1.3;
      break;
    case 'LARGE':
      fontSize = 1.5;
      break;
    case 'EXTRA_LARGE':
      fontSize = 2;
      break;
    default:
      fontSize = 1;
  }

  return fontSize;
};

export const postMessage = (result: any) => {
  const stringifyObj = JSON.stringify(result);
  if(window.TripsMerchant) {
    window.TripsMerchant.postMessage(stringifyObj);

  } else if (window.FlutterWebviewPlugin) {
    // post message to Flutter
    window.flutter_inappwebview.postMessage(stringifyObj);
    window.FlutterWebviewPlugin.postMessage(stringifyObj);
  } else if (window.ReactNativeWebView) {
    // post message to react native
    window.ReactNativeWebView.postMessage(stringifyObj);
	 
  } else if (window?.webkit?.messageHandlers) {
    // post message to Ionic
    window.webkit.messageHandlers.cordova_iab.postMessage(
      stringifyObj,
    );
  } else {
    // post message via Iframe
    // eslint-disable-next-line no-console
    console.log('postMsg:', stringifyObj);
    window.parent.postMessage(stringifyObj, '*');
    window.postMessage(stringifyObj, '*');
  }
};
 
export const isMobileScreen = () => window.outerWidth < 992;

export const getBrowserLanguage = (): LanguageCode => {
  const langCode = window.localStorage.getItem('langCode') as LanguageCode;
  return SUPPORTED_LANGUAGES.includes(langCode) ? langCode : 'en';
};

export const getDisabledDateRange = (refDate: Moment, dateLimit = moment().startOf('day')) => refDate && refDate <= dateLimit;
export const getStartOfDay = (refDate?: Moment) => (refDate ? refDate.startOf('day') : moment().startOf('day'));

export const groupBy = (list: AvailableSeat[], keyGetter: any) => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

export const previousValidTwoDays = (date: Moment) => {
  const dates = [date.clone().subtract(2, 'days'), date.clone().subtract(1, 'days')];

  return dates.filter((date) => date.isAfter(moment(), 'day'));
}

export const generateNextDates = (date: Moment, limit: number) => {
  const dates = [];
  for (let i = 1; i <= limit; i++) {
    dates.push(date.clone().add(i, 'days'));
  }

  return dates;
}

export const validateRequired = (_, value) => {
  if (!value || (value && value.trim() === '')) {
    return Promise.reject(new Error('Please enter a valid input'));
  }
  return Promise.resolve();
};

export const validateEmailRequired = (_, value) => {
  const trimmedValue = value.trim();
  const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!trimmedValue || (trimmedValue && !validEmail.test(trimmedValue))) {
      return Promise.reject(new Error('Please enter a valid email address'));
  }

  return Promise.resolve();
};

export const validateNameRequired = (_, value, callback) => {
  if (!value || (value && value.trim() === '')) {
    return Promise.reject(new Error('Please enter a valid input'));
  }

  const trimmedValue = value.trim();

  const specialCharactersRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  const spaceRegex = /\s/;
  const numberRegex = /\d/;

  if (specialCharactersRegex.test(trimmedValue)) {
    callback('Special characters are not allowed');
  }

  if(spaceRegex.test(trimmedValue)) {
    callback('Name should not contain space');
  }

  if(numberRegex.test(trimmedValue)) {
    callback('Name should not contain numbers');
  }

  callback();
};

export const validatePhoneRequired = (_, value) => {
  const data = value.split(',');
  const phone = data[0];

  try {
    return parsePhoneNumber(phone).valid ? Promise.resolve() : Promise.reject(new Error('Please enter a valid phone number'));
  } catch (err) {
    return Promise.reject(new Error('Please enter a valid phone number'));
  }
};


export const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};
