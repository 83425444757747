export default {
  en: {
    searchButton: 'Search flights',
    searchRoadButton: 'Search bus',
    addNewButton: 'Add New Trip',
    oneWay: 'One-way',
    roundTrip: 'Return',
    multiCity: 'Multi-city',
    economy: 'Economy',
    premiumEconomy: 'Premium economy',
    firstClass: 'First Class',
    businessClass: 'Business',
    inputs: {
      from: 'From',
      to: 'To',
      departureDate: 'Departure',
      arrivalDate: 'Return',
      sameAirlineText: 'Return with same airline',
      fromLabel: 'From',
      fromPlaceholder: 'From:',
      toLabel: 'To',
      toPlaceholder: 'To:',
    },
    validations: {
      departure: 'Please select departure',
      destination: 'Please select destination',
      departureDate: 'Please select departure date',
      destinationDate: 'Please select return date',
      invalidDestinationDate: 'Return date is before departure date',
      invalidDestination: 'Destination should be different from departure',
      invalidDeparture: 'Departure should be different from destination',
    },
  },
  fr: {
    searchButton: 'Rechercher un voyage',
    searchRoadButton: 'Search bus',
    addNewButton: 'Ajouter un nouveau voyage',
    oneWay: 'One-way',
    roundTrip: 'Return',
    multiCity: 'Multi-city',
    economy: 'Économie',
    firstClass: 'Première classe',
    businessClass: 'Affaires',
    premiumEconomy: 'Premium economy',
    inputs: {
      from: 'De',
      to: 'À',
      departureDate: 'Date de départ',
      arrivalDate: 'Date d\'arrivée',
      sameAirlineText: 'Retour avec la même compagnie aérienne',
      fromLabel: 'From',
      fromPlaceholder: 'Enter departure city/airport name',
      toLabel: 'To',
      toPlaceholder: 'Enter  your destination',
    },
    validations: {
      departure: 'Veuillez sélectionner le départ',
      destination: 'Veuillez choisir la destination',
      departureDate: 'Veuillez choisir la date de départ',
      destinationDate: 'Veuillez sélectionner la date de retour',
      invalidDestinationDate: 'La date de retour est antérieure à la date de départ',
      invalidDestination: 'La destination doit être différente du départ',
    },
  },
  sp: {
    searchButton: 'Búsqueda del viaje',
    searchRoadButton: 'Search bus',
    addNewButton: 'Agregar nuevo viaje',
    oneWay: 'One-way',
    roundTrip: 'Return',
    multiCity: 'Multi-city',
    economy: 'Economía',
    firstClass: 'Primera clase',
    businessClass: 'Negocios',
    premiumEconomy: 'Premium economy',
    inputs: {
      from: 'Desde',
      to: 'Para',
      departureDate: 'Fecha de salida',
      arrivalDate: 'Fecha de llegada',
      sameAirlineText: 'Regreso con la misma aerolínea',
      fromLabel: 'From',
      fromPlaceholder: 'Enter departure city/airport name',
      toLabel: 'To',
      toPlaceholder: 'Enter  your destination',
    },
    validations: {
      departure: 'Por favor, seleccione la salida',
      destination: 'Seleccione el destino',
      departureDate: 'Seleccione la fecha de salida',
      destinationDate: 'Seleccione la fecha de regreso',
      invalidDestinationDate: 'La fecha de regreso es anterior a la fecha de salida',
      invalidDestination: 'El destino debe ser diferente de la salida',
    },

  },
  sw: {
    searchButton: 'Tafuta Safari',
    searchRoadButton: 'Search bus',
    addNewButton: 'Ongeza Safari Mpya',
    oneWay: 'Njia moja',
    roundTrip: 'Safari ya Kurudi',
    multiCity: 'Mji wa Multi',
    economy: 'Uchumi',
    firstClass: 'Darasa la Kwanza',
    businessClass: 'Biashara',
    inputs: {
      from: 'Kutoka',
      to: 'Kwa',
      departureDate: 'Tarehe ya kuondoka',
      arrivalDate: 'Tarehe ya Kuwasili',
      sameAirlineText: 'Rudi na shirika moja la ndege',
      fromLabel: 'From',
      fromPlaceholder: 'Enter departure city/airport name',
    },
    validations: {
      departure: 'Tafadhali chagua kuondoka',
      destination: 'Tafadhali chagua unakoenda',
      departureDate: 'Tafadhali chagua tarehe ya kuondoka',
      destinationDate: 'Tafadhali chagua tarehe ya kurudi',
      invalidDestinationDate: 'Tarehe ya kurudi ni kabla ya tarehe ya kuondoka',
      invalidDestination: 'Unakoenda kunapaswa kuwa tofauti na kuondoka',
    },
  },
};
