import styled from 'styled-components';
import device from '../../utils/mediaQuery';
import {PRIMARY} from "../../utils/colors";

export const Wrapper = styled.div<{ inline: boolean}>`
    display: ${({ inline }) => (inline ? 'inline' : 'block')};

  .ant-drawer-title {
    font-size: 20px;
  }
  
  .ant-drawer-footer {
    padding: 15px;
  }
`;

export const InlineTextWrapper = styled.span`
  color: ${PRIMARY};
  text-decoration: underline;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
`;

export const LinkText = styled.strong`
  cursor: pointer;
`;

export const AcceptButtonWrapper = styled.div`
  @media(${device.large}){
    margin: 15px 0;
  }
`;
