import React from 'react';
import {
  Typography, Row, Col, Empty,
} from 'antd';
import {
  Wrapper,
  DetailsWrapper,
  ItemWrapper,
} from './styles';
import {
  StyledParagraph,
  StyledText,
  StyledTitle,
} from '../CustomAntdComponents/Typography';

import {
  StyledHR,
} from '../CustomAntdComponents/Utitlities';
import SkeletonLayout from './SkeletonLayout';
import { formatNumberAsCurrency, pluralizeText } from '../../utils/helpers';
import { StyledButton } from '../CustomAntdComponents/StyledButton';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/SearchRailListings/translations';

const { Title } = Typography;

type Props = {
  results?: RailSearchResult,
  error?: string | null,
  loading?: boolean,
  onSetActiveResultItem: (arg: RailResultItem) => void
}
function AvailableTrains({
  loading, results, onSetActiveResultItem, error,
}: Props) {
  const {
    state: { t },
  } = useI18nContext();
  const resultLists: RailResultItem[] = results ? results.ResultList : [];
  const currency = results?.Currency || 'NGN';
  const TripsSessionId = results?.TripsSessionId || '';

  const handleSetBusResult = (r: RailResultItem) => {
    onSetActiveResultItem({
      ...r,
      TripsSessionId,
    });
  };

  return (
    <Wrapper>
      <Title level={5}>Available Trains</Title>

      {!loading && error && <Empty description={error} /> }
      {!loading && !error && resultLists.length === 0 && <Empty description={t(translations).emptyMessage} /> }

      {loading ? (
        <>
          <SkeletonLayout tripType="road" />
          <SkeletonLayout tripType="road" />
        </>
      ) : resultLists.map((result) => (
        <ItemWrapper key={result.CombinationId}>
          <Row align="middle">
            <Col xs={20} lg={8}>
              <DetailsWrapper>
                <StyledParagraph size="large">
                  {result.AgentName}
                </StyledParagraph>
              </DetailsWrapper>
            </Col>
            <Col xs={0} lg={16}>
              <DetailsWrapper rightAlign>
                <StyledParagraph size="large" color="secondary">
                  {t(translations).startingPrice}
                </StyledParagraph>
                <StyledTitle level={4}>
                  {formatNumberAsCurrency(result.StartingPrice, currency)}
                </StyledTitle>
                <StyledButton type="primary" padded="large" onClick={() => handleSetBusResult(result)}>
                  {pluralizeText(result.AvailableTrains.length, t(translations).ride, t(translations).rides)}
                </StyledButton>
              </DetailsWrapper>
            </Col>
            <Col xs={4} lg={0}>
              <DetailsWrapper rightAlign>
                <StyledText color="primary" strong>
                  {pluralizeText(result.AvailableTrains.length, t(translations).ride, t(translations).rides)}
                </StyledText>
              </DetailsWrapper>
            </Col>
            <Col xs={24} lg={0}>
              <StyledHR />
              <StyledParagraph align="center" color="primary" onClick={() => handleSetBusResult(result)} strong>
                {t(translations).seeMore}
              </StyledParagraph>
            </Col>
          </Row>
        </ItemWrapper>

      ))}
    </Wrapper>
  );
}

AvailableTrains.defaultProps = {
  loading: false,
  error: undefined,
  results: {
    TotalCount: 0,
    ResultList: [],
  },
};
export default AvailableTrains;
