import React from 'react';
import { Space } from 'antd';
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import {getAirportStateName} from "../TripSummary/helper";
import moment from "moment";
import {pluralizeText} from "../../utils/helpers";
import {TitleWrapper} from "./styles";
import { ReactComponent as RightArrowIcon } from "../../assets/images/arrow-right.svg";

type Props = {
    flight: FlightDetails,
    airports: Airport[],
    numberOfPassengers?: number
}

const FlightHeading = ({ airports, flight, numberOfPassengers }: Props) => {
    return (
        <TitleWrapper>
            <Space>
                <StyledTitle level={3}>
                    {getAirportStateName(airports, flight.DepartureAirportCode)}
                </StyledTitle>

                <RightArrowIcon />

                <StyledTitle level={3}>
                    {getAirportStateName(airports, flight.ArrivalAirportCode)}
                </StyledTitle>
            </Space>

            <StyledParagraph>
                {moment(flight.DepartureDate).format('ddd, MMM DD')}
                &nbsp;-&nbsp;
                {flight.Duration}
                &nbsp;-&nbsp;
                {
                    flight.StopOvers === 0 ? "Direct" : pluralizeText(flight.StopOvers, 'stop', 'stops')
                }
                {
                    numberOfPassengers && " - " + pluralizeText(numberOfPassengers, 'passenger', 'passengers')
                }
            </StyledParagraph>
        </TitleWrapper>
    );
};

export default FlightHeading;
