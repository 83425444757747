import React, {useState, useMemo, useEffect} from 'react';
import {DrawerContainer, DrawerFilterContent, DrawerTitle, FilterItem, FilterWrapper} from "./styles";
import { ReactComponent as SortIcon } from "../../assets/images/sort.svg";
import StyledDrawer from "../CustomAntdComponents/StyledDrawer";
import {Button} from "antd";
import FlightFilters from "./FlightFilters";

const categories = ['Sort', 'Airlines', 'Price', 'Stops'];

type Props = {
    isDomestic: boolean,
    handleAirlineFiltering?: (arg: string[]) => void,
    handleStopoverFiltering: (arg0: number | null) => void,
    handleSort: (arg0: string | null) => void,
    farePriceRange: PriceRangeType,
    handleFilterByPriceRange: (arg0: [number, number]) => void,
    selectedAirlines: string[],
    airlines: AirlineOption[],
    selectedStopOver: number | null,
    sortBy: string | null
}


const ListingFilterMobile = ({
        isDomestic, handleAirlineFiltering, selectedAirlines, airlines, selectedStopOver, sortBy,
        handleStopoverFiltering, handleSort, farePriceRange, handleFilterByPriceRange
}: Props) => {
    const [active, setActive] = useState(categories[0])
    const [isOpen, setIsOpen] = useState(false)
    const [payload, setPayload] = useState({
        airlines: selectedAirlines,
        stops: selectedStopOver,
        price: [farePriceRange.min, farePriceRange.max],
        sortBy: sortBy
    });

    useEffect(() => {
        setPayload({
            airlines: selectedAirlines,
            stops: selectedStopOver,
            price: [farePriceRange.min, farePriceRange.max],
            sortBy
        })
    }, [selectedAirlines, selectedStopOver, farePriceRange, sortBy])

    const needsConfirmation = useMemo(() => ['Airlines', 'Price'].includes(active), [active])

    const handleFilterCallback = (currentSortValue = null) => {
        setIsOpen(false);

        switch (active) {
            case 'Airlines':
                handleAirlineFiltering((currentSortValue === null) ?  payload.airlines : currentSortValue);
                break;
            case 'Price':
                handleFilterByPriceRange((currentSortValue === null) ?  payload.price : currentSortValue);
                break;
            case 'Stops':
                handleStopoverFiltering((currentSortValue === null) ?  payload.stops : currentSortValue);
                break;
            case 'Sort':
                handleSort((currentSortValue === null) ?  payload.sortBy : currentSortValue);
                break;
            default:
                break;
        }
    }

    const handleOpenDrawer = (category: string) => {
        setIsOpen(true)
        setActive(category)
    }

    const handleFilterChange = (name, value) => {
        setPayload({
            ...payload,
            [name]: value
        })

        if(!needsConfirmation) {
            setIsOpen(false);
            handleFilterCallback(value);
        }
    }

    const DrawerFooter = needsConfirmation && (
        <Button type="primary" block onClick={()=>handleFilterCallback()}>
            Confirm
        </Button>
    )

    const allowableCategories = useMemo(() => {
        if (isDomestic) {
            return categories.filter((c) => c !== 'Stops');
        }
        return categories;
    }, [isDomestic])

    return (
        <>
            <FilterWrapper>
                {
                    allowableCategories.map((category) => (
                        <FilterItem key={category} onClick={() => handleOpenDrawer(category)} active={active === category}>
                            <SortIcon />
                            <span>
                            {category}
                        </span>
                        </FilterItem>
                    ))
                }
            </FilterWrapper>
            <StyledDrawer
                open={isOpen}
                onClose={() => setIsOpen(false)}
                footer={DrawerFooter}
            >
                <DrawerContainer>
                    <DrawerTitle align="center">
                        {active}
                    </DrawerTitle>
                    <DrawerFilterContent>
                        <FlightFilters
                            selectedStopOver={selectedStopOver}
                            category={active}
                            airlines={airlines}
                            onFilterChange={handleFilterChange}
                            farePriceRange={farePriceRange}
                            selectedAirlines={payload.airlines}
                            sortBy={payload.sortBy}
                        />
                    </DrawerFilterContent>
                </DrawerContainer>
            </StyledDrawer>
        </>
    );
};

export default ListingFilterMobile;
