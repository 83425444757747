import styled from 'styled-components';
import {BLACK, BLACK_300, GREY_2, GREY_7} from "../../../utils/colors";
import device from "../../../utils/mediaQuery";

export const Container = styled.section`
  background: #fff;
  padding: 20px;
    
    .ant-modal {
        top: 20px;
    }
`;

export const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 20px 16px 20px;
  background: #fff;
  column-gap: 10px;
  
    @media ${device.large} {
      padding: 0;
      background: unset;
    }
`;

export const TextContent = styled.div`
  text-align: center;
  flex: 1;
  text-transform: capitalize;
`;

export const DetailsWrapper = styled.div`
    display: flex;
    column-gap: 10px;
    align-items: center;

      svg {
        path {
          fill: ${BLACK_300} !important;
        }
      }

  @media ${device.large} {
    column-gap: 20px;
  }
`;

export const EditIconWrapper = styled.div`
  text-align: right;
  
  svg {
    cursor: pointer;
  }
`;

export const BackIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 10px;
  
  svg {
    path {
      fill: ${BLACK} !important;
    }
  }
`;
export const NavImageIcon = styled.img`
  width: 15px;
  height: 15px;
  padding: 10px;
  box-sizing: content-box;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
`;

export const FormFieldsWrapper = styled.div`
  .ant-form-item {
    margin-bottom: 12px;
  }
`;

export const DrawerContent = styled.div`
  min-height: 300px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
`;

export const MobileDrawerContent = styled.div`
  //height: calc(100vh - 140px);
  //overflow-y: auto;
`;


export const SearchContainer = styled.div`
  flex: 1;
  text-align: center;
`;


export const SearchItemWrapper = styled.div`
    border-left: solid 2px ${GREY_7};
    padding: 0 25px;
    display: flex;
    align-items: center;
    column-gap: 5px;
`;


export const SearchWrapper = styled.div`
    display: inline-flex;
    background: ${GREY_2};
    border: solid 1px ${GREY_7};
    padding: 15px 0;
    border-radius: 16px;
    column-gap: 20px;
    cursor: pointer;
  
    ${SearchItemWrapper} {
      &:first-of-type {
        border-left: none;
      }
    }
`;
