import styled, { css } from "styled-components";
import device from "../../utils/mediaQuery";

export const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
`;

export const Content = styled.div`
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    row-gap: 5px;
  
  .ant-btn {
    margin-top: 20px;
    padding: 10.6px 40px
  }
  
  img {
    margin-bottom: 20px;
    width: 130px;
  }
  
  @media(${device.large}) {
    img {
        width: 100px;
    }
  }
`;
