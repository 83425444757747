import React, {useRef, useState} from 'react';
import {CustomWrapper, SuffixWrapper} from "./styles";
import { DatePicker, DatePickerProps} from "antd";
import type {Moment} from "moment/moment";
import { ReactComponent as CalendarIcon } from '../../../assets/images/calendar-grey.svg';
import {Label} from "../StyledInput/styles";

type Props = {
    onChange?: (dateString: Moment | null) => void
    label: string;
    icon?: React.ReactNode
} & DatePickerProps

const CustomDatePicker = ({ onChange, disabledDate, value, label }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const popoverContainerRef = useRef(null);
    const handleDateChange: DatePickerProps['onChange'] = (date) => {
        if (onChange) onChange(date);
    };

    const getContainer = () => popoverContainerRef.current as unknown as HTMLElement;

    const renderPrefix = () => {
        return <SuffixWrapper onClick={() => setIsOpen(true)}>
           <CalendarIcon />
            <Label>
                {`${label}: `}
            </Label>
        </SuffixWrapper>
    }

    return (
        <CustomWrapper
            ref={popoverContainerRef}
        >
            {renderPrefix()}
            <DatePicker
                open={isOpen}
                placement="bottomLeft"
                placeholder=""
                suffixIcon={null}
                disabledDate={disabledDate}
                value={value}
                onChange={handleDateChange}
                inputReadOnly
                getPopupContainer={getContainer}
                onOpenChange={(status) => setIsOpen(status)}
            />
        </CustomWrapper>
    );
};

export default CustomDatePicker;
