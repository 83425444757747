import React, {useEffect, useMemo, useRef, useState} from 'react';
import { Modal } from 'antd';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/arrow-left.svg';
import { ReactComponent as SearchIcon } from '../../../assets/images/search.svg';
import { ReactComponent as RightArrowIcon } from "../../../assets/images/arrow-right-grey.svg";
import { ReactComponent as DirectionIcon } from "../../../assets/images/direction-hr.svg";

import {
  DetailsWrapper,
  BackIconWrapper,
  Wrapper,
  SearchWrapper,
  SearchContainer,
  SearchItemWrapper,
  Container,
} from './styles';
import {StyledParagraph, StyledText} from "../../CustomAntdComponents/Typography";
import { useFlightSearchStore } from "../../../store/flightSearch/flightSearchStore";
import { pluralizeText } from "../../../utils/helpers";
import FlightSearchDesktop from "./FlightSearchDesktop";
import {getAirportStateName} from "../../TripSummary/helper";
import {useStore} from "../../../store/global/appStore";
import {FlightSearchStore} from "../../../store/flightSearch/types";
import {generateMultiCityFlightRoutes} from "../../FlightSearch/helper";


type Props = {
  navigateHandler: () => void,
  refetchSearch: (arg: FlightSearchStore) => void,
  modify: boolean,
  onModifyChange: (value: boolean) => void
}
function FlightDesktopHeader({ navigateHandler, modify, onModifyChange, refetchSearch }: Props) {
  const modalContainerRef = useRef(null);

  const { airports } = useStore();
  const {
    formData, updateFormData
  } = useFlightSearchStore();
  const {
    FlightClass,
    DepartureDate,
    ReturnDate,
    numberOfAdults,
    DestinationLocationCode,
    OriginLocationCode,
    numberOfInfants,
    numberOfChildren,
    tripType,
    multiCity
  } = formData
  const [payload, setPayload] = useState<FlightSearchStore>(formData);

  const numberOfPassengers = useMemo(() => {
    return numberOfAdults + (numberOfChildren || 0) + (numberOfInfants || 0);
  }, [numberOfAdults, numberOfChildren, numberOfInfants]);

  const allAirports = [...airports]
  const isRoundTrip = tripType === 'round_trip';
  const isMultiCity = tripType === 'multi_city' && multiCity?.length > 0;

  const departure = isMultiCity ? multiCity[0].DepartureDate : DepartureDate;
  const arrival = isMultiCity ? multiCity[multiCity.length - 1].DepartureDate : ReturnDate;
  const originCode = isMultiCity ? multiCity[0].OriginLocationCode : OriginLocationCode;
  const destinationCode = isMultiCity ? multiCity[multiCity.length - 1].DestinationLocationCode : DestinationLocationCode;

  const handleSubmit = (cities) => {
    const payloadData = cities ? { ...payload, multiCity: generateMultiCityFlightRoutes(cities), isDomestic: false } : payload;
    refetchSearch(payloadData);
    updateFormData(payloadData);
    onModifyChange(false);
  }

  const handleChange = (object) => {
    setPayload({ ...payload, ...object });
  }

  return (
      <Container ref={modalContainerRef}>
        <Wrapper className="container">
          <BackIconWrapper onClick={navigateHandler}>
            <ArrowLeftIcon width={20} height={20} />
            <StyledText size="default">Back</StyledText>
          </BackIconWrapper>

          <SearchContainer>
            <SearchWrapper onClick={() => {
              onModifyChange(true);
            }}>
              <SearchItemWrapper>
                <DetailsWrapper>
                  <StyledParagraph size="default">
                    {getAirportStateName(allAirports, originCode)}
                  </StyledParagraph>

                  {
                    (isRoundTrip) ? <DirectionIcon /> : <RightArrowIcon />
                  }

                  <StyledParagraph size="default">
                    {getAirportStateName(allAirports, destinationCode)}
                  </StyledParagraph>

                  {
                      isMultiCity && (
                          <StyledParagraph size="default" color="primary">
                            -&nbsp;&nbsp;Multi City
                          </StyledParagraph>
                      )
                  }
                </DetailsWrapper>
              </SearchItemWrapper>

              <SearchItemWrapper>
                <StyledParagraph size="default">
                  {
                    (isRoundTrip || isMultiCity) && arrival ? `${departure.format("ddd, MMM D")} - ${arrival.format("ddd, MMM D")}` : departure.format("ddd, MMM D")
                  }
                </StyledParagraph>
              </SearchItemWrapper>

              <SearchItemWrapper>
                <StyledParagraph size="default">
                  {`${pluralizeText(numberOfPassengers, "Passenger", "Passengers")}, ${FlightClass}`}
                </StyledParagraph>
              </SearchItemWrapper>

              <SearchItemWrapper>
                <SearchIcon />
                <StyledParagraph size="default" color="primary">
                  Modify Search
                </StyledParagraph>
              </SearchItemWrapper>
            </SearchWrapper>
          </SearchContainer>
        </Wrapper>

        <Modal
            open={modify}
            footer={null}
            onCancel={() => onModifyChange(false)}
            width={980}
            bodyStyle={{
              height: "auto"
            }}
            getContainer={modalContainerRef.current as HTMLElement}
        >
          <FlightSearchDesktop onSubmit={handleSubmit} onChange={handleChange} payload={payload}  />
        </Modal>
      </Container>
  );
}

FlightDesktopHeader.defaultProps = {
  name: undefined,
  navigateHandler: undefined,
};

export default FlightDesktopHeader;
