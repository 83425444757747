import React, {useEffect, useState} from 'react';
import {
    ActionButtonWrapper,
    ActionContentLink,
    ActionContentWrapper,
    ActionWrapper,
} from "./styles";
import FareRule from "../FareRule";
import {StyledTitle} from "../CustomAntdComponents/Typography";
import {Link} from "react-router-dom";
import {Button, Checkbox} from "antd";
import {useFlightSearchStore} from "../../store/flightSearch/flightSearchStore";
import useIsMobile from "../../hooks/useIsMobile";
import {SpaceBetweenAlignContent} from "../CustomAntdComponents/Utitlities";
import FlightFareBreakdown from "./FlightFareBreakdown";

type Props = {
    proceedToPayment?: () => void
}
const FlightPaymentBreakdown = ({ proceedToPayment } : Props) => {
    const { isMobile } = useIsMobile();
    const {
        formData: {
            fareRuleAccepted
        },
        updateFormData
    } = useFlightSearchStore();
    const [accept, setAccept] = useState(Boolean(fareRuleAccepted));

    const handleChange = (e) => {
        setAccept(e.target.checked);
        updateFormData({
            fareRuleAccepted: e.target.checked
        });
    }

    useEffect(() => {
        setAccept(fareRuleAccepted);
    }, [fareRuleAccepted]);

    return isMobile ? (
        <ActionWrapper>
            <ActionContentWrapper>
                <ActionButtonWrapper>
                    <Link to="/payment">
                        <Button block size="large" type="primary">
                            Continue to payment
                        </Button>
                    </Link>
                </ActionButtonWrapper>
            </ActionContentWrapper>
        </ActionWrapper>
    ) : (
        <>
            <SpaceBetweenAlignContent>
                <StyledTitle level={4}>
                    Price breakdown
                </StyledTitle>
                <ActionContentLink>
                    <FareRule />
                </ActionContentLink>
            </SpaceBetweenAlignContent>

            <div>
                <FlightFareBreakdown hideBreakdown={Boolean(proceedToPayment)} />

                <ActionButtonWrapper>
                    {
                        proceedToPayment ? (
                            <>
                                <div style={{ marginBottom: "10px" }}>
                                    <Checkbox onChange={handleChange} checked={accept}>
                                        I have read and agree to the terms and condition within the <FareRule inline />
                                    </Checkbox>
                                </div>
                                <Button block size="large" type="primary" onClick={proceedToPayment} disabled={!accept}>
                                    Make payment
                                </Button>
                            </>

                            ) : (
                            <Button block size="large" type="primary" htmlType="submit">
                                Continue to payment
                            </Button>
                        )
                    }

                </ActionButtonWrapper>
            </div>
        </>
    );
};

export default FlightPaymentBreakdown;
