import styled, { css } from "styled-components";
import {BLACK_400, PRIMARY} from "../../../utils/colors";


export const Wrapper = styled.div<{ block: boolean }>`
  label.ant-checkbox-wrapper {
    color: ${BLACK_400};
    font-size: 14px;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner, 
  .ant-checkbox:hover .ant-checkbox-inner, 
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${PRIMARY};
  }
  
    ${({ block }) => block && css`
      .ant-checkbox-wrapper {
        display: block;
        position: relative;
      }
      .ant-checkbox {
        position: absolute;
        right: 0;
      }
    `};
`;

export const CheckboxItem = styled.div`
  margin: 15px 0
`;
