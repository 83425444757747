import React from 'react';
import {
  Col, Row, Skeleton,
} from 'antd';
import {
  DetailsWrapper, ItemWrapper,
} from './styles';

type Props = {
  tripType?: string;
}
function SkeletonLayout({ tripType }: Props) {
  const renderFlightSkeleton = () => (
    <ItemWrapper>
      <Row align="middle">
        <Col xs={24} lg={8}>
          <DetailsWrapper>
            <Skeleton.Image style={{ height: '50px' }} />
            <Skeleton active paragraph={{ rows: 1 }} />
          </DetailsWrapper>
        </Col>

        <Col xs={0} lg={16}>
          <DetailsWrapper rightAlign>
            <Skeleton active paragraph={{ rows: 0 }} />
            <Skeleton.Button active shape="square" block />
          </DetailsWrapper>
        </Col>
      </Row>
    </ItemWrapper>
  );

  return (
    <>
      { tripType === 'flight' && renderFlightSkeleton()}
      { tripType === 'road' && renderFlightSkeleton()}
    </>
  );
}

SkeletonLayout.defaultProps = {
  tripType: 'flight',
};
export default SkeletonLayout;
