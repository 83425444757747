import React from 'react';
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import {FlightSelectInfoWrapper} from "./styles";

type Props = {
    isReturning: boolean
    isDomestic: boolean
    count: number
}


const FlightSelectInfo = ({ isReturning, isDomestic, count }: Props) => {
    const title = !isDomestic ? "Select your trip" : isReturning ? 'Select your return' : 'Select your departure';
    return (
        <FlightSelectInfoWrapper>
            <StyledTitle level={4}>
                {title} ({count})
            </StyledTitle>
            <StyledParagraph>
                Fares displayed are for all passengers
            </StyledParagraph>
        </FlightSelectInfoWrapper>
    );
};

export default FlightSelectInfo;
