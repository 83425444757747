import React from 'react';
import { Row, Col } from 'antd';
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import {useFlightSearchStore} from "../../store/flightSearch/flightSearchStore";
import {ListItem} from "./styles";
import moment from "moment";

const PassengerList = () => {
    const {
        passengers,
    } = useFlightSearchStore();

    if(!passengers){
        return <></>
    }

    return (
        <>
            {passengers.map((p, key) => (
                <ListItem key={key}>
                    <Row>
                        <Col span={12}>
                            <StyledParagraph>
                                Name
                            </StyledParagraph>
                            <StyledTitle level={5}>
                                {p.FirstName} {p.LastName}
                            </StyledTitle>
                        </Col>
                        <Col span={12}>
                            <StyledParagraph>
                                Date of birth
                            </StyledParagraph>
                            <StyledTitle level={5}>
                                {p.BirthDate ? moment(p.BirthDate).format("DD/MM/YYYY") : "-"}
                            </StyledTitle>
                        </Col>
                    </Row>
                </ListItem>

            ))}
        </>

    );
};

export default PassengerList;
