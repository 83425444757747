import React, { useState } from 'react';
import StyledRadioGroup from "../../CustomAntdComponents/StyledRadioGroup";
import useIsMobile from "../../../hooks/useIsMobile";
import {PillRadioGroup, PillRadioItem, PillRadioItemIcon} from "../styles";
import {StyledParagraph, StyledTitle} from "../../CustomAntdComponents/Typography";

type Props = {
  onChange: (arg) => void,
  sortBy: string
}

const options = [
  {
    name: 'Cheapest',
    value: "cheapest",
  },
  {
    name: 'Fastest',
    value: "fastest",
  },
  {
    name: 'Earliest',
    value: "earliest",
  },
];

function Sort({ onChange, sortBy }: Props) {
  const { isMobile } = useIsMobile();
  const [sortType, setSortType] = useState<string>(sortBy);

  const handleChange = (e) => {
    setSortType(e.target.value);

    onChange(e.target.value);
  };

  const onSelect = (val) => {
    setSortType(val);

    onChange(val);
  };

  return isMobile ? (
      <div>
        <StyledRadioGroup options={options} value={sortType} onChange={handleChange} block />
      </div>
  ) :  (
      <PillRadioGroup>
        {options.map((option) => (
            <PillRadioItem active={sortType === option.value} onClick={() => onSelect(option.value)} key={option.value}>
              <PillRadioItemIcon />
              <StyledParagraph>
                {option.name}
              </StyledParagraph>
          </PillRadioItem>))}
      </PillRadioGroup>
  );
}

export default Sort;
