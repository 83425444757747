import styled from 'styled-components';
import {PRIMARY, FONT_SIZE, GREY_12, BLACK_200, GREY_6, WHITE} from '../../../utils/colors';
import device from '../../../utils/mediaQuery';

export const CustomWrapper = styled.div`
    border: solid 1px ${GREY_6} !important;
    border-radius: 12px !important;
    background: ${WHITE};
    display: flex;
    column-gap: 5px;
    padding: 13.2px 11px;
    
  .ant-picker {
      width: 100%;
      border: none !important;
      border-radius: 12px !important;
      background: none;
      padding: 0;
  }
    .ant-picker-focused {
        box-shadow: none !important;
        border: none !important;
        border-radius: 12px !important;
    }

    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner
    {
        background: ${PRIMARY};
    }

    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
        border: solid 1px ${PRIMARY};
    }

    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
    .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
        background: ${GREY_12};
    }

    svg {
        width: 20px;
        height: 20px;

        path {
            fill: ${BLACK_200} !important;
        }
    }
`;

export const Wrapper = styled.div`
  position: relative;

  .label {
    font-weight: normal;
    position: absolute;
    font-size: 14px !important;
    pointer-events: none;
    left: 12px;
    top: 16px;
    transition: 0.2s ease all;
    line-height: 13px;
  }

  .as-placeholder {
    color: gray;
    left: 35px;
    font-size: 14px !important;
  }

  .as-label {
    top:  calc(-8px * ${FONT_SIZE});
    font-size: ${FONT_SIZE > 1 ? '14px' : '11px'}!important;
    background: #ffffff;
    padding: 0 4px;
    margin-left: -4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 98%;
  }

  .ant-form-item-control-input-content.ant-picker {
    border: solid 1px ${GREY_6} !important;
  }
    
  .ant-picker {
    width: 100%;
    border: solid 1px ${GREY_6} !important;
    border-radius: 12px !important;
    padding-left: 35px !important;
  }

  .ant-picker-focused {
    box-shadow: none !important;
    border: solid 1px ${GREY_6} !important;
    border-radius: 12px !important;
  }

  @media ${device.large} {
    .ant-picker-focused, 
    .ant-picker {
      border: solid 1px ${GREY_6} !important;
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner
  {
    background: ${PRIMARY};
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: solid 1px ${PRIMARY};
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, 
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: ${GREY_12};
  }

  svg {
    width: 20px;
    height: 20px;
    
    path {
      fill: ${BLACK_200} !important;
    }
  }
`;

export const PrefixIconWrapper = styled.div`
    position: absolute;
    top: 14px;
    left: 10px;
  
    svg {
      width: 20px;
      height: 20px;
    }
`;

export const SuffixWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 3px;
    cursor: pointer;
`;
