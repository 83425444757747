import React, { useState, ChangeEvent } from 'react';
import {Input, InputProps} from 'antd';
import {CustomWrapper, Label, PrefixIconWrapper, PrefixWrapper, Wrapper} from './styles';

type Props = {
  onChange?: (value: string) => void;
  label: string;
  icon?: React.ReactNode
} & InputProps

function StyledInput({ label, onChange, type, icon, defaultValue, value
}: Props) {
  const handleChange = (input: ChangeEvent<HTMLInputElement>) => {
    input.preventDefault();
    if (onChange) { onChange(input.target.value); }
  };

  const renderPrefix = () => {
    return <PrefixWrapper>
        {icon}
        <Label>
          {`${label}: `}
        </Label>
    </PrefixWrapper>
  }

  return (
    <CustomWrapper>
      <Input
          prefix={renderPrefix()}
          onChange={handleChange}
          defaultValue={defaultValue}
          value={value}
          type={type}
      />
    </CustomWrapper>
  );
}

StyledInput.defaultProps = {
  size: 'large',
  placeholder: undefined,
  onChange: undefined,
  filled: undefined,
  type: 'text',
};
export default StyledInput;
