import React from 'react';
import Sort from "./Sort";
import StopOvers from "./StopOvers";
import PriceRange from "./PriceRange";
import AirlinesCheckbox from "./AirlinesCheckbox";

type Props = {
    category: string,
    airlines: AirlineOption[],
    onFilterChange: (arg1, arg2) => void,
    farePriceRange: PriceRangeType,
    selectedAirlines: string[],
    selectedStopOver: number | null,
    sortBy: string
}

const FlightFilters = ({ airlines, category, farePriceRange, sortBy, onFilterChange, selectedStopOver, selectedAirlines }: Props) => {
    const renderFilter = () => {
        switch (category) {
            case 'Sort':
                return (
                    <Sort onChange={(v) => onFilterChange('sort', v)} sortBy={sortBy} />
                )
            case 'Airlines':
                return (
                    <AirlinesCheckbox airlines={airlines} selectedAirlines={selectedAirlines} onChange={(v) => onFilterChange('airlines', v)} />
                )
            case 'Price':
                return (
                    <PriceRange onChange={(v) => onFilterChange('price', v)} farePriceRange={farePriceRange} />
                )
            case 'Stops':
                return (
                    <StopOvers selectedStopOver={selectedStopOver} onChange={(v) => onFilterChange('stops', parseInt(v))} />
                )
            default:
                return null
        }
    }
    return (
        <div>
            {renderFilter()}
        </div>
    );
};

export default FlightFilters;
