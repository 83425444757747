import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom'; // uncomment when the route is ready
import { Form } from 'antd';
import { useStore } from '../../store/global/appStore';
import { getAPI } from '../../utils/api';
import {
  GET_SUPPORTED_ROAD_ROUTES,
} from '../../utils/constants';
import { useRoadSearchStore } from '../../store/roadSearch/roadSearchStore';
import useIsMobile from "../../hooks/useIsMobile";
import DesktopRoadSearchForm from "./desktop/form";
import MobileRoadSearchForm from "./mobile/form";
import DesktopNav from "../FlightSearch/FlighSearchDesktopNav";
import MobileNav from "../FlightSearch/FlighSearchMobileNav";
import {RoadLocalStoreKey} from "../ContinueSearch/constant";
import {SAVED_SEARCH_LIMIT} from "../FlightSearch/constant";

function RoadSearch() {
  const { isMobile } = useIsMobile();
  const [checkReturnDate, setCheckReturnDate] = useState(false);
  const navigate = useNavigate();
  const { setData, } = useStore();
  const {
    formData,
  } = useRoadSearchStore();

    const {
      DepartureId, ArrivalId, TripType, numberOfAdults, DepartureDate, ArrivalDate,
    } = formData;
  const isRoundTrip = TripType === 'round_trip';
  const [form] = Form.useForm();

  const onFinish = () => {
    let roadSearchData = JSON.parse(localStorage.getItem(RoadLocalStoreKey) || '[]');
    if(roadSearchData.length >= SAVED_SEARCH_LIMIT) {
      roadSearchData = roadSearchData.slice(0, SAVED_SEARCH_LIMIT - 1);
    }

    roadSearchData.unshift(formData);

    localStorage.setItem(RoadLocalStoreKey, JSON.stringify(roadSearchData));
    navigate('/search-road-listings');
  };

  const getRoadRoutes = useCallback(async () => {
    const roadRoutesAPI = await getAPI(GET_SUPPORTED_ROAD_ROUTES);
    if (roadRoutesAPI.status === 200) {
      setData(roadRoutesAPI.data.Data, 'roadRoutes');
      localStorage.setItem('roadRoutes', JSON.stringify(roadRoutesAPI.data.Data));
    }
  }, []);

  useEffect(() => {
    getRoadRoutes();
  }, []);

  const formInitialValues = {
    DepartureId,
    ArrivalId,
    DepartureDate,
    ArrivalDate,
    numberOfAdults,
    BusDateRange: [DepartureDate, ArrivalDate],
  };

  useEffect(() => {
    setCheckReturnDate(isRoundTrip);
  }, [isRoundTrip, setCheckReturnDate]);

  useEffect(() => {
    form.validateFields(['ReturnDate']);
  }, [checkReturnDate, form]);

  return (
    <div>
      {
        isMobile ? (<MobileNav />) : (<DesktopNav />)
      }
      <Form
          wrapperCol={{ span: 24 }}
          layout="horizontal"
          initialValues={formInitialValues}
          form={form}
          onFinish={onFinish}
          size="large"
      >
        {
            isMobile ? (
                <MobileRoadSearchForm form={form} />
            ) : (
                <DesktopRoadSearchForm form={form} />
            )
        }
      </Form>
    </div>
  );
}

export default RoadSearch;
