import React, { useState, useEffect } from 'react';
import styled, { css } from "styled-components";
import {BLACK, BLACK_100, GREY_2} from "../../utils/colors";
import { ReactComponent as MinusIcon } from "../../assets/images/dash.svg";
import { ReactComponent as PlusIcon } from "../../assets/images/plus.svg";

type Props = {
    min?: number
    value: number
    onChange: (value: number) => void
}

const NumberInput = ({ value, onChange, min = 0 }: Props) => {
    const [count, setCount] = useState(value);

    const handleIncrement = () => {
        setCount(value + 1);
    }

    const handleDecrement = () => {
        const newValue = value - 1;
        setCount(newValue < min ? min : newValue);
    }

    useEffect(() => {
        onChange(count);
    }, [count]);

    return (
        <Wrapper>
            <IconWrapper onClick={handleDecrement} disabled={value === min}>
                <MinusIcon />
            </IconWrapper>

            <span>
                {value}
            </span>

            <IconWrapper onClick={handleIncrement}>
                <PlusIcon />
            </IconWrapper>
        </Wrapper>
    );
};

export const Wrapper = styled.div`
    display: flex;
    column-gap: 16px;
    align-items: center;
    font-size: 20px;
`;

export const IconWrapper = styled.div<{ disabled?: boolean }>`
    width: 40px;
    height: 40px;
    background: ${GREY_2};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    cursor: pointer;

      svg {
        stroke: ${BLACK} !important;
        fill: ${BLACK} !important;
        
        path {
          fill: ${BLACK} !important;
        }
      }
  
    ${({ disabled }) => disabled && css`
        svg {
          stroke: ${BLACK_100};
          
            path {
                fill: ${BLACK_100};
            }
        }
        cursor: not-allowed;
    `}
`;

export default NumberInput;
