import styled from 'styled-components';
import {BLACK} from "../../utils/colors";

export const Wrapper = styled.section`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 20px;
  background: #fff;
`;

export const TextContent = styled.div`
  text-align: center;
  flex: 1;
  text-transform: capitalize;
`;

export const NavIconWrapper = styled.div`
  width: 15px;
  height: 15px;
  box-sizing: content-box;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
  
  svg {
    path {
      fill: ${BLACK} !important;
    }
  }
`;
export const NavImageIcon = styled.img`
  width: 15px;
  height: 15px;
  padding: 10px;
  box-sizing: content-box;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
`;
