import React, {useMemo, useRef, useState} from 'react';
import { Drawer } from "antd";
import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/arrow-left.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/pencil.svg';
import { ReactComponent as RightArrowIcon } from "../../../assets/images/arrow-right-grey.svg";
import { ReactComponent as DirectionIcon } from "../../../assets/images/direction-hr.svg";

import {
  DetailsWrapper,
  BackIconWrapper, Wrapper, EditIconWrapper, MobileDrawerContent,
} from './styles';
import { StyledParagraph, StyledTitle } from "../../CustomAntdComponents/Typography";
import { useFlightSearchStore } from "../../../store/flightSearch/flightSearchStore";
import { pluralizeText } from "../../../utils/helpers";
import FlightSearchMobile from "./FlightSearchMobile";
import {Button, Form} from "antd";
import {FlightSearchStore} from "../../../store/flightSearch/types";
import {generateMultiCityFlightRoutes} from "../../FlightSearch/helper";


type Props = {
  navigateHandler: () => void
  modify: boolean,
  refetchSearch: (arg: FlightSearchStore) => void,
  onModifyChange: (value: boolean) => void
}
function FlightMobileHeader({ navigateHandler, modify, onModifyChange, refetchSearch }: Props) {
  const [form] = Form.useForm();
  const drawerContainerRef = useRef(null);
  const {
    formData, updateFormData,
  } = useFlightSearchStore();
  const {
    FlightClass,
    DepartureDate,
    ReturnDate,
    numberOfAdults,
    DestinationLocationCode,
    OriginLocationCode,
    numberOfInfants,
    numberOfChildren,
    tripType,
    multiCity
  } = formData
  const isRoundTrip = tripType === 'round_trip';
  const isMultiCity = tripType === 'multi_city' && multiCity?.length > 0;

  const departure = isMultiCity ? multiCity[0].DepartureDate : DepartureDate;
  const arrival = isMultiCity ? multiCity[multiCity.length - 1].DepartureDate : ReturnDate;
  const originCode = isMultiCity ? multiCity[0].OriginLocationCode : OriginLocationCode;
  const destinationCode = isMultiCity ? multiCity[multiCity.length - 1].DestinationLocationCode : DestinationLocationCode;
  const [payload, setPayload] = useState<FlightSearchStore>(formData);

  const numberOfPassengers = useMemo(() => {
    return numberOfAdults + (numberOfChildren || 0) + (numberOfInfants || 0);
  }, [numberOfAdults, numberOfChildren, numberOfInfants]);

  const handleSubmit = () => {
    form.validateFields().then(() => {
      const cities = form.getFieldValue('trips');
      const payloadData = cities ? { ...payload, multiCity: generateMultiCityFlightRoutes(cities), isDomestic: false } : payload;
      refetchSearch(payloadData);
      updateFormData(payloadData);
      onModifyChange(false);
    }).catch(() => {});
  }

  const handleChange = (object) => {
    setPayload({ ...payload, ...object });
    form.setFieldsValue(object);
  }

  const DrawerFooter = (
      <Button type="primary" block onClick={() => handleSubmit()}>
        Search
      </Button>
  )

  return (
      <>
        <Wrapper ref={drawerContainerRef}>
          <BackIconWrapper onClick={navigateHandler}>
            <ArrowLeftIcon width={20} height={20} />
          </BackIconWrapper>

          <div>
            <DetailsWrapper>
              <StyledTitle level={4}>
                {originCode}
              </StyledTitle>

              {
                (isRoundTrip || isMultiCity) ? <DirectionIcon /> : <RightArrowIcon />
              }

              <StyledTitle level={4}>
                {destinationCode}
              </StyledTitle>

              {
                isMultiCity && (
                      <StyledParagraph size="default" color="primary">
                       - Multi City
                      </StyledParagraph>
                  )
              }
            </DetailsWrapper>

            <StyledParagraph>
                <span>
                  {
                    (isRoundTrip || isMultiCity) && arrival ? `${departure.format("ddd, MMM D")} - ${arrival.format("ddd, MMM D")}` : departure.format("ddd, MMM D")
                  }
                </span>
              <span> &nbsp;|&nbsp; {pluralizeText(numberOfPassengers, "Passenger", "Passengers")} </span>
              <span> &nbsp;|&nbsp; {FlightClass}</span>
            </StyledParagraph>
          </div>

          <EditIconWrapper>
            <EditIcon width={30} height={30} onClick={() => {
              onModifyChange(true);
            }} />
          </EditIconWrapper>
        </Wrapper>

        <Drawer
            open={modify}
            onClose={() => onModifyChange(false)}
            footer={DrawerFooter}
            title="Modify Search"
            placement="bottom"
            height="100%"
            getContainer={drawerContainerRef.current as HTMLElement}
        >
          <MobileDrawerContent>
            <FlightSearchMobile onSubmit={handleSubmit} onChange={handleChange} payload={payload} form={form} />
          </MobileDrawerContent>
        </Drawer>
      </>
  );
}

FlightMobileHeader.defaultProps = {
  name: undefined,
  navigateHandler: undefined,
};

export default FlightMobileHeader;
