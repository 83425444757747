import styled, { css } from 'styled-components';
import device from '../../utils/mediaQuery';
import { UserDetails } from '../../components/Header/styles';
import { LIGHT_PRIMARY, PRIMARY, FONT_SIZE } from '../../utils/colors';

export const Wrapper = styled.section`
`;

export const ContentHeader = styled.div``;

export const Content = styled.div`
  padding: 15px;
  
  h3 {
    margin: 30px 0;
    
    @media ${device.large} {
      margin: 5px 0 60px;
    }
  }
  
  .ant-card-body {
    text-align: center;
    cursor: pointer;
    
    h5 {
      margin-bottom: 15px;
      font-size: calc(12px *  ${FONT_SIZE});
      
      @media ${device.large} {      
        font-size: calc(15px *  ${FONT_SIZE});
      }
    }
    
    img {
      width: 80px;
      height: 80px;
    }
  }



  ${ContentHeader} {
    h3 {
      
      @media ${device.large} {
        color: #fff;
        margin: 10px 0 60px;
        font-weight: 500;
        font-size: calc(20px *  ${FONT_SIZE});
        line-height: 24px;
      }
    }

    h4 {
      @media ${device.large} {
        color: #fff;
        font-size: calc(43px *  ${FONT_SIZE});
        font-weight: 600;
        line-height: 43px;
        margin: 12px 0px;
      }
    }
  }

  @media ${device.large} {
    background: linear-gradient(279.27deg,${PRIMARY} 1.45%,${LIGHT_PRIMARY} 87.41%);
    height: 100vh;
    
    ${UserDetails} {
      margin-top: 50px;
    }
  }
`;

export const IconWrapper = styled.div`
  background: ${LIGHT_PRIMARY};
  border-radius: 5px;
  padding: 15px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonWrapper = styled.div<{ justify?: boolean}>`
  display: flex;
  background: ${PRIMARY};
  border-radius: 5px;
  padding: 40px 20px 16px 20px;
  margin: 20px 0;
  align-items: center;
  width: 100%;
  cursor: pointer;
  
  .ant-typography {
    color: #fff;
    font-weight: 600;
    margin-left: 30px;
  }
  
  ${({ justify }) => justify && css`
    justify-content: space-between;
    
    .ant-typography {
      margin-left: 0;
    }
  `}
`;

export const CloseIconDrawer = styled.div`
  //width: 40px;
  //height: 40px;
  //background: #F6F6F6;
  //border-radius: 5px;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  color: green;
`;

export const TripModesWrapper = styled.div`
  h5 {
    text-transform: capitalize;
  }

  svg {
    width: 80px;
    height: 80px;
  }
`;
