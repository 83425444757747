import React, {useState, useEffect, useCallback, useRef, useMemo} from 'react';
import moment from "moment";
import {Col, Empty, Row} from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  Wrapper,
} from './styles';
import {
  DesktopContentWrapper,
  ListingHeaderContentWrapper, MobileContentWrapper,
} from '../SearchListings/styles';
import AvailableBuses from '../../components/AvailableBuses';
import HeaderNavigation from "../../components/HeaderNavigation/Road";

import { SEARCH_BUS_ROUTES } from '../../utils/constants';
import { postAPI } from '../../utils/api';
import { buildSearchPayload } from './helpers';
import { useRoadSearchStore } from '../../store/roadSearch/roadSearchStore';
import BusDateFilter from "../../components/AvailableBuses/BusDateFilter";
import RoadSelectInfo from "../../components/AvailableBuses/RoadSelectInfo";
import Sort from "../../components/ListingFilter/FlightFilters/Sort";
import RoadListingFilterMobile from "../../components/ListingFilter/RoadListingFilterMobile";
import RoadListingFilterDesktop from "../../components/ListingFilter/RoadListingFilterDesktop";
import BusRequestLoading from "../../components/RequestLoading/BusRequestLoading";
import EmptyResult from "../../components/EmptyResult";
import {isMobileScreen} from "../../utils/helpers";

function SearchRoadListings() {
  const drawerContainerRef = useRef(null);
  const navigate = useNavigate();
  const isMobile = isMobileScreen();
  const [busResults, setBusResults] = useState<RoadSearchResult>();
  const [filteredBusResults, setFilteredBusResults] = useState<RoadSearchResult>();
  const [sortBy, setSortBy] = useState("cheapest");
  const [selectedBuses, setSelectedBuses] = useState<AvailableBus[]>([]);
  const [selectedOperators, setSelectedOperators] = useState<string[]>([]);
  const [operators, setOperators] = useState<SelectOption[]>([]);
  const [error, setError] = useState<string | null>();
  const [openModify, setOpenModify] = useState<boolean>(false);

  const [loadingData, setLoadingData] = useState<boolean>(true);
  const { formData, selectedResult, updateFormData, updateStoreData } = useRoadSearchStore();
  const isOneWay = formData.TripType === 'one_way';
  const isReturning = selectedBuses.length > 0;
  const filteredResultKey = isReturning ? "SecondLeg" : "FirstLeg";

  const legResult = useMemo(() => {
    if(!busResults) {
      return {
        TotalCount: 0,
        ResultList: [],
      }
    }

    return isReturning ? busResults?.SecondLeg : busResults?.FirstLeg;
  }, [busResults, isReturning]);

  const filteredLegResult: RoadLegResult = useMemo(() => {
    if(!filteredBusResults) {
      return {
        TotalCount: 0,
        ResultList: [],
      }
    }

    return isReturning ? filteredBusResults?.SecondLeg : filteredBusResults?.FirstLeg;
  }, [filteredBusResults, isReturning]);

  const searchBuses = useCallback(async (payload = formData) => {
    setLoadingData(true);
    setError(null);
    setSelectedBuses([]);
    updateFormData({
      tripsSessionId: null,
      numberOfAdults: 1,
      selectedBus: null,
      selectedResult: null,
      selectedBusSeat: null,
    });

    const reformedPayload = buildSearchPayload(payload);
    const searchResults = await postAPI(SEARCH_BUS_ROUTES, reformedPayload);

    if (searchResults.status === 200) {
      setBusResults(searchResults.data);
      setFilteredBusResults(searchResults.data);
      setLoadingData(false);
    } else {
      setError(searchResults.data);
      setLoadingData(false);
    }
  }, [formData]);

  useEffect(() => {
    searchBuses();
  }, []);

  const handleNavigation = () => {
    if (selectedResult) {
      updateStoreData({ selectedResult: null });
    } else {
      navigate(-1);
    }
  };

  const handleResetFilters = () => {
    setSelectedOperators(operators.map(a => a.value));
    setSortBy("cheapest");
    setFilteredBusResults(busResults);
  }

  const handleBookingRedirect = () => {
    if(isMobile) {
      navigate('/itinerary/road');
    } else {
      navigate('/passenger-details/road');
    }
  };

  const handleOperatorFiltering = (operatorValues: string[]) => {
    if(operatorValues.length === 0 || operatorValues.length === operators.length) {
        setFilteredBusResults(busResults);
        setSelectedOperators(operatorValues);
        handleSort(sortBy, busResults[filteredResultKey]);
     } else {
       const filteredResults = busResults[filteredResultKey].ResultList.filter((bus) => {
                return operatorValues.includes(bus.AgentName);
         });
       const filteredLegResult = {
         TotalCount: filteredResults.length,
         ResultList: filteredResults
       }

         setFilteredBusResults({
                ...busResults,
                [filteredResultKey]: {
                  ...filteredLegResult
                }
         });
         setSelectedOperators(operatorValues);
         handleSort(sortBy, filteredLegResult);
     }
  };

  const handleFilterByPriceRange = (range: [number, number]) => {
    const [min, max] = range;

    const legAvailableBuses = legResult.ResultList.map((result)=> {
      return result.AvailableBuses;
    });

    let filteredTotalCount = 0;

    const filteredAvailableBuses = legAvailableBuses.map((buses) => {
      const filteredBuses = buses.filter((bus) => {
        return bus.TotalFare >= min && bus.TotalFare <= max;
      });

      filteredTotalCount += filteredBuses.length;
      return filteredBuses;
    });

    const filteredLegResult = {
      TotalCount: filteredTotalCount,
        ResultList: legResult.ResultList.map((result, index) => {
          return {
            ...result,
            AvailableBuses: filteredAvailableBuses[index]
          }
        })
    };

    setFilteredBusResults({
      ...busResults,
      [filteredResultKey]: {
        ...filteredLegResult
      }
    });
  };

  const handleSort = (value: string, data = filteredLegResult) => {
    setSortBy(value);
    switch (value) {
      case "cheapest":
        handleSortByPrice("asc", data)
        return;
      case "fastest":
        handleSortByDuration("asc", data)
        return;
      case "earliest":
        handleSortByDate("desc", data)
        return;
    }
  }

  useEffect(() => {
    let uniqueOperators = legResult.ResultList.map((d) => d.AgentName);
    uniqueOperators = uniqueOperators.filter((v, i, a) => a.indexOf(v) === i);

    setOperators(uniqueOperators.map((a) => ({ label: a, value: a })));
    setSelectedOperators(uniqueOperators);
  }, [legResult]);

  const handleSortByPrice = (order: string| null, data = filteredLegResult) => {
    const sortedResult = data.ResultList.map((result)=> {
        return {
            ...result,
            AvailableBuses: result.AvailableBuses.sort((a, b) => {
                if (order === 'asc') {
                    return a.TotalFare - b.TotalFare;
                }
                return b.TotalFare - a.TotalFare;
            })
        };
    });

    setFilteredBusResults({
        ...filteredBusResults,
        [filteredResultKey]: {
          TotalCount: data.TotalCount,
          ResultList: sortedResult
        }
    });
  };

  const handleSortByDuration = (order: string| null, data = filteredLegResult) => {
    const sortedResult = data.ResultList.map((result)=> {
      return {
        ...result,
        AvailableBuses: result.AvailableBuses.sort((a, b) => {
          const aDuration = moment(a.EstimatedArrivalDate).diff(moment(a.EstimatedDepartureDate));
          const bDuration = moment(b.EstimatedArrivalDate).diff(moment(b.EstimatedDepartureDate));

          if (order === 'asc') {
            return aDuration - bDuration;
          }
          return bDuration - aDuration;
        })
      };
    });


    setFilteredBusResults({
      ...filteredBusResults,
      [filteredResultKey]: {
        TotalCount: data.TotalCount,
        ResultList: sortedResult
      }
    });
  }

  const handleSortByDate = (order: string| null, data = filteredLegResult) => {
    const sortedResult = data.ResultList.map((result) => {
      return {
        ...result,
        AvailableBuses: result.AvailableBuses.sort((a, b) => {
          if (order === 'asc') {
            return moment(b.EstimatedDepartureDate).diff(moment(a.EstimatedDepartureDate));
          }
          return moment(a.EstimatedDepartureDate).diff(moment(b.EstimatedDepartureDate));
        })
      };
    });

    setFilteredBusResults({
      ...filteredBusResults,
      [filteredResultKey]: {
        TotalCount: data.TotalCount,
        ResultList: sortedResult
      }
    });
  }

  const totalCount = legResult.TotalCount;
  const resultCount =  filteredLegResult.TotalCount;
  const hasResults = totalCount > 0;

  const cheapestFares = useMemo(() => {
    if(!busResults || totalCount === 0){
      return null;
    }

    const cheapestDeparture = busResults.FirstLeg.ResultList[0]?.AvailableBuses[0]?.TotalFare;
    const cheapestReturn = busResults.SecondLeg.ResultList[0]?.AvailableBuses[0]?.TotalFare;

    return {
      cheapestDeparture,
      cheapestReturn
    };
  }, [busResults, totalCount]);

  const handleEmptyResult = () => {
    if(hasResults) {
      handleResetFilters();
    } else {
      setOpenModify(true)
    }
  };

  const farePriceRange = useMemo(() => {
    if(!busResults || totalCount === 0){
      return { min: 0, max: 0};
    }

    const results = isReturning ? busResults.SecondLeg.ResultList : busResults.FirstLeg.ResultList;

    const fares = results.map(f => f.AvailableBuses.map(b => b.TotalFare)).flat();

    return {
      min: Math.min(...fares), max: Math.max(...fares)
    };
  }, [busResults, totalCount, isReturning]);

  const renderAvailableBusResults = () => {
    return (
        <AvailableBuses
            results={filteredBusResults}
            selectedBuses={selectedBuses}
            onBusSelect={(b)=>{
              handleResetFilters();
              setSelectedBuses(b)
            }}
            onBookingRedirect={handleBookingRedirect}
            cheapestFares={cheapestFares}
        />
    )
  }

  if(loadingData) {
    return <BusRequestLoading />
  }

  return (
      <Wrapper>
        <Row>
          <Col span={24}>
            <HeaderNavigation
                navigateHandler={handleNavigation}
                modify={openModify}
                onModifyChange={(value) => setOpenModify(value)}
                refetchSearch={searchBuses}
            />
          </Col>
        </Row>

        {/*  Desktop devices */}
        <Row>
          <Col xs={0} lg={24}>
            <DesktopContentWrapper className="container" ref={drawerContainerRef}>
              <Row gutter={8}>
                <Col span={5}>
                    <RoadListingFilterDesktop
                        handleOperatorFiltering={handleOperatorFiltering}
                        handleFilterByPriceRange={handleFilterByPriceRange}
                        farePriceRange={farePriceRange}
                        selectedOperators={selectedOperators}
                        operators={operators}
                        onResetFilters={handleResetFilters}
                      />
                </Col>

                <Col span={18} offset={1}>
                  {
                      isOneWay && <BusDateFilter refetchSearch={searchBuses}/>
                  }
                  <ListingHeaderContentWrapper>
                    <RoadSelectInfo count={resultCount} isReturning={isReturning} />
                    <Sort onChange={handleSort} sortBy={sortBy}/>
                  </ListingHeaderContentWrapper>

                  {
                      !error && resultCount === 0 && <EmptyResult onAction={handleEmptyResult} emptyOnFilter={hasResults} />
                  }

                  {(error) ? <Empty description={error} /> : renderAvailableBusResults()}
                </Col>
              </Row>
            </DesktopContentWrapper>
          </Col>
        </Row>

        {/*  Mobile Devices */}
        <Row>
          <Col xs={24} lg={0}>
            {
                isOneWay && <BusDateFilter refetchSearch={searchBuses}/>
            }
              <MobileContentWrapper>
                  <Col xs={24} lg={0}>
                    <RoadSelectInfo count={resultCount} isReturning={isReturning} />
                    <RoadListingFilterMobile
                        handleOperatorFiltering={handleOperatorFiltering}
                        handleSort={handleSort}
                        handleFilterByPriceRange={handleFilterByPriceRange}
                        farePriceRange={farePriceRange}
                        selectedOperators={selectedOperators}
                        operators={operators}
                        sortBy={sortBy}
                    />

                    {
                      !error && resultCount === 0 && <EmptyResult onAction={handleEmptyResult} emptyOnFilter={hasResults} />
                    }

                    {(error) ? <Empty description={error} /> : renderAvailableBusResults()}
                  </Col>
              </MobileContentWrapper>
          </Col>
        </Row>
      </Wrapper>
  )
}

export default SearchRoadListings;
