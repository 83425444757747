import React from 'react';
import {
    ActionButtonWrapper,
    ActionContentLink,
    BreakdownTitle, BreakdownTotalWrapper,
    TripLabel, TripsContainer
} from "./styles";
import FareRule from "../FareRule";
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import {formatNumberAsCurrency} from "../../utils/helpers";
import {Link} from "react-router-dom";
import {Button} from "antd";
import useIsMobile from "../../hooks/useIsMobile";
import {SpaceBetweenAlignContent} from "../CustomAntdComponents/Utitlities";
import {useRoadSearchStore} from "../../store/roadSearch/roadSearchStore";
import {calculateTotalFare} from "../AvailableBuses/helper";
import RoadFareBreakdown from "./RoadFareBreakdown";

const RoadPriceBreakdown = () => {
    const { isMobile } = useIsMobile();

    const {
        formData: {
            numberOfAdults
        },
       selectedBusSeat,
        selectedResult,
        selectedBus
    } = useRoadSearchStore();

    const { totalAmount } = calculateTotalFare(selectedBus, numberOfAdults);
    const { totalAmount: departureTotalAmount  } = calculateTotalFare([selectedBus[0]], numberOfAdults);
    const returnTotalAmount = selectedBus[1] ? calculateTotalFare([selectedBus[1]], numberOfAdults).totalAmount : 0;

    return isMobile ? (
        <div>
            <BreakdownTitle>
                <StyledTitle level={5}>
                    Price breakdown
                </StyledTitle>
            </BreakdownTitle>

            <TripsContainer>
                <div>
                    <TripLabel size="normal">
                        Departure
                    </TripLabel>

                    <SpaceBetweenAlignContent>
                        <StyledParagraph size="normal">
                            Seat No. {selectedBusSeat[0].map(seat => seat.SeatNumber).join(", ")}
                        </StyledParagraph>

                        <StyledTitle level={5}>
                            {formatNumberAsCurrency(departureTotalAmount || 0, selectedResult[0]?.Currency)}
                        </StyledTitle>
                    </SpaceBetweenAlignContent>
                </div>

                {
                    selectedBusSeat[1]?.length > 0 && (
                        <div>
                            <TripLabel size="normal">
                                Return
                            </TripLabel>

                            <SpaceBetweenAlignContent>
                                <StyledParagraph size="normal">
                                    Seat No. {selectedBusSeat[1].map(seat => seat.SeatNumber).join(", ")}
                                </StyledParagraph>

                                <StyledTitle level={5}>
                                    {formatNumberAsCurrency(returnTotalAmount || 0, selectedResult[1]?.Currency)}
                                </StyledTitle>
                            </SpaceBetweenAlignContent>
                        </div>
                    )
                }
            </TripsContainer>

            <BreakdownTotalWrapper>
                <SpaceBetweenAlignContent>
                    <StyledParagraph size="normal">
                        Total for all passengers
                    </StyledParagraph>

                    <StyledTitle level={5}>
                        {formatNumberAsCurrency(totalAmount || 0, selectedResult[0]?.Currency)}
                    </StyledTitle>
                </SpaceBetweenAlignContent>
            </BreakdownTotalWrapper>
        </div>
    ) : (
        <>
            <SpaceBetweenAlignContent>
                <StyledTitle level={4}>
                    Price breakdown
                </StyledTitle>
                <ActionContentLink>
                    <FareRule />
                </ActionContentLink>
            </SpaceBetweenAlignContent>

            <div>
                <RoadFareBreakdown hideBreakdown={false} />
                <ActionButtonWrapper>
                    <Link to="/passenger-details/flight">
                        <Button block size="large" type="primary">
                            Continue
                        </Button>
                    </Link>
                </ActionButtonWrapper>
            </div>
        </>
    );
};

export default RoadPriceBreakdown;
