import React, {useEffect, useRef, useState} from 'react';
import {
  Button, Col, Row, Checkbox
} from 'antd';
import {
  ActionWrapper,
  ActionContentWrapper, DesktopContentWrapper, MobileContentWrapper,
  TermsWrapper, MobileContentContainer,
} from './styles';
import {StyledParagraph, StyledTitle} from '../../components/CustomAntdComponents/Typography';
import {
  formatNumberAsCurrency,
} from '../../utils/helpers';
import { useBookingStore } from '../../store/booking/bookingStore';
import RoadPaymentBreakdown from "../../components/RoadItineraryBreakdown/RoadPaymentBreakdown";
import {SectionCard, SectionCardBody, SectionCardHeading} from "../PassengerDetails/styles";
import RoadItinerarySummary from "../../components/RoadItineraryBreakdown/RoadItinerarySummary";
import useIsMobile from "../../hooks/useIsMobile";
import PassengerList from "../../components/PassengerList/RoadPassengerList";
import BookingContact from "../../components/BookingContact";
import {ActionButtonWrapper} from "../../components/FlightItinerary/styles";
import RoadBookingSummary from "../../components/RoadItineraryBreakdown/RoadBookingSummary";
import {useRoadSearchStore} from "../../store/roadSearch/roadSearchStore";
import {calculateTotalFare} from "../../components/AvailableBuses/helper";
import {calculateTicketTotal} from "../../components/AvailableFlights/helper";
import FareRule from "../../components/FareRule/RoadFareRule";

type Props = {
  handlePayment: () => void;
}
function RoadPayment({ handlePayment }: Props) {
  const {
    selectedResult,
    formData: {
      numberOfAdults,
      fareRuleAccepted
    },
    selectedBus,
  } = useRoadSearchStore();

  const [accept, setAccept] = useState(Boolean(fareRuleAccepted));
  const { isMobile } = useIsMobile();
  const drawerContainerRef = useRef(null);

  const {
    bookingPayload,
  } = useBookingStore();

  useEffect(() => {
    setAccept(fareRuleAccepted);
  }, [fareRuleAccepted]);

  const handleChange = (e) => {
    setAccept(e.target.checked)
  }
  const { totalAmount } = calculateTotalFare(selectedBus, numberOfAdults);
  // Use booking ticket amount to cater for promo discount
  const bookingAmount = bookingPayload ? calculateTicketTotal(bookingPayload.TicketingDetails) : totalAmount

  return (
      <div ref={drawerContainerRef}>
        {
          isMobile ? (
              <Row>
                <Col xs={24} lg={0}>
                  <MobileContentContainer>
                    <MobileContentWrapper>
                      <div style={{ marginBottom: "20px" }}>
                        <StyledParagraph size="default">
                          Booking summary
                        </StyledParagraph>
                      </div>

                      <RoadBookingSummary />
                    </MobileContentWrapper>

                    <ActionWrapper>
                      <TermsWrapper>
                        <Checkbox onChange={handleChange} checked={accept}>
                          I have read and agree to the terms and condition within the <FareRule inline />
                        </Checkbox>
                      </TermsWrapper>
                      <ActionContentWrapper>
                        <div>
                          <StyledParagraph>
                            Total fare
                          </StyledParagraph>
                          <StyledTitle level={3}>
                            {formatNumberAsCurrency(bookingAmount || 0, selectedResult[0]?.Currency)}
                            &nbsp;
                          </StyledTitle>
                        </div>
                        <ActionButtonWrapper>
                          <Button block size="large" type="primary" htmlType="submit" disabled={!accept} onClick={handlePayment}>
                            Make payment
                          </Button>
                        </ActionButtonWrapper>
                      </ActionContentWrapper>
                    </ActionWrapper>
                  </MobileContentContainer>
                </Col>
              </Row>
          ): (
              <Row>
                <Col xs={0} lg={24}>
                  <DesktopContentWrapper className="container">
                    <Row>
                      <Col span={12}>
                        <SectionCard>
                          <SectionCardHeading>
                            <StyledTitle level={4}>
                              Trip Itinerary
                            </StyledTitle>
                          </SectionCardHeading>
                          <SectionCardBody>
                            <RoadItinerarySummary />
                          </SectionCardBody>
                        </SectionCard>

                        <SectionCard>
                          <SectionCardHeading>
                            <StyledTitle level={4}>
                              Passenger information
                            </StyledTitle>
                          </SectionCardHeading>
                          <SectionCardBody>
                            <PassengerList />
                          </SectionCardBody>
                        </SectionCard>

                        <SectionCard>
                          <SectionCardHeading>
                            <StyledTitle level={4}>
                              Contact details
                            </StyledTitle>
                          </SectionCardHeading>
                          <SectionCardBody>
                            <BookingContact />
                          </SectionCardBody>
                        </SectionCard>
                      </Col>

                      <Col offset={4} span={8}>
                        <RoadPaymentBreakdown proceedToPayment={handlePayment}  />
                      </Col>
                    </Row>
                  </DesktopContentWrapper>
                </Col>
              </Row>
          )
        }
      </div>
  );
}

export default RoadPayment;
