import React from 'react';
import styled, {css} from "styled-components";
import {GREY_4, PRIMARY, WHITE} from "../../utils/colors";
import type {Moment} from 'moment';
import {useRoadSearchStore} from "../../store/roadSearch/roadSearchStore";
import {generateNextDates, previousValidTwoDays} from "../../utils/helpers";

type Props = {
    refetchSearch: () => void
}

const BusDateFilter = ({ refetchSearch }: Props) => {
    const {
        formData: {
            DepartureDate
        }, updateFormData,
    } = useRoadSearchStore();

    const previousDates = previousValidTwoDays(DepartureDate);
    const nextDates = generateNextDates(DepartureDate, (4 - previousDates.length));

    const handleDateChange = (date: Moment) => {
        updateFormData({
            DepartureDate: date
        });
        refetchSearch();
    }

    return (
        <Container>
            <Wrapper>
                {
                    previousDates.map((date, key) => (
                        <Item onClick={() => handleDateChange(date)} key={key}>
                            {date.format('ddd, DD MMM')}
                        </Item>
                    ))
                }

                <Item active>
                    {DepartureDate.format('ddd, DD MMM')}
                </Item>

                {
                    nextDates.map((date, index) => (
                        <Item onClick={() => handleDateChange(date)} key={index}>
                            {date.format('ddd, DD MMM')}
                        </Item>
                    ))
                }
            </Wrapper>
        </Container>
    );
};


export const Container = styled.div`
  width: 100%;

  @media(max-width: 991px) {
    overflow-x:auto;
    background: ${WHITE};
  }
`;

export const Wrapper = styled.div`
      border-bottom: 1px solid ${GREY_4};
      display: flex;
      justify-content: space-between;
   
      @media(max-width: 991px) {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap; 
        
        &::-webkit-scrollbar {
          display: none;
        }
      }
`;

export const Item = styled.div<{ active?: boolean}>`
    padding: 10px 0;
    font-size: 16px;
    cursor: pointer;
  
  
   ${({ active }) => active && css`
    color: ${PRIMARY};
     cursor: default;
    border-bottom: solid 2px ${PRIMARY};
  `};

    @media(max-width: 991px) {
      display: inline-block;
      margin-right: 30px;
    }
`;

export default BusDateFilter;
