/* eslint-disable react/no-array-index-key */
import React, {useEffect} from 'react';
import { useFlightSearchStore } from '../../store/flightSearch/flightSearchStore';
import {useNavigate} from "react-router-dom";
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import moment from "moment";
import {pluralizeText} from "../../utils/helpers";
import styled from "styled-components";
import {GREY_4, WHITE} from "../../utils/colors";
import { ReactComponent as RightArrowIcon } from "../../assets/images/arrow-right-grey.svg";

function FlightBookingSummary() {
  const {
    selectedFlight,
      passengers,
      bookingContact,
    formData: {
      tripType,
      isDomestic,
      numberOfAdults,
      numberOfChildren,
      numberOfInfants,
      FlightClass
    },
  } = useFlightSearchStore();

  const isMultiCity = tripType === 'multi_city';
  const isRoundTrip = tripType === 'round_trip';
  const totalPassengers = numberOfAdults + (numberOfChildren || 0) + (numberOfInfants || 0);

  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedFlight) {
      navigate('/search/flight');
    }
  }, [selectedFlight, navigate]);

  const departureFlight = selectedFlight[0].FlightDetails[0];
  const returningFlight = selectedFlight && selectedFlight.length == 2 ? selectedFlight[1].FlightDetails[0] : null;
  const consolidatedDetails = isMultiCity || (!isDomestic && isRoundTrip);

  const labelTrip = (index: number) => {
    if(isMultiCity) {
        return `Flight ${index + 1}`;
    }

    return index === 0 ? "Departure" : "Return";
  }

  return (
      <div>
        {
            consolidatedDetails ? (
                <div>
                  {
                    selectedFlight[0].FlightDetails.map((flight, key) => (
                      <div key={key}>
                        <Section>
                          <StyledTitle level={5}>
                            {labelTrip(key)}
                          </StyledTitle>

                          <FlightCode>
                            <StyledTitle level={4}>
                              {flight.DepartureAirportCode}
                            </StyledTitle>
                            <RightArrowIcon />
                            <StyledTitle level={4}>
                              {flight.ArrivalAirportCode}
                            </StyledTitle>
                          </FlightCode>

                          <StyledParagraph size="default">
                            {moment(flight.DepartureDate).format('ddd, D MMM')} | {pluralizeText(totalPassengers, 'Passenger', 'Passengers')} | {FlightClass}
                          </StyledParagraph>
                        </Section>
                      </div>
                    ))}
                </div>
            ) :(
                <>
                  <Section>
                    <StyledTitle level={5}>
                      Departure
                    </StyledTitle>

                    <FlightCode>
                      <StyledTitle level={4}>
                        {departureFlight.DepartureAirportCode}
                      </StyledTitle>
                      <RightArrowIcon />
                      <StyledTitle level={4}>
                        {departureFlight.ArrivalAirportCode}
                      </StyledTitle>
                    </FlightCode>

                    <StyledParagraph size="default">
                      {moment(departureFlight.DepartureDate).format('ddd, D MMM')} | {pluralizeText(totalPassengers, 'Passenger', 'Passengers')} | {FlightClass}
                    </StyledParagraph>
                  </Section>

                  {returningFlight && (
                      <Section>
                        <StyledTitle level={5}>
                          Return
                        </StyledTitle>

                        <FlightCode>
                          <StyledTitle level={4}>
                            {returningFlight.DepartureAirportCode}
                          </StyledTitle>
                          <RightArrowIcon />
                          <StyledTitle level={4}>
                            {returningFlight.ArrivalAirportCode}
                          </StyledTitle>
                        </FlightCode>


                        <StyledParagraph size="default">
                          {moment(returningFlight.DepartureDate).format('ddd, D MMM')} | {pluralizeText(totalPassengers, 'Passenger', 'Passengers')} | {FlightClass}
                        </StyledParagraph>
                      </Section>
                  )}
                </>
            )
        }

        {
          passengers && (
              <Section>
                <StyledTitle level={5}>
                  Passengers
                </StyledTitle>

                <Content>
                  {passengers.map((p, key) => (
                      <StyledParagraph size="default" key={key}>
                        {p.FirstName} {p.LastName}
                      </StyledParagraph>
                  ))}
                </Content>
              </Section>
            )
        }

        {
          bookingContact && (
              <Section>
                <StyledTitle level={5}>
                  Contact details (This is where we will send your booking details)
                </StyledTitle>

                <Content>
                  <StyledParagraph size="default">
                    Email: {bookingContact.email}
                  </StyledParagraph>
                  <StyledParagraph size="default">
                    Mobile: {bookingContact.phone}
                  </StyledParagraph>
                </Content>
              </Section>
            )
        }
      </div>
  )
}

const Content = styled.div`
  margin-top: 10px;
`;

const FlightCode = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  margin: 10px 0 5px;
`;

const Section = styled.div`
  background: ${WHITE};
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 20px;
  border: solid 1px ${GREY_4};
`;

export default FlightBookingSummary;
