import React from 'react';
import { Card, Space, Button } from 'antd';
import moment from 'moment';
import {
  StyledParagraph, StyledText, StyledTitle,
} from '../CustomAntdComponents/Typography';
import {
  BillingDetailsWrapper,
  DetailsWrapper,
  HighlightDetailItem,
  TitleWrapper,
} from './styles';
import {
  SpaceBetweenAlignContent,
  VerticalAlignContent,
} from '../CustomAntdComponents/Utitlities';
import { ReactComponent as ClockIcon } from '../../assets/images/clock.svg';
import { formatNumberAsCurrency, pluralizeText } from '../../utils/helpers';
import {
  calculateTotalFare,
} from '../AvailableBoats/helper';
import { useRailSearchStore } from '../../store/railSearch/railSearchStore';
import RailTripSummary from '../TripSummary/RailTripSummary';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/SearchRoadListings/translations';

type Props = {
  showSubmitButton?: boolean,
}
function TrainItinerary({ showSubmitButton }: Props) {
  const {
    state: { t },
  } = useI18nContext();
  const {
    selectedTrain,
    selectedResult,
    formData,
  } = useRailSearchStore();

  const oneWayTrip = formData.TripType === 'one_way';
  const totalFare = calculateTotalFare(selectedTrain ? selectedTrain.PassengerFares : []);

  const renderTripDetails = (returning = false) => ((selectedResult && selectedTrain) ? (
    <>
      <Space direction="vertical" size="middle">
        <VerticalAlignContent>
          <StyledTitle level={3}>
            15609
          </StyledTitle>
          <StyledTitle level={4} color="primary">
            Abadh Assam Express
          </StyledTitle>
        </VerticalAlignContent>

        <RailTripSummary showChangeButton={false} returning={returning} itinerary />
      </Space>

      <DetailsWrapper>
        <HighlightDetailItem>
          <SpaceBetweenAlignContent>
            <StyledText size="small">
              {moment(selectedTrain.EstimatedDepartureDate).format('hh:mm a')}
            </StyledText>
            <StyledText size="small" strong>
              {moment(selectedTrain.EstimatedArrivalDate).format('hh:mm a')}
            </StyledText>
          </SpaceBetweenAlignContent>
        </HighlightDetailItem>

        <VerticalAlignContent>
          {selectedResult.Stops !== 0 && (
          <StyledText size="small">
            {pluralizeText(selectedResult.Stops, t(translations).stop, t(translations).stops)}
          </StyledText>
          ) }

          <VerticalAlignContent>
            <ClockIcon />
            <StyledText size="small">
              {selectedTrain.Duration}
            </StyledText>
          </VerticalAlignContent>
        </VerticalAlignContent>
      </DetailsWrapper>
    </>
  ) : (<div>&nbsp;</div>));

  return (
    <Space direction="vertical" size="middle">
      <Card size="default">
        <TitleWrapper>
          <StyledParagraph strong color="secondary">
            {t(translations).departure}
          </StyledParagraph>
        </TitleWrapper>
        {
          selectedResult && renderTripDetails()
        }
      </Card>

      {/* Return Info not ready yet  */}
      {
        !oneWayTrip && selectedTrain && (
        <Card size="default">
          <TitleWrapper>
            <StyledParagraph strong color="secondary">
              {t(translations).return}
            </StyledParagraph>
          </TitleWrapper>

          {
            selectedTrain && renderTripDetails(
              true,
            )
          }
        </Card>
        )
      }

      {
        selectedTrain && (
        <Card size="default">
          <TitleWrapper>
            <StyledParagraph strong color="secondary">
              {t(translations).billingBreakdown}
            </StyledParagraph>
          </TitleWrapper>

          {/* TODO Differentiate departure and return fee */}
          <BillingDetailsWrapper>
            <Space direction="vertical" style={{ width: '100%' }}>
              <SpaceBetweenAlignContent>
                <StyledText size="small" color="secondary">
                  {t(translations).departureFare}
                </StyledText>
                <StyledText strong>
                  {formatNumberAsCurrency(totalFare.ticketAmount, selectedTrain.Currency)}
                </StyledText>
              </SpaceBetweenAlignContent>

              {!oneWayTrip && (
              <SpaceBetweenAlignContent>
                <StyledText size="small" color="secondary">
                  {t(translations).returnFare}
                </StyledText>
                <StyledText strong>
                  {formatNumberAsCurrency(totalFare.ticketAmount, selectedTrain.Currency)}
                </StyledText>
              </SpaceBetweenAlignContent>
              )}

              <SpaceBetweenAlignContent>
                <StyledText size="small" color="secondary">
                  {t(translations).serviceFee}
                </StyledText>
                <StyledText strong>
                  {formatNumberAsCurrency(totalFare.totalCharges, selectedTrain.Currency)}
                </StyledText>
              </SpaceBetweenAlignContent>
            </Space>
          </BillingDetailsWrapper>

          <HighlightDetailItem>
            <SpaceBetweenAlignContent>
              <StyledText size="small" color="secondary">
                {t(translations).totalAmount}
              </StyledText>
              <StyledText strong>
                {formatNumberAsCurrency(totalFare.totalAmount, selectedTrain.Currency)}
              </StyledText>
            </SpaceBetweenAlignContent>
          </HighlightDetailItem>
          { showSubmitButton && (
            <Button type="primary" htmlType="submit" block>
              {t(translations).book}
            </Button>
          )}
        </Card>
        )
      }

    </Space>
  );
}

TrainItinerary.defaultProps = {
  showSubmitButton: true,
};
export default TrainItinerary;
