import React from 'react';
import {
    Row, Col, Form, Checkbox
} from 'antd';
import StyledSelect from '../CustomAntdComponents/StyledSelect';
import StyledInput from '../CustomAntdComponents/StyledInput';
import {genderOptions, titleOptions} from './helper';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/PassengerDetails/translations';
import {
    validateEmailRequired,
    validateNameRequired,
    validatePhoneRequired,
    validateRequired
} from "../../utils/helpers";
import PhoneNumberInput from "../PhoneNumberInput";
import {CheckBoxLabel, InfoWrapper} from "./styles";
import {useRoadSearchStore} from "../../store/roadSearch/roadSearchStore";
import {StyledParagraph} from "../CustomAntdComponents/Typography";

type Props = {
  field: any,
  passengerType: string,
}
function RoadPassengerForm({
                               field, passengerType,
                             }: Props) {
  const {
    state: { t },
  } = useI18nContext();

    const {
        formData: {
            numberOfAdults,
        }
    } = useRoadSearchStore()


  const isAdult = passengerType === 'ADT';

  return (
      <Row gutter={16}>
        <Col span={24}>
            <InfoWrapper>
                <StyledParagraph size="default">
                    To avoid boarding complications, enter your details exactly as they appear on your official ID
                </StyledParagraph>
            </InfoWrapper>
        </Col>
        <Col span={12}>
          <Form.Item
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...field}
              name={[field.name, 'namePrefix']}
              fieldKey={[field.key, 'namePrefix']}
              rules={[{ required: true, message: t(translations).validations.title }]}
          >
            <StyledSelect options={titleOptions} placeholder={t(translations).input.title} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
              {...field}
              name={[field.name, 'gender']}
              fieldKey={[field.key, 'gender']}
              rules={[{ required: true, message: 'Please select gender' }]}
          >
            <StyledSelect options={genderOptions} placeholder="Gender" />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...field}
              name={[field.name, 'firstName']}
              fieldKey={[field.key, 'firstName']}
              rules={[{ validator: validateNameRequired }]}
          >
            <StyledInput placeholder={t(translations).input.firstName} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...field}
              name={[field.name, 'lastName']}
              fieldKey={[field.key, 'lastName']}
              rules={[{ validator: validateNameRequired }]}
          >
            <StyledInput placeholder={t(translations).input.lastName} />
          </Form.Item>
        </Col>

        {
            isAdult && (
                <>
                  <Col span={24}>
                    <Form.Item
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        {...field}
                        name={[field.name, 'nextOfKinName']}
                        fieldKey={[field.key, 'nextOfKinName']}
                        rules={[{ validator: validateRequired, message: t(translations).validations.nok }]}
                    >
                      <StyledInput placeholder={t(translations).input.nok} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        {...field}
                        name={[field.name, 'nextOfKinEmail']}
                        fieldKey={[field.key, 'nextOfKinEmail']}
                        rules={[{ validator: validateEmailRequired, message: t(translations).validations.emailInvalid }]}
                    >
                      <StyledInput placeholder={t(translations).input.nokEmail} />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        {...field}
                        name={[field.name, 'nextOfKinPhoneNumber']}
                        fieldKey={[field.key, 'nextOfKinPhoneNumber']}
                        rules={[{ validator: validatePhoneRequired, message: t(translations).validations.phone }]}
                    >
                        <PhoneNumberInput
                            onChange={() =>{}}
                            name={"nextOfKinPhoneNumber"}
                            placeholder={t(translations).input.nokPhone}
                            phoneOnly
                        />
                    </Form.Item>
                  </Col>
                </>
            )
        }

      {
          (field.key === 0  && isAdult) && (
              <>
                  <Col span={24}>
                      <Form.Item
                          /* eslint-disable-next-line react/jsx-props-no-spreading */
                          {...field}
                          valuePropName="checked"
                          name={[field.name, 'savePassengerData']}
                          fieldKey={[field.key, 'savePassengerData']}
                          style={{ marginBottom: 0 }}
                      >
                          <Checkbox>
                              <CheckBoxLabel>
                                  Save my data for future purposes
                              </CheckBoxLabel>
                          </Checkbox>
                      </Form.Item>
                  </Col>

                  {
                      numberOfAdults > 1 && (
                          <Col span={24}>
                              <Form.Item
                                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                                  {...field}
                                  valuePropName="checked"
                                  name={[field.name, 'applyToOtherPassengers']}
                                  fieldKey={[field.key, 'applyToOtherPassengers']}
                              >
                                  <Checkbox>
                                      <CheckBoxLabel>
                                          Apply details to other passengers
                                      </CheckBoxLabel>
                                  </Checkbox>
                              </Form.Item>
                          </Col>
                      )
                  }
              </>
          )
      }

      </Row>
  );
}

export default RoadPassengerForm;
