import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from 'antd';
import {
  ContentWrapper, DriverSeatWrapper, IndicatorContent,
  IndicatorWrapper, PadContentWrapper,
  PadWrapper, SeatWrapper,
  Wrapper,
} from './styles';
import { StyledParagraph } from '../CustomAntdComponents/Typography';
import { StyledButton } from '../CustomAntdComponents/StyledButton';
import { pluralizeText } from '../../utils/helpers';
import steering from '../../assets/images/steering.svg';
import { useRailSearchStore } from '../../store/railSearch/railSearchStore';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/PassengerDetails/translations';

function TrainSeatSelection() {
  const {
    state: { t },
  } = useI18nContext();
  const navigate = useNavigate();
  const [isCompleted, setIsCompleted] = useState(false);
  const [selectedSeats, setSelectedSeats] = useState<AvailableRailSeat[]>([]);
  const [sortedSeats, setSortedSeats] = useState<AvailableRailSeat[]>([]);
  const [column, setColumn] = useState(3);
  const { formData: { numberOfAdults, numberOfChildren, numberOfInfants }, updateFormData, selectedTrain } = useRailSearchStore();
  const totalPassengers = numberOfAdults + numberOfChildren + numberOfInfants;

  useEffect(() => {
    if (selectedTrain && selectedTrain.AvailableSeats) {
      const seats = selectedTrain.AvailableSeats.sort((a: AvailableRailSeat, b: AvailableRailSeat) => (a.SeatNumber - b.SeatNumber));
      setSortedSeats(seats);
    }
  }, [selectedTrain]);

  const getSeatStatus = (seat: AvailableRailSeat) => {
    const findSeat = selectedSeats.find((s) => s.SeatNumber === seat.SeatNumber);
    if (!findSeat) {
      return seat.IsAvailable ? 'available' : 'booked';
    }

    return 'selected';
  };

  const handleSeatSelection = (seat: AvailableRailSeat) => {
    const findSeat = selectedSeats.find((s) => s.SeatNumber === seat.SeatNumber);

    if (!findSeat && seat.IsAvailable && !isCompleted) {
      setSelectedSeats([...selectedSeats, { ...seat }]);
    } else {
      const newSelections = selectedSeats.filter((s) => s.SeatNumber !== seat.SeatNumber);
      setSelectedSeats([...newSelections]);
    }
  };

  const handleProceed = () => {
    updateFormData({ selectedSeats });
    navigate('/passenger-details/rail');
  };

  useEffect(() => {
    setIsCompleted(selectedSeats.length === totalPassengers);
  }, [selectedSeats, totalPassengers]);

  return (
    <Wrapper>
      <Card>
        <ContentWrapper size="medium">
          <IndicatorWrapper>
            <IndicatorContent>
              <SeatWrapper />
              <StyledParagraph type="secondary" size="small" align="center">
                {t(translations).available}
              </StyledParagraph>
            </IndicatorContent>
            <IndicatorContent>
              <SeatWrapper status="booked" />
              <StyledParagraph type="secondary" size="small" align="center">
                {t(translations).booked}
              </StyledParagraph>
            </IndicatorContent>
            <IndicatorContent>
              <SeatWrapper status="blocked" />
              <StyledParagraph type="secondary" size="small" align="center">
                {t(translations).blocked}
              </StyledParagraph>
            </IndicatorContent>
            <IndicatorContent>
              <SeatWrapper status="selected" />
              <StyledParagraph type="secondary" size="small" align="center">
                {t(translations).selected}
              </StyledParagraph>
            </IndicatorContent>
          </IndicatorWrapper>

          <StyledParagraph align="center" padded="true">
            {`${t(translations).seatSelectionText} ${pluralizeText(
              totalPassengers,
              t(translations).passenger,
              t(translations).passengers,
            )}`}
          </StyledParagraph>

          <PadContentWrapper>
            <PadWrapper column={column}>

              <SeatWrapper isDriver>
                <img src={steering} alt="" />
              </SeatWrapper>
              {

                sortedSeats?.map((seat) => (
                  <SeatWrapper
                    key={seat.SeatNumber}
                    status={getSeatStatus(seat)}
                    onClick={() => handleSeatSelection(seat)}
                    marginalized={[5, 7, 10].includes(seat.SeatNumber)}
                  >
                    {seat.SeatNumber}
                  </SeatWrapper>
                ))
              }

            </PadWrapper>
          </PadContentWrapper>

          <StyledButton type="primary" block size="large" disabled={!isCompleted} onClick={handleProceed}>
            {t(translations).done}
          </StyledButton>
        </ContentWrapper>
      </Card>
    </Wrapper>
  );
}

export default TrainSeatSelection;
