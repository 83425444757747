import styled from "styled-components";
import {BLACK_200, GREY_10, GREY_12, WHITE} from "../../utils/colors";
import device from "../../utils/mediaQuery";

export const Wrapper = styled.div`
  display: flex;
  column-gap: 10px;
  margin-top: 20px;

  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
`;

export const FlightDetail = styled.div`
    display: flex;
     column-gap: 15px;
  align-items: center;
`;

export const FlightIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: ${GREY_10};
  
    @media(${device.large}) {
      width: 50px;
      height: 50px;
    }
`;

export const ItemWrapper = styled.div`
    background: ${WHITE};
    padding: 15px 50px 15px 20px;
    border-radius: 15px;
    border: solid 1px ${GREY_12};
    display: inline-flex;
    flex-direction: column;
    row-gap: 5px;
    cursor: pointer;
  
  svg {
    path {
        fill: ${BLACK_200} !important;
    }
  }
`
