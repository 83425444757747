import styled from 'styled-components';
import {GREY_12} from '../../../utils/colors';

export const Wrapper = styled.div`
  textarea {
    border-radius: 12px !important;
    border: solid 1px ${GREY_12} !important;
  };

  .ant-input:focus, .ant-input-focused {
    box-shadow: none !important;
    border-radius: 12px !important;
  }
`;
