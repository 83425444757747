import React, {useState, useMemo, useEffect} from 'react';
import { Collapse } from 'antd';
import {
    CollapseWrapper,
    FilterHeading,
    FilterLinkText,
} from "./styles";
import AirlinesCheckbox from "./FlightFilters/AirlinesCheckbox";
import PriceRange from "./FlightFilters/PriceRange";
import StopOvers from "./FlightFilters/StopOvers";
import {StyledTitle} from "../CustomAntdComponents/Typography";

const { Panel } = Collapse;

const categories = ['Stops', 'Airlines', 'Price'];
type Props = {
    isDomestic: boolean,
    handleAirlineFiltering?: (arg: string[]) => void,
    handleStopoverFiltering: (arg0: number | null) => void,
    onResetFilters: () => void,
    farePriceRange: PriceRangeType,
    handleFilterByPriceRange: (arg0: [number, number]) => void,
    selectedAirlines: string[],
    airlines: AirlineOption[],
    selectedStopOver: number | null,
}

const ListingFilterDesktop = ({
        isDomestic, handleAirlineFiltering, handleFilterByPriceRange, airlines, selectedStopOver,
        handleStopoverFiltering, selectedAirlines, onResetFilters, farePriceRange
}: Props) => {
    const [active, setActive] = useState(categories[0])
    const allowableCategories = useMemo(() => {
        if (isDomestic) {
            return categories.filter((c) => c !== 'Stops');
        }
        return categories;
    }, [isDomestic])

    const renderFilterContent = (category: string) => {
        switch (category) {
            case 'Airlines':
                return (
                    <AirlinesCheckbox selectedAirlines={selectedAirlines} airlines={airlines} onChange={(v) => handleAirlineFiltering(v)} />
                )
            case 'Price':
                return (
                    <PriceRange onChange={(v) => handleFilterByPriceRange(v)} farePriceRange={farePriceRange} />
                )
            case 'Stops':
                return (
                    <StopOvers selectedStopOver={selectedStopOver} onChange={(v) => handleStopoverFiltering(parseInt(v))} />
                )
            default:
                return null
        }
    }

    return (
        <>
            <FilterHeading>
                <StyledTitle level={5}>Filter by</StyledTitle>
                <FilterLinkText level={5} color="primary" onClick={onResetFilters}>Reset</FilterLinkText>
            </FilterHeading>
            <CollapseWrapper>
                <Collapse accordion activeKey={active} onChange={(c: string) => setActive(c)}>
                    {
                        allowableCategories.map((category) => (
                            <Panel header={category} key={category}>
                                {renderFilterContent(category)}
                            </Panel>
                        ))
                    }
                </Collapse>
            </CollapseWrapper>
        </>
    );
};

export default ListingFilterDesktop;
