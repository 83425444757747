import styled from 'styled-components';
import {BLACK} from "../../../utils/colors";
import device from "../../../utils/mediaQuery";

export const Container = styled.section`
  background: #fff;
  padding: 20px;
`;

export const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 20px 16px 20px;
  background: #fff;
  column-gap: 10px;
  
    @media ${device.large} {
      padding: 0;
      background: unset;
    }
`;

export const BackIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 10px;
  
  svg {
    path {
      fill: ${BLACK} !important;
    }
  }
`;

export const TimelineContainer = styled.div`
  flex: 1;
  text-align: center;
`;

export const TimelineWrapper = styled.div`
    display: inline-flex;
    width: 700px;
    padding: 0;
`;
