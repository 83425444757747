import React, { useMemo } from 'react';
import {
    Row, Col, Form, Collapse, Checkbox
} from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import StyledSelect from '../CustomAntdComponents/StyledSelect';
import StyledInput from '../CustomAntdComponents/StyledInput';
import {
    allowedAgedRange,
    genderOptions,
    titleOptions,
    validateDOB,
    validateNoFutureDate,
    validatePassportExpiration
} from './helper';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/PassengerDetails/translations';
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import {useFlightSearchStore} from "../../store/flightSearch/flightSearchStore";
import moment from "moment";
import { getCountries } from 'country-state-picker';
import {CheckBoxLabel, CollapseWrapper, InfoWrapper} from "./styles";
import {useStore} from "../../store/global/appStore";
import {validateNameRequired, validateRequired} from "../../utils/helpers";
import CustomDatePicker from "../CustomDatePicker";

const { Panel } = Collapse;

type Props = {
    field: any,
    passengerType: string,
}
function FlightPassengerForm({
                           field, passengerType,
                       }: Props) {
    const {
        state: { t },
    } = useI18nContext();

    const { airlines } = useStore();

    const airlinesOptions = useMemo(() => airlines.map((airline: Airline) => ({
        label: airline.Name,
        value: airline.Code,
    })), [airlines]);

    const {
        formData: {
            isDomestic
        },
    } = useFlightSearchStore();
    const countryOptions = useMemo(() => {
        const countries = getCountries().map(({ name, code }: any) => ({
            label: name,
            value: code,
        }));

        const priorities = [
            {
                label: "Nigeria",
                value: "ng",
            },
            {
                label: "Kenya",
                value: "ke",
            }
        ]
        const others = countries.filter((c) => c.label !== "Kenya" && c.label !== "Nigeria");

        return [
            ...priorities,
            ...others
        ];
    }, []);

    const isAdult = passengerType === 'ADT';

    return (
        <Row gutter={16}>
            <Col span={24}>
                <InfoWrapper>
                    <StyledParagraph size="default">
                        To avoid boarding complications, enter your details exactly as they appear on your passport
                    </StyledParagraph>
                </InfoWrapper>
            </Col>

            <Col span={12}>
                <Form.Item
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...field}
                    name={[field.name, 'title']}
                    fieldKey={[field.key, 'title']}
                    rules={[{ required: true, message: t(translations).validations.title }]}
                >
                    <StyledSelect options={titleOptions} placeholder={t(translations).input.title} />
                </Form.Item>
            </Col>

             <Col span={12}>
               <Form.Item
                 {...field}
                 name={[field.name, 'gender']}
                 fieldKey={[field.key, 'gender']}
                 rules={[{ required: true, message: 'Please select gender' }]}
               >
                 <StyledSelect options={genderOptions} placeholder="Gender" />
               </Form.Item>
             </Col>

            <Col span={24}>
                <Form.Item
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...field}
                    name={[field.name, 'firstName']}
                    fieldKey={[field.key, 'firstName']}
                    rules={[{ validator: validateNameRequired }]}
                >
                    <StyledInput placeholder={t(translations).input.firstName} />
                </Form.Item>
            </Col>

            <Col span={24}>
                <Form.Item
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...field}
                    name={[field.name, 'lastName']}
                    fieldKey={[field.key, 'lastName']}
                    rules={[{ validator: validateNameRequired }]}
                >
                    <StyledInput placeholder={t(translations).input.lastName} />
                </Form.Item>
            </Col>

            <Col span={24}>
                <Form.Item
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...field}
                    name={[field.name, 'dob']}
                    fieldKey={[field.key, 'dob']}
                    rules={[
                        {
                            validator: (_, value: any) => {
                                return validateDOB(value, passengerType);
                            }
                        }
                    ]}
                >
                    <CustomDatePicker label={t(translations).input.dob} onChange={(val) => val } />
                </Form.Item>
            </Col>

            {
                !isDomestic && (
                    <>
                        <Col span={24}>
                            <div style={{ marginBottom: "20px"}}>
                                <StyledTitle level={4}>
                                    Passport details
                                </StyledTitle>
                            </div>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                /* eslint-disable-next-line react/jsx-props-no-spreading */
                                {...field}
                                name={[field.name, 'passportNumber']}
                                fieldKey={[field.key, 'passportNumber']}
                                rules={[
                                    { validator: validateRequired, message: t(translations).validations.passportNumber },
                                ]}
                            >
                                <StyledInput placeholder={t(translations).input.passportNumber} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                /* eslint-disable-next-line react/jsx-props-no-spreading */
                                {...field}
                                name={[field.name, 'issuanceCountry']}
                                fieldKey={[field.key, 'issuanceCountry']}
                                rules={[{ required: true, message: t(translations).validations.issuanceCountry }]}
                            >
                                <StyledSelect
                                    showSearch
                                    options={countryOptions}
                                    placeholder={t(translations).input.issuanceCountry}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24}>
                            <Form.Item
                                /* eslint-disable-next-line react/jsx-props-no-spreading */
                                {...field}
                                name={[field.name, 'issuanceDate']}
                                rules={[
                                    {
                                        validator: (_, value: any) => {
                                            return validateNoFutureDate(value);
                                        }
                                    }
                                ]}
                            >
                                <CustomDatePicker label={t(translations).input.passportIssuanceDate} onChange={(val) => val } />
                            </Form.Item>
                        </Col>

                        <Col xs={24}>
                            <Form.Item
                                /* eslint-disable-next-line react/jsx-props-no-spreading */
                                {...field}
                                name={[field.name, 'passportExpiration']}
                                rules={[
                                    {
                                        validator: (_, value: any) => {
                                            return validatePassportExpiration(value);
                                        }
                                    }
                                ]}
                            >
                                <CustomDatePicker label={t(translations).input.passportExpiration} onChange={(val) => val } startYearIndex={30} />
                            </Form.Item>
                        </Col>
                    </>
                )
            }

            <Col span={24}>
                <div style={{ marginBottom: "20px"}}>
                    <StyledTitle level={4}>
                        Additional information <span>(optional)</span>
                    </StyledTitle>
                </div>

                <CollapseWrapper>
                    <Collapse>
                        <Panel header="Frequent flyer? (optional)" key="1">
                            <Form.Item
                                /* eslint-disable-next-line react/jsx-props-no-spreading */
                                {...field}
                                name={[field.name, 'frequentFlyerAirline']}
                                fieldKey={[field.key, 'frequentFlyerAirline']}
                            >
                                <StyledSelect
                                    showSearch
                                    allowClear
                                    options={airlinesOptions}
                                    placeholder={t(translations).input.frequentFlyerAirline}
                                />
                            </Form.Item>

                            <Form.Item
                                /* eslint-disable-next-line react/jsx-props-no-spreading */
                                {...field}
                                name={[field.name, 'frequentFlyerNumber']}
                                fieldKey={[field.key, 'frequentFlyerNumber']}
                            >
                                <StyledInput placeholder={t(translations).input.frequentFlyerNumber} />
                            </Form.Item>
                        </Panel>
                    </Collapse>
                </CollapseWrapper>
            </Col>

            {
                (field.key === 0  && isAdult)&& (
                    <Col span={24}>
                        <Form.Item
                            /* eslint-disable-next-line react/jsx-props-no-spreading */
                            {...field}
                            valuePropName="checked"
                            name={[field.name, 'savePassengerData']}
                            fieldKey={[field.key, 'savePassengerData']}
                        >
                            <Checkbox>
                                <CheckBoxLabel>
                                    Save my data for future purposes
                                </CheckBoxLabel>
                            </Checkbox>
                        </Form.Item>
                    </Col>
                )
            }
        </Row>
    );
}

export default FlightPassengerForm;
