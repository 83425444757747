import { create } from 'zustand';
import {RoadSearchStore, RoadSearchStoreState} from './types';

export const initialFormData: RoadSearchStore = {
  TripType: 'one_way',
  numberOfAdults: 1,
  OrderbyDepartureTime: 'ASC',
  OrderByPrice: 'ASC',
  Currency: 'NGN',
  PageSize: 5000,
  From: 0,
  selectedSeats: [],
};

export const useRoadSearchStore = create<RoadSearchStoreState>((set) => ({
  // initial state
  formData: initialFormData,
  loading: false,
  bookingContact: null,
  passengers: null,
  selectedBus: null,
  tripsSessionId: null,
  selectedResult: null,
  selectedBusSeat: null,
  updateFormData: (data: any) => {
    set((state) => ({
      ...state,
      formData: {
        ...state.formData,
        ...data,
      },
    }));
  },
  updateStoreData: (data: any) => {
    set((state) => ({
      ...state,
      ...data,
    }));
  },
  resetFormData: () => {
    set((state) => ({
      ...state,
      formData: {
        ...initialFormData,
      },
    }));
  },
}));
