export default {
  en: {
    title: 'Flight Listings',
    listingsTitle: 'Available Flight',
    changeButton: 'Change',
    filterTitle: 'Filter',
    allAirlines: 'All Airlines',
    airline: 'Airline',
    stops: 'Stops',
    stop: 'Stop',
    price: 'Price',
    listings: 'listings',
    departure: 'Departure',
    arrival: 'Arrival',
    duration: 'Flight duration',
    return: 'Return',
    selectReturn: 'Select Return',
    bookFlight: 'Book Flight',
    cheapest: 'Cheapest',
    expensive: 'Expensive',
    view: 'View Details',
    layover: 'Layover',
    emptyMessage: 'No flight matches your search',
    fareRule: {
      title: 'Do you agree with the Fare rules',
      text: 'To continue. You have to agree with Fare rules',
      acceptButton: 'I Accept',
    },
    totalAmount: 'Total Amount',
    totalPassenger: 'Total Passenger',
    ticketFare: 'Ticket Fare',
  },
  fr: {
    title: 'Liste des vols',
    listingsTitle: 'Vol disponible',
    changeButton: 'Changer',
    filterTitle: 'Filtrer',
    allAirlines: 'Toutes les compagnies aériennes',
    airline: 'Compagnie aérienne',
    stops: 'Arrêts',
    stop: 'Arrêt',
    price: 'Prix',
    listings: 'listings',
    departure: 'Départ',
    arrival: 'Arrivée',
    return: 'Sélectionnez Retour',
    selectReturn: 'Select Return',
    duration: 'Durée du vol',
    bookFlight: 'Réserver un vol',
    cheapest: 'Le moins cher',
    expensive: 'Plus cher',
    view: 'Voir les détails',
    layover: 'Escale',
    emptyMessage: 'Aucun vol ne correspond à votre recherche',
    fareRule: {
      title: 'Êtes-vous d\'accord avec les règles tarifaires',
      text: 'Pour continuer. Vous devez accepter les règles tarifaires',
      acceptButton: 'J\'accepte',
    },
    totalAmount: 'Montant total',
    totalPassenger: 'Nombre total de passagers',
    ticketFare: 'Tarif total',
  },
  sp: {
    title: 'Lista de vuelos',
    listingsTitle: 'Vuelo disponible',
    changeButton: 'CambiarCambiar',
    filterTitle: 'Filtrar',
    allAirlines: 'Todas las aerolíneas',
    airline: 'Aerolínea',
    stops: 'Paradas',
    stop: 'Parada',
    price: 'Precio',
    listings: 'listados',
    departure: 'Salida',
    arrival: 'Llegada',
    duration: 'Duración del vuelo',
    return: 'Regreso',
    selectReturn: 'Seleccione Retorno',
    bookFlight: 'Reservar vuelo',
    cheapest: 'Más barato',
    expensive: 'Más caro',
    view: 'Ver detalles',
    layover: 'Escala',
    emptyMessage: 'Ningún vuelo coincide con su búsqueda',
    fareRule: {
      title: '¿Está de acuerdo con las reglas de la tarifa?',
      text: 'Para continuar. Tiene que estar de acuerdo con las reglas de la tarifa',
      acceptButton: 'Acepto',
    },
    totalAmount: 'Importe total',
    totalPassenger: 'Total de pasajeros',
    ticketFare: 'Tarifa total',
  },
  sw: {
    title: 'Orodha za Ndege',
    listingsTitle: 'Ndege Inayopatikana',
    changeButton: 'Badilika',
    filterTitle: 'Chuja',
    allAirlines: 'Mashirika yote ya ndege',
    airline: 'Shirika la ndege',
    stops: 'Inasimama',
    stop: 'Acha',
    price: 'Bei',
    listings: 'orodha',
    departure: 'Kuondoka',
    arrival: 'Kuwasili',
    return: 'Rudi',
    selectReturn: 'Chagua Kurudi',
    duration: 'Muda wa ndege',
    bookFlight: 'Weka Nafasi ya Ndege',
    cheapest: 'Nafuu zaidi',
    expensive: 'Ghali',
    view: 'Tazama Maelezo',
    layover: 'Layover',
    emptyMessage: 'Hakuna ndege inayolingana na utafutaji wako',
    fareRule: {
      title: 'Je, unakubaliana na sheria za Nauli',
      text: 'Kuendelea. Lazima ukubaliane na sheria za Nauli',
      acceptButton: 'Nakubali',
    },
    totalAmount: 'Jumla',
    totalPassenger: 'Jumla ya abiria',
    ticketFare: 'Nauli Jumla',
  },
};
