import styled from "styled-components";
import {BLACK_100, GREY_12, PRIMARY, WHITE} from "../../utils/colors";
import device from "../../utils/mediaQuery";

export const Wrapper = styled.div`
  .ant-drawer-title {
    font-size: 20px;
    text-transform: capitalize;
  }

  .ant-drawer-footer {
    padding: 30px;
  }

  .ant-input {
    border: solid 1px ${GREY_12} !important;
  }

  .ant-input:focus, .ant-input-focused {
    border: solid 1px ${GREY_12} !important;
  }
  
  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    border: solid 1px ${GREY_12} !important;
  }

  .ant-select-selector {
    box-shadow: none !important;
    border: solid 1px ${GREY_12} !important;
  }
  
    .ant-drawer-content-wrapper {
        height: 100%;
    }
    
  .ant-picker-focused,
  .ant-picker {
    border: solid 1px ${GREY_12} !important;
  }
  
  @media ${device.large} {
    .ant-drawer-content-wrapper {
      width: 600px !important;
    }
  }
`;

export const PassengerDataItemIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    min-width: 50px;
    min-height: 50px;
    border-radius: 50%;
    border: solid 1px ${BLACK_100};
    background: ${WHITE};
  
    .ant-typography {
      color: ${PRIMARY};
      text-transform: uppercase;
      font-size: 18px;
    }
`;

export const PassengerDataItemDetails = styled.div`
    display: flex;
     align-items: center;
    column-gap: 10px;
`;

export const SuccessIconWrapper = styled.div`
    svg {
      path {
        fill: #00A859;
      }
    }
`;
export const ErrorIconWrapper = styled.div`
    svg {
      path {
        fill: #FF9F0A !important;
      }
    }
`;

export const PassengerDataItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
    padding: 15px 0;
      border-bottom: 1px solid ${GREY_12};
      cursor: pointer;

  @media ${device.large} {
    padding: 20px 0;
    column-gap: 15px;
  }
`;

export const PassengerName = styled.div`
  .ant-typography {
    text-transform: capitalize;
    font-size: 16px;
  }
`;
