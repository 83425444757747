import React, { useRef, useState } from 'react';
import {
  DatePicker,
} from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import type { Moment } from 'moment';
import { Wrapper, PrefixIconWrapper } from './styles';
import {ReactComponent as CalendarIcon} from '../../../assets/images/calendar-grey.svg';

type Props = {
  label?: string,
  onChange: (dates: [Moment, Moment]) => void
} & RangePickerProps;

const { RangePicker } = DatePicker;

function StyledDateRangePicker({
    label,
  size, placeholder, value, onChange, disabledDate,
}: Props) {
  const popoverContainerRef = useRef(null);

  const [focus, setFocus] = useState(false);
  const [selectedValue, setSelectedValue] = useState<[Moment, Moment] | null>();

  const hasValue = focus || (selectedValue) || value;
  const labelClass = hasValue ? 'label as-label' : 'label as-placeholder';

  const handleDateChange: RangePickerProps['onChange'] = (date, formatString) => {
    setSelectedValue(date);
    if (onChange) onChange(date);
  };

  const getContainer = () => popoverContainerRef.current as unknown as HTMLElement;

  return (
    <Wrapper
      ref={popoverContainerRef}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <RangePicker
        size={size}
        suffixIcon={null}
        disabledDate={disabledDate}
        value={value}
        onChange={handleDateChange}
        inputReadOnly
        getPopupContainer={getContainer}
       placeholder={placeholder}
      />
      {
        (label && hasValue) && (
            <span className={labelClass}>
              {label}
            </span>)
      }
      <PrefixIconWrapper>
        <CalendarIcon />
      </PrefixIconWrapper>
    </Wrapper>
  );
}

StyledDateRangePicker.defaultProps = {
  placeholder: '',
  size: 'large',
  value: undefined,
  disabledDate: undefined,
  onChange: undefined,
};
export default StyledDateRangePicker;
