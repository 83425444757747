import React, {useMemo} from 'react';
import FlightSearch from "./Flight";
import RoadSearch from "./Road";
import {StyledTitle} from "../CustomAntdComponents/Typography";
import {FlightLocalStoreKey, RoadLocalStoreKey} from "./constant";

type ContinueSearchProps = {
    tripType: string;
}
const ContinueSearch = ({ tripType }: ContinueSearchProps) => {
    const flightSearchItems = JSON.parse(localStorage.getItem(FlightLocalStoreKey) || '[]');
    const roadSearchItems = JSON.parse(localStorage.getItem(RoadLocalStoreKey) || '[]');

    const renderTripSearch = () => {
        switch (tripType) {
            case 'flight':
                return <FlightSearch data={flightSearchItems} />;
            case 'road':
                return <RoadSearch data={roadSearchItems} />;
            default:
                return null;
        }
    };

    const isEmpty = useMemo(() => {
        if(tripType === 'flight'){
            return flightSearchItems.length === 0;
        }

        if(tripType === 'road'){
            return roadSearchItems.length === 0;
        }

        return true;
    }, [flightSearchItems, roadSearchItems, tripType]);

    return isEmpty ? null : (
        <div>
            <StyledTitle level={3}>
                Continue Search
            </StyledTitle>

            {renderTripSearch()}
        </div>
    );
};

export default ContinueSearch;
