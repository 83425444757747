import styled, { css } from 'styled-components';
import { Card } from 'antd';
import {
  GREY_5, PRIMARY,
} from '../../utils/colors';
import device from '../../utils/mediaQuery';

export const TabWrapper = styled.div`
  background: #fff;
  display: flex;
`;

export const FormWrapper = styled.div`

  @media ${device.large} {
    .as-label {
      background: #fff;
    }
    
    .ant-form-item-control-input-content .ant-picker {
      background: #FCFCFC !important;
      border: 0.5px solid #CCD5E0 !important;
      border-radius: 5px;
    }
    
    .ant-select .ant-select-selector {
      background: #FCFCFC !important;
      border: 0.5px solid #CCD5E0 !important;
      border-radius: 5px;
    }

    .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
      border: 0.5px solid #CCD5E0 !important;
      border-color: #CCD5E0 !important;
    }

    .ant-select:not(.ant-select-disabled):focus .ant-select-selector,
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border: 0.5px solid #CCD5E0 !important;
    }
  }
`;

export const TabItemWrapper = styled.div< { active: boolean }>`
  padding: 15px;
  flex: 1;
  text-align: center;
  border-bottom: 4px solid transparent;
  cursor: pointer;

  transition: all 0.5s ease-in-out;
  
  .ant-typography {
    font-weight: 600;
  }
  
  ${({ active }) => active && css`
    border-bottom: 4px solid ${PRIMARY};
  `}
`;

export const FormCard = styled(Card)`
  @media ${device.large} {
    padding: 20px 80px;
  }
`;

export const FlexColumnItem = styled.div`
  flex: 1;
`;

export const FormDivider = styled.div`
  width: 1px;
  margin: 0 auto 0;
  height: 90%;
  background: ${GREY_5};
`;
