import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Button, Modal, Drawer } from 'antd';
import {AcceptButtonWrapper, Content, InlineTextWrapper, LinkText, Wrapper} from './styles';
import { postAPI } from '../../utils/api';
import { GET_FLIGHT_FARE_RULES } from '../../utils/constants';
import {
  useFlightSearchStore,
} from '../../store/flightSearch/flightSearchStore';
import {
  StyledParagraph,
  StyledTitle,
} from '../CustomAntdComponents/Typography';
import { ReactComponent as InfoIcon} from "../../assets/images/info.svg";
import FareContent from "./FareContent";
import useIsMobile from "../../hooks/useIsMobile";

const defaultFareRules: FlightFareRule[] = [
  {
    Title: 'Refund and Cancellation',
    Text: ' Non-refundable but changeable If for emergency reasons, cancellation fees may apply depending on fare and the time of cancellation. Refunds are processed in accordance with the aviation policy and may take some time to reflect in your account.',
    AirlineCode: '',
    AirlineName: '',
  },
  {
    Title: ' Visa requirement',
    Text: 'Visa requirements vary according to destination and passenger nationality. It is recommended that you check visa requirements well in advance of your travel date.',
    AirlineCode: '',
    AirlineName: '',
  },
  {
    Title: 'Baggage Allowance',
    Text: 'A minimum of 23kg for the check-in bag and 8kg for hand luggage. Baggage allowance varies by fare class, route, and airline policy.Excess baggage fees may apply if the baggage exceeds the allowed weight or dimensions.',
    AirlineCode: '',
    AirlineName: '',
  },
  {
    Title: 'Name Edit',
    Text: 'Passenger name cannot be corrected or edited. It is critical to ensure that the passenger names provided during the booking process match the travel documents exactly.',
    AirlineCode: '',
    AirlineName: '',
  },
  {
    Title: 'Changes',
    Text: 'Changes to travel dates, times, or routes may be allowed based on availability. Fees and fare differences may apply based on the new itinerary. Rescheduling requests must be submitted in advance and are subject to airline approval.',
    AirlineCode: '',
    AirlineName: '',
  }
];

type Props = {
  inline?: boolean;
}

function FareRule({ inline }: Props) {
  const {
    selectedFlight,
     updateFormData
  } = useFlightSearchStore();

  const { isMobile } = useIsMobile();
  const modalContainerRef = useRef(null);

  const [visible, setVisible] = useState(false);
  const [loadingFareData, setLoadingFareData] = useState<boolean>(true);
  const [fareRulesError, setFareRulesError] = useState<string| null>(null);
  const [fareRules, setFareRules] = useState<FlightFareRule[] | null>(null);

  const getFlightRues = useCallback(async () => {
    setLoadingFareData(true);
    setFareRulesError(null);

    if (selectedFlight) {
      const activeFlight = selectedFlight[0];
      const flightRules = await postAPI(GET_FLIGHT_FARE_RULES, {
        AgentId: activeFlight.AgentId,
        GDSId: activeFlight.GDSId,
        CombinationID: activeFlight.Properties.CombinationID,
        RecommendationID: activeFlight.Properties.RecommendationID,
        PassengerTypeCode: 'ADT',
        SessionId: activeFlight.Properties.TripsSessionId,
      });

      setLoadingFareData(false);

      if (flightRules.status === 200) {
        const rules = flightRules.data.FlightRules.length === 0 ? defaultFareRules : flightRules.data.FlightRules;
        setFareRules(rules);
      } else {
        setFareRules(defaultFareRules);
      }
    }
  }, [selectedFlight]);

  const handleAccept = () => {
    updateFormData({
      fareRuleAccepted: true,
    });

    setVisible(false);
  }

  const AcceptButton = (<AcceptButtonWrapper>
    <Button block type="primary" onClick={handleAccept}> I Accept </Button>
  </AcceptButtonWrapper>);

  useEffect(() => {
    getFlightRues();
  }, []);

  return (
      <Wrapper ref={modalContainerRef} inline={inline}>
        {
            inline ? (<InlineTextWrapper onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              setVisible(true)
            }}> fare rules
            </InlineTextWrapper>) : (
                <Content onClick={() => setVisible(true)}>
                <StyledParagraph color="primary">
                  Read fare rules
                </StyledParagraph>
                <LinkText>
                  <InfoIcon />
                </LinkText>
            </Content>)
        }

        {/* Mobile display */}
        { isMobile ? (
            <Drawer
                placement="bottom"
                open={visible}
                onClose={() => setVisible(false)}
                footer={AcceptButton}
                height="100%"
                title="Fare Rules"
                getContainer={modalContainerRef.current}
            >
              <FareContent loadingFareData={loadingFareData} fareRules={fareRules} fareRulesError={fareRulesError} />
            </Drawer>
        ) : (
            <Modal
                title={<StyledTitle level={4}>Fare Rules</StyledTitle>}
                open={visible}
                onCancel={() => setVisible(false)}
                getContainer={modalContainerRef.current}
                footer={AcceptButton}
                width={750}
            >
              <FareContent loadingFareData={loadingFareData} fareRules={fareRules} fareRulesError={fareRulesError} />
            </Modal>
        )}
      </Wrapper>
  );
}

export default FareRule;
