import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Button, Modal, Drawer } from 'antd';
import {AcceptButtonWrapper, Content, InlineTextWrapper, LinkText, Wrapper} from './styles';
import { postAPI } from '../../utils/api';
import {GET_ROAD_FARE_RULES} from '../../utils/constants';
import {
  StyledParagraph,
  StyledTitle,
} from '../CustomAntdComponents/Typography';
import { ReactComponent as InfoIcon} from "../../assets/images/info.svg";
import FareContent from "./RoadFareContent";
import useIsMobile from "../../hooks/useIsMobile";
import {useRoadSearchStore} from "../../store/roadSearch/roadSearchStore";

const defaultFareRules: RoadFareRule[] = [
  {
    Code: 'Refund and Cancellation',
    Text: 'Bus tickets are non-refundable and reuseable in the event of lost tickets or missed journeys. It is solely your responsibility to ensure the safety and security of your ticket and to arrive at the terminus on time for your scheduled journey.'
  },
  {
    Code: 'Ticket Change',
    Text: 'You may contact the transport provider directly to make any changes to your ticket, cancel your ticket, or request alterations.'
  },
  {
    Code: 'Refund Payment',
    Text: 'Refunds will be processed within fourteen working days after contacting the company if it is determined that the seat you booked was already allocated to another passenger at the time of booking. Please note that no interest or penalties will be applied beyond the refunded fare amount.'
  }
];

type Props = {
  inline?: boolean;
}

function RoadFareRule({ inline }: Props) {
  const {
    selectedResult,
    tripsSessionId,
      selectedBus,
      updateFormData
  } = useRoadSearchStore();

  const { isMobile } = useIsMobile();
  const modalContainerRef = useRef(null);

  const [visible, setVisible] = useState(false);
  const [loadingFareData, setLoadingFareData] = useState<boolean>(true);
  const [fareRulesError, setFareRulesError] = useState<string| null>(null);
  const [fareRules, setFareRules] = useState<RoadFareRule[] | null>(null);

  const getBusRules = useCallback(async () => {
    setLoadingFareData(true);
    setFareRulesError(null);

    if (selectedResult) {
      const operator = selectedResult[0];
      const activeBus = selectedBus[0];
      const rules = await postAPI(GET_ROAD_FARE_RULES, {
        CombinationId: operator.CombinationId,
        SessionId: tripsSessionId,
        RecommendationId: activeBus.RecommendationId,
      });

      setLoadingFareData(false);

      if (rules.status === 200) {
        setFareRules(rules.data);
      } else {
        setFareRules(defaultFareRules);
      }
    }
  }, [selectedBus, selectedResult]);

  const handleAccept = () => {
    updateFormData({
      fareRuleAccepted: true,
    });

    setVisible(false);
  }

  const AcceptButton = (<AcceptButtonWrapper>
    <Button block type="primary" onClick={handleAccept}> I Accept </Button>
  </AcceptButtonWrapper>);

  useEffect(() => {
    getBusRules();
  }, []);

  return (
      <Wrapper ref={modalContainerRef} inline={inline}>
        {
          inline ? (<InlineTextWrapper onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setVisible(true)
          }}> fare rules
          </InlineTextWrapper>) : (
              <Content onClick={() => setVisible(true)}>
                <StyledParagraph color="primary">
                  Read fare rules
                </StyledParagraph>
                <LinkText>
                  <InfoIcon />
                </LinkText>
              </Content>)
        }

        {/* Mobile display */}
        { isMobile ? (
            <Drawer
                placement="bottom"
                open={visible}
                onClose={() => setVisible(false)}
                footer={AcceptButton}
                height="100%"
                title="Fare Rules"
                getContainer={modalContainerRef.current}
            >
              <FareContent loadingFareData={loadingFareData} fareRules={fareRules} fareRulesError={fareRulesError} />
            </Drawer>
        ) : (
            <Modal
                title={<StyledTitle level={4}>Fare Rules</StyledTitle>}
                open={visible}
                onCancel={() => setVisible(false)}
                getContainer={modalContainerRef.current}
                footer={AcceptButton}
                width={750}
            >
              <FareContent loadingFareData={loadingFareData} fareRules={fareRules} fareRulesError={fareRulesError} />
            </Modal>
        )}
      </Wrapper>
  );
}

export default RoadFareRule;
