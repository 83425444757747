import React from 'react';
import {
    Form, Row, Col,
} from 'antd';
import {FormFieldsWrapper} from "./styles";
import translations from "../../../screens/Search/translations";
import StyledDatePicker from "../../CustomAntdComponents/StyledDatePicker/DatePicker";
import {getDisabledDateRange, getStartOfDay} from "../../../utils/helpers";
import {useI18nContext} from "../../../utils/I18n";
import {RangePickerProps} from "antd/es/date-picker";
import {RoadSearchStore} from "../../../store/roadSearch/types";
import {tripModes} from "../../../screens/Search/constant";
import StyledRadioGroup from "../../CustomAntdComponents/StyledRadioGroup";
import styled from "styled-components";
import {DepartureWrapper, SwitcherWrapper} from "../../FlightSearch/styles";
import {ReactComponent as DirectionIcon} from "../../../assets/images/direction.svg";
import ModifyRouteSelector from "../Flight/MobileRouteModifySelector";

type SearchProps = {
    onSubmit: () => void;
    payload: RoadSearchStore;
    onChange: (arg) => void;
    form: any;
}

const SearchMobile = ({ onSubmit, onChange, payload, form  }: SearchProps) => {
    const {
        state: { t },
    } = useI18nContext();

    const {
        TripType, DepartureId, ArrivalId, DepartureDate, ArrivalDate,
    } = payload
    const tripTypeWatch = Form.useWatch('TripType', form);
    const departureDateWatch = Form.useWatch('DepartureDate', form);
    const departureWatch = Form.useWatch('DepartureId', form);
    const arrivalWatch = Form.useWatch('ArrivalId', form);
    const isRoundTrip = tripTypeWatch === 'round_trip';
    const formInitialValues = {
        TripType, DepartureId, ArrivalId, DepartureDate, ArrivalDate,
        DateRange: [DepartureDate, ArrivalDate]
    };

    const validateDestination = (_: any, value: any) => {
        if (!value) {
            return Promise.reject(new Error(t(translations).validations.destination));
        }

        if (form.getFieldValue('DepartureId') === value) {
            return Promise.reject(new Error(t(translations).validations.invalidDestination));
        }

        return Promise.resolve();
    };

    const checkReturningDate = (_: any, value: any) => {
        if(tripTypeWatch === 'one_way') return Promise.resolve();

        const departureDate = form.getFieldValue('DepartureDate');

        if (!value) {
            return Promise.reject(new Error(t(translations).validations.destinationDate));
        }

        if (value.isBefore(departureDate)) {
            return Promise.reject(new Error(t(translations).validations.invalidDestinationDate));
        }
        return Promise.resolve();
    };

    const onFormInputChange = (value: any, name: string) => {
        onChange({
            [name]: value,
        });
    };

    const disabledReturnDate: RangePickerProps['disabledDate'] = (current) => getDisabledDateRange(current, getStartOfDay(departureDateWatch));
    const onFinish = () => {
        onSubmit();
    };

    const handleSwitchTerminal = () => {
        onChange({
            DepartureId: arrivalWatch,
            ArrivalId: departureWatch,
        });

        form.setFieldsValue({
            DepartureId: arrivalWatch,
            ArrivalId: departureWatch,
        });
    }

    const flightModes = tripModes(t(translations));

    return (
        <Form
            wrapperCol={{ span: 24 }}
            layout="horizontal"
            initialValues={formInitialValues}
            form={form}
            size="large"
            onFinish={onFinish}
        >
            <FormFieldsWrapper>
                <Row gutter={[8, 8]}>
                        <Col xs={24}>
                            <Form.Item
                                name="TripType"
                                rules={[{ required: true, message: t(translations).validations.departure }]}
                            >
                                <StyledRadioGroup options={flightModes} value={TripType} onChange={(e) => onFormInputChange(e.target.value, 'TripType')} />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <HeaderDepartureWrapper>
                                <Form.Item
                                    name="DepartureId"
                                    rules={[{ required: true, message: t(translations).validations.departure }]}
                                >
                                    <ModifyRouteSelector
                                        label={t(translations).inputs.from}
                                        value={DepartureId}
                                        onChange={(value) => onFormInputChange(value, 'DepartureId')}
                                        icon="circle"
                                        tripType="road"
                                    />
                                </Form.Item>
                                <HeaderSwitcherWrapper onClick={handleSwitchTerminal}>
                                    <DirectionIcon />
                                </HeaderSwitcherWrapper>
                            </HeaderDepartureWrapper>
                        </Col>

                        <Col xs={24}>
                            <Form.Item
                                name="ArrivalId"
                                rules={[
                                    {
                                        validator: validateDestination,
                                    },
                                ]}
                            >
                                <ModifyRouteSelector
                                    label={t(translations).inputs.to}
                                    value={DepartureId}
                                    onChange={(value) => onFormInputChange(value, 'ArrivalId')}
                                    icon="location"
                                    tripType="road"
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24}>
                            <Form.Item
                                name="DepartureDate"
                                rules={[{ required: true, message: t(translations).validations.departureDate }]}
                            >
                                <StyledDatePicker
                                    label={t(translations).inputs.departureDate}
                                    value={DepartureDate}
                                    disabledDate={getDisabledDateRange}
                                    onChange={(value) => onFormInputChange(value, 'DepartureDate')}
                                />
                            </Form.Item>
                        </Col>

                    {
                        isRoundTrip && (
                            <Col xs={24}>
                                <Form.Item
                                    name="ArrivalDate"
                                    rules={[
                                        {
                                            validator: checkReturningDate,
                                        },
                                    ]}
                                >
                                    <StyledDatePicker
                                        label={t(translations).inputs.arrivalDate}
                                        value={ArrivalDate}
                                        disabledDate={disabledReturnDate}
                                        onChange={(value) => onFormInputChange(value, 'ArrivalDate')}
                                    />
                                </Form.Item>
                            </Col>
                        )
                    }
                </Row>
            </FormFieldsWrapper>
        </Form>
    );
};

const HeaderSwitcherWrapper = styled(SwitcherWrapper)`
    bottom: -25px;
`;

const HeaderDepartureWrapper = styled(DepartureWrapper)`
`;

export default SearchMobile;
