import React, {
  useReducer, useContext, createContext, Context,
} from 'react';
import { getBrowserLanguage } from './helpers';
import { SUPPORTED_LANGUAGES } from './constants';

export type I18nContextStateType = {
  langCode: string;
  t: (translation: any) => any;
};

export type I18nAction = {
  type: string;
  payload: any;
};

export type I18nContextType = {
  state: I18nContextStateType;
  dispatch: (arg0: { type: string; payload: any }) => void;
};

export const defaultLang = getBrowserLanguage();

export const getTranslate = (langCode: string) => (translation: any) => {
  const foundCode = SUPPORTED_LANGUAGES.find((lang) => lang === langCode);
  return translation[foundCode || defaultLang];
};

const initialState: I18nContextStateType = {
  langCode: defaultLang,
  t: getTranslate(defaultLang),
};
export const I18nContext: Context<any> = createContext(initialState);

export const useI18nContext = (): I18nContextType => useContext(I18nContext);

export function I18nContextProvider({ children }: any) {
  const reducer = (
    state: I18nContextStateType,
    action: I18nAction,
  ): I18nContextStateType => {
    const { type, payload } = action;

    if (type === 'SET_LANGUAGE') {
      window.localStorage.setItem('langCode', payload.langCode);
      return {
        langCode: payload.langCode,
        t: getTranslate(payload.langCode),
      };
    }
    return { ...state };
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValues = {
    state,
    dispatch,
  };

  return (
    <I18nContext.Provider
      value={contextValues}
    >
      {children}
    </I18nContext.Provider>
  );
}
export default I18nContext;
