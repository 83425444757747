import React from 'react';
import {
    Typography, Row, Col,
} from 'antd';
import {
    Content, UserDetails, Wrapper,
} from './styles';
import sunlightIcon from '../../assets/images/sunlight.svg';
import { getUser } from '../../utils/helpers';
import {StyledParagraph} from "../CustomAntdComponents/Typography";

const { Title } = Typography;

function MobileHeader() {
    const user = getUser();
    return (
        <Wrapper>
            <div className="container">
                <Content>
                    <Row>
                        <Col xs={24} lg={0}>
                            <UserDetails>
                                <Title level={4}>{`Hello ${user?.CustomerName || ''},`}</Title>
                                <img src={sunlightIcon} alt="day-icon" />
                            </UserDetails>
                            <StyledParagraph>
                                Let's start your trip
                            </StyledParagraph>
                        </Col>
                    </Row>
                </Content>
            </div>
        </Wrapper>
    );
}

export default MobileHeader;
