import styled from 'styled-components';
import { GREY_10 } from '../../utils/colors';

export const TitleWrapper = styled.div`
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: solid 1px #eeeeee;
`;

export const DetailsWrapper = styled.div`
  padding: 5px 0 10px;
  margin-bottom: 15px;
  border-bottom: solid 1px #eeeeee;
  border-top: solid 1px #eeeeee;
`;

export const BillingDetailsWrapper = styled.div`
  padding: 0 0 10px;
  margin-bottom: 15px;
  border-bottom: solid 1px #eeeeee;
`;

export const HighlightDetailItem = styled.div`
  border-radius: 5px;
  background: ${GREY_10};
  padding: 7px 10px;
  margin-bottom: 7px;
`;
