import React from 'react';
import {
    Empty, Card,
} from 'antd';
import { useParams } from 'react-router-dom';
import FlightPassengers from './FlightPassengers';
import RoadPassengers from './RoadPassengers';
import WaterPassengers from './WaterPassengers';

function PassengerDetails() {
    let { tripType } = useParams();

    tripType = tripType || 'flight';

    const renderPassengersForm = () => {
        switch (tripType) {
            case 'road':
                return <RoadPassengers />;
            case 'flight':
                return <FlightPassengers />;
            case 'water':
                return <WaterPassengers />;
            default:
                return <Card><Empty description="This feature is not available yet! " /></Card>;
        }
    };

    return (
        <>
            {renderPassengersForm()}
        </>

    );
}

export default PassengerDetails;
