import styled, { css, keyframes } from "styled-components";
import { PRIMARY, WHITE } from "../../utils/colors";
import device from '../../utils/mediaQuery';
import {StyledParagraph} from "../CustomAntdComponents/Typography";

const fadeInLoaderKeyframe = keyframes`
  from {
    opacity: 0;
    top: 3px
  }

  to {
    opacity: 1;
    top: 0;
  }
`;

const fadingKeyframe = keyframes`
  from {
    scale: 1
  }

  to {
    scale: 1.2;
  }
`;

export const Container = styled.div``;

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 95vh;
`;

export const Content = styled.div`
    text-align: center;
`;

export const TextWrapper = styled.div`
  margin-bottom: 15px;
`;

export const InfoWrapper = styled.div`
  padding-top: 45px;
  background: linear-gradient(180deg, ${PRIMARY} 70%, rgba(255, 255, 255, 0.8) 100%);
  border-radius: 40px;
  margin-bottom: 30px;
  width: 300px;
  height: 300px;
  position: relative;
  animation: ${fadingKeyframe} 1s alternate infinite;

    .ant-typography {
    color: ${WHITE};
  }
`;

export const ImageWrapper = styled.div`
    text-align: center;
    margin-bottom: 20px;
   position: relative;
   &::before {
    content: '';
    position: absolute;
     top: 3px;
     left: 0;
     right: 0;
     bottom: 0;
     animation: ${fadeInLoaderKeyframe} 2s alternate-reverse infinite;
     background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 6%, rgba(255, 255, 255, 0.9) 100%);
   }

  @media ${device.large} {
    &::before {
      background: linear-gradient(180deg, rgba(246, 246, 245, 0.1) 6%, rgba(246, 246, 245, 0.9) 100%);
    }
  }
`;

export const InfoTextWrapper = styled.div`
    width: 185px;
    margin: 0 auto;
`;

export const AirportInfo = styled.div`
   display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 23px;
    margin-bottom: 5px;
  
  svg {
    width: 30px;
    height: 30px;
    
    path {
      fill: ${WHITE} !important
    }
  }
`;

export const BusInfo = styled(AirportInfo)`
  padding: 0 5px;
`;

export const AirportItem = styled.div`
  max-width: 110px;
  
  .ant-typography {
    text-transform: capitalize;
  }
  
  .ant-typography {
    color: ${WHITE};
  }
    
    ${StyledParagraph} {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

export const TerminalLabel = styled(StyledParagraph)`
  @supports (-webkit-line-clamp: 1) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

export const BusItem = styled(AirportItem)`
    h1.ant-typography {
        line-height: 35px;
        font-size: 28px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

export const BoxesWrapper = styled.div`
  background: rgba(255, 255, 255, 0.1);
  padding: 4px 2px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 195px;
  border-radius: 0 0 40px 40px;
`;

export const BoxItem = styled.div<{ index: number }>`
  display: inline-block;
  background: ${WHITE};
  width: 25px;
  height: 25px;
  border-radius: 4px;
  margin: 2px;
  opacity: 0.3;
  
    ${({ index }) => index === 5 && css`
        opacity: 0.8;
    `}
`;

