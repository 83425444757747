import React, {useMemo, useRef, useState, useEffect } from 'react';
import {
    ErrorIconWrapper,
    PassengerDataItem,
    PassengerDataItemDetails,
    PassengerDataItemIcon,
    PassengerName,
    SuccessIconWrapper,
    Wrapper
} from "./styles";
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import {ReactComponent as ArrowRightIcon} from "../../assets/images/caret-right.svg";
import {ReactComponent as CheckIcon} from "../../assets/images/check-circle.svg";
import {ReactComponent as InfoIcon} from "../../assets/images/info.svg";
import {Button, Drawer, FormListFieldData} from "antd";
import useIsMobile from "../../hooks/useIsMobile";
import FlightPassengerForm from "../PassengerForm/FlightPassengerForm";
import RoadPassengerForm from "../PassengerForm/RoadPassengerForm";
import {ADULT, passengerCategoryLabel} from "./helpers";
import {useParams} from "react-router-dom";

type Props = {
    field: FormListFieldData,
    passengerType?: string,
    fieldName: string,
    data?: any
    form?: any,
    errorFields: any,
    onSetErrorFields: (arg: any) => void
    setPaxData?: (arg: any) => void,
    usedSavePaxData?: boolean
}
const PassengerFormItem = ({ field, data, passengerType, form, fieldName, errorFields, onSetErrorFields, setPaxData, usedSavePaxData }: Props) => {
    const drawerContainerRef = useRef(null);
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const { isMobile } = useIsMobile();
    const { tripType } = useParams();

    const index = field.key + 1;

    const renderForm = () => {
        switch (tripType) {
            case 'flight':
                return <FlightPassengerForm field={field} passengerType={passengerType}/>;
            case 'road':
                return <RoadPassengerForm field={field} passengerType={ADULT}/>;
            default:
                return null;
        }
    };

    const validateField = () => {
        form.validateFields([fieldName, field.key], { recursive: true })
            .then(() => {
                setIsOpened(false);
                setHasError(false);
                setIsValid(true);
                const filteredErrorFields = errorFields.filter((errorField) => {
                    return errorField.name.length !== 3 || (errorField.name[0] !== fieldName) || (errorField.name[1] !== field.key);
                });

                onSetErrorFields(filteredErrorFields);
            })
            .catch(({ errorFields: fieldErrors }) => {
                let consolidatedErrors = errorFields.filter((errorField) => {
                    return errorField.name.length !== 3 || (errorField.name[0] !== fieldName);
                });
                consolidatedErrors = [...consolidatedErrors, ...fieldErrors];
                onSetErrorFields(consolidatedErrors);

                const formItemErrorFields = [];
                fieldErrors.forEach((errorField) => {
                    if(errorField.name.length === 3 && errorField.name[0] === fieldName && errorField.name[1] === field.key) {
                        formItemErrorFields.push(true);
                    }
                });

                if(formItemErrorFields.length === 0) {
                    setIsOpened(false);
                    setHasError(false);
                    setIsValid(true);
                } else {
                    setHasError(true);
                    setIsValid(false);
                }
            });
    }

    useEffect(() => {
        if(!hasError) {
            const formItemErrorFields = [];
            errorFields.forEach((errorField) => {
                if(errorField.name.length === 3 && errorField.name[0] === fieldName && errorField.name[1] === field.key) {
                    formItemErrorFields.push(true);
                }
            });

            setHasError(formItemErrorFields.length > 0)
        }
    }, [errorFields, field.key]);

    useEffect(() => {
        const fieldValues = form.getFieldValue(fieldName)[field.key];
        const data = Object.values(fieldValues).join("")
        const isEmpty = Number(data.trim()) === field.key;

        if(!isEmpty && usedSavePaxData) {
            validateField();
        }
    }, [form]);

    const handleCloseDrawer = () => {
        const fieldValues = form.getFieldValue(fieldName);
        if(fieldValues[0].applyToOtherPassengers && setPaxData) {
            const data = fieldValues[0];
            setPaxData(data);
        } else if(setPaxData && field.key === 0 && usedSavePaxData) {
            setPaxData(null);
        }

        validateField();
    }

    const Footer = (
        <Button block size="large" type="primary" onClick={handleCloseDrawer}>
            Save & Continue
        </Button>
    )

    const fullNameIsEntered = useMemo(() => {
        return Boolean(data && data.firstName.trim() && data.lastName.trim())
    }, [data]);

    const firstName = useMemo(() => {
        return (data && data.firstName.trim())
    }, [data]);

    const lastName = useMemo(() => {
        return (data && data.lastName.trim())
    }, [data]);

    const passengerName = useMemo(() => {
        if(!fullNameIsEntered) {
            return passengerType ? `${passengerCategoryLabel(passengerType)} ${index}` : `Passenger ${index}`
        }

        return `${firstName || ""} ${lastName || ""}`
    }, [fullNameIsEntered, firstName, lastName, passengerType]);

    const drawerTitle = useMemo(() => {
        if(!fullNameIsEntered) {
            return "Passenger Information"
        }

        return `${data.firstName || ""} ${data.lastName || ""}`
    }, [fullNameIsEntered, data, passengerType])

    const iconLabel = useMemo(() => {
        if(!fullNameIsEntered) {
            return passengerType ? passengerType[0] : "A";
        }

        return `${firstName[0]}${lastName[0]}`;
    }, [passengerType, fullNameIsEntered, firstName, lastName]);

    const renderItemIcon = () => {
       if(hasError) {
            return <ErrorIconWrapper><InfoIcon width={20} height={20} /></ErrorIconWrapper>
       }

        if(isValid) {
            return <SuccessIconWrapper><CheckIcon /></SuccessIconWrapper>
        }

        return <ArrowRightIcon />
    }

    return (
        <Wrapper ref={drawerContainerRef}>
            <PassengerDataItem onClick={() => setIsOpened(true)}>
                <PassengerDataItemDetails>
                    <PassengerDataItemIcon>
                        <StyledTitle level={2}>
                            {iconLabel}
                        </StyledTitle>
                    </PassengerDataItemIcon>
                    <div>
                        <PassengerName>
                            <StyledTitle level={4}>
                                {passengerName}
                            </StyledTitle>
                        </PassengerName>
                        {!fullNameIsEntered && <StyledParagraph>
                            Add passenger information
                        </StyledParagraph>}
                    </div>
                </PassengerDataItemDetails>
                {renderItemIcon()}
            </PassengerDataItem>
            <Drawer
                title={drawerTitle}
                placement={ isMobile ? "bottom" : "right"}
                onClose={() => setIsOpened(false)}
                open={isOpened}
                footer={Footer}
                forceRender={true}
                height="100%"
                getContainer={drawerContainerRef.current as HTMLElement}
            >
                {renderForm()}
            </Drawer>
        </Wrapper>
    );
};

export default PassengerFormItem;
