import React from 'react';
import {
  Route, BrowserRouter, Routes,
} from 'react-router-dom';
import routes from './routes';
import Demo from '../components/FlightSearch/Demo';

function AppRoute() {
  return (
    <BrowserRouter>
      <Routes>
        {
          routes.map((route) => <Route key={route.id} path={route.path} element={route.element} />)
        }
        <Route key={14} path="/demo" element={<Demo />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoute;
