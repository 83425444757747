import { create } from 'zustand';
export const useBookingStore = create<BookingStoreState>((set) => ({
  // initial state
  bookingPayload: null,
  flightReservation: null,
  roadReservation: null,
  setReservation: (data: any, key: string) => {
    set(() => ({
      [key]: data,
    }));
  },
  resetBooking: () => {
    set(() => ({
      bookingPayload: null,
      flightReservation: null,
      roadReservation: null,
    }));
  }
}));
