import React from 'react';
import { Wrapper } from './styles';
import { useI18nContext } from '../../utils/I18n';
import {useRoadSearchStore} from "../../store/roadSearch/roadSearchStore";
import BusDetails from "./BusDetails";

type Props = {
  results?: RoadSearchResult,
  selectedBuses: AvailableBus[]
  onBookingRedirect: () => void
  onBusSelect: (arg: AvailableBus[]) => void
  cheapestFares: {
    cheapestDeparture: number | null,
    cheapestReturn: number | null
  }
}

function AvailableBuses({ results, onBookingRedirect, onBusSelect, selectedBuses, cheapestFares
}: Props) {
  const departureBuses = results?.FirstLeg?.ResultList || [];
  const returnBuses = results?.SecondLeg?.ResultList || [];
  const cheapestDeparture = cheapestFares?.cheapestDeparture;
  const cheapestReturn = cheapestFares?.cheapestReturn;
  const { formData: { TripType }, selectedResult, selectedBusSeat, updateStoreData } = useRoadSearchStore();
  const isReturning = selectedBuses.length > 0;
  const isOneWay = TripType === 'one_way';
  const tripsSessionId = results?.TripsSessionId;


  const handleSelectBus = (resultItem: BusResultItem, bus: AvailableBus, seats: AvailableSeat[]) => {
    if(isOneWay) {
      onBookingRedirect();
      updateStoreData({
        tripsSessionId,
        selectedResult: [resultItem],
        selectedBusSeat: [seats],
        selectedBus: [bus]
      });
      return;
    }

    if (!isReturning) {
      onBusSelect([bus]);
      updateStoreData({
        tripsSessionId,
        selectedResult: [resultItem],
        selectedBusSeat: [seats],
        selectedBus: [bus]
      })
    } else {
      let resultItems = [...selectedResult];
        resultItems[1] = resultItem;
      let selections = [...selectedBuses];
      selections[1] = bus;
      let busSeats = [...selectedBusSeat];
      busSeats[1] = seats;

      const storeData = {
        selectedResult: resultItems,
        selectedBus: selections,
        selectedBusSeat: busSeats,
      };
      onBusSelect(selections);
      updateStoreData(storeData);
      onBookingRedirect();
    }
  };

  return (
    <Wrapper>
      {
        !isReturning && departureBuses.map((bus: BusResultItem, index) => (
            <BusDetails
              key={index}
              cheapestFare={cheapestDeparture}
              result={bus}
              handleSelectBus={handleSelectBus}
              onlyDepartureSelected={!isOneWay}
              isReturning={false}
            />
          ))
      }

      {
        isReturning && returnBuses.map((bus: BusResultItem, index) => (
            <BusDetails
              key={index}
              cheapestFare={cheapestReturn}
              result={bus}
              handleSelectBus={handleSelectBus}
              onlyDepartureSelected={false}
              isReturning={true}
            />
          ))
      }
    </Wrapper>
  );
}

AvailableBuses.defaultProps = {
  loading: false,
  error: undefined,
  results: {
    TotalCount: 0,
    ResultList: [],
  },
};
export default AvailableBuses;
