import React from 'react';
import Sort from "./Sort";
import PriceRange from "../FlightFilters/PriceRange";
import OperatorsCheckbox from "./OperatorsCheckbox";

type Props = {
    category: string,
    operators: SelectOption[],
    onFilterChange: (arg1, arg2) => void,
    farePriceRange: PriceRangeType,
    selectedOperators: string[],
    sortBy: string
}

const RoadFilters = ({ operators, category, sortBy, farePriceRange, onFilterChange, selectedOperators }: Props) => {
    const renderFilter = () => {
        switch (category) {
            case 'Sort':
                return (
                    <Sort onChange={(v) => onFilterChange('sort', v)} sortBy={sortBy} />
                )
            case 'Operators':
                return (
                    <OperatorsCheckbox operators={operators} selectedOperators={selectedOperators} onChange={(v) => onFilterChange('operators', v)} />
                )
            case 'Price':
                return (
                    <PriceRange onChange={(v) => onFilterChange('price', v)} farePriceRange={farePriceRange} />
                )
            default:
                return null
        }
    }
    return (
        <div>
            {renderFilter()}
        </div>
    );
};

export default RoadFilters;
