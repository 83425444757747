import React, {useMemo} from 'react';
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import {useFlightSearchStore} from "../../store/flightSearch/flightSearchStore";
import { Space } from 'antd';
import {useParams} from "react-router-dom";
import {useRoadSearchStore} from "../../store/roadSearch/roadSearchStore";

const BookingContact = () => {
    const { tripType } = useParams();

    const {
        bookingContact: flightBookingContact
    } = useFlightSearchStore();

    const {
        bookingContact: roadBookingContact,
    } = useRoadSearchStore();

    const bookingContact = useMemo(() => {
        if(tripType === 'flight'){
            return flightBookingContact;
        }

        if(tripType === 'road'){
            return roadBookingContact;
        }

        return null;
    }, [tripType]);


    if(!bookingContact){
        return <></>
    }

    return (
        <div>
            <Space direction="vertical" size="small" style={{ display: 'flex' }}>
                <StyledParagraph size="normal">
                    This is where we will send your booking details to. Please ensure they are correct.
                </StyledParagraph>

                <div>
                    <StyledTitle level={5}>
                        Email: {bookingContact.email}
                    </StyledTitle>

                    <StyledTitle level={5}>
                        Mobile: {bookingContact.phone}
                    </StyledTitle>
                </div>
            </Space>
        </div>

    );
};

export default BookingContact;
