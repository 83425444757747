import React, {useState, useMemo, useEffect} from 'react';
import {DrawerContainer, DrawerFilterContent, DrawerTitle, FilterItem, FilterWrapper} from "./styles";
import { ReactComponent as SortIcon } from "../../assets/images/sort.svg";
import StyledDrawer from "../CustomAntdComponents/StyledDrawer";
import {Button} from "antd";
import RoadFilters from "./RoadFilters";

const categories = ['Sort', 'Operators', 'Price'];

type Props = {
    handleOperatorFiltering?: (arg: string[]) => void,
    handleSort: (arg0: string | null) => void,
    farePriceRange: PriceRangeType,
    handleFilterByPriceRange: (arg0: [number, number]) => void,
    selectedOperators: string[],
    sortBy: string,
    operators: SelectOption[]
}


const RoadListingFilterMobile = ({ handleOperatorFiltering, selectedOperators, operators, handleSort,
                                     farePriceRange, handleFilterByPriceRange, sortBy
}: Props) => {
    const [active, setActive] = useState(categories[0])
    const [isOpen, setIsOpen] = useState(false)
    const [payload, setPayload] = useState({
        operators: selectedOperators,
        price: [farePriceRange.min, farePriceRange.max],
        sort: sortBy
    });

    useEffect(() => {
        setPayload({
            operators: selectedOperators,
            price: [farePriceRange.min, farePriceRange.max],
            sort: sortBy
        })
    }, [selectedOperators, farePriceRange, sortBy]);

    const needsConfirmation = useMemo(() => ['Operators', 'Price'].includes(active), [active])

    const handleFilterCallback = (currentSortValue = null) => {
        setIsOpen(false);

        switch (active) {
            case 'Operators':
                handleOperatorFiltering((currentSortValue === null) ? payload.operators : currentSortValue);
                break;
            case 'Price':
                handleFilterByPriceRange((currentSortValue === null) ?  payload.price : currentSortValue);
                break;
            case 'Sort':
                handleSort((currentSortValue === null) ? payload.sort : currentSortValue);
                break;
            default:
                break;
        }
    }

    const handleOpenDrawer = (category: string) => {
        setIsOpen(true)
        setActive(category)
    }

    const handleFilterChange = (name, value) => {
        setPayload({
            ...payload,
            [name]: value
        })

        if(!needsConfirmation) {
            setIsOpen(false);
            handleFilterCallback(value);
        }
    }

    const DrawerFooter = needsConfirmation && (
        <Button type="primary" block onClick={() => handleFilterCallback()}>
            Confirm
        </Button>
    )

    return (
        <>
            <FilterWrapper>
                {
                    categories.map((category) => (
                        <FilterItem key={category} onClick={() => handleOpenDrawer(category)} active={active === category}>
                            <SortIcon />
                            <span>
                            {category}
                        </span>
                        </FilterItem>
                    ))
                }
            </FilterWrapper>
            <StyledDrawer
                open={isOpen}
                onClose={() => setIsOpen(false)}
                footer={DrawerFooter}
            >
                <DrawerContainer>
                    <DrawerTitle align="center">
                        {active}
                    </DrawerTitle>
                    <DrawerFilterContent>
                        <RoadFilters
                            category={active}
                            operators={operators}
                            onFilterChange={handleFilterChange}
                            farePriceRange={farePriceRange}
                            selectedOperators={payload.operators}
                            sortBy={payload.sort}
                        />
                    </DrawerFilterContent>
                </DrawerContainer>
            </StyledDrawer>
        </>
    );
};

export default RoadListingFilterMobile;
