import React from 'react';
import ReactDOM from 'react-dom/client';

import AppRoute from './routing';
import './index.less';
import reportWebVitals from './reportWebVitals';
import { getThemeName } from './utils/helpers';
import { I18nContextProvider } from './utils/I18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const themeName = getThemeName();
const auth = JSON.parse(localStorage.getItem('trips_auth')) || {};
document.documentElement.style.setProperty('--primary-color', `${auth?.ExtraData?.PrimaryColor || '002E63'}`);

root.render(
  <I18nContextProvider>
    <div className="theme-default" id="app-container">
      <AppRoute />
    </div>
  </I18nContextProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
