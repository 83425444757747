import React, {useMemo, useRef, useState} from 'react';
import { Modal } from 'antd';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/arrow-left.svg';
import { ReactComponent as SearchIcon } from '../../../assets/images/search.svg';
import { ReactComponent as RightArrowIcon } from "../../../assets/images/arrow-right-grey.svg";
import { ReactComponent as DirectionIcon } from "../../../assets/images/direction-hr.svg";

import {
  DetailsWrapper,
  BackIconWrapper,
  Wrapper,
  SearchWrapper,
  SearchContainer,
  SearchItemWrapper,
  Container,
} from './styles';
import {StyledParagraph, StyledText} from "../../CustomAntdComponents/Typography";
import SearchDesktop from "./SearchDesktop";
import {useStore} from "../../../store/global/appStore";
import {useRoadSearchStore} from "../../../store/roadSearch/roadSearchStore";
import {RoadSearchStore} from "../../../store/roadSearch/types";
import {getTerminalName} from "../../TripSummary/helper";

type Props = {
  navigateHandler: () => void,
  refetchSearch: (arg: RoadSearchStore) => void,
  modify: boolean,
  onModifyChange: (value: boolean) => void
}
function DesktopHeader({ navigateHandler, modify, onModifyChange, refetchSearch }: Props) {
  const modalContainerRef = useRef(null);
  const {
    formData, updateFormData
  } = useRoadSearchStore();
  const {
    DepartureDate,
    ArrivalDate,
    ArrivalId,
    DepartureId,
    TripType,
  } = formData
  const [payload, setPayload] = useState<RoadSearchStore>(formData);
  const { roadRoutes } = useStore();

  const isRoundTrip = TripType === 'round_trip';
  const departure = getTerminalName(roadRoutes, DepartureId);
  const arrival = getTerminalName(roadRoutes, ArrivalId);

  const handleSubmit = () => {
    updateFormData(payload);
    onModifyChange(false);
    refetchSearch(payload);
  }

  const handleChange = (object) => {
    setPayload({ ...payload, ...object });
  }


  return (
      <Container ref={modalContainerRef}>
        <Wrapper className="container">
          <BackIconWrapper onClick={navigateHandler}>
            <ArrowLeftIcon width={20} height={20} />
            <StyledText size="default">Back</StyledText>
          </BackIconWrapper>

          <SearchContainer>
            <SearchWrapper onClick={() => {
              onModifyChange(true);
            }}>
              <SearchItemWrapper>
                <DetailsWrapper>
                  <StyledParagraph size="default">
                    {departure}
                  </StyledParagraph>

                  {
                    (isRoundTrip) ? <DirectionIcon /> : <RightArrowIcon />
                  }

                  <StyledParagraph size="default">
                    {arrival}
                  </StyledParagraph>
                </DetailsWrapper>
              </SearchItemWrapper>

              <SearchItemWrapper>
                <StyledParagraph size="default">
                  {
                    (isRoundTrip) && ArrivalDate ? `${DepartureDate.format("ddd, MMM D")} - ${ArrivalDate.format("ddd, MMM D")}` : DepartureDate.format("ddd, MMM D")
                  }
                </StyledParagraph>
              </SearchItemWrapper>

              <SearchItemWrapper>
                <SearchIcon />
                <StyledParagraph size="default" color="primary">
                  Modify Search
                </StyledParagraph>
              </SearchItemWrapper>
            </SearchWrapper>
          </SearchContainer>
        </Wrapper>

        <Modal
            open={modify}
            footer={null}
            onCancel={() => onModifyChange(false)}
            width={980}
            getContainer={modalContainerRef.current as HTMLElement}
            forceRender
        >
          <SearchDesktop onSubmit={handleSubmit} onChange={handleChange} payload={payload} />
        </Modal>
      </Container>
  );
}

DesktopHeader.defaultProps = {
  name: undefined,
  navigateHandler: undefined,
};

export default DesktopHeader;
