import React, { useState } from 'react';
import { Form, Spin, Button } from 'antd';
import StyledInput from '../CustomAntdComponents/StyledInput';
import { useStore } from '../../store/global/appStore';
import {
  SEARCH_RESERVATION_URL,
  SEARCH_ROAD_RESERVATION_URL,
} from '../../utils/constants';
import { postAPI } from '../../utils/api';
import AlertMessage from '../AlertMessage';
import { FormWrapper } from './styles';
import { useBookingStore } from '../../store/booking/bookingStore';
import StyledSelect from '../CustomAntdComponents/StyledSelect';

type Props = {
  filled?: boolean;
  handleNavigate?: (arg: string) => void;
}

const tripOptions : SelectOption[] = [{ label: 'Flight', value: 'flight' }, { label: 'Road', value: 'road' }];

function SearchBooking({ filled, handleNavigate }: Props) {
  const { requesting, setRequesting } = useStore();
  const { setReservation } = useBookingStore();
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [form] = Form.useForm();

  const formInitialValues = {
    Surname: '',
    Trip: 'flight',
    BookingReferenceId: '',

  };

  const getSearchURL = (tripType: string) => {
    switch (tripType) {
      case 'flight':
        return SEARCH_RESERVATION_URL;
      case 'road':
        return SEARCH_ROAD_RESERVATION_URL;
      default:
        return SEARCH_RESERVATION_URL;
    }
  };

  const onFinish = async (values: any) => {
    setRequesting(true);
    const payload = {
      BookingReferenceId: values.BookingReferenceId,
      Surname: values.Surname,
      BookingReferenceType: 1,
    };

    const tripType = values.Trip;

    const searchBooking = await postAPI(getSearchURL(tripType), payload);
    setRequesting(false);

    if (searchBooking.status === 200) {
      setReservation(searchBooking.data, `${tripType}Reservation`);

      if (handleNavigate && tripType) {
        handleNavigate(tripType);
      }
    } else {
      setErrorMessage(searchBooking.data);
    }
  };
  return (
    <Spin spinning={requesting}>
      <FormWrapper>
        {errorMessage && (
        <AlertMessage
          message={errorMessage}
          fullWidth
        />
        )}
        <Form
          wrapperCol={{ span: 24 }}
          layout="horizontal"
          initialValues={formInitialValues}
          form={form}
          onFinish={onFinish}
          size="large"
        >
          <Form.Item
            name="Trip"
          >
            <StyledSelect options={tripOptions} placeholder="Trip type" filled={filled} />
          </Form.Item>

          <Form.Item
            name="Surname"
            rules={[{ required: true, message: 'Please enter last name' }]}
          >
            <StyledInput placeholder="Last name" filled={filled} />
          </Form.Item>

          <Form.Item
            name="BookingReferenceId"
            rules={[{ required: true, message: 'Please enter booking code' }]}
          >
            <StyledInput placeholder="PNR" filled={filled} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" size="large" block htmlType="submit">Search</Button>
          </Form.Item>
        </Form>
      </FormWrapper>
    </Spin>
  );
}

SearchBooking.defaultProps = {
  filled: false,
  handleNavigate: undefined,
};

export default SearchBooking;
