import React from 'react';
import Index from './Index/index';
import Home from './Home/index';
import Search from './Search';
import SearchListings from './SearchListings';
import SearchRoadListings from './SearchRoadListings';
import SearchWaterListings from './SearchWaterListings';
import BusSeatSelection from './BusSeatSelection';
import Itinerary from './Itinerary';
import PassengerDetails from './PassengerDetails';
import Payment from './Payment';
import PaymentReceipt from './PaymentReceipt';
import BookingSearch from './BookingSearch';
import SearchRailListings from './SearchRailListings';
import TrainSeatSelection from './TrainSeatSelection';

export function IndexScreen() {
  return <Index />;
}

export function HomeScreen() {
  return <Home />;
}

export function SearchScreen() {
  return <Search />;
}

export function SearchListingsScreen() {
  return <SearchListings />;
}
export function SearchRoadListingsScreen() {
  return <SearchRoadListings />;
}
export function SearchWaterListingsScreen() {
  return <SearchWaterListings />;
}
export function SearchRailListingsScreen() {
  return <SearchRailListings />;
}
export function SeatSelectionScreen() {
  return <BusSeatSelection />;
}

export function TrainSeatSelectionScreen() {
  return <TrainSeatSelection />;
}

export function ItineraryScreen() {
  return <Itinerary />;
}

export function PaymentScreen() {
  return <Payment />;
}

export function PassengerDetailsScreen() {
  return <PassengerDetails />;
}

export function PaymentReceiptScreen() {
  return <PaymentReceipt />;
}

export function BookingSearchScreen() {
  return <BookingSearch />;
}

export default {
  Home,
  Search,
  SearchListings,
  Itinerary,
  PassengerDetails,
  Payment,
  PaymentReceipt,
  BookingSearch,
};

