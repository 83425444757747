import React from 'react';
import {PassengerDataWrapper} from "./styles";
import WaterPassengers from "./WaterPassengers";
import TrainPassengers from "./TrainPassengers";
import {useParams} from "react-router-dom";
import FlightPassengerData from "./trips/FlightPassengersData";
import RoadPassengerData from "./trips/RoadPassengersData";

type Props = {
    form: any,
    errorFields: any,
    onSetErrorFields: (arg: any) => void
}
const PassengerData = ({ form, errorFields, onSetErrorFields }: Props) => {
    const { tripType } = useParams();

    const renderTripForm = () => {
        switch (tripType) {
            case 'flight':
                return <FlightPassengerData form={form} errorFields={errorFields} onSetErrorFields={onSetErrorFields} />;
            case 'road':
                return <RoadPassengerData form={form} errorFields={errorFields} onSetErrorFields={onSetErrorFields} />;
            case 'water':
                return <WaterPassengers />;
            case 'rail':
                return <TrainPassengers />;
            default:
                return <div>&nbsp;</div>;
        }
    };


    return (
        <PassengerDataWrapper>
            {renderTripForm()}
        </PassengerDataWrapper>
    );
};

export default PassengerData;
