import styled, { css } from 'styled-components';
import { BLACK_400} from '../../../utils/colors';
import device from '../../../utils/mediaQuery';

export const Wrapper = styled.section<{ height?: number, fullHeight?: boolean }>`
    svg {
        path {
            fill: #434455 !important;
        }
    }

  .ant-drawer-title{
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: ${BLACK_400};
  }
  
  @media (${device.large})  {
    .ant-drawer-title {
      text-align: left;
      font-size: 20px;
    }
  }
  
  .ant-drawer-header {
    border: none;
    padding: 16px 16px 0;
    margin-bottom: 10px;
  }
  .ant-drawer-header-title {
    flex-direction: row-reverse;
  }

  .ant-drawer-close {
    margin-right: 0;
  }

  .ant-drawer-footer {
    padding: 24px 16px;
  }

  .ant-drawer-content-wrapper {
    height: auto !important;
  }
  .ant-drawer-body {
    padding: 0 20px 10px;
  }

  ${({ height }) => height && css`
      .ant-drawer-content-wrapper {
        max-height: ${height ? `${height}px` : 'fit-content'}
      }
    `};
  
  ${({ fullHeight }) => fullHeight && css`
    .ant-drawer-content-wrapper {
            height: 100vh !important;
        }
    `};
`;
