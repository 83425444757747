import React from 'react';
import { Alert } from 'antd';
import { Wrapper } from './styles';

type Props = {
  type?: 'success' | 'info' | 'warning' | 'error';
  message: string;
  fullWidth?: boolean;
  closable?: boolean;
}
function AlertMessage({
  type, message, fullWidth, closable,
}: Props) {
  return (
    <Wrapper fullWidth={fullWidth}>
      <Alert
        message={message}
        type={type}
        showIcon
        closable={closable}
      />
    </Wrapper>
  );
}

AlertMessage.defaultProps = {
  type: 'error',
  fullWidth: false,
  closable: true,
};
export default AlertMessage;
