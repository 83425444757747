import styled, { css } from "styled-components";
import device from '../../utils/mediaQuery';
import {BLACK_100, GREY, GREY_7, PRIMARY, WHITE} from "../../utils/colors";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  
  @media(${device.large}) {
    row-gap: 50px;
  }
`;

export const ReactionsWrapper = styled.div`
  display: flex;
  column-gap: 40px;
  justify-content: center;
`;

export const ReactionItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5px;
  
  .ant-typography {
    text-transform: capitalize;
  }
`;

export const ContentWrapper = styled.div`
`;

export const OptionGroupWrapper = styled.div`
  padding: 20px 0 10px;
  border-top: 1px solid ${GREY_7};
`;

export const HeadingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 5px;

  @media(${device.large}) {
    margin-top: 50px;
  }
`;


export const OptionsWrapper = styled.div`
  margin: 15px 0;
`;

export const Option = styled.div<{ active?: boolean }>`
    text-align: center;
    border: 1px solid ${BLACK_100};
       padding: 6px 15px;
    border-radius: 5px;
  display: inline-block;
  margin: 0 10px 10px 0;
  cursor: pointer;
  
  .ant-typography {
    color: ${GREY}; 
    font-size: 16px;
  }
  
    ${({ active }) => active && css`
        background: ${PRIMARY};
      border-color: ${PRIMARY};
      
        .ant-typography {
            color: ${WHITE};
        }
    `}
`;

export const CommentInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 15px;
`;
