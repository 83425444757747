import React from 'react';
import {
    Row, Col, Form, Button,
} from 'antd';
import StyledInput from '../CustomAntdComponents/StyledInput';
import {StyledParagraph} from "../CustomAntdComponents/Typography";

function PromoCodeInput() {
    return (
        <Row gutter={[16, 8]}>
            <Col xs={24} lg={18}>
                <Form.Item
                    name="promoCode"
                >
                    <StyledInput placeholder="Enter promo code" />
                </Form.Item>
            </Col>
        </Row>
    );
}

export default PromoCodeInput;
