import React from 'react';
import {SpaceBetweenAlignContent} from "../CustomAntdComponents/Utitlities";
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import {passengerCategoryLabel} from "../PassengerFormItem/helpers";
import {formatNumberAsCurrency} from "../../utils/helpers";
import {BreakdownWrapper} from "./styles";
import {calculateFlightFareBreakdown, calculateFlightTotalFare, calculateTicketTotal} from "../AvailableFlights/helper";
import {useFlightSearchStore} from "../../store/flightSearch/flightSearchStore";
import {useBookingStore} from "../../store/booking/bookingStore";
import {TripLabel} from "../RoadItineraryBreakdown/styles";

type Props = {
    hideBreakdown: boolean
}
const FlightFareBreakdown = ({ hideBreakdown }: Props) => {
    const { selectedFlight, formData: {
        isDomestic,
    }} = useFlightSearchStore();

    const { bookingPayload} = useBookingStore();
    const { totalTicketAmount } = calculateFlightTotalFare(selectedFlight, isDomestic);
    const departureFares = calculateFlightFareBreakdown([selectedFlight[0]]);
    const returnFares = (isDomestic && selectedFlight.length > 1) ? calculateFlightFareBreakdown([selectedFlight[1]]) : null;
    const bookingAmount = bookingPayload ? calculateTicketTotal(bookingPayload.TicketingDetails) : totalTicketAmount

    return (
        <div>
            {
                !hideBreakdown && (
                    <BreakdownWrapper>
                        {
                            returnFares && (
                                <TripLabel size="normal">
                                    Departure
                                </TripLabel>
                            )
                        }
                        {
                            departureFares.map((f, key) => (
                                <SpaceBetweenAlignContent key={key}>
                                    <StyledParagraph style={{ fontSize: "14px" }}>
                                        {passengerCategoryLabel(f.PassengerType)} &nbsp;x&nbsp; {f.Quantity}
                                    </StyledParagraph>

                                    <StyledTitle level={5} style={{ color: "#434455" }}>
                                        {formatNumberAsCurrency((f.TotalFare * f.Quantity) || 0, selectedFlight[0]?.Currency)}
                                    </StyledTitle>
                                </SpaceBetweenAlignContent>
                            ))
                        }

                        {
                            returnFares && (
                                <div style={{ marginTop: "10px" }}>
                                    <TripLabel size="normal">
                                        Return
                                    </TripLabel>
                                    {
                                        returnFares.map((f, key) => (
                                            <SpaceBetweenAlignContent key={key}>
                                                <StyledParagraph style={{ fontSize: "14px" }}>
                                                    {passengerCategoryLabel(f.PassengerType)} &nbsp;x&nbsp; {f.Quantity}
                                                </StyledParagraph>

                                                <StyledTitle level={5} style={{ color: "#434455" }}>
                                                    {formatNumberAsCurrency((f.TotalFare * f.Quantity) || 0, selectedFlight[0]?.Currency)}
                                                </StyledTitle>
                                            </SpaceBetweenAlignContent>
                                        ))
                                    }
                                </div>
                            )
                        }
                    </BreakdownWrapper>
                )
            }

            <SpaceBetweenAlignContent>
                <StyledParagraph size="default">
                    Total for all passengers
                </StyledParagraph>

                <StyledTitle level={3}>
                    {formatNumberAsCurrency(bookingAmount || 0, selectedFlight[0]?.Currency)}
                </StyledTitle>
            </SpaceBetweenAlignContent>
        </div>
    );
};

export default FlightFareBreakdown;
