import React, { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthenticatedRoute from './AuthenticatedRoute';
import { useRailSearchStore } from '../store/railSearch/railSearchStore';

type Props = {
  children: ReactNode
}
function RailDataFilled({ children }: Props) {
  const navigate = useNavigate();
  const {
    formData,
  } = useRailSearchStore();

  useEffect(() => {
    if (!formData.DepartureId) {
      navigate('/search/rail');
    }
  }, [formData, navigate]);
  return (
    <AuthenticatedRoute>
      {children}
    </AuthenticatedRoute>
  );
}

export default RailDataFilled;
