import React from 'react';
import { Link } from 'react-router-dom';
import {
  Row, Col, Typography, Button,
} from 'antd';
import moment from 'moment';
import {
  DetailItem,
  DetailItemIcon,
  DetailItemsWrapper,
  StepItemContent,
  StepItemWrapper,
  StepProgressLine,
  StepsWrapper,
  Wrapper,
} from './styles';
import { ReactComponent as PassengerIcon } from '../../assets/images/passenger-color.svg';
import { ReactComponent as ArrowIcon } from '../../assets/images/arrow-directions.svg';
import { ReactComponent as CarIcon } from '../../assets/images/car.svg';
import { pluralizeText } from '../../utils/helpers';
import { useRoadSearchStore } from '../../store/roadSearch/roadSearchStore';
import { useStore } from '../../store/global/appStore';
import { getTerminalName } from './helper';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/SearchListings/translations';
import globalTranslations from '../../utils/globalTranslation';

const { Title, Paragraph } = Typography;
type Props = {
  showChangeButton?: boolean,
  returning?: boolean
  itinerary?: boolean
}

function RoadTripSummary({ showChangeButton, returning, itinerary }: Props) {
  const {
    formData,
  } = useRoadSearchStore();
  const {
    state: { t },
  } = useI18nContext();
  const { roadRoutes } = useStore();

  const isRoundTrip = formData.TripType === 'round_trip';

  const departure = getTerminalName(roadRoutes, formData.DepartureId);
  const arrival = getTerminalName(roadRoutes, formData.ArrivalId);

  const renderFlightTime = () => {
    if (itinerary) {
      return moment(returning ? formData.ArrivalDate : formData.DepartureDate).format('ll');
    }

    if (isRoundTrip) {
      return `${moment(formData.DepartureDate).format('ll')} - ${moment(formData.ArrivalDate).format('ll')}`;
    }

    return `${moment(formData.DepartureDate).format('ll')}`;
  };

  return (
    <Wrapper>
      <Row gutter={40} align="middle">
        <Col lg={showChangeButton ? 12 : 24} xs={24}>
          <StepsWrapper>
            <StepProgressLine />
            <StepItemWrapper>
              <StepItemContent>
                <CarIcon />
              </StepItemContent>
            </StepItemWrapper>

            <StepProgressLine />

          </StepsWrapper>
          <Row justify="space-between" align="middle">
            {
              returning ? (
                <>
                  <Col span={12}>
                    <Title level={4}>
                      {arrival}
                    </Title>
                    <Paragraph>
                      {/* TODO Missing data */}
                      Park
                    </Paragraph>
                  </Col>
                  <Col span={12}>
                    <Title level={4} style={{ textAlign: 'right' }}>
                      {departure}
                    </Title>
                    <Paragraph style={{ textAlign: 'right' }}>
                      {/* TODO Missing data */}
                      Park
                    </Paragraph>
                  </Col>
                </>
              ) : (
                <>
                  <Col span={12}>
                    <Title level={4}>
                      {departure}
                    </Title>
                    <Paragraph>
                      {/* TODO Missing data */}
                      Park
                    </Paragraph>
                  </Col>
                  <Col span={12}>
                    <Title level={4} style={{ textAlign: 'right' }}>
                      {arrival}
                    </Title>
                    <Paragraph style={{ textAlign: 'right' }}>
                      {/* TODO Missing data */}
                      Park
                    </Paragraph>
                  </Col>
                </>
              )
            }
          </Row>
        </Col>

        <Col lg={showChangeButton ? 8 : 24} xs={24}>
          <Row gutter={[8, 0]}>
            <Col lg={showChangeButton ? 24 : 12} xs={12}>
              <DetailItemsWrapper>
                <DetailItem>
                  <DetailItemIcon>
                    <ArrowIcon />
                  </DetailItemIcon>

                  <Paragraph strong>
                    {renderFlightTime()}
                  </Paragraph>
                </DetailItem>
              </DetailItemsWrapper>

            </Col>
            <Col lg={showChangeButton ? 24 : 12} xs={12}>
              <DetailItemsWrapper>
                <DetailItem>
                  <DetailItemIcon>
                    <PassengerIcon />
                  </DetailItemIcon>
                  <Paragraph style={{ textAlign: 'right' }} strong>
                    <span>
                      {pluralizeText(
                        formData.numberOfAdults,
                        t(globalTranslations).passengerSelector.adult,
                        t(globalTranslations).passengerSelector.adults,
                      )}
                      &nbsp;&nbsp;
                    </span>
                  </Paragraph>
                </DetailItem>
              </DetailItemsWrapper>
            </Col>
          </Row>
        </Col>

        <Col lg={showChangeButton ? 4 : 0} xs={0}>
          <div className="align-right">
            <Link to="/search/road">
              <Button type="primary">
                {t(translations).changeButton}
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
}

RoadTripSummary.defaultProps = {
  showChangeButton: true,
  returning: false,
  itinerary: false,
};

export default RoadTripSummary;
