import React from 'react';
import {
  Row, Col, Form,
} from 'antd';
import StyledDatePicker from '../../CustomAntdComponents/StyledDatePicker/DatePicker';
import translations from '../../../screens/Search/translations';
import { useI18nContext } from '../../../utils/I18n';
import { getDisabledDateRange } from '../../../utils/helpers';
import {AirportFieldsWrapper, DepartureWrapper, SwitcherWrapper} from "../styles";
import AirportSelector from "../../RouteSelector";
import {ReactComponent as DirectionIcon} from "../../../assets/images/direction-hr.svg";
import {ReactComponent as VerticalDirectionIcon} from "../../../assets/images/direction.svg";
import useIsMobile from "../../../hooks/useIsMobile";
import {StyledParagraph} from "../../CustomAntdComponents/Typography";

type Props = {
  field: any,
  form: any,
  airportOptions
  limitSuggestionHeight?: boolean;
}
export function FlightSearchForm({
  airportOptions, field, form, limitSuggestionHeight
}: Props) {
  const {
    state: { t },
  } = useI18nContext();
  const { isMobile } = useIsMobile();

  const validateDestination = (arg: any, value) => {
    if (!value) {
      return Promise.reject(new Error(t(translations).validations.destination));
    }

    if(value === form.getFieldValue(`trips[${field.key}].OriginLocationCode`)) {
        return Promise.reject(new Error(t(translations).validations.invalidDestination));
    }

    return Promise.resolve();
  };

  const validateDeparture = (arg: any, value) => {
    if (!value) {
      return Promise.reject(new Error(t(translations).validations.departure));
    }

    if(!form.getFieldValue(`trips[${field.key}].DestinationLocationCode`)) {
        return Promise.resolve();
    }

    if(value === form.getFieldValue(`trips[${field.key}].DestinationLocationCode`)) {
        return Promise.reject(new Error(t(translations).validations.invalidDeparture));
    }

    return Promise.resolve();
  };

  const handleSwitchAirports = () => {
    const trips = form.getFieldValue("trips");
    const tripIndex = field.key;

    const origin = form.getFieldValue(`trips[${field.key}].OriginLocationCode`) || form.getFieldValue('trips')[field.key].OriginLocationCode;
    const destination = form.getFieldValue(`trips[${field.key}].DestinationLocationCode`) || form.getFieldValue('trips')[field.key].DestinationLocationCode;

    trips[tripIndex] = {
      ...trips[tripIndex],
      OriginLocationCode: destination,
      DestinationLocationCode: origin,
    }

    form.setFieldValue("trips", trips);
    form.setFieldValue(`trips[${field.key}].OriginLocationCode`, destination);
    form.setFieldValue(`trips[${field.key}].DestinationLocationCode`, origin);
  }

  return (
      <Row gutter={8}>
        <Col xs={24} lg={16}>
          <AirportFieldsWrapper>
            <Row gutter={[16, 0]}>
              <Col xs={24} lg={0}>
                <div style={{ marginBottom: "10px" }}>
                  <StyledParagraph size="normal">
                    {`Flight ${field.key + 1}`}
                  </StyledParagraph>
                </div>
              </Col>
              <Col xs={24} lg={12}>
                <DepartureWrapper>
                  <Form.Item
                      {...field}
                      name={[field.name, 'OriginLocationCode']}
                      fieldKey={[field.key, 'OriginLocationCode']}
                      key={`OriginLocationCode${field.key}`}
                      rules={[
                        {
                          validator: validateDeparture,
                        }
                      ]}
                  >
                      <AirportSelector
                          label={t(translations).inputs.fromLabel}
                          placeholder={t(translations).inputs.fromPlaceholder}
                          icon="takeoff"
                          value={form.getFieldValue(`trips[${field.key}].OriginLocationCode`)}
                          options={airportOptions}
                          onChange={(value) => {
                              form.setFieldValue(`trips[${field.key}].OriginLocationCode`, value);
                          }}
                          tripType="flight"
                          limitSuggestionHeight={limitSuggestionHeight}
                      />
                  </Form.Item>
                  <SwitcherWrapper onClick={handleSwitchAirports}>
                    {isMobile ? <VerticalDirectionIcon /> :<DirectionIcon />}
                  </SwitcherWrapper>
                </DepartureWrapper>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                    {...field}
                    name={[field.name, 'DestinationLocationCode']}
                    fieldKey={[field.key, 'DestinationLocationCode']}
                    dependencies={[field.name, 'OriginLocationCode']}
                    rules={[
                      {
                        validator: validateDestination,
                      },
                    ]}
                >
                  <AirportSelector
                      label={t(translations).inputs.toLabel}
                      placeholder={t(translations).inputs.toPlaceholder}
                      options={airportOptions}
                      value={form.getFieldValue(`trips[${field.key}].DestinationLocationCode`)}
                      onChange={(value) => form.setFieldValue(`trips[${field.key}].DestinationLocationCode`, value)}
                      icon="land"
                      tripType="flight"
                      limitSuggestionHeight={limitSuggestionHeight}
                  />
                </Form.Item>
              </Col>
            </Row>
          </AirportFieldsWrapper>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item
              name={[field.name, 'DepartureDate']}
              fieldKey={[field.key, 'DepartureDate']}
              rules={[{ required: true, message: t(translations).validations.departureDate }]}
              key={`DepartureDate${field.key}`}

          >
            <StyledDatePicker label={t(translations).inputs.departureDate} disabledDate={getDisabledDateRange} />
          </Form.Item>
        </Col>
      </Row>
  );
}
