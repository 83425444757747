import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom'; // uncomment when the route is ready
import {
  Button, Form, Row, Col,
} from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import {
  FlexColumnItem,
  FormCard, FormDivider, FormWrapper,
} from './styles';
import PassengerCountSelector from './PassengerSelector';
import RailModes from '../RoadSearch/RoadModes';
import RailModePill from '../RoadSearch/RoadModePill';
import StyledSelect from '../CustomAntdComponents/StyledSelect';
import { useStore } from '../../store/global/appStore';
import { getSelectOptions } from './helper';
import StyledDatePicker from '../CustomAntdComponents/StyledDatePicker';
import { getAPI } from '../../utils/api';
import {
  GET_SUPPORTED_ROAD_ROUTES,
} from '../../utils/constants';
import { GiveMeSpace } from '../CustomAntdComponents/Utitlities';
import { useRailSearchStore } from '../../store/railSearch/railSearchStore';
import { tripModes } from '../../screens/Search/constant';
import translations from '../../screens/Search/translations';
import globalTranslations from '../../utils/globalTranslation';
import { useI18nContext } from '../../utils/I18n';
import { getDisabledDateRange, getStartOfDay } from '../../utils/helpers';
import ComingSoon from '../ComingSoon';

function RailSearch() {
  const {
    state: { t },
  } = useI18nContext();
  const [checkReturnDate, setCheckReturnDate] = useState(false);
  const [noBooking, setNoBooking] = useState(false);
  const navigate = useNavigate(); // uncomment when the route is ready
  const {
    roadRoutes, setData,
  } = useStore();
  const {
    formData: {
      DepartureId, ArrivalId, TripType, numberOfAdults, numberOfChildren, DepartureDate, ArrivalDate,
    }, updateFormData,
  } = useRailSearchStore();
  const isRoundTrip = TripType === 'round_trip';
  const [form] = Form.useForm();

  const onFinish = () => {
    navigate('/search-rail-listings'); // uncomment when the route is ready
    // setNoBooking(true);
  };

  const departureRouteOptions = getSelectOptions(roadRoutes.filter((route) => route.IsDeparture));
  const arrivalRouteOptions = getSelectOptions(roadRoutes.filter((route) => route.IsArrival));

  const getRoadRoutes = useCallback(async () => {
    const roadRoutesAPI = await getAPI(GET_SUPPORTED_ROAD_ROUTES);
    if (roadRoutesAPI.status === 200) {
      setData(roadRoutesAPI.data.Data, 'roadRoutes');
    }
  }, [setData]);

  useEffect(() => {
    if (roadRoutes.length === 0) {
      getRoadRoutes();
    }
  }, [getRoadRoutes, roadRoutes.length]);

  const onFormInputChange = (value: any, name: string) => {
    updateFormData({ [name]: value });
  };

  const onDateInputChange = (value: any, name: string) => {
    updateFormData({ [name]: value });
  };

  const checkReturningDate = (_: any, value: any) => {
    if (!isRoundTrip || !DepartureDate) {
      return Promise.resolve();
    }

    if (!value) {
      return Promise.reject(new Error(t(translations).validations.destinationDate));
    }

    if (value.isBefore(DepartureDate)) {
      return Promise.reject(new Error(t(translations).validations.invalidDestinationDate));
    }
    return Promise.resolve();
  };

  const validateDestination = (_: any, value: any) => {
    if (!value) {
      return Promise.reject(new Error(t(translations).validations.destination));
    }

    if (DepartureId === value) {
      return Promise.reject(new Error(t(translations).validations.invalidDestination));
    }

    return Promise.resolve();
  };

  const formInitialValues = {
    DepartureId,
    ArrivalId,
    DepartureDate,
    ArrivalDate,
    numberOfAdults,
    numberOfChildren,
  };
  const disabledReturnDate: RangePickerProps['disabledDate'] = (current) => getDisabledDateRange(current, getStartOfDay(DepartureDate));

  useEffect(() => {
    setCheckReturnDate(isRoundTrip);
  }, [isRoundTrip, setCheckReturnDate]);

  useEffect(() => {
    form.validateFields(['ReturnDate']);
  }, [checkReturnDate, form]);

  return (
    <div>
      {/* {noBooking && <ComingSoon text={t(globalTranslations).readiness} />} */}
      {/* <ComingSoon text={t(globalTranslations).readiness} /> */}
      <Form
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        initialValues={formInitialValues}
        form={form}
        onFinish={onFinish}
        size="large"
      >
        <FormWrapper>
          <Row>
            <Col xs={24} lg={0}>
              <RailModes mode={TripType} onTripTypeChange={(value) => onFormInputChange(value, 'TripType')} />
              <GiveMeSpace />
            </Col>
          </Row>

          <Row>
            <Col xs={0} lg={24}>
              <FormCard>
                <Row gutter={[24, 24]}>
                  <Col lg={11}>
                    <Row gutter={[0, 32]}>
                      <Col span={24}>
                        <RailModePill
                          options={tripModes(t(translations))}
                          mode={TripType}
                          setOption={(value) => onFormInputChange(value, 'TripType')}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col lg={2}>
                    &nbsp;
                  </Col>

                  <Col lg={11}>
                    <Row gutter={[0, 32]}>
                      <Col span={24} />
                    </Row>
                  </Col>

                  <Col lg={11} style={{ display: 'flex' }}>
                    <FlexColumnItem>
                      <Row gutter={24}>
                        <Col lg={24}>
                          <Form.Item
                            name="DepartureId"
                            rules={[{ required: true, message: t(translations).validations.departure }]}
                          >
                            <StyledSelect
                              placeholder={t(translations).inputs.from}
                              options={departureRouteOptions}
                              onChange={(value) => onFormInputChange(value, 'DepartureId')}
                              showSearch
                            />
                          </Form.Item>
                          <Form.Item
                            name="ArrivalId"
                            rules={[
                              {
                                validator: validateDestination,
                              },
                            ]}
                          >
                            <StyledSelect
                              placeholder={t(translations).inputs.to}
                              options={arrivalRouteOptions}
                              onChange={(value) => onFormInputChange(value, 'ArrivalId')}
                              showSearch
                            />
                          </Form.Item>
                        </Col>

                        <Col lg={isRoundTrip ? 12 : 24}>
                          <Form.Item
                            name="DepartureDate"
                            rules={[{ required: true, message: t(translations).validations.departureDate }]}
                          >
                            <StyledDatePicker
                              placeholder={t(translations).inputs.departureDate}
                              value={DepartureDate}
                              disabledDate={getDisabledDateRange}
                              onChange={(value) => onDateInputChange(value, 'DepartureDate')}
                            />
                          </Form.Item>
                        </Col>

                        <Col lg={isRoundTrip ? 12 : 0}>
                          <Form.Item
                            name="ReturnDate"
                            rules={[
                              {
                                validator: checkReturningDate,
                              },
                            ]}
                          >
                            <StyledDatePicker
                              placeholder={t(translations).inputs.arrivalDate}
                              value={ArrivalDate}
                              disabledDate={disabledReturnDate}
                              onChange={(value) => onDateInputChange(value, 'ArrivalDate')}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                    </FlexColumnItem>
                  </Col>

                  <Col lg={2} style={{ display: 'flex' }}>
                    <FlexColumnItem>
                      <FormDivider> &nbsp; </FormDivider>
                    </FlexColumnItem>
                  </Col>

                  <Col lg={11} style={{ display: 'flex' }}>
                    <FlexColumnItem>
                      <PassengerCountSelector showTitle={false} />
                    </FlexColumnItem>
                  </Col>

                  <Col lg={8} offset={16}>
                    <Button type="primary" htmlType="submit" size="large" block>
                      {t(translations).searchButton}
                    </Button>
                  </Col>
                </Row>
              </FormCard>
            </Col>
          </Row>

          {/* Small device Form */}
          <Row gutter={[0, 16]}>
            <Col xs={24} lg={0}>
              <Row gutter={8}>
                <Col xs={24}>
                  <Form.Item
                    name="DepartureId"
                    rules={[{ required: true, message: t(translations).validations.departure }]}
                  >
                    <StyledSelect
                      placeholder={t(translations).inputs.from}
                      showSearch
                      options={departureRouteOptions}
                      onChange={(value) => onFormInputChange(value, 'DepartureId')}
                    />
                  </Form.Item>

                  <Form.Item
                    name="ArrivalId"
                    rules={[
                      {
                        validator: validateDestination,
                      },
                    ]}
                  >
                    <StyledSelect
                      placeholder={t(translations).inputs.to}
                      showSearch
                      options={arrivalRouteOptions}
                      onChange={(value) => onFormInputChange(value, 'ArrivalId')}
                    />
                  </Form.Item>
                </Col>

                <Col xs={isRoundTrip ? 12 : 24}>
                  <Form.Item
                    name="DepartureDate"
                    rules={[{ required: true, message: t(translations).validations.departureDate }]}
                  >
                    <StyledDatePicker
                      placeholder={t(translations).inputs.departureDate}
                      value={DepartureDate}
                      disabledDate={getDisabledDateRange}
                      onChange={(value) => onFormInputChange(value, 'DepartureDate')}
                    />
                  </Form.Item>
                </Col>

                <Col xs={isRoundTrip ? 12 : 0}>
                  <Form.Item
                    name="ReturnDate"
                    rules={[
                      {
                        validator: checkReturningDate,
                      },
                    ]}
                  >
                    <StyledDatePicker
                      placeholder={t(translations).inputs.arrivalDate}
                      value={ArrivalDate}
                      disabledDate={disabledReturnDate}
                      onChange={(value) => onFormInputChange(value, 'ArrivalDate')}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} lg={0}>
              <PassengerCountSelector />
            </Col>

            <Col xs={24} lg={0}>
              <Form.Item>
                <Button type="primary" htmlType="submit" size="large" block>
                  {t(translations).searchButton}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </FormWrapper>

      </Form>
    </div>
  );
}

export default RailSearch;
