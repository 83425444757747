import React from 'react';
import {
  Card, Row, Col, Space, Divider, Button,
} from 'antd';
import moment from 'moment';
import { useBookingStore } from '../../store/booking/bookingStore';
import {
  DetailItem, DetailItemIcon, DetailItemsWrapper, StepItemContent,
  StepItemWrapper,
  StepProgressLine, StepsWrapper,
} from '../TripSummary/styles';
import { ReactComponent as BusIcon } from '../../assets/images/car.svg';
import {
  SpaceBetweenAlignContent,
  VerticalAlignContent,
} from '../CustomAntdComponents/Utitlities';
import {
  StyledParagraph,
  StyledText, StyledTitle,
} from '../CustomAntdComponents/Typography';
import { HighlightDetailItem } from '../FlightItinerary/styles';
import { ContentWrapper } from './styles';
import { ReactComponent as ArrowIcon } from '../../assets/images/arrow-directions.svg';
import { ReactComponent as PassengerIcon } from '../../assets/images/passenger-color.svg';
import { formatNumberAsCurrency, pluralizeText } from '../../utils/helpers';
import { ReactComponent as ClockIcon } from '../../assets/images/clock.svg';
import { getRoadTransportLogo } from '../AvailableBuses/helper';

function ManageRoadBooking() {
  const { roadReservation } = useBookingStore();
  const renderSummary = () => {
    if (!roadReservation) {
      return <div>&nbsp;</div>;
    }

    const numberOfAdults = roadReservation.TravellersModel.filter((pax) => pax.PassengerTypeCode === 'ADT').length;
    const numberOfChildren = roadReservation.TravellersModel.filter((pax) => pax.PassengerTypeCode === 'CHD').length;
    const numberOfInfants = roadReservation.TravellersModel.filter((pax) => pax.PassengerTypeCode === 'INF').length;

    return (
      <Row gutter={[32, 8]}>
        <Col span={24}>
          <HighlightDetailItem>
            <SpaceBetweenAlignContent>
              <StyledText size="small" color="secondary">
                {`Reference ID: ${roadReservation.BookingReferenceId}`}
              </StyledText>
              <StyledText strong>
                {formatNumberAsCurrency(roadReservation.TotalFare)}
              </StyledText>
            </SpaceBetweenAlignContent>
          </HighlightDetailItem>
        </Col>
        <Col lg={12} xs={24}>
          <Row justify="space-between" align="middle">
            <Col span={24}>
              <Space direction="vertical" size="middle">
                <VerticalAlignContent>
                  <img src={getRoadTransportLogo(roadReservation.AgentName)} alt="" />
                  <StyledText color="secondary" size="small">
                    {roadReservation.AgentName}
                  </StyledText>
                </VerticalAlignContent>
              </Space>
            </Col>
            <Col span={24}>
              <StepsWrapper>
                <StepProgressLine />
                <StepItemWrapper>
                  <StepItemContent>
                    <BusIcon />
                  </StepItemContent>
                </StepItemWrapper>

                <StepProgressLine />

              </StepsWrapper>
            </Col>
            <Col span={9}>
              <StyledTitle level={2}>
                {roadReservation.DepartureLocation}
              </StyledTitle>
              <StyledParagraph>
                Park, Name
              </StyledParagraph>
            </Col>
            <Col span={6}>
              &nbsp;
            </Col>
            <Col span={9}>
              <StyledTitle level={2} align="right">
                {roadReservation.ArrivalLocation}
              </StyledTitle>
              <StyledParagraph align="right">
                {/* TODO Missing data */}
                Airport, State
              </StyledParagraph>
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={12}>
          <Row gutter={[0, 8]}>
            <Col span={12}>
              <DetailItemsWrapper>
                <DetailItem>
                  <DetailItemIcon>
                    <ArrowIcon />
                  </DetailItemIcon>
                  <StyledParagraph strong>
                    {moment(roadReservation.DepartureDate).format('ddd, D MMM') }
                  </StyledParagraph>
                </DetailItem>
              </DetailItemsWrapper>
            </Col>

            <Col span={12}>
              <DetailItemsWrapper>
                <VerticalAlignContent>
                  <DetailItemIcon>
                    <PassengerIcon />
                  </DetailItemIcon>
                  <StyledParagraph align="right">
                    <span>
                      {pluralizeText(numberOfAdults, 'Adult', 'Adults')}
                  &nbsp;&nbsp;
                    </span>

                    {Boolean(numberOfChildren) && (
                      <span>
                          {pluralizeText(numberOfChildren, 'Child', 'Children')}
                        &nbsp;&nbsp;
                      </span>
                    )}

                    {Boolean(numberOfInfants) && (
                      <span>
                          {pluralizeText(numberOfInfants, 'Infant', 'Infants')}
                        &nbsp;&nbsp;
                      </span>
                    )}
                  </StyledParagraph>
                </VerticalAlignContent>
              </DetailItemsWrapper>
            </Col>

            <Col span={24}>
              <HighlightDetailItem>
                <SpaceBetweenAlignContent>
                  <StyledParagraph>
                    {moment(roadReservation.DepartureDate).format('ll')}
                  </StyledParagraph>
                  <StyledText strong>
                    {moment(roadReservation.DepartureDate).format('hh:mm a')}
                  </StyledText>
                </SpaceBetweenAlignContent>
              </HighlightDetailItem>
            </Col>

            <Col span={24}>
              <VerticalAlignContent>
                <VerticalAlignContent>
                  <ClockIcon />
                  <StyledText size="small">
                    {moment(roadReservation.ArrivalDate).diff(moment(roadReservation.DepartureDate), 'hours')}
&nbsp; hours
                  </StyledText>
                </VerticalAlignContent>
              </VerticalAlignContent>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return roadReservation ? (
    <Card size="small">
      <ContentWrapper>
        {renderSummary()}

        <Divider orientationMargin={4} />

        <Row>
          <Col xs={{ span: 12, offset: 12 }} lg={{ span: 4, offset: 20 }}>
            <Button type="primary" block>
              Modify
            </Button>
          </Col>
        </Row>
      </ContentWrapper>

    </Card>
  ) : (
    <Card>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi, voluptate?
    </Card>
  );
}

export default ManageRoadBooking;
