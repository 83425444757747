export const getAirportOptions = (data: Airport[]) : SelectOption[] => data.map((airport) => ({
  label: airport.Name,
  value: airport.Code,
  tags: `${airport.StateCode} ${airport.StateName}`,
}));

export const generateFlightSearchInputs = (numberOfInputs: number) => {
  const flightFields = new Array(numberOfInputs).fill({
    OriginLocationCode: '',
    DestinationLocationCode: '',
    DepartureDate: '',
  });

  return flightFields.map(({
    OriginLocationCode,
    DestinationLocationCode,
    DepartureDate,
  }, key) => ({
    id: key,
    OriginLocationCode,
    DestinationLocationCode,
    DepartureDate,

  }));
};

export const generateMultiCityFlightRoutes = (routes) => routes.map(((route) => ({
  DepartureDate: route.DepartureDate,
  OriginLocationCode: route.OriginLocationCode,
  DestinationLocationCode: route.DestinationLocationCode,
})
));


export const validateRoundTripDates = (_, value) => {
  value = value || [];
  value = value.filter((item) => item !== undefined);

  if (value.length !== 2) {
    return Promise.reject(new Error('Please select departure and return date'));
  }
  return Promise.resolve();
};
