import styled from "styled-components";
import {BLACK, GREY, GREY_12, RED} from "../../utils/colors";

export const Wrapper = styled.div<{ $error?: boolean }>`
  padding-top: 6px;

  .react-tel-input .flag-dropdown.open {
    z-index: 20;
    width: 100%;
  }
  .react-tel-input .form-control {
    border: solid 1px ${GREY_12};
    border-color: ${({ $error }) => $error ? RED : GREY_12};
    font-size: 13px;
    border-radius: 12px;
    padding: 17px 25px 17px 60px;
    width: 100%;
    color: ${BLACK};

    &:hover {
      border-color: ${({ $error }) => $error ? RED : GREY_12}!important;
    }
    &:focus {
      border-color: ${({ $error }) => $error ? RED : GREY_12} !important;
      box-shadow: none;
    }
  }

  .react-tel-input .flag {
    width: 25px;
    height: 20px;
  }

  .react-tel-input .selected-flag {
    z-index: 1;
    width: 55px;
  }

  .react-tel-input .selected-flag .flag {
    margin-top: -11px;
    border-radius: 5px;
  }

  .react-tel-input .selected-flag .arrow {
    border: solid ${GREY};
    border-width: 0 1px 1px 0;
    padding: 3px;
    top: 40%;
    left: 33px;
    transform: rotate(45deg);
  }

  .react-tel-input .country-list {
    width: 100%;
  }

  .react-tel-input {
    font-family: unset !important;
  }
  
  .react-tel-input .invalid-number-message,
  .react-tel-input .special-label {
    z-index: 2;
    top: -12px;
    left: 10px;
    font-size: 14px;
    color: ${({ $error }) => ($error ? RED : BLACK)};
  }
`;

export const HelperTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  align-items: center;
`;

export const HelperText = styled.span`
  color: ${RED};
  padding-left: 6px;
  font-size: 13px;
  font-weight: 400;
`;

