import styled, { css } from 'styled-components';
import {
  Divider, Card,
} from 'antd';
import device from '../../utils/mediaQuery';
import {BLACK_100, GREY_1, GREY_12, GREY_2, GREY_4, GREY_6, LIGHT_GREY} from "../../utils/colors";

export const Wrapper = styled.div`
  margin-top: 15px;

  .ant-space {
    display: flex;
    margin-top: 15px;
  }
  
  .ant-typography {
    margin-bottom: 0;
  }

  @media ${device.large} {
    margin-top: 0;
  }
`;

export const HeaderWrapper = styled.div`
 margin-bottom: 10px;
`;

export const HeaderBusDetails = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0 10px;

  img {
      margin-right: 5px;
      width: 80px;
  }

  @media ${device.large} {
    display: block;
    text-align: center;
    justify-content: center;
    
    .ant-typography {
      text-align: center;
      margin: 10px 0 !important;
    }
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 0;
`;

export const StyledCard = styled(Card)`
  @media ${device.large} {
    padding: 20px;
  }
`;

export const ColumnItem = styled.div<{ index?: number}>`
  background: #fff;
  flex: 1;
  padding: 0 20px;

  @media ${device.extraSmall} {
    background: none;
  }
  
  @media ${device.large} {
    background: #fff;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    ${({ index }) => index === 1 && css`
      border-radius: 5px 0 0 5px;
    `}
    
    ${({ index }) => index === 2 && css`
      border-radius: 0;
    `}
    
    ${({ index }) => index === 3 && css`
      border-radius: 0 5px 5px 0;
    `}
  }
`;

export const TextWrapper = styled.div`
  margin-bottom: 10px;
`;

export const RowWrapper = styled.section`
  background: #EEEDF9;
  margin-top: 15px;
  border-radius: 5px;
    
    ${HeaderBusDetails} {
        img {
            width: 100%;
        }
    }

  @media ${device.extraSmall} {
    background: #fff;
  }

  @media ${device.large} {
    background: #EEEDF9;
  }
`;

export const ItemWrapper = styled(Card)`
  margin: 15px 0;
`;

export const DetailsWrapper = styled.div<{ rightAlign?: boolean}>`
    display: flex;
    align-items: center;
    column-gap: 15px;
    
    ${({ rightAlign }) => rightAlign && css`
        justify-content: flex-end;
    `}
`;

export const SelectInfoWrapper = styled.div`
  margin-bottom: 20px;
  
  @media ${device.large} {
    margin-bottom: 0;
  }
`;

export const BusImage = styled.img`
    width: 100%;
`;

export const BusCardImage = styled.div`
  background: ${GREY_2};
  border-radius: 12px 0 0 12px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 5px 10px;
`;

export const BusCardContent = styled.div<{ receipt?: boolean }>`
  padding: 12px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  border-bottom: solid 1px ${GREY_4};

  ${({ receipt }) => receipt && css`
        padding: 0;
        row-gap: 10px;
    `}

  @media ${device.large} {
    padding: 20px 30px;
    row-gap: 20px;
    border-bottom: none;

    ${({ receipt }) => receipt && css`
        padding: 0;
        row-gap: 10px;
    `}
  }
   
`;

export const BusCardPriceContent = styled(BusCardContent)`
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
`;

export const BusCard = styled.div<{ leftBordered?: boolean, rightBordered?: boolean }>`
    background: #fff;
    border-radius: 14px;
    border: solid 1px ${GREY_6};
    margin-bottom: 15px;
    cursor: pointer;
  
    @media ${device.large} {
      flex-grow: 1;
      cursor: default;
    }
  
    ${({ leftBordered }) => leftBordered && css`border-left: dashed 1px ${BLACK_100};`}
    ${({ rightBordered }) => rightBordered && css`border-right: dashed 1px ${BLACK_100};`}
`;

export const BusStepWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 22px;
    margin: 0;
  
    @media ${device.large} {
      margin-bottom: 0;
    }
`;

export const BusDetailsWrapper = styled.div`
  .ant-drawer-body {
    background: ${GREY_2}; 
    height: 100vh;
  }
  
  .ant-drawer-footer {
    padding: 20px 30px;
  }
`;

export const SeatFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

export const SeatsNumberWrapper = styled.div`
  border-bottom: solid 1px ${GREY_4};
  padding: 10px;
`;
