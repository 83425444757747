import React, {useMemo} from 'react';
import {NameContent, NameWrapper, SearchItem, SectionTitle} from "./styles";
import {StyledParagraph, StyledText, StyledTitle} from "../CustomAntdComponents/Typography";
import {useStore} from "../../store/global/appStore";
import {SuggestionType} from "./index";
import { FixedSizeList as List } from 'react-window';
import styled from "styled-components";

const suggestedSearches: SuggestionType[] = [
    {
        name: "Lagos, Nigeria",
        label: "Murtala Muhammed International Airport",
        code: "LOS",
        value: "LOS"
    },
    {
        name: "Nairobi, Kenya",
        label: "Jomo Kenyatta International Airport",
        code: "NBO",
        value: "NBO",
    },
    {
        name: "Abuja, Nigeria",
        label: "Nnamdi Azikiwe International Airport",
        code: "ABV",
        value: "ABV"
    },
    {
        name: "Mombasa, Kenya",
        label: "Moi International Airport",
        code: "MBA",
        value: "MBA",
    },
    {
        name: "Kano, Nigeria",
        label: "Mallam Aminu Kano International Airport",
        code: "KAN",
        value: "KAN",
    },
    {
        name: "Eldoret, Kenya",
        label: "Eldoret International Airport",
        code: "EDL",
        value: "EDL",
    },
    {
        name: "London, England",
        label: "London Heathrow Airport",
        code: "LHR",
        value: "LHR"
    },
    {
        name: "Accra, Ghana",
        label: "Kotoka International Airport",
        code: "ACC",
        value: "ACC"
    },
    {
        name: "Mississauga, Ontario",
        label: "Toronto Pearson International Airport",
        code: "YYZ",
        value: "YYZ"
    }
];

const SelectSearches = ({ onSelect, searchText, height = 500, limitSuggestionHeight }: { limitSuggestionHeight?: boolean, height?: number, onSelect: (airport: string) => void; searchText: string; }) => {
    const { airports } = useStore();
    const airportsList = airports;

    const filteredOptions = useMemo(() => {
        const textLower = searchText.toLowerCase();
        return airportsList.filter((option) => {
            const concatenatedFields = `${option?.Code} ${option?.StateName} ${option?.StateCode} ${option?.Name}`.toLowerCase();
            return concatenatedFields.includes(textLower);
        });
    }, [airportsList, searchText]);

    const filteredLength = filteredOptions.length
    const suggestionHeight = limitSuggestionHeight ? `${height}px` : "100%";

    return (
        <div>
            {
                searchText ? (
                    <>
                        {
                            filteredLength === 0 && (
                                <Content height={suggestionHeight}>
                                    <SectionTitle>
                                        <StyledParagraph align="center" size="normal">
                                            No results found.
                                        </StyledParagraph>
                                    </SectionTitle>
                                </Content>
                            )
                        }
                        <List
                            height={height}
                            itemCount={filteredOptions.length}
                            itemSize={66}
                            width={"100%"}
                        >
                            {({ index, style }) => {
                                const option = filteredOptions[index];
                                return (
                                    <div style={{ ...style, paddingLeft: "16px", paddingRight: "16px" }} >
                                        <SearchItem key={index} onClick={() => onSelect(option.Code)}>
                                            <NameWrapper>
                                                <NameContent>
                                                    <StyledTitle level={5}>{`${option.StateCode}, ${option.StateName}`}</StyledTitle>
                                                    <StyledText>{option.Name}</StyledText>
                                                </NameContent>
                                            </NameWrapper>
                                            <StyledTitle level={4}>{option.Code}</StyledTitle>
                                        </SearchItem>
                                    </div>
                                )
                            }}
                        </List>
                    </>
                ) : (
                    <Content height={suggestionHeight}>
                        <SectionTitle>
                            <StyledText>
                                Suggested Searches
                            </StyledText>
                        </SectionTitle>
                        {suggestedSearches.map((search, index) => (
                            <SearchItem key={index} onClick={() => onSelect(search.value)}>
                                <NameWrapper>
                                    <NameContent>
                                        <StyledTitle level={5}>{search.name}</StyledTitle>
                                        <StyledText>{search.label}</StyledText>
                                    </NameContent>
                                </NameWrapper>
                                {search?.code && <StyledTitle level={4}>{search?.code}</StyledTitle>}
                            </SearchItem>
                        ))}
                    </Content>
                )
            }
        </div>
    )
};

const Content = styled.div<{ height: string }>`
    padding: 0 16px;    
    max-height: ${({ height }) => `${height}`};
    overflow-y: auto;
`;

export default SelectSearches;
