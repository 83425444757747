import React, {useState} from 'react';
import { Collapse } from 'antd';
import {
    CollapseWrapper,
    FilterHeading,
    FilterLinkText,
} from "./styles";
import PriceRange from "./FlightFilters/PriceRange";
import {StyledTitle} from "../CustomAntdComponents/Typography";
import OperatorsCheckbox from "./RoadFilters/OperatorsCheckbox";

const { Panel } = Collapse;

const categories = ['Operators', 'Price'];
type Props = {
    handleOperatorFiltering?: (arg: string[]) => void,
    farePriceRange: PriceRangeType,
    handleFilterByPriceRange: (arg0: [number, number]) => void,
    selectedOperators: string[],
    operators: SelectOption[],
    onResetFilters: () => void,
}

const RoadListingFilterDesktop = ({
                                      onResetFilters, handleOperatorFiltering, selectedOperators, operators,
                                      farePriceRange, handleFilterByPriceRange

}: Props) => {
    const [active, setActive] = useState(categories[0])

    const renderFilterContent = (category: string) => {
        switch (category) {
            case 'Operators':
                return (
                    <OperatorsCheckbox operators={operators} selectedOperators={selectedOperators} onChange={handleOperatorFiltering} />
                )
            case 'Price':
                return (
                    <PriceRange onChange={(v) => handleFilterByPriceRange(v)} farePriceRange={farePriceRange} />
                );
            default:
                return null
        }
    }

    return (
        <>
            <FilterHeading>
                <StyledTitle level={5}>Filter by</StyledTitle>
                <FilterLinkText level={5} color="primary" onClick={onResetFilters}>Reset</FilterLinkText>
            </FilterHeading>
            <CollapseWrapper>
                <Collapse accordion activeKey={active} onChange={(c: string) => setActive(c)}>
                    {
                        categories.map((category) => (
                            <Panel header={category} key={category}>
                                {renderFilterContent(category)}
                            </Panel>
                        ))
                    }
                </Collapse>
            </CollapseWrapper>
        </>
    );
};

export default RoadListingFilterDesktop;
