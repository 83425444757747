import styled, { css } from 'styled-components';
import { Card } from 'antd';
import {
  GREY_5, PRIMARY,
} from '../../utils/colors';
import device from '../../utils/mediaQuery';

export const TabWrapper = styled.div`
  display: flex;
`;

export const FormWrapper = styled.div`
  margin-top: 20px;

  .ant-form-item {
    margin-bottom:12px;
  }

  @media ${device.large} {
    margin-bottom: 24px;
  }
`;

export const TabItemWrapper = styled.div< { active: boolean }>`
  padding: 8px 10px;
  flex: 1;
  text-align: center;
  border-bottom: 1px solid transparent;
  cursor: pointer;

  transition: all 0.5s ease-in-out;

  .ant-typography {
    font-weight: 600;
  }

  ${({ active }) => active && css`
    border-bottom: 1px solid ${PRIMARY};
  `}
`;

export const FormCard = styled(Card)<{ bustrip?: boolean }>`
  @media ${device.large} {
    padding: 0 20px 20px;
    border-radius: 0 20px 20px 20px;
    border: none;

    ${({ bustrip }) => bustrip && css`
        border-radius: 20px;
    `
    }
`;

export const FlexColumnItem = styled.div`
  flex: 1;
`;

export const FormDivider = styled.div`
  width: 1px;
  margin: 0 auto 0;
  height: 90%;
  background: ${GREY_5};
`;
