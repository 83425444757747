import abcLogo from '../../assets/images/roadTransports/abc.svg';
import chiscoLogo from '../../assets/images/roadTransports/chisco.svg';
import gigLogo from '../../assets/images/roadTransports/gig.svg';
import guoLogo from '../../assets/images/roadTransports/guo.svg';
import libraLogo from '../../assets/images/roadTransports/libra.svg';

import threeSeater from '../../assets/images/bus/3-seater.png';
import sixSeater from '../../assets/images/bus/6-seater.png';
import fifteenSeater from '../../assets/images/bus/15-seater.png';
import fiftyTwoSeater from '../../assets/images/bus/52-seater.png';

export const getRoadTransportLogo = (name: string) => {
  // TODO fix default
  // TODO confirm codes
  switch (name) {
    case 'ABC Transport':
      return abcLogo;
    case 'Chisco Transport':
      return chiscoLogo;
    case 'GIG Motors':
      return gigLogo;
    case 'GUO Transport':
      return guoLogo;
    case 'LIBRA Motors':
      return libraLogo;
    default:
      return abcLogo;
  }
};
export const getBusTypeImage = (seat: number) => {
  if (seat <= 3) {
    return threeSeater;
  } if (seat <= 6) {
    return sixSeater;
  } if (seat <= 15) {
    return fifteenSeater;
  }
  return fiftyTwoSeater;
};

export const calculateTotalFare = (selectedBus: AvailableBus[], numberOfPassengers: number) => {
  if(!selectedBus) {
    return { totalCharges: 0, totalAmount: 0, ticketAmount: 0 };
  }
  const totalAmount = numberOfPassengers * selectedBus.reduce((acc, item) => (acc + item.TotalFare), 0);
  return { totalCharges: 0, totalAmount, ticketAmount: totalAmount };
};

export const calculateRoadFareBreakdown = (selectedBus: AvailableBus[] | null): FlightPassengerFare[]  => {
  if (!selectedBus) {
    return [];
  }


  let departurePassengerFares  = selectedBus[0].PassengerFares;
  let totalFares = [...departurePassengerFares]
  const returningPassengerFares  = selectedBus[1] ? selectedBus[1].PassengerFares : null;

  if(returningPassengerFares) {
    totalFares.push(...returningPassengerFares)
  }

  return totalFares;
};
