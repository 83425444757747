import React, {useMemo, useEffect, useState} from 'react';
import { Progress } from 'antd';
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import {
    Content,
    InfoTextWrapper,
    ImageWrapper,
    InfoWrapper,
    TextWrapper,
    Wrapper,
    BusItem, BoxesWrapper, BoxItem, BusInfo
} from "./styles";
import { ReactComponent as LoadingIcon} from "../../assets/images/loader.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/images/arrow-right.svg";
import { ReactComponent as DirectionIcon } from "../../assets/images/direction-hr.svg";
import {pluralizeText} from "../../utils/helpers";
import {useStore} from "../../store/global/appStore";
import {getTerminalName} from "../TripSummary/helper";
import {PRIMARY} from "../../utils/colors";
import useIsMobile from "../../hooks/useIsMobile";
import {useRoadSearchStore} from "../../store/roadSearch/roadSearchStore";

type Props = {
    booking?: boolean
}

const BusRequestLoading = ({ booking }: Props) => {
    const [percent, setPercent] = useState<number>(5);
    const { isMobile } = useIsMobile();
    const {
        formData: {
            numberOfAdults, TripType,
            ArrivalDate, DepartureDate, DepartureId, ArrivalId
        },
    } = useRoadSearchStore();
    const { roadRoutes } = useStore();

    const numberOfPassengers = useMemo(() => {
        return numberOfAdults;
    }, [numberOfAdults]);

    const isRoundTrip = TripType === 'round_trip';
    const departure = getTerminalName(roadRoutes, DepartureId);
    const arrival = getTerminalName(roadRoutes, ArrivalId);

    const getLabel = (label: string) => {
        return label.split('(')[0].slice(0, 10);
    }
    const departureLabel = useMemo(() => {
        return getLabel(departure);
    }, [departure]);

    const arrivalLabel = useMemo(() => {
        return getLabel(arrival);
    }, [arrival]);

    const dynamicProgress = () => {
        const newPercent = percent + 1;

        if(newPercent > 100){
            setPercent(60);
        } else {
            setPercent(newPercent)
        }
    }

    useEffect(() => {
        const intervalId =  setInterval(dynamicProgress, 1000)

        return () => clearInterval(intervalId);
    })

    return (
        <>
            {
                !isMobile && <Progress strokeColor={PRIMARY} percent={percent} type="line" showInfo={false} size="small" />
            }
            <Wrapper>
                <Content>
                    <InfoWrapper>
                        <BusInfo>
                            <BusItem>
                                <StyledParagraph size="small" align="left">
                                    {departure}
                                </StyledParagraph>
                                <StyledTitle>
                                    {departureLabel}
                                </StyledTitle>
                            </BusItem>

                            {
                                isRoundTrip ? <DirectionIcon /> : <RightArrowIcon />
                            }

                            <BusItem>
                                <StyledParagraph size="small" align="left">
                                    {arrival}
                                </StyledParagraph>
                                <StyledTitle>
                                    {arrivalLabel}
                                </StyledTitle>
                            </BusItem>
                        </BusInfo>
                        <TextWrapper>
                            {(isRoundTrip) && ArrivalDate ? (
                                <StyledParagraph align="center">
                                    {DepartureDate.format("ddd, MMM D YYYY") + " - " + ArrivalDate.format("ddd, MMM D YYYY")}
                                </StyledParagraph>
                            ) : (
                                <StyledParagraph align="center">
                                    {DepartureDate.format("ddd, MMM D YYYY")}
                                </StyledParagraph>
                            )}
                        </TextWrapper>

                        <TextWrapper>
                            <StyledParagraph align="center">
                                {`${pluralizeText(numberOfPassengers, "Passenger", "Passengers")}`}
                            </StyledParagraph>
                        </TextWrapper>

                        <BoxesWrapper>
                            {new Array(30).fill(0).map((_, index) => (
                                <BoxItem key={index} index={index}>&nbsp;</BoxItem>
                            ))}
                        </BoxesWrapper>
                    </InfoWrapper>

                    <ImageWrapper>
                        <LoadingIcon />
                    </ImageWrapper>

                    <InfoTextWrapper>
                        <StyledParagraph size="large" align="center">
                            {
                                booking ? "We are confirming your booking..." : "We are searching for the best prices..."
                            }
                        </StyledParagraph>
                    </InfoTextWrapper>
                </Content>
            </Wrapper>
        </>
    );
};

export default BusRequestLoading;
