/* eslint-disable react/no-array-index-key */
import React, {useEffect} from 'react';
import RoadCard from "./RoadCard";
import {useNavigate} from "react-router-dom";
import RoadHeading from "./RoadHeading";
import {ContentWrapper} from "./styles";
import RoadPriceBreakdown from "./RoadPriceBreakdown";
import {useRoadSearchStore} from "../../store/roadSearch/roadSearchStore";

function RoadItineraryBreakdown() {
  const {
    selectedBus,
      selectedResult,
    formData: {
      TripType,
        numberOfAdults
    },
  } = useRoadSearchStore();

  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedResult) {
      navigate('/search/road');
    }
  }, [selectedResult]);

  const isRoundTrip = TripType === 'round_trip';

  const departureBus = selectedBus[0];
  const returningBus = selectedBus && selectedBus.length == 2 ? selectedBus[1] : null;
  const departureOperator = selectedResult[0];
  const returningOperator = selectedResult[1];

  return (
      <>
        <ContentWrapper>
          <div>
            {
                departureBus && (<>
                  <RoadHeading operator={departureOperator} bus={departureBus} label="Departure" numberOfPassengers={numberOfAdults}  />
                  <RoadCard operator={departureOperator} bus={departureBus} />
                </>)
            }

            {
                isRoundTrip && returningBus && (<>
                  <RoadHeading operator={returningOperator} bus={returningBus} label="Return" />
                  <RoadCard operator={returningOperator} bus={returningBus} />
                </>)
            }
          </div>

           <RoadPriceBreakdown />
        </ContentWrapper>
      </>
  );
}
export default RoadItineraryBreakdown;
