import { create } from 'zustand';

export const useStore = create<AppStoreState>((set) => ({
  // initial state
  airports: JSON.parse(localStorage.getItem('airports') || '[]'),
  roadRoutes: JSON.parse(localStorage.getItem('roadRoutes') || '[]'),
  requesting: false,
  airlines: [],
  setAirports: (airports: Airport[], key: string) => {
    set(() => ({
      [key]: [...airports],
    }));
  },
  setRequesting: (requesting: boolean) => {
    set(() => ({
      requesting,
    }));
  },
  setData: (data: any, key: string) => {
    set(() => ({
      [key]: data,
    }));
  },
}));
