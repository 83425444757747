import React from 'react';
import { useNavigate } from 'react-router-dom';
import useIsMobile from "../../../hooks/useIsMobile";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";


type Props = {
  navigateHandler?: () => void
  modify: boolean,
  onModifyChange: (value: boolean) => void
  refetchSearch: () => void
}
function HeaderNavigation({ navigateHandler, modify, onModifyChange, refetchSearch }: Props) {
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (navigateHandler) {
      navigateHandler();
    } else {
      navigate(-1);
    }
  };

  return (
      isMobile ? (
          <MobileHeader navigateHandler={handleNavigate} modify={modify} onModifyChange={onModifyChange} refetchSearch={refetchSearch} />
      ) : (
          <DesktopHeader navigateHandler={handleNavigate} modify={modify} onModifyChange={onModifyChange} refetchSearch={refetchSearch} />
      )
  )
}

HeaderNavigation.defaultProps = {
  name: undefined,
  navigateHandler: undefined,
};

export default HeaderNavigation;
