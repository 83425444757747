import React from 'react';
import {
    CircleIcon,
    FlightDetails,
    FlightDetailsContainer,
    FlightDetailsWrapper,
    FlightLogoWrapper, LayoverLine,
    LayoverWrapper,
    ProgressIcon,
    RowItem,
    TimeWrapper
} from "./styles";
import {StyledParagraph, StyledText, StyledTitle} from "../CustomAntdComponents/Typography";
import {ReactComponent as FlightIcon} from "../../assets/images/flight-on.svg";
import moment from "moment";
import {getAirlineLogo} from "../AvailableFlights/helper";
import {getAirportStateName} from "../TripSummary/helper";
import { ReactComponent as ClockIcon } from "../../assets/images/clock-fill.svg";

type Props = {
    flight: FlightDetails,
    airports: Airport[]
}

const FlightCard = ({ flight, airports }: Props) => {
    const totalEntries = flight.FlightEntries.length;

    const renderLayoverDetails = (city: string, arrivalDate: string, leavingDate: string) => {
        const st = moment(arrivalDate);
        const ed = moment(leavingDate);
        const duration = moment.duration(ed.diff(st));
        const hours = duration.asHours().toFixed(0);
        const minutes = duration.asMinutes() % 60;

        const time = [];
        if (parseInt(hours) > 0) {
            time.push(`${hours}h`);
        }
        if (minutes > 0) {
            time.push(`${minutes}m`);
        }

        return (
            <LayoverWrapper>
                <StyledParagraph size="normal">
                    {
                       `${time.join(" ")} in ${city}`
                    }
                </StyledParagraph>
                <LayoverLine />
                <ClockIcon />
            </LayoverWrapper>
        )
    }

    return (
        <FlightDetailsContainer>
            {
                flight.FlightEntries.map((entry, index) => {
                    return (
                        <div  key={index}>
                            <FlightDetails>
                                <RowItem>
                                    <TimeWrapper>
                                        <StyledTitle level={5}>
                                            {moment(entry.DepartureDate).format('hh:mm a')}
                                        </StyledTitle>
                                    </TimeWrapper>

                                    <ProgressIcon>
                                        <CircleIcon />
                                    </ProgressIcon>

                                    <FlightDetailsWrapper>
                                        <StyledParagraph size="large">
                                            {`${getAirportStateName(airports, entry.DepartureAirportCode)}  (${entry.DepartureAirportCode})`}
                                        </StyledParagraph>
                                        <StyledParagraph>
                                            {entry.DepartureAirportName}
                                        </StyledParagraph>
                                    </FlightDetailsWrapper>
                                </RowItem>

                                <RowItem>
                                    <TimeWrapper>
                                        <StyledParagraph>
                                            {entry.TotalDuration}
                                        </StyledParagraph>
                                    </TimeWrapper>

                                    <ProgressIcon flight>
                                        <FlightIcon />
                                    </ProgressIcon>

                                    <FlightDetailsWrapper>
                                        <div>
                                            <FlightLogoWrapper>
                                                <img
                                                    src={getAirlineLogo(entry.OperatingAirlineCode)}
                                                    alt={entry.OperatingAirlineCode}
                                                />
                                            </FlightLogoWrapper>

                                            <StyledText>
                                                {entry.OperatingAirlineName}
                                            </StyledText>
                                            <StyledText>
                                                &nbsp;| {entry.FlightClass}
                                            </StyledText>
                                        </div>
                                        <StyledParagraph size="small">
                                            {entry.FlightNumber} | {entry.Aircraft}
                                        </StyledParagraph>
                                    </FlightDetailsWrapper>
                                </RowItem>

                                <RowItem>
                                    <TimeWrapper>
                                        <StyledTitle level={5}>
                                            {moment(entry.ArrivalDate).format('hh:mm a')}
                                        </StyledTitle>
                                    </TimeWrapper>

                                    <ProgressIcon>
                                        <CircleIcon />
                                    </ProgressIcon>

                                    <FlightDetailsWrapper>
                                        <StyledParagraph size="large">
                                            {`${getAirportStateName(airports, entry.ArrivalAirportCode)}  (${entry.ArrivalAirportCode})`}
                                        </StyledParagraph>
                                        <StyledParagraph>
                                            {entry.ArrivalAirportName}
                                        </StyledParagraph>
                                    </FlightDetailsWrapper>
                                </RowItem>
                            </FlightDetails>

                            {
                                index < totalEntries - 1 && renderLayoverDetails(
                                    getAirportStateName(airports, entry.ArrivalAirportCode),
                                    entry.ArrivalDate, flight.FlightEntries[index + 1].DepartureDate)
                            }
                        </div>

                    )
                })
            }
        </FlightDetailsContainer>
    );
};

export default FlightCard;
