import React from 'react';
import { Input } from 'antd';
import { Wrapper } from './styles';
import {TextAreaProps} from "antd/lib/input";
const { TextArea } = Input;

function StyledTextarea( props: TextAreaProps) {

  return (
    <Wrapper>
        <TextArea {...props} />
    </Wrapper>
  );
}

export default StyledTextarea;
