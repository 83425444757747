import React, {useEffect, useMemo, useRef, useState} from 'react';
import { Button, Col, Drawer, Row, Form, Modal } from 'antd';
import {
    DesktopContentWrapper,
    SectionCard,
    SectionCardBody,
    SectionCardHeading,
    PriceModalContentWrapper,
    Wrapper,
    FooterActionWrapper,
    FooterActionItemWrapper,
    MobilePassengerDataWrapper,
    ContactWrapper,
    MobileContentBody, MobileContentFooter, Content,
} from './styles';
import PassengerHeaderNavigation from "../../components/HeaderNavigation/Passenger";
import FlightItinerarySummary from "../../components/FlightItinerary/FlightItinerarySummary";
import {StyledParagraph, StyledTitle, StyledText} from "../../components/CustomAntdComponents/Typography";
import FlightPaymentBreakdown from "../../components/FlightItinerary/FlightPaymentBreakdown";
import { ReactComponent as ArrowRightIcon } from "../../assets/images/caret-right.svg";
import FlightItinerary from "../../components/FlightItinerary";
import PassengerData from "./PassengerData";
import {
    ActionButtonWrapper,
    ActionContentWrapper,
    ActionFareWrapper,
} from "../../components/FlightItinerary/styles";
import useIsMobile from "../../hooks/useIsMobile";
import {ReactComponent as CaretUpIcon } from "../../assets/images/caret-up.svg"
import {ReactComponent as CaretDownIcon } from "../../assets/images/caret-down.svg"
import {ReactComponent as TagIcon } from "../../assets/images/tag.svg"
import {
    CentralizedContent,
    SpaceBetweenAlignContent,
    VerticalAlignContent
} from "../../components/CustomAntdComponents/Utitlities";
import {formatNumberAsCurrency} from "../../utils/helpers";
import {calculateFlightTotalFare} from "../../components/AvailableFlights/helper";
import {useFlightSearchStore} from "../../store/flightSearch/flightSearchStore";
import { useBookingStore } from '../../store/booking/bookingStore';
import ContactForm from "../../components/PassengerForm/ContactForm";
import PromoCodeInput from "../../components/PassengerForm/PromoCodeInput";
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';
import { ReactComponent as EditIcon } from '../../assets/images/pencil.svg';
import { ReactComponent as ContactIcon } from '../../assets/images/contact.svg';
import AlertMessage from '../../components/AlertMessage';
import {
    buildPassengersPayload,
    getInitialFormData,
    savePassengerData
} from './helper';

import {
    StyledButton
} from '../../components/CustomAntdComponents/StyledButton';
import { useI18nContext } from '../../utils/I18n';

import {
    CONFIRM_DOMESTIC_TICKET_URL,
    CONFIRM_TICKET_URL,
} from '../../utils/constants';
import { useStore } from '../../store/global/appStore';
import { postAPI } from '../../utils/api';
import { Link, useNavigate } from 'react-router-dom';
import translations from './translations';
import FlightRequestLoading from "../../components/RequestLoading";
import FlightFareBreakdown from "../../components/FlightItinerary/FlightFareBreakdown";

function PassengerDetails() {
    const navigate = useNavigate();
    const {
        state: { t },
    } = useI18nContext();

    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [openContactDrawer, setOpenContactDrawer] = useState<boolean>(false);
    const [openPromoDrawer, setOpenPromoDrawer] = useState<boolean>(false);
    const [showPromoInput, setShowPromoInput] = useState<boolean>(false);
    const drawerContainerRef = useRef(null);
    const { isMobile } = useIsMobile();
    const { requesting, setRequesting } = useStore();
    const { setReservation } = useBookingStore();
    const [errorMessage, setErrorMessage] = useState<null | string>(null);
    const [errorFields, setErrorFields] = useState([]);
    const [priceUpdatedModal, setPriceUpdateModal] = useState(false);
    const [ticketReservation, setTicketReservation] = useState<ConfirmTicketResponse[] | null>(null);

    const {
        selectedFlight,
        formData: {
            isDomestic,
        },
        updateStoreData
    } = useFlightSearchStore();
    const { totalTicketAmount } = calculateFlightTotalFare(selectedFlight, isDomestic);
    const flightType = isDomestic ?  "domestic" : "international";
    const initialFormData = getInitialFormData();

    const PriceUpdateButtons = (
        <SpaceBetweenAlignContent style={{ padding: "10px" }}>
            <Link to="/search" style={{ width: '100%' }}>
                <StyledButton mode="default" block>
                    {t(translations).cancel}
                </StyledButton>
            </Link>

            <StyledButton
                type="primary"
                block
                onClick={() => {
                    setPriceUpdateModal(false);
                    handleBookTicket();
                }}
            >
                {t(translations).proceed}
            </StyledButton>
        </SpaceBetweenAlignContent>
    );

    const PriceUpdatedContent = ticketReservation ? (
        <PriceModalContentWrapper>
            <StyledParagraph align="center" size="default">
                {t(translations).priceUpdate}
                &nbsp;
                <StyledText color="primary">
                    {`${formatNumberAsCurrency(((ticketReservation[0].OldPrice || 0) + (ticketReservation[1]?.OldPrice || 0)), 'NGN')}`}
                </StyledText>
                {' '}
                {t(translations).to}
                &nbsp;
                <StyledText color="primary">
                    {`${formatNumberAsCurrency(((ticketReservation[0].NewPrice || 0) + (ticketReservation[1]?.NewPrice || 0)), 'NGN')}`}
                </StyledText>
            </StyledParagraph>
        </PriceModalContentWrapper>
    ) : <div>&nbsp;</div>;

    const buildBookingPayload = (data: any) => {
        if (data.length === undefined) {
            return data;
        }
        const [departure, arrival] = data;

        return { departure, arrival };
    };

    const handleBookTicket = async (payload?: any) => {
        const bookingDetails = buildBookingPayload(payload || ticketReservation);
        let bookingData;

        const { departure, arrival } = bookingDetails;
        const TicketingDetails: any = [
            {
                Price: departure.NewPrice,
                ConfirmationCode: departure.ConfirmationCode,
                Hash: departure.Hash,
            },
        ];

        if(arrival) {
            TicketingDetails.push({
                Price: arrival.NewPrice,
                ConfirmationCode: arrival.ConfirmationCode,
                Hash: arrival.Hash,
            });
        }

        bookingData = {
            TripsType: flightType,
            TripsMode: 'flight',
            Hash: departure.Hash,
            PassengerDetails: departure.PassengerDetails,
            TravelInformations: departure.TravelInformations,
            TicketingDetails,
        };

        setReservation(bookingData, 'bookingPayload');
        navigate('/payment/flight');
    };

    const handleConfirmTicket = async (payload: any) => {
        setErrorMessage(null);
        setRequesting(true);

        const confirmURL = isDomestic ? CONFIRM_DOMESTIC_TICKET_URL : CONFIRM_TICKET_URL;
        const confirmTicket = await postAPI(confirmURL, payload);
        setRequesting(false);

        if (confirmTicket.status === 200) {
            const confirmTicketData = confirmTicket.data.length === undefined ? [confirmTicket.data] : confirmTicket.data;
            const oldPrice = confirmTicketData[0].OldPrice;
            const newPrice = confirmTicketData[0].NewPrice;

            setTicketReservation(confirmTicketData);
            if ((oldPrice < newPrice) && payload.PromoCode) {
                await handleBookTicket(confirmTicketData);
            } else if (oldPrice === newPrice) {
                await handleBookTicket(confirmTicketData);
            } else {
                setRequesting(false);
                setPriceUpdateModal(true);
            }
        } else {
            setErrorMessage(confirmTicket.data);
            window.scrollTo(0, 0);
        }
    };

    const handleOnFinish = (values: any) => {
        setErrorMessage(null);
        setErrorFields([]);

        if (selectedFlight) {
            const fullPhoneData = values.contactPhone.split(',');
            const bookingContact = {
                email: values.contactEmail.trim(),
                phone: fullPhoneData[0],
                country: fullPhoneData[1] || initialFormData.country,
            }

            if(Boolean(values['adults'][0].savePassengerData)) {
                savePassengerData({
                    ...values.adults[0],
                    contactEmail: bookingContact.email,
                    contactPhone: bookingContact.phone,
                    country: bookingContact.country,
                });
            }

            const payload = buildPassengersPayload(values, selectedFlight, bookingContact, isDomestic);
            updateStoreData({
                passengers: payload.AirTravellers,
                bookingContact
            })
            handleConfirmTicket(payload);
        }
    };
    const [form] = Form.useForm();

    const hasContactError = useMemo(() => {
        return errorFields.some((field) => field.name[0] === 'contactEmail' || field.name[0] === 'contactPhone');
    }, [errorFields]);


    const watchContactEmail =  Form.useWatch('contactEmail', form);
    const watchContactPhone =  Form.useWatch('contactPhone', form);
    const watchPromoCode =  Form.useWatch('promoCode', form);

    const hasContactDetails = useMemo(() => {
        return !!(watchContactEmail && watchContactPhone);
    }, [watchContactPhone, watchContactEmail]);


    if(requesting) {
        return <FlightRequestLoading booking />
    }

    return (
        <>
            <div ref={drawerContainerRef}>
                    {
                        isMobile ? (
                            <Form
                                onFinish={handleOnFinish}
                                initialValues={initialFormData}
                                form={form}
                                onFinishFailed={({errorFields}) => {
                                    setErrorFields(errorFields);
                                }}
                                layout="vertical"
                            >
                                <Wrapper>
                                    <Content>
                                        <Row>
                                            <Col span={24}>
                                                <PassengerHeaderNavigation />
                                            </Col>
                                        </Row>
                                    <MobileContentBody>
                                        {
                                            errorFields.length > 0 && !errorMessage && <AlertMessage message="Please add all required information" closable={false} />
                                        }
                                        {
                                            errorFields.length === 0 && errorMessage && <AlertMessage message={errorMessage} closable={false} />
                                        }
                                            <Row>
                                                <Col span={24}>
                                                    <div style={{ marginBottom: "10px" }}>
                                                        <StyledTitle level={4}>
                                                            Add passenger information
                                                        </StyledTitle>
                                                    </div>

                                                    <MobilePassengerDataWrapper>
                                                        <PassengerData form={form} errorFields={errorFields} onSetErrorFields={setErrorFields} />
                                                    </MobilePassengerDataWrapper>
                                                </Col>
                                            </Row>

                                            <Drawer
                                                title="Contact Details"
                                                placement="bottom"
                                                onClose={() => setOpenContactDrawer(false)}
                                                open={openContactDrawer}
                                                forceRender
                                                footer={<StyledButton type="primary" block onClick={() => {
                                                    form.validateFields(['contactEmail', 'contactPhone']).then(() => {
                                                        setOpenContactDrawer(false);
                                                        setErrorFields((prev) => prev.filter((field) => field.name[0] !== 'contactEmail' && field.name[0] !== 'contactPhone'));
                                                    }).catch(() => {});
                                                }}>
                                                    Confirm
                                                </StyledButton>}
                                                getContainer={document.getElementById('app-container') as HTMLElement}
                                            >
                                                <ContactForm />
                                            </Drawer>

                                            <Drawer
                                                title="Promo code"
                                                height={250}
                                                placement="bottom"
                                                onClose={() => setOpenPromoDrawer(false)}
                                                open={openPromoDrawer}
                                                forceRender
                                                footer={<StyledButton type="primary" block onClick={() => setOpenPromoDrawer(false)}>Apply</StyledButton>}
                                                getContainer={document.getElementById('app-container') as HTMLElement}
                                            >
                                                <div>
                                                    <PromoCodeInput />
                                                </div>
                                            </Drawer>
                                    </MobileContentBody>
                                    <MobileContentFooter>
                                        <FooterActionWrapper>
                                            <FooterActionItemWrapper onClick={() => setOpenContactDrawer(true)}>
                                                <ContactWrapper error={hasContactError} hasContactDetails={hasContactDetails}>
                                                    {
                                                        hasContactDetails ? (
                                                            <>
                                                                <div>
                                                                    <VerticalAlignContent>
                                                                        <ContactIcon />
                                                                        <StyledParagraph size="normal">
                                                                            Contact details
                                                                        </StyledParagraph>
                                                                    </VerticalAlignContent>
                                                                    <StyledParagraph>
                                                                        {`Email: ${watchContactEmail}`}
                                                                    </StyledParagraph>
                                                                    <StyledParagraph>
                                                                        {`Mobile: ${watchContactPhone.split(',')[0]}`}
                                                                    </StyledParagraph>
                                                                </div>

                                                                <EditIcon height={16} width={16} />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <VerticalAlignContent>
                                                                    <ContactIcon />
                                                                    <StyledParagraph size="default">
                                                                        Add contact details <span>(Compulsory)</span>
                                                                    </StyledParagraph>
                                                                </VerticalAlignContent>

                                                                <PlusIcon height={14} width={14} />
                                                            </>
                                                        )
                                                    }
                                                </ContactWrapper>
                                            </FooterActionItemWrapper>

                                            <FooterActionItemWrapper onClick={() => setOpenPromoDrawer(true)}>
                                                {
                                                    watchPromoCode ? (
                                                        <>
                                                            <div>
                                                                <VerticalAlignContent>
                                                                    <TagIcon />
                                                                    <StyledParagraph size="normal">
                                                                        Promo Code
                                                                    </StyledParagraph>
                                                                </VerticalAlignContent>
                                                                <StyledParagraph size="normal">
                                                                    {watchPromoCode}
                                                                </StyledParagraph>
                                                            </div>

                                                            <EditIcon height={16} width={16} />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <VerticalAlignContent>
                                                                <TagIcon />
                                                                <StyledParagraph size="default">
                                                                    Add promo code <span>(If any)</span>
                                                                </StyledParagraph>
                                                            </VerticalAlignContent>

                                                            <PlusIcon height={14} width={14} />
                                                        </>
                                                    )
                                                }

                                            </FooterActionItemWrapper>
                                        </FooterActionWrapper>
                                        <ActionFareWrapper>
                                            <SpaceBetweenAlignContent>
                                                <StyledParagraph size="normal">
                                                    Total for all passengers
                                                </StyledParagraph>
                                                <CentralizedContent style={{ width: '100%', justifyContent: 'flex-end' }}>
                                                    <StyledTitle level={3}>
                                                        {formatNumberAsCurrency(totalTicketAmount || 0, selectedFlight[0]?.Currency)}
                                                        &nbsp;
                                                    </StyledTitle>
                                                    <CaretUpIcon width={17} height={17} onClick={() => setOpenDrawer(true)} />
                                                </CentralizedContent>
                                            </SpaceBetweenAlignContent>
                                            <ActionContentWrapper style={{ marginTop: "15px" }}>
                                                <ActionButtonWrapper>
                                                    <Button block size="large" type="primary" htmlType="submit">
                                                        Continue to payment
                                                    </Button>
                                                </ActionButtonWrapper>
                                            </ActionContentWrapper>
                                        </ActionFareWrapper>
                                    </MobileContentFooter>
                                    </Content>
                                </Wrapper>
                            </Form>
                        ) : (
                            <>
                                <Row>
                                    <Col span={24}>
                                        <PassengerHeaderNavigation />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={0} lg={24}>
                                        <DesktopContentWrapper className="container">
                                            {
                                                errorFields.length > 0 && !errorMessage && <AlertMessage message="Please add all required information" closable={false}  />
                                            }
                                            {
                                                errorMessage && <AlertMessage message={errorMessage} closable={false} />
                                            }
                                            <Form
                                                onFinish={handleOnFinish}
                                                onFinishFailed={({errorFields}) => {
                                                    setErrorFields(errorFields);
                                                }}
                                                form={form}
                                                layout="vertical"
                                                initialValues={initialFormData}
                                            >
                                                <Row>
                                                    <Col span={13}>
                                                        <SectionCard>
                                                            <SectionCardHeading onClick={() => setOpenDrawer(true)} clickable bordered>
                                                                <StyledTitle level={4}>
                                                                    Trip Itinerary
                                                                </StyledTitle>
                                                                <ArrowRightIcon />
                                                            </SectionCardHeading>
                                                            <SectionCardBody>
                                                                <FlightItinerarySummary />
                                                            </SectionCardBody>
                                                        </SectionCard>

                                                        <SectionCard>
                                                            <SectionCardHeading>
                                                                <StyledTitle level={4}>
                                                                    Add passenger information
                                                                </StyledTitle>
                                                            </SectionCardHeading>
                                                            <SectionCardBody>
                                                                <PassengerData form={form} errorFields={errorFields} onSetErrorFields={setErrorFields} />
                                                            </SectionCardBody>
                                                        </SectionCard>

                                                        <SectionCard>
                                                            <SectionCardHeading>
                                                                <StyledTitle level={4}>
                                                                    Add contact details
                                                                </StyledTitle>
                                                            </SectionCardHeading>
                                                            <SectionCardBody>
                                                                <div style={{ marginBottom: "20px" }}>
                                                                    <StyledParagraph size="default">
                                                                        This is where we will send your booking details to. Please ensure they are correct.
                                                                    </StyledParagraph>
                                                                </div>
                                                                <ContactForm />
                                                            </SectionCardBody>
                                                        </SectionCard>

                                                        <SectionCard>
                                                            <SectionCardHeading onClick={() => setShowPromoInput(!showPromoInput)} clickable>
                                                                <StyledTitle level={4}>
                                                                    Add promo code
                                                                </StyledTitle>
                                                                {
                                                                    showPromoInput ? <CaretUpIcon /> : <CaretDownIcon />
                                                                }
                                                            </SectionCardHeading>
                                                            <SectionCardBody>
                                                                {
                                                                    showPromoInput && <PromoCodeInput />
                                                                }
                                                            </SectionCardBody>
                                                        </SectionCard>
                                                    </Col>
                                                    <Col offset={3} span={8}>
                                                        <FlightPaymentBreakdown />
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </DesktopContentWrapper>
                                    </Col>
                                </Row>
                            </>
                        )
                    }
            </div>

            <Drawer
                title="Trip Summary"
                placement={isMobile ? "bottom" : "right"}
                onClose={() => setOpenDrawer(false)}
                open={openDrawer}
                height="100%"
                width={isMobile ? "100%" : "500px"}
                getContainer={drawerContainerRef.current as HTMLElement}
            >
                <>
                    <div style={{ padding: "0 10px 20px" }}>
                        <StyledParagraph size="default">
                            Here are your trip details
                        </StyledParagraph>
                    </div>
                    <FlightItinerary hidePriceBreakdown />
                    <div style={{ padding: "0 10px" }}>
                        <FlightFareBreakdown hideBreakdown={false} />
                    </div>
                </>
            </Drawer>

            <Modal
                open={priceUpdatedModal}
                closable={false}
                maskClosable={false}
                footer={PriceUpdateButtons}
                title="Price Update"
                onCancel={() => setPriceUpdateModal(false)}
                centered
                getContainer={document.getElementById('app-container') as HTMLElement}
            >
                {PriceUpdatedContent}
            </Modal>
        </>
    );
}

export default PassengerDetails;
