export const flightClassOptions = (t: any) : DataOption[] => ([
  {
    id: 1,
    name: t.economy,
    value: 'Economy',
  },
  {
    id: 1,
    name: t.premiumEconomy,
    value: 'Premium economy',
  },
  {
    id: 2,
    name: t.businessClass,
    value: 'Business',
  },
  {
    id: 3,
    name: t.firstClass,
    value: 'First Class',
  },
]);

export const SAVED_SEARCH_LIMIT = 3;
