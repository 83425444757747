import React, { useState, useEffect, useMemo } from 'react';
import {
  Wrapper,
} from './styles';
import {
  useFlightSearchStore,
} from '../../store/flightSearch/flightSearchStore';
import FlightDetails from "./FlightDetails";
import {useStore} from "../../store/global/appStore";


type Props = {
  results?: FlightAggregateResultItem,
  onBookingRedirect: (arg: AirlineResultItem[]) => void
  selectedFlights: AirlineResultItem[],
  onFlightSelect: (arg: AirlineResultItem[]) => void
  cheapestFares: {
    cheapestDeparture: number | null,
    cheapestReturn: number | null
  }
}
function AvailableFlights({
                            results, onBookingRedirect,
                            selectedFlights, onFlightSelect, cheapestFares
                          }: Props) {
  const {
    formData,
  } = useFlightSearchStore();
  const { airports } = useStore();

  const resultLists = useMemo<FlightResultItem[]>(() => (results ? results.ResultList : []), [results]);
  const departureFlights: AirlineResultItem[] = resultLists[0]?.ResultList || [];
  const [returnFlights, setReturnFlights] = useState<AirlineResultItem[]>(resultLists[1]?.ResultList || []);
  const cheapestDeparture = cheapestFares?.cheapestDeparture;
  const cheapestReturn = cheapestFares?.cheapestReturn;
  const isOneWay = formData.tripType === 'one_way';

  const handleSelectFlight = (result: AirlineResultItem[]) => {
    if(isOneWay) {
      onBookingRedirect(result);
      return;
    }

    if (!formData.isDomestic) {
        onFlightSelect(result);
        onBookingRedirect(result);
        return;
    }

    if (selectedFlights.length === 0) {
      onFlightSelect(result);
    } else {
      let selections = [...selectedFlights];
      selections[1] = result[0];
      onFlightSelect(selections);
      onBookingRedirect(selections);
    }
  };

  useEffect(() => {
    setReturnFlights(resultLists[1]?.ResultList || []);
  }, [resultLists]);

  return (
      <Wrapper>
        {formData.isDomestic ? (
                <>
                  {selectedFlights.length === 0 && departureFlights.map((result, index) => (
                      <FlightDetails
                          key={index}
                          result={result}
                          onlyDepartureSelected={!isOneWay}
                          handleSelectFlight={handleSelectFlight}
                          cheapestFare={cheapestDeparture}
                          airports={airports}
                      />
                  ))}

                  {selectedFlights.length > 0 && returnFlights.map((result, index) => (
                      <FlightDetails
                          key={index}
                          result={result}
                          onlyDepartureSelected={false}
                          handleSelectFlight={handleSelectFlight}
                          cheapestFare={cheapestReturn}
                          airports={airports}
                      />
                  ))}
                </>
            )
            : (
               <>
                 {departureFlights.map((result, index) => (
                       <FlightDetails
                           key={index}
                           result={result}
                           onlyDepartureSelected={false}
                           handleSelectFlight={handleSelectFlight}
                           cheapestFare={cheapestDeparture}
                           airports={airports}
                       />
                 ))}
               </>
            )}

      </Wrapper>

  );
}

AvailableFlights.defaultProps = {
  loading: false,
  error: undefined,
  results: undefined,
};
export default AvailableFlights;
