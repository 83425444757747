import React from 'react';
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import {FlightDetail, FlightIconWrapper, ItemWrapper, Wrapper} from "./syles";
import { ReactComponent as FlightIcon } from "../../assets/images/flight-takeoff-grey.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/images/arrow-right.svg";
import { ReactComponent as DirectionIcon } from "../../assets/images/direction-hr.svg";
import {FlightSearchStore} from "../../store/flightSearch/types";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {useFlightSearchStore} from "../../store/flightSearch/flightSearchStore";

type FlightSearchProps = {
    data: FlightSearchStore[];
}

const FlightSearch = ({ data }: FlightSearchProps) => {
    const navigate = useNavigate();
    const {
        updateFormData,
    } = useFlightSearchStore();

    const searchItems = data;
    if(searchItems.length === 0) return null;

    const tripTypeName =(trip: string) => {
        switch (trip) {
            case 'round_trip':
                return 'Round trip';
            case 'one_way':
                return 'One way';
            case 'multi_city':
                return 'Multi city';
            default:
                return '';
        }
    };

    const handleContinueSearch = (item: FlightSearchStore, index: number) => {
        let cities = item.multiCity ? item.multiCity : null;
        const reformattedCities = cities?.map((city: any) => {
            return {
                ...city,
                DepartureDate: moment(city.DepartureDate)
            }
        });

        updateFormData({
            ...item,
            DepartureDate: moment(item.DepartureDate),
            ReturnDate: item.ReturnDate ? moment(item.ReturnDate) : null,
            multiCity: reformattedCities
        });

        navigate('/search-listings');
    }

    const renderLocationCode = (item: FlightSearchStore) => {
        const isMultiCity = item.tripType === 'multi_city' && item.multiCity?.length > 0;

        if(isMultiCity) {
           return item.multiCity[0].OriginLocationCode
        }

        return item.OriginLocationCode;
    }

    const renderArrivalCode = (item: FlightSearchStore) => {
        const isMultiCity = item.tripType === 'multi_city' && item.multiCity?.length > 0;

        if(isMultiCity) {
           return item.multiCity[0].DestinationLocationCode
        }

        return item.DestinationLocationCode;
    }

    const renderDepartureDate = (item: FlightSearchStore) => {
        const isMultiCity = item.tripType === 'multi_city' && item.multiCity?.length > 0;

        if(isMultiCity) {
           return item.multiCity[0].DepartureDate
        }

        return item.DepartureDate;
    }

    const renderArrivalDate = (item: FlightSearchStore) => {
        const isMultiCity = item.tripType === 'multi_city' && item.multiCity?.length > 0;

        if(isMultiCity) {
           return item.multiCity[item.multiCity.length - 1].DepartureDate
        }

        return item.ReturnDate;
    }

    return (
            <Wrapper className="container">
                {
                    searchItems.map((item: FlightSearchStore, index: number) => {
                        return (
                            <ItemWrapper key={index} onClick={() => handleContinueSearch(item, index)}>
                                <FlightIconWrapper>
                                    <FlightIcon />
                                </FlightIconWrapper>

                                <StyledParagraph size="normal">
                                    {tripTypeName(item.tripType)}
                                </StyledParagraph>

                                <FlightDetail>
                                    <StyledTitle level={3}>
                                        {renderLocationCode(item)}
                                    </StyledTitle>

                                    {
                                        item.ReturnDate ? (
                                            <DirectionIcon width={15} height={15} />
                                        ) : (
                                            <RightArrowIcon width={15} height={15} />
                                        )
                                    }

                                    <StyledTitle level={3}>
                                        {renderArrivalCode(item)}
                                    </StyledTitle>
                                </FlightDetail>

                                {
                                    item.tripType === 'one_way' ? (
                                        <StyledParagraph size="normal">
                                            {moment(item.DepartureDate).format('ddd, DD MMM')}
                                        </StyledParagraph>
                                    ) :  <StyledParagraph size="normal">
                                        {moment(renderDepartureDate(item)).format('ddd, DD MMM')} - {moment(renderArrivalDate(item)).format('ddd, DD MMM')}
                                    </StyledParagraph>
                                }
                            </ItemWrapper>
                        )
                    })
                }
            </Wrapper>
    );
};

export default FlightSearch;
