import React from 'react';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/arrow-left.svg';

import {
  BackIconWrapper, Wrapper
} from './styles';
import {StyledTitle} from "../../CustomAntdComponents/Typography";


type Props = {
  navigateHandler: () => void
}
function PassengerMobileHeader({ navigateHandler }: Props) {
  return (
      <>
        <Wrapper>
          <BackIconWrapper onClick={navigateHandler}>
            <ArrowLeftIcon width={20} height={20} />
          </BackIconWrapper>

          <div style={{ flex: 1 }}>
              <StyledTitle align="center" level={4}>
                  Passenger details
              </StyledTitle>
          </div>
        </Wrapper>
      </>
  );
}

PassengerMobileHeader.defaultProps = {
  name: undefined,
  navigateHandler: undefined,
};

export default PassengerMobileHeader;
