import React from 'react';
import {
    Form, Row, Col, Button,
} from 'antd';
import {validateRoundTripDates} from "../../FlightSearch/helper";
import translations from "../../../screens/Search/translations";
import StyledDatePicker from "../../CustomAntdComponents/StyledDatePicker/DatePicker";
import {getDisabledDateRange} from "../../../utils/helpers";
import {useI18nContext} from "../../../utils/I18n";
import {useStore} from "../../../store/global/appStore";
import {
    AirportFieldsWrapper,
    DepartureWrapper,
    SwitcherWrapper,
    TripRadioGroupWrapper
} from "../../FlightSearch/styles";
import StyledRadioGroup from "../../CustomAntdComponents/StyledRadioGroup";
import {ReactComponent as DirectionIcon} from "../../../assets/images/direction-hr.svg";
import StyledDateRangePicker from "../../CustomAntdComponents/StyledDateRangePicker";
import {tripModes} from "../../../screens/Search/constant";
import {Moment} from "moment";
import {RoadSearchStore} from "../../../store/roadSearch/types";
import LocationSelector from "../../RouteSelector";
import {getSelectOptions} from "../../RoadSearch/helper";

type Props = {
    onSubmit: () => void;
    payload: RoadSearchStore;
    onChange: (arg) => void;
}
const SearchDesktop = ({ onSubmit, onChange, payload }: Props) => {
    const [form] = Form.useForm();
    const {
        state: { t },
    } = useI18nContext();
    const {
        TripType, DepartureId, ArrivalId, DepartureDate, ArrivalDate,
        numberOfAdults,
    } = payload
    const { roadRoutes } = useStore();

    const isRoundTrip = TripType === 'round_trip';

    const formInitialValues = {
        TripType, DepartureId, ArrivalId, DepartureDate, ArrivalDate,
        numberOfAdults, BusDateRange: [DepartureDate, ArrivalDate]
    };

    const validateDestination = (_: any, value: any) => {
        if (!value) {
            return Promise.reject(new Error(t(translations).validations.destination));
        }

        if (form.getFieldValue('DepartureId') === value) {
            return Promise.reject(new Error(t(translations).validations.invalidDestination));
        }

        return Promise.resolve();
    };

    const handleSwitchLocation = () => {
        const tempDeparture = form.getFieldValue('DepartureId');
        const tempDestination = form.getFieldValue('ArrivalId');

        onChange({
            DepartureId: tempDestination,
            ArrivalId: tempDeparture,
        });

        form.setFieldsValue({
            DepartureId: tempDestination,
            ArrivalId: tempDeparture,
        });
    }

    const onFormInputChange = (value: any, name: string) => {
        onChange({
            [name]: value,
        });

        form.setFieldsValue({
            [name]: value,
        });
    };

    const onDateRangeChange = (values: [Moment, Moment]) => {
        let data = {};
        if(!values) {
            data = {DepartureDate: "", ArrivalDate: "" }
        } else {
            data = { DepartureDate: values[0], ArrivalDate: values[1] }
        }

        onChange(data);
        form.setFieldsValue(data);
    }

    const onFinish = () => {
        onSubmit();
    };

    const flightModes = tripModes(t(translations));
    const departureRouteOptions = getSelectOptions(roadRoutes.filter((route) => route.IsDeparture));
    const arrivalRouteOptions = getSelectOptions(roadRoutes.filter((route) => route.IsArrival));

    return (
        <Form
            wrapperCol={{ span: 24 }}
            layout="horizontal"
            initialValues={formInitialValues}
            form={form}
            size="large"
            onFinish={onFinish}
        >

            <Row gutter={[8, 8]}>
                <Col span={24}>
                    <TripRadioGroupWrapper>
                        <StyledRadioGroup options={flightModes} value={TripType} onChange={(e) => onFormInputChange(e.target.value, 'TripType')} />
                    </TripRadioGroupWrapper>
                </Col>

                <Col span={16}>
                    <AirportFieldsWrapper>
                        <Row gutter={[16, 0]}>
                            <Col span={12}>
                                <DepartureWrapper>
                                    <Form.Item
                                        name="DepartureId"
                                        rules={[{ required: true, message: t(translations).validations.departure }]}
                                    >
                                        <LocationSelector
                                            label={t(translations).inputs.fromLabel}
                                            placeholder={t(translations).inputs.fromPlaceholder}
                                            value={DepartureId}
                                            options={departureRouteOptions}
                                            icon="circle"
                                            onChange={(value) => onFormInputChange(value, 'DepartureId')}
                                            tripType="road"
                                            limitSuggestionHeight
                                        />
                                    </Form.Item>
                                    <SwitcherWrapper onClick={handleSwitchLocation}>
                                        <DirectionIcon />
                                    </SwitcherWrapper>
                                </DepartureWrapper>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="ArrivalId"
                                    rules={[
                                        {
                                            validator: validateDestination,
                                        },
                                    ]}
                                >
                                    <LocationSelector
                                        label={t(translations).inputs.toLabel}
                                        placeholder={t(translations).inputs.toPlaceholder}
                                        value={ArrivalId}
                                        options={arrivalRouteOptions}
                                        icon="location"
                                        onChange={(value) => onFormInputChange(value, 'ArrivalId')}
                                        tripType="road"
                                        limitSuggestionHeight
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </AirportFieldsWrapper>
                </Col>

                <Col span={8}>
                    {
                        isRoundTrip ? (
                            <Form.Item
                                name="BusDateRange"
                                rules={[
                                    { validator: validateRoundTripDates }
                                ]}
                            >
                                <StyledDateRangePicker
                                    value={[DepartureDate, ArrivalDate]}
                                    onChange={onDateRangeChange}
                                    disabledDate={getDisabledDateRange}
                                    placeholder={[t(translations).inputs.departureDate, t(translations).inputs.arrivalDate]}
                                />
                            </Form.Item>
                        ) : (
                            <Form.Item
                                name="DepartureDate"
                                rules={[{ required: true, message: t(translations).validations.departureDate }]}
                            >
                                <StyledDatePicker
                                    label={t(translations).inputs.departureDate}
                                    value={DepartureDate}
                                    disabledDate={getDisabledDateRange}
                                    onChange={(value) => onFormInputChange(value, 'DepartureDate')}/>
                            </Form.Item>
                        )
                    }
                </Col>

                <Col
                    span={6}
                    offset={18}
                >
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        block
                    >
                        Modify search
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default SearchDesktop;
