import React, {useRef, useState} from 'react';
import { Button, Col, Drawer, Row, Form, Modal } from 'antd';
import {
  DesktopContentWrapper,
  MobileContentWrapper, SectionCard, SectionCardBody, SectionCardHeading, PriceModalContentWrapper,
  Wrapper, FooterActionWrapper, FooterActionItemWrapper,
} from './styles';
import PassengerHeaderNavigation from "../../components/HeaderNavigation/Passenger";
import RoadItinerarySummary from "../../components/RoadItineraryBreakdown/RoadItinerarySummary";
import {StyledParagraph, StyledTitle, StyledText} from "../../components/CustomAntdComponents/Typography";
import FlightPaymentBreakdown from "../../components/FlightItinerary/FlightPaymentBreakdown";
import RoadPaymentBreakdown from "../../components/RoadItineraryBreakdown/RoadPaymentBreakdown";
import { ReactComponent as ArrowRightIcon } from "../../assets/images/caret-right.svg";
// import FlightItinerary from "../../components/FlightItinerary";
import PassengerData from "./PassengerData";
import {ActionButtonWrapper, ActionContentWrapper, ActionWrapper} from "../../components/FlightItinerary/styles";
import useIsMobile from "../../hooks/useIsMobile";
import {ReactComponent as CaretUpIcon } from "../../assets/images/caret-up.svg"
import {ReactComponent as CaretDownIcon } from "../../assets/images/caret-down.svg"
import {ReactComponent as TagIcon } from "../../assets/images/tag.svg"
import {
  CentralizedContent,
  SpaceBetweenAlignContent,
  VerticalAlignContent
} from "../../components/CustomAntdComponents/Utitlities";
import {formatNumberAsCurrency} from "../../utils/helpers";
import { useBookingStore } from '../../store/booking/bookingStore';
import ContactForm from "../../components/PassengerForm/ContactForm";
import PromoCodeInput from "../../components/PassengerForm/PromoCodeInput";
import ModalContent from '../../components/ModalContent';
import StyledDrawer from '../../components/CustomAntdComponents/StyledDrawer';
import { ReactComponent as PayIcon } from '../../assets/images/pay.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';
import { ReactComponent as ContactIcon } from '../../assets/images/contact.svg';
import AlertMessage from '../../components/AlertMessage';
import {buildRoadPayload} from './helper';

import {
  StyledButton
} from '../../components/CustomAntdComponents/StyledButton';
import { useI18nContext } from '../../utils/I18n';

import {
  CONFIRM_DOMESTIC_TICKET_URL,
  CONFIRM_TICKET_URL, ROAD_CONFIRM_TICKET_URL,
} from '../../utils/constants';
import { useStore } from '../../store/global/appStore';
import { postAPI } from '../../utils/api';
import { Link, useNavigate } from 'react-router-dom';
import translations from './translations';
import FlightRequestLoading from "../../components/RequestLoading";
import {useRoadSearchStore} from "../../store/roadSearch/roadSearchStore";
import {calculateTotalFare} from "../../components/AvailableBuses/helper";

function PassengerDetails() {
  const navigate = useNavigate();
  const {
    state: { t },
  } = useI18nContext();

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openContactDrawer, setOpenContactDrawer] = useState<boolean>(false);
  const [openPromoDrawer, setOpenPromoDrawer] = useState<boolean>(false);
  const [showPromoInput, setShowPromoInput] = useState<boolean>(false);
  const drawerContainerRef = useRef(null);
  const { isMobile } = useIsMobile();
  const { requesting, setRequesting } = useStore();
  const { setReservation } = useBookingStore();
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [errorFields, setErrorFields] = useState([]);
  const [priceUpdatedModal, setPriceUpdateModal] = useState(false);
  const [priceUpdatedDialog, setPriceUpdateDialog] = useState(false);
  const [ticketReservation, setTicketReservation] = useState<ConfirmTicketResponse | null>(null);

  const store = useRoadSearchStore();

  const {
    formData: {
      numberOfAdults
    },
    selectedBus,
    updateStoreData
  } = store;

  const { totalAmount } = calculateTotalFare(selectedBus, numberOfAdults);

  const PriceUpdateButtons = (
      <SpaceBetweenAlignContent>
        <Link to="/search" style={{ width: '100%' }}>
          <StyledButton mode="secondary" block>
            {t(translations).cancel}
          </StyledButton>
        </Link>

        <StyledButton
            type="primary"
            block
            onClick={() => {
              setPriceUpdateDialog(false);
              setPriceUpdateModal(false);
              handleBookTicket();
            }}
        >
          {t(translations).proceed}
        </StyledButton>
      </SpaceBetweenAlignContent>
  );

  const PriceUpdatedContent = ticketReservation ? (
      <PriceModalContentWrapper>
        <StyledParagraph align="center" size="middle">
          {t(translations).priceUpdate}
          &nbsp;
          <StyledText color="primary">
            {`${formatNumberAsCurrency(ticketReservation.OldPrice || 0, 'NGN')}`}
          </StyledText>
          {' '}
          {t(translations).to}
          &nbsp;
          <StyledText color="primary">
            {`${formatNumberAsCurrency(ticketReservation.NewPrice || 0, 'NGN')}`}
          </StyledText>
        </StyledParagraph>
      </PriceModalContentWrapper>
  ) : <div>&nbsp;</div>;

  const buildBookingPayload = (data: any) => {
    if (data.length === undefined) {
      return data;
    }
    const [departure, arrival] = data;

    return { departure, arrival };
  };

  const handleBookTicket = async (payload?: any) => {
    const { PassengerDetails, NewPrice, ConfirmationCode } = payload || ticketReservation;

    const bookingData = {
      TripsType: '',
      TripsMode: 'road',
      PassengerDetails,
      TicketingDetails: [
        {
          NewPrice,
          ConfirmationCode,
        },
      ],
    };

    setReservation(bookingData, 'bookingPayload');
    navigate('/payment/road');
  };

  const handleConfirmTicket = async (payload: any) => {
    setErrorMessage(null);
    setRequesting(true);

    const confirmTicket = await postAPI(ROAD_CONFIRM_TICKET_URL, payload);
    setRequesting(false);

    if (confirmTicket.status === 200) {
      setTicketReservation(confirmTicket.data);
      if ((confirmTicket.data.OldPrice < confirmTicket.data.NewPrice) && payload.PromoCode) {
        await handleBookTicket(confirmTicket.data);
      } else if (confirmTicket.data.OldPrice === confirmTicket.data.NewPrice) {
        await handleBookTicket(confirmTicket.data);
      } else if (isMobile) {
        setRequesting(false);
        setPriceUpdateDialog(true);
      } else {
        setRequesting(false);
        setPriceUpdateModal(true);
      }
    } else {
      setErrorMessage(confirmTicket.data);
      window.scrollTo(0, 0);
    }
  };

  const handleOnFinish = (values: any) => {
    setErrorMessage(null);
    setErrorFields([]);

    if (selectedBus) {
      const fullPhoneData = values.contactPhone.split(',');
      const bookingContact = {
        email: values.contactEmail,
        phone: fullPhoneData[0],
        country: fullPhoneData[1],
      }

      const payload = buildRoadPayload(values, store, bookingContact);
      console.log(payload, 'payload');
      return;

      updateStoreData({
        passengers: payload.Travellers,
        bookingContact
      })
      handleConfirmTicket(payload);
    }
  };
  const [form] = Form.useForm();

  if(requesting) {
    return <FlightRequestLoading booking />
  }

  return (
      <Wrapper ref={drawerContainerRef}>
        <Row>
          <Col span={24}>
            <PassengerHeaderNavigation />
          </Col>
        </Row>
        {
          isMobile ? (
              <Row>
                <Col span={24}>
                  <MobileContentWrapper>
                    {
                        errorFields.length > 0 && !errorMessage && <AlertMessage message="Please add all required information" />
                    }
                    {
                        errorMessage && <AlertMessage message={errorMessage} />
                    }

                    <Form
                        onFinish={handleOnFinish}
                        form={form}
                        onFinishFailed={({errorFields}) => {
                          setErrorFields(errorFields);
                        }}
                        layout="vertical"
                    >
                      <Row>
                        <Col span={24}>
                          <div style={{ marginBottom: "30px" }}>
                            <StyledTitle level={3}>
                              Add passenger information
                            </StyledTitle>
                          </div>

                          <PassengerData form={form} errorFields={errorFields}  onSetErrorFields={setErrorFields} />
                        </Col>
                        <Col span={24}>

                        </Col>
                      </Row>
                    </Form>

                  </MobileContentWrapper>
                </Col>
              </Row>
          ) : (
              <Row>
                <Col span={24}>
                  <DesktopContentWrapper className="container">
                    {
                        errorFields.length > 0 && !errorMessage && <AlertMessage message="Please add all required information" closable={false}  />
                    }
                    {
                        errorMessage && <AlertMessage message={errorMessage} closable={false} />
                    }

                    <Form
                        onFinish={handleOnFinish}
                        onFinishFailed={({errorFields}) => {
                          setErrorFields(errorFields);
                        }}
                        form={form}
                        layout="vertical"
                    >

                      <Row>
                        <Col span={13}>
                          <SectionCard>
                            <SectionCardHeading bordered>
                              <StyledTitle level={4}>
                                Trip Itinerary
                              </StyledTitle>
                            </SectionCardHeading>
                            <SectionCardBody>
                              <RoadItinerarySummary />
                            </SectionCardBody>
                          </SectionCard>

                        </Col>
                        <Col offset={3} span={8}>
                          <RoadPaymentBreakdown />
                        </Col>
                      </Row>
                    </Form>
                  </DesktopContentWrapper>
                </Col>
              </Row>
          )
        }
      </Wrapper>
  )
  return (
      <Wrapper ref={drawerContainerRef}>
        <Row>
          <Col span={24}>
            <PassengerHeaderNavigation />
          </Col>
        </Row>


        {/*  <= XS Devices */}
        {
          isMobile ? (
              <Row>
                <Col xs={24} lg={0}>
                  <MobileContentWrapper>
                    {
                        errorFields.length > 0 && !errorMessage && <AlertMessage message="Please add all required information" />
                    }
                    {
                        errorMessage && <AlertMessage message={errorMessage} />
                    }
                    <Form
                        onFinish={handleOnFinish}
                        form={form}
                        onFinishFailed={({errorFields}) => {
                          setErrorFields(errorFields);
                        }}
                        layout="vertical"
                    >
                      <Row>
                        <Col span={24}>
                          <div style={{ marginBottom: "30px" }}>
                            <StyledTitle level={3}>
                              Add passenger information
                            </StyledTitle>
                          </div>

                          <PassengerData form={form} errorFields={errorFields} onSetErrorFields={setErrorFields} />
                        </Col>
                        <Col span={24}>
                          <FooterActionWrapper>
                            <FooterActionItemWrapper onClick={() => setOpenContactDrawer(true)}>
                              <VerticalAlignContent>
                                <ContactIcon />
                                <StyledParagraph size="extra">
                                  Add contact details <span>(Compulsory)</span>
                                </StyledParagraph>
                              </VerticalAlignContent>

                              <PlusIcon height={16} width={16} />
                            </FooterActionItemWrapper>

                            <FooterActionItemWrapper>
                              <VerticalAlignContent onClick={() => setOpenPromoDrawer(true)}>
                                <TagIcon />
                                <StyledParagraph size="extra">
                                  Add promo code <span>(If any)</span>
                                </StyledParagraph>
                              </VerticalAlignContent>

                              <PlusIcon height={16} width={16} />
                            </FooterActionItemWrapper>
                          </FooterActionWrapper>

                          <ActionWrapper>
                            <SpaceBetweenAlignContent>
                              <StyledParagraph>
                                Total for all passengers
                              </StyledParagraph>
                              <CentralizedContent>
                                <StyledTitle level={3}>
                                  {formatNumberAsCurrency(totalAmount || 0, selectedBus[0]?.Currency)}
                                  &nbsp;
                                </StyledTitle>
                                <CaretUpIcon width={17} height={17} onClick={() => setOpenDrawer(true)} />
                              </CentralizedContent>
                            </SpaceBetweenAlignContent>
                            <ActionContentWrapper style={{ marginTop: "15px" }}>
                              <ActionButtonWrapper>
                                <Button block size="large" type="primary" htmlType="submit">
                                  Continue to payment
                                </Button>
                              </ActionButtonWrapper>
                            </ActionContentWrapper>
                          </ActionWrapper>
                        </Col>
                      </Row>

                      <Drawer
                          title="Contact Details"
                          placement="bottom"
                          onClose={() => setOpenContactDrawer(false)}
                          open={openContactDrawer}
                          forceRender
                          footer={<StyledButton type="primary" block onClick={() => setOpenContactDrawer(false)}>Confirm</StyledButton>}
                          getContainer={document.getElementById('app-container') as HTMLElement}
                      >
                        <ContactForm />
                      </Drawer>

                      <Drawer
                          title="Promo code"
                          height={250}
                          placement="bottom"
                          onClose={() => setOpenPromoDrawer(false)}
                          open={openPromoDrawer}
                          footer={<StyledButton type="primary" block onClick={() => setOpenPromoDrawer(false)}>Apply</StyledButton>}
                          getContainer={document.getElementById('app-container') as HTMLElement}
                      >
                        <div>
                          <PromoCodeInput />
                        </div>
                      </Drawer>
                    </Form>
                  </MobileContentWrapper>
                </Col>
              </Row>
          ) : (
              <Row>
                <Col xs={0} lg={24}>
                  <DesktopContentWrapper className="container">
                    {
                        errorFields.length > 0 && !errorMessage && <AlertMessage message="Please add all required information" closable={false}  />
                    }
                    {
                        errorMessage && <AlertMessage message={errorMessage} closable={false} />
                    }
                    <Form
                        onFinish={handleOnFinish}
                        onFinishFailed={({errorFields}) => {
                          setErrorFields(errorFields);
                        }}
                        form={form}
                        layout="vertical"
                    >
                      <Row>
                        <Col span={13}>
                          <SectionCard>
                            <SectionCardHeading onClick={() => setOpenDrawer(true)} clickable bordered>
                              <StyledTitle level={4}>
                                Trip Itinerary
                              </StyledTitle>
                              <ArrowRightIcon />
                            </SectionCardHeading>
                            <SectionCardBody>
                              <h3>Itinearay summary</h3>
                              {/*<FlightItinerarySummary />*/}
                            </SectionCardBody>
                          </SectionCard>

                          <SectionCard>
                            <SectionCardHeading>
                              <StyledTitle level={4}>
                                Add passenger information
                              </StyledTitle>
                            </SectionCardHeading>
                            <SectionCardBody>
                              <PassengerData form={form} errorFields={errorFields} onSetErrorFields={setErrorFields} />
                            </SectionCardBody>
                          </SectionCard>

                          <SectionCard>
                            <SectionCardHeading>
                              <StyledTitle level={4}>
                                Add contact details
                              </StyledTitle>
                            </SectionCardHeading>
                            <SectionCardBody>
                              <div style={{ marginBottom: "20px" }}>
                                <StyledParagraph size="default">
                                  This is where we will send your booking details to. Please ensure they are correct.
                                </StyledParagraph>
                              </div>
                              <ContactForm />
                            </SectionCardBody>
                          </SectionCard>

                          <SectionCard>
                            <SectionCardHeading onClick={() => setShowPromoInput(!showPromoInput)} clickable>
                              <StyledTitle level={4}>
                                Add promo code
                              </StyledTitle>
                              {
                                showPromoInput ? <CaretUpIcon /> : <CaretDownIcon />
                              }
                            </SectionCardHeading>
                            <SectionCardBody>
                              {
                                  showPromoInput && <PromoCodeInput />
                              }
                            </SectionCardBody>
                          </SectionCard>
                        </Col>
                        <Col offset={3} span={8}>
                          <FlightPaymentBreakdown />
                        </Col>
                      </Row>
                    </Form>
                  </DesktopContentWrapper>
                </Col>
              </Row>
          )
        }

        <Drawer
            title="Flight Details"
            placement={isMobile ? "bottom" : "right"}
            onClose={() => setOpenDrawer(false)}
            open={openDrawer}
            getContainer={drawerContainerRef.current as HTMLElement}
        >
          <h3>Itinearay breakdown</h3>

          {/*<FlightItinerary hidePriceBreakdown />*/}
        </Drawer>

        <Modal
            centered
            closable={false}
            maskClosable={false}
            open={priceUpdatedModal}
            footer={null}
            onCancel={() => setPriceUpdateModal(false)}
            width={400}
            getContainer={document.getElementById('app-container') as HTMLElement}
        >
          <ModalContent Icon={<PayIcon />} title={t(translations).priceUpdateTitle}>
            {ticketReservation && (
                <>
                  {PriceUpdatedContent}
                  {PriceUpdateButtons}
                </>
            )}
          </ModalContent>

        </Modal>

        <StyledDrawer
            open={priceUpdatedDialog}
            onClose={() => setPriceUpdateDialog(false)}
            footer={PriceUpdateButtons}
        >
          <ModalContent bordered Icon={<PayIcon />} title={t(translations).priceUpdateTitle}>
            {PriceUpdatedContent}
          </ModalContent>
        </StyledDrawer>
      </Wrapper>
  );
}

export default PassengerDetails;
