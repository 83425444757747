import React from 'react';
import { Card, Col, Row } from 'antd';
import Header from '../../components/Header';
import { Wrapper } from './styles';
import HeaderNavigation from '../../components/HeaderNavigation';
import { TripSummaryWrapper } from '../SearchRoadListings/styles';
import RoadTripSummary from '../../components/TripSummary/RoadTripSummary';
import SeatSelection from '../../components/SeatSelection';
import { GiveMeSpace } from '../../components/CustomAntdComponents/Utitlities';
import BusItinerary from '../../components/BusItinerary';
import { StyledTitle } from '../../components/CustomAntdComponents/Typography';
import { useI18nContext } from '../../utils/I18n';
import translations from '../PassengerDetails/translations';

function BusSeatSelection() {
  const {
    state: { t },
  } = useI18nContext();
  return (
    <>
      <Row>
        <Col xs={0} lg={24}>
          <Header />
        </Col>
      </Row>

      <div className="container">
        <Wrapper>
          <Row>
            <Col xs={24} lg={0}>
              <HeaderNavigation name={t(translations).seatSelection} />
            </Col>
            <Col xs={0} lg={24}>
              <HeaderNavigation />
            </Col>
          </Row>

          <Row gutter={32}>
            <Col xs={24} lg={0}>
              <Card size="small">
                <TripSummaryWrapper>
                  <RoadTripSummary />
                </TripSummaryWrapper>
              </Card>
              <GiveMeSpace />
            </Col>
            <Col xs={24} lg={16}>
              <StyledTitle level={4}>
                {t(translations).seatSelectionTitle}
              </StyledTitle>
              <GiveMeSpace />
              <SeatSelection />
            </Col>
            <Col xs={0} lg={8}>
              <StyledTitle level={4}>
                {t(translations).itineraryTitle}
              </StyledTitle>
              <GiveMeSpace />
              <BusItinerary showSubmitButton={false} />
            </Col>
          </Row>
        </Wrapper>
      </div>
    </>
  );
}

export default BusSeatSelection;
