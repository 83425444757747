import React from 'react';
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import {FlightDetail, FlightIconWrapper, ItemWrapper, Wrapper} from "./syles";
import { ReactComponent as BusIcon } from "../../assets/images/bus-grey.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/images/arrow-right.svg";
import { ReactComponent as DirectionIcon } from "../../assets/images/direction-hr.svg";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {useRoadSearchStore} from "../../store/roadSearch/roadSearchStore";
import {RoadSearchStore} from "../../store/roadSearch/types";
import {useStore} from "../../store/global/appStore";
import {getTerminalName} from "../TripSummary/helper";

export const roadStorageKey = 'roadSearchData'

type RoadSearchProps = {
    data: RoadSearchStore[];
}

const RoadSearch = ({ data }: RoadSearchProps) => {
    const navigate = useNavigate();
    const {
        updateFormData,
    } = useRoadSearchStore();
    const {
        roadRoutes,
    } = useStore();

    const searchItems = data;
    if(searchItems.length === 0) return null;

    const tripTypeName =(trip: string) => {
        switch (trip) {
            case 'round_trip':
                return 'Round trip';
            case 'one_way':
                return 'One way';
            default:
                return '';
        }
    };

    const handleContinueSearch = (item: RoadSearchStore, index: number) => {
        updateFormData({
            ...item,
            DepartureDate: moment(item.DepartureDate),
            ArrivalDate: item.ArrivalDate ? moment(item.ArrivalDate) : null,
        });

        navigate('/search-road-listings');
    }

    const renderLocationCode = (item: RoadSearchStore) => {
        return getTerminalName(roadRoutes, item.DepartureId);
    }

    const renderArrivalCode = (item: RoadSearchStore) => {
        return getTerminalName(roadRoutes, item.ArrivalId);
    }

    const renderDepartureDate = (item: RoadSearchStore) => {
        return item.DepartureDate;
    }

    const renderArrivalDate = (item: RoadSearchStore) => {
        return item.ArrivalDate;
    }

    return (
            <Wrapper className="container">
                {
                    searchItems.map((item: RoadSearchStore, index: number) => {
                        return (
                            <ItemWrapper key={index} onClick={() => handleContinueSearch(item, index)}>
                                <FlightIconWrapper>
                                    <BusIcon />
                                </FlightIconWrapper>

                                <StyledParagraph size="normal">
                                    {tripTypeName(item.TripType)}
                                </StyledParagraph>

                                <FlightDetail>
                                    <StyledTitle level={3}>
                                        {renderLocationCode(item)}
                                    </StyledTitle>

                                    {
                                        item.ArrivalDate ? (
                                            <DirectionIcon width={15} height={15} />
                                        ) : (
                                            <RightArrowIcon width={15} height={15} />
                                        )
                                    }

                                    <StyledTitle level={3}>
                                        {renderArrivalCode(item)}
                                    </StyledTitle>
                                </FlightDetail>

                                {
                                    item.TripType === 'one_way' ? (
                                        <StyledParagraph size="normal">
                                            {moment(item.DepartureDate).format('ddd, DD MMM')}
                                        </StyledParagraph>
                                    ) :  <StyledParagraph size="normal">
                                        {moment(renderDepartureDate(item)).format('ddd, DD MMM')} - {moment(renderArrivalDate(item)).format('ddd, DD MMM')}
                                    </StyledParagraph>
                                }
                            </ItemWrapper>
                        )
                    })
                }
            </Wrapper>
    );
};

export default RoadSearch;
