import styled, { css } from 'styled-components';
import {BLACK, FONT_SIZE, GREY, GREY_10, GREY_4, PRIMARY, WHITE} from '../../utils/colors';
import device from '../../utils/mediaQuery';
import {StyledParagraph, StyledText, StyledTitle} from "../CustomAntdComponents/Typography";
import { lighten } from 'polished'

export const HeaderDivider = styled.div`
  border: 1px solid #EEEEEE;
  margin-bottom: 25px;
`;

export const Wrapper = styled.div`
  margin-top: 15px;

  ${device.large} {
    margin-top: 0;
  }
  .ant-select {
    width: 100%;
  }
`;

export const ClearWrapper = styled.div`
    text-align: right;
  
      ${StyledText} {
        cursor: pointer;
      }
`;

export const AirlineCheckboxWrapper = styled.div`
  label.ant-checkbox-wrapper {
    color: #92949E;
  }
`;

export const PillItem = styled.span<{ active?: boolean }>`
  display: inline-block;
  padding: 5px 30px;
  border: 1px solid #EBE9FF;
  margin-left: 5px;
  margin-top: 20px;
  color: #92949E;
  border-radius: 5px;
  font-size: calc(10px *  ${FONT_SIZE});
  cursor: pointer;
  
  ${({ active }) => active && css`
    background: ${PRIMARY};
    color: #fff;
  `};
`;

export const RangeSelectorWrapper = styled.div`
  .ant-slider-mark {
    .ant-slider-mark-text {
      font-weight: bold;
      font-size: calc(10px *  ${FONT_SIZE});
      line-height: 12px;
      color: #92949E;
      
      transform: translateX(0) !important;
      
      &:last-of-type {
        transform: translateX(-100%) !important;
      }
    }
  }
  
  .ant-slider-handle {
    border: solid 2px ${PRIMARY};
  }

  .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: ${PRIMARY};
  }
  .ant-slider-track {
    background: ${PRIMARY};
  }

  .ant-slider:hover .ant-slider-track {
    background: ${lighten(0.2, PRIMARY)};
  }
`;

export const PillWrapper = styled.div`
  column-gap: 10px;
  margin: 5px 0 0;
  background: #fff;
  padding: 0;
  border-radius: 5px;
  display: inline-flex;
`;

export const PillItemWrapper = styled.div<{ disabled?: boolean, active?: boolean}>`
  border-radius: 5px;
  text-align: center;
  transition: all 0.2s ease-in;
  font-weight: 600;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  padding: 15px 20px;
  flex: 1;
  background: #fff;
  border: solid 1px ${GREY_10};
  

  ${({ active }) => active && css`
    background: ${PRIMARY};
    color: #fff;
  `}
`;

// ListingFilter desktop styles
export const FilterHeading = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const FilterLinkText = styled(StyledTitle)`
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
`;

export const CollapseWrapper = styled.div`
  margin-top: 15px;
  
  .ant-collapse-expand-icon {
    svg {
      path {
        fill: ${BLACK};
      }
    }
  }

  .ant-collapse-content, .ant-collapse {
    border: none;
    background: none;
  }
  .ant-collapse > .ant-collapse-item {
    border: none;
    background: none;
  }
  
  .ant-collapse-content-box {
    padding-top: 0;
    padding-left: 0;
  }
  
  .ant-collapse-header-text {
    font-weight: 700;
    font-size: 16px;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    align-items: center;
    padding-left: 0;
  }
`;

export const PillRadioGroup= styled.div`
    display: flex;
    column-gap: 10px;
    align-items: center;
`;

export const PillRadioItemIcon = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: solid 1px ${GREY};
`;

export const PillRadioItem = styled.div<{ active: boolean }>`
    background: #fff;
    padding: 10px 18px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    border: 1px solid ${GREY_4};
    cursor: pointer;
  
    ${({ active }) => active && css`
        ${PillRadioItemIcon} {
          border: solid 3px ${PRIMARY};
        }
    `}
`;


// ListingFilter mobile styles

export const FilterWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 10px;
`;

export const FilterItem = styled.div<{ active: boolean }>`
    background: ${WHITE};
    border: 1px solid ${GREY_4};
    padding: 8px 16px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    column-gap: 7px;
  
    svg {
      display: none;
    }
  
    ${({ active }) => active && css`
        background: ${PRIMARY};
        border-color: ${PRIMARY};
        color: ${WHITE};
      
        svg {
            display: inline-block;
        }
    `}
`;

export const DrawerContainer = styled.div`
  padding: 0 15px 30px;
`;

export const DrawerFilterContent = styled.div`
  max-height: 400px;
`;

export const DrawerTitle = styled(StyledParagraph)`
  padding-bottom: 20px;
  font-size: 16px;
`;
