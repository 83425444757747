import React from "react";
import PhoneInput, { CountryData } from "react-phone-input-2";
import en from "react-phone-input-2/lang/de.json";
import "react-phone-input-2/lib/material.css";
import { Wrapper, HelperText, HelperTextContainer } from "./styles";

type Props = {
    value?: string;
    placeholder: string;
    onChange?: (
        value: string,
        country: string,
    ) => void;
    hasError?: boolean;
    name: string;
    helperText?: string;
    phoneOnly?: boolean;
};
const PhoneNumberInput: React.FC<Props> = ({
                                               placeholder,
                                               hasError,
                                               onChange,
                                               name,
                                               helperText,
                                               value,
                                               phoneOnly
                                           }) => {
    const handleChange = (
        value: string,
        data: CountryData,
        event_: React.ChangeEvent<HTMLInputElement>,
    ) => {
        if(phoneOnly && onChange ) {
            onChange(`+${value}`, data.name)
        } else if(onChange) {
            // TODO: Fix better way too pass the country code
            onChange(`+${value},${data.countryCode}`, data.name);
        }
    };

    return (
        <Wrapper $error={hasError} data-testid="phone-input">
            <PhoneInput
                inputProps={{
                    name,
                }}
                value={value}
                country={"ng"}
                placeholder={placeholder}
                onChange={handleChange}
                enableTerritories={true}
                preferredCountries={["ng", "ke" ,"uk"]}
                preserveOrder={["preferredCountries"]}
                countryCodeEditable={false}
                specialLabel={placeholder}
                localization={en}
            />
            {hasError && helperText && (
                <HelperTextContainer>
                    <HelperText>{helperText}</HelperText>
                </HelperTextContainer>
            )}
        </Wrapper>
    );
};

export default PhoneNumberInput;
