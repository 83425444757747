/* eslint-disable react/no-array-index-key */
import React, {useEffect, useMemo} from 'react';
import { useFlightSearchStore } from '../../store/flightSearch/flightSearchStore';
import FlightCard from "./FlightCard";
import {useNavigate} from "react-router-dom";
import {useStore} from "../../store/global/appStore";
import FlightHeading from "./FlightHeading";
import {ContentWrapper} from "./styles";
import FlightPriceBreakdown from "./FlightPriceBreakdown";
import useIsMobile from "../../hooks/useIsMobile";

type Props = {
  hidePriceBreakdown?: boolean
}

function FlightItinerary({ hidePriceBreakdown }: Props) {
  const { isMobile } = useIsMobile();
  const {
    selectedFlight,
    formData: {
      tripType,
      isDomestic,
        numberOfAdults,
        numberOfChildren,
        numberOfInfants,
    },
  } = useFlightSearchStore();
  const { airports } = useStore();

  const numberOfPassengers = useMemo(() => {
    return numberOfAdults + (numberOfChildren || 0) + (numberOfInfants || 0);
  }, [numberOfAdults, numberOfChildren, numberOfInfants]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedFlight) {
      navigate('/search/flight');
    }
  }, [selectedFlight, navigate]);

  const isRoundTrip = tripType === 'round_trip';
  const isMultiCity = tripType === 'multi_city';
  const departureItinerary = isMultiCity ? selectedFlight[0].FlightDetails : [selectedFlight[0].FlightDetails[0]];
  const arrivalItinerary = selectedFlight && selectedFlight.length == 2 ? selectedFlight[1].FlightDetails[0] : null;

  const showPriceBreakDown = isMobile && !hidePriceBreakdown;

  return (
      <>
        <ContentWrapper showPriceBreakDown={showPriceBreakDown}>
          <div>
            {
              !isDomestic && (isMultiCity || isRoundTrip) ? (
                  selectedFlight[0].FlightDetails.map((flightDetails, index) => (
                      <div key={index}>
                        <FlightHeading airports={airports} flight={flightDetails} numberOfPassengers={numberOfPassengers} />
                        <FlightCard key={index} flight={flightDetails} airports={airports} />
                      </div>
                  ))) :
                  (<>
                      {
                          departureItinerary && (<>
                            <FlightHeading airports={airports} flight={departureItinerary[0]} numberOfPassengers={numberOfPassengers} />
                            {departureItinerary?.map((flightDetails: FlightDetails, index) => (
                                <FlightCard key={index} flight={flightDetails} airports={airports} />
                            ))}
                          </>)
                      }

                      {
                          isRoundTrip && arrivalItinerary && (
                              <>
                                <FlightHeading airports={airports} flight={selectedFlight[1].FlightDetails[0]} />
                                <FlightCard flight={arrivalItinerary} airports={airports} />
                              </>
                          )
                      }
                    </>)
            }
          </div>
        </ContentWrapper>

        {
          (isMobile && !hidePriceBreakdown) && <FlightPriceBreakdown />
        }
      </>
  );
}
export default FlightItinerary;
