import React from 'react';
import { Row, Col } from 'antd';
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import {ListItem} from "./styles";
import moment from "moment";
import {useRoadSearchStore} from "../../store/roadSearch/roadSearchStore";

const PassengerList = () => {
    const {
        passengers,
    } = useRoadSearchStore();

    if(!passengers){
        return <></>
    }

    return (
        <>
            {passengers.map((p, key) => (
                <ListItem key={key}>
                    <Row>
                        <Col span={12}>
                            <StyledParagraph>
                                Name
                            </StyledParagraph>
                            <StyledTitle level={5}>
                                {p.FirstName} {p.LastName}
                            </StyledTitle>
                        </Col>
                    </Row>
                </ListItem>

            ))}
        </>

    );
};

export default PassengerList;
