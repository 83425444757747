import React from 'react';
import { Typography } from 'antd';
import { TabItemWrapper, TabWrapper } from './styles';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/Search/translations';
import { tripModes } from '../../screens/Search/constant';

type Props = {
  mode: string,
  onTripTypeChange: (arg: string) => void
}
const { Text } = Typography;

function RoadModes({ mode, onTripTypeChange }: Props) {
  const {
    state: { t },
  } = useI18nContext();
  return (
    <TabWrapper>
      {
        tripModes(t(translations)).map((tab) => (tab.value === mode ? (
          <TabItemWrapper
            key={tab.id}
            active
            onClick={() => onTripTypeChange(tab.value)}
          >
            <Text>
              {tab.name}
            </Text>
          </TabItemWrapper>
        )
          : (
            <TabItemWrapper
              key={tab.id}
              active={tab.value === mode}
              onClick={() => onTripTypeChange(tab.value)}
            >
              <Text
                type="secondary"
              >
                {tab.name}
              </Text>
            </TabItemWrapper>
          )))
      }
    </TabWrapper>
  );
}

export default RoadModes;
