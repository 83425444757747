import React, {ReactNode, useEffect, useMemo, useRef, useState} from 'react';
import {Popover} from "antd";
import {useStore} from "../../../../store/global/appStore";
import {debounce} from "../../../../utils/helpers";
import { ReactComponent as FlightIcon } from "../../../../assets/images/flight-takeoff.svg";
import { ReactComponent as FlightLandIcon } from "../../../../assets/images/flight-land.svg";
import { ReactComponent as LocationIcon } from '../../../../assets/images/location.svg';
import { ReactComponent as CircleIcon } from '../../../../assets/images/circle.svg';
import SelectRoadSearches from "../../../RouteSelector/SelectRoadSearches";
import SelectSearches from "../../../RouteSelector/SelectSearches";
import styled from "styled-components";
import StyledInput from "../../../CustomAntdComponents/StyledInput/Input";

type Props = {
    label: string,
    defaultValue?: string,
    onChange?: (value: any) => void;
    suffixIcon?: ReactNode,
    value?: string | number,
    icon: 'takeoff' | 'land' | 'circle' | 'location',
    tripType: 'flight' | 'road'
 }

const renderIcon = (icon: string): ReactNode => {
    if (icon === 'takeoff') {
        return <FlightIcon />;
    } else if (icon === 'land') {
        return <FlightLandIcon />;
    }  else if (icon === 'location') {
        return <LocationIcon />;
    }  else if (icon === 'circle') {
        return <CircleIcon />;
    } else {
        return <FlightIcon />;
    }
}

const ModifyRouteSelector = ({ value, label, onChange, icon, tripType }: Props) => {
    const popoverContainerRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [text, setText] = useState("");
    const [searchText, setSearchText] = useState("");

    const { roadRoutes, airports} = useStore();

    const debouncedSetSearchText = debounce(setSearchText, 300);

    const displayValue = useMemo(() => {
        if(tripType === "road"){
            return roadRoutes.find((option) => option.TerminalId.toString() === value)?.TerminalName;
        }

        const airport = airports.find((option) => option.Code === value);

        if(airport){
            return `${airport.Code}, ${airport.StateName}`;
        }

        if(value) {
            return value.toString();
        }

        return '';
    }, [value, airports, roadRoutes]);

    const handleSelect = (val: any) => {
        onChange(val);
        setIsOpen(false);

        if(val === value) {
            setText(displayValue);
        }
    }

    const  popoverContent =  tripType === "road" ? (
        <PopoverContent>
            <SelectRoadSearches
                onSelect={handleSelect}
                searchText={searchText}
            />
        </PopoverContent>
    ) : (
        <PopoverContent>
            <SelectSearches
                onSelect={handleSelect}
                searchText={searchText}
            />
        </PopoverContent>
    )

    useEffect(() => {
        if(displayValue) {
            setText(displayValue);
        }
    }, [displayValue]);

    return (
        <PopoverWrapper ref={popoverContainerRef}>
            <Popover
                placement="bottomLeft"
                content={popoverContent}
                overlayStyle={{ minWidth: '100%' }}
                open={isOpen}
                getPopupContainer={() => popoverContainerRef.current as HTMLElement}
                onOpenChange={(open) => setIsOpen(open)}
            >
                <div>
                    <StyledInput
                        icon={renderIcon(icon)}
                        label={label}
                        value={text}
                        onChange={(val) => {
                            setText(val);
                            debouncedSetSearchText(val);
                        }}
                        defaultValue={value}
                    />
                </div>
            </Popover>
        </PopoverWrapper>
    );
};

export default ModifyRouteSelector;

const PopoverWrapper = styled.div`
    position: relative;

  .ant-popover-inner-content {
      width: 100% !important;
      overflow-y: auto;
    }
`;

const PopoverContent = styled.div`
    max-height: 30vh;
`;
