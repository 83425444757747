import React from 'react';
import moment from 'moment';
import {
  ContentWrapper,
  InfoWrapper,
  ItineraryWrapper,
  TransactionItem,
  TransactionWrapper,
} from '../../screens/PaymentReceipt/styles';
import bookingIcon from '../../assets/images/bookingSuccess.svg';
import {
  StyledParagraph,
  StyledTitle,
} from '../CustomAntdComponents/Typography';
import { formatNumberAsCurrency } from '../../utils/helpers';
import {
  useFlightSearchStore,
} from '../../store/flightSearch/flightSearchStore';
import {calculateFlightTotalFare, calculateTicketTotal} from '../AvailableFlights/helper';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/PaymentReceipt/translations';
import FlightItinerarySummary from "../FlightItinerary/FlightItinerarySummary";
import {useBookingStore} from "../../store/booking/bookingStore";

function FlightReceipt() {
  const {
    state: { t },
  } = useI18nContext();
  const {
    formData: { isDomestic },
    selectedFlight,
    bookingContact
  } = useFlightSearchStore();

  const {
    bookingPayload
  } = useBookingStore();

  const totalTicketAmount = calculateFlightTotalFare(selectedFlight, isDomestic).totalAmount;
  // Use booking ticket amount to cater for promo discount
  const bookingAmount = bookingPayload ? calculateTicketTotal(bookingPayload.TicketingDetails) : totalTicketAmount

  if(!selectedFlight) {
   return (
       <StyledParagraph>
         {t(translations).noData}
       </StyledParagraph>
   )
  }

  return (
      <ContentWrapper>
        <img src={bookingIcon} alt="booking" width={120} />

        <InfoWrapper>
          <StyledTitle align="center" level={3}>
            Booking Successful
          </StyledTitle>
          <StyledParagraph align="center" size="normal">
            Your booking was successful and ticket details will be sent to &nbsp;
            <strong>{bookingContact?.email}</strong>
          </StyledParagraph>
        </InfoWrapper>

        <ItineraryWrapper>
          <FlightItinerarySummary hideOperator />

          <TransactionWrapper>
            <TransactionItem>
              <StyledParagraph>
                {t(translations).amount}
              </StyledParagraph>
              <StyledTitle level={5}>
                {formatNumberAsCurrency(bookingAmount, selectedFlight[0]?.Currency)}
              </StyledTitle>
            </TransactionItem>

            <TransactionItem>
              <StyledParagraph>
                {t(translations).status}
              </StyledParagraph>
              <StyledTitle level={5}>
                {t(translations).successful}
              </StyledTitle>
            </TransactionItem>

            <TransactionItem>
              <StyledParagraph>
                {t(translations).date}
              </StyledParagraph>
              <StyledTitle level={5}>
                {moment().format('ll')}
              </StyledTitle>
            </TransactionItem>
          </TransactionWrapper>
        </ItineraryWrapper>

      </ContentWrapper>
  );
}

export default FlightReceipt;
