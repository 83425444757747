import styled from 'styled-components';
import device from '../../utils/mediaQuery';
import {GREY_2, WHITE} from "../../utils/colors";

export const Wrapper = styled.div`
  @media ${device.large} {
    padding: 0 0 20px;
  }

  .ant-drawer-title {
    font-size: 20px;
  }

  .ant-modal-footer, .ant-drawer-footer {
    padding: 30px;
  }

  .ant-drawer-content-wrapper {
    height: 92vh !important;
  }

  @media ${device.large} {
    .ant-drawer-content-wrapper {
      width: 520px !important;
      height: unset !important;
    }
  }
`;

export const MobileContentContainer = styled.div`
    padding-bottom: 160px;
    background: ${GREY_2};
`;

export const MobileContentWrapper = styled.div`
  padding: 24px 20px 5px;
  background: ${WHITE};
`;

export const DesktopContentWrapper = styled.div`
  margin-top: 40px;
`;

export const ContentWrapper = styled.div`
  @media ${device.large} {
    padding: 15px;
    background: #fff;
  }
`;

export const ActionContentWrapper = styled.div`
  display: flex;
  column-gap: 40px;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 16px;

  @media ${device.large} {
    display: block;
  }
`;

export const TermsWrapper = styled.div`
  background: ${WHITE};
  padding: 16px;
  margin-bottom: 10px;
`;

export const ActionWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 20px;
  background: ${WHITE};

  @media ${device.large} {
    position: unset;
    border: none;
    padding-top: 10px;
  }
`;

export const Space = styled.div`
  height: 25px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
`;


export const BookingFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
  padding: 16px;
`;
