import React from 'react';
import { useNavigate } from 'react-router-dom';
import useIsMobile from "../../../hooks/useIsMobile";
import FlightDesktopHeader from "./FlightDesktopHeader";
import FlightMobileHeader from "./FlightMobileHeader";
import {FlightSearchStore} from "../../../store/flightSearch/types";


type Props = {
  navigateHandler?: () => void
  modify: boolean,
  onModifyChange: (value: boolean) => void
  refetchSearch: (arg: FlightSearchStore) => void
}
function FlightHeaderNavigation({ navigateHandler, modify, onModifyChange, refetchSearch }: Props) {
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (navigateHandler) {
      navigateHandler();
    } else {
      navigate(-1);
    }
  };

  return (
      isMobile ? (
          <FlightMobileHeader navigateHandler={handleNavigate} modify={modify} onModifyChange={onModifyChange} refetchSearch={refetchSearch} />
      ) : (
          <FlightDesktopHeader navigateHandler={handleNavigate} modify={modify} onModifyChange={onModifyChange} refetchSearch={refetchSearch} />
      )
  )
}

FlightHeaderNavigation.defaultProps = {
  name: undefined,
  navigateHandler: undefined,
};

export default FlightHeaderNavigation;
