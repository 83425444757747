import React from 'react';
import { Image, Col, Row } from 'antd';
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import {HeadingWrapper} from "./styles";
import lagos from '../../assets/images/destinations/lagos.png';
import abuja from '../../assets/images/destinations/abuja.png';
import london from '../../assets/images/destinations/london.png';
import nairobi from '../../assets/images/destinations/nairobi.png';

const PopularDestinations = () => {
    return (
        <>
            <HeadingWrapper>
                <StyledTitle level={3}>
                    Popular Destinations
                </StyledTitle>
                <StyledParagraph>
                    These destinations are picked just for you
                </StyledParagraph>
            </HeadingWrapper>

            <Row gutter={[32, 32]}>
                <Col xs={24} lg={16}>
                    <Image src={lagos} width="100%"/>
                </Col>

                <Col xs={24} lg={8}>
                    <Image src={abuja} width="100%" />
                </Col>

                <Col xs={24} lg={8}>
                    <Image src={nairobi} width="100%" />
                </Col>

                <Col xs={24} lg={16}>
                    <Image src={london} width="100%" />
                </Col>
            </Row>
        </>
    );
};

export default PopularDestinations;
