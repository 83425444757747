/* eslint-disable react/no-array-index-key */
import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import useIsMobile from "../../hooks/useIsMobile";
import {Col, Row} from "antd";
import {
  StepItemContent, StepItemWrapper,
  StepProgressLine,
  StepsContainer,
  StepsWrapper, Tag, TagsWrapper
} from "../AvailableFlights/styles";
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import { ReactComponent as BusIcon } from '../../assets/images/bus-grey.svg';
import {formatNumberAsCurrency} from "../../utils/helpers";
import {useRoadSearchStore} from "../../store/roadSearch/roadSearchStore";
import {getBusTypeImage, getRoadTransportLogo} from "../AvailableBuses/helper";
import {BusCardContent, BusCardImage, BusImage, BusStepWrapper} from "../AvailableBuses/styles";
import {ReactComponent as PassengerIcon} from "../../assets/images/passenger.svg";
import {ReactComponent as ClockIcon} from "../../assets/images/clock.svg";
import styled from "styled-components";
import moment from "moment";

type Props = {
  receipt?: boolean;
}

function RoadItinerarySummary({ receipt }: Props) {
  const { isMobile } = useIsMobile();
  const { selectedBus, selectedBusSeat, selectedResult } = useRoadSearchStore();

  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedBus) {
      navigate('/search/road');
    }
  }, [selectedBus, navigate]);

  const departureBus = selectedBus[0];
  const returningBus = selectedBus && selectedBus.length == 2 ? selectedBus[1] : null;
  const departureOperator = selectedResult[0];
  const returningOperator = selectedResult[1];
  const departureSeats = selectedBusSeat[0];
  const returningSeats = selectedBusSeat[1];

  const renderMobileTripRow = (operator: BusResultItem, bus: AvailableBus, seats: SelectedBusSeat) => {
    return (
       <div>
         <Row>
           <Col span={receipt ? 0 : 6}>
             <BusCardImage>
               <img src={getRoadTransportLogo(operator.AgentName)} alt={operator.AgentName} width={50} />
               <BusImage src={getBusTypeImage(bus.Capacity)} alt="bus" />
             </BusCardImage>
           </Col>

           <Col span={receipt ? 24 : 18}>
             <BusCardContent style={ receipt ? { padding: 0, border: 'none' } : {}}>
               {
                    !receipt && (
                       <StyledTitle level={5}>
                         {bus.BusType}
                       </StyledTitle>
                   )
               }

               <BusStepWrapper>
                 <div>
                   <StyledTitle level={5}>
                     {operator.DepartureTerminal}
                   </StyledTitle>
                   <StyledParagraph size="default">
                     {
                         bus.BoardingDetails && `${bus.BoardingDetails.Name}`
                     }
                   </StyledParagraph>
                 </div>

                 <StepsContainer>
                   <StepsWrapper>
                     <StepProgressLine />
                     <StepItemWrapper>
                       <StepItemContent />
                     </StepItemWrapper>

                     <StepProgressLine />
                   </StepsWrapper>
                 </StepsContainer>

                 <div>
                   <StyledTitle level={5} align="left">
                     {operator.ArrivalTerminal}
                   </StyledTitle>
                   <StyledParagraph size="default">
                     {
                         bus.DropOffDetails && `${bus.DropOffDetails.Name}`
                     }
                   </StyledParagraph>
                 </div>
               </BusStepWrapper>

               <TagsWrapper>
                 <Tag>
                   <ClockIcon />
                   {moment(bus.EstimatedDepartureDate).format('DD MMM, hh:mm A')}
                 </Tag>
                 <Tag>
                   <PassengerIcon />
                   {`Seats selected: ${seats.map(seat => seat.SeatNumber).join(', ')}`}
                 </Tag>
               </TagsWrapper>
             </BusCardContent>
           </Col>
         </Row>

         {
           !receipt && (
                 <Row>
                   <Col span={24}>
                     <StyledTitle level={4} align="right" style={{ padding: "12px" }}>
                       {formatNumberAsCurrency(bus.TotalFare, operator.Currency)}
                     </StyledTitle>
                   </Col>
                 </Row>
             )
         }
       </div>
    )
  }

  const renderTripRow = (operator: BusResultItem, bus: AvailableBus, seats: SelectedBusSeat) => {
    return (
        <Row>
          <Col span={receipt ? 0 : 6}>
            <BusCardImage>
              <img src={getRoadTransportLogo(operator.AgentName)} alt={operator.AgentName} width={50} />
              <BusImage src={getBusTypeImage(bus.Capacity)} alt="bus" />
            </BusCardImage>
          </Col>
          <Col span={receipt ? 24 : 18}>
            <BusCardContent receipt={Boolean(receipt)}>
              {
                !receipt && (
                      <StyledTitle level={3}>
                        {bus.BusType}
                      </StyledTitle>
                  )
              }

              <BusStepWrapper>
                <div>
                  <StyledTitle level={4}>
                    {operator.DepartureTerminal}
                  </StyledTitle>
                  <StyledParagraph size="default">
                    {
                        bus.BoardingDetails && `${bus.BoardingDetails.Name}`
                    }
                  </StyledParagraph>
                </div>

                <StepsContainer>
                  <StepsWrapper>
                    <StepProgressLine />
                    <StepItemWrapper>
                      <StepItemContent>
                        <BusIcon />
                      </StepItemContent>
                    </StepItemWrapper>

                    <StepProgressLine />
                  </StepsWrapper>
                </StepsContainer>

                <div>
                  <StyledTitle level={4} align="left">
                    {operator.ArrivalTerminal}
                  </StyledTitle>
                  <StyledParagraph size="default">
                    {
                        bus.DropOffDetails && `${bus.DropOffDetails.Name}`
                    }
                  </StyledParagraph>
                </div>
              </BusStepWrapper>

              <TagsWrapper receipt={receipt}>
                <Tag>
                  <ClockIcon />
                  {moment(bus.EstimatedDepartureDate).format('MMM DD, YYYY, hh:mm A')}
                </Tag>
                <Tag>
                  <PassengerIcon />
                  {`Seats selected: ${seats.map(seat => seat.SeatNumber).join(', ')}`}
                </Tag>
              </TagsWrapper>
            </BusCardContent>
          </Col>
        </Row>
    )
  }

  return isMobile ? (
      <Wrapper>
        {renderMobileTripRow(departureOperator, departureBus, departureSeats)}

        {
            returningBus && returningOperator && renderMobileTripRow(returningOperator, returningBus, returningSeats)
        }
      </Wrapper>
  ) : (
      <Wrapper>
        {renderTripRow(departureOperator, departureBus, departureSeats)}

        {
            returningBus && returningOperator && renderTripRow(returningOperator, returningBus, returningSeats)
        }
      </Wrapper>
  )
}
export default RoadItinerarySummary;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;
