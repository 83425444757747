import React, {useState, useMemo, useRef} from 'react';
import {
    Button, Popover
} from 'antd';
import {
    DropdownContentWrapper, HelperText,
    InputDisplay, InputTriggerItem,
} from './styles';
import { ReactComponent as PassengerIcon } from '../../assets/images/man.svg';
import { ReactComponent as FlightClassIcon } from '../../assets/images/card.svg';
import StyledDrawer from "../CustomAntdComponents/StyledDrawer";
import CabinOptions from "./CabinOptions";
import PassengerCount from "./PassengerCount";
import { useFlightSearchStore } from "../../store/flightSearch/flightSearchStore";
import { pluralizeText } from "../../utils/helpers";
import useIsMobile from "../../hooks/useIsMobile";
import {FlightSearchStore} from "../../store/flightSearch/types";

type Props = {
  showTitle?: boolean,
  onChange?: (obj) => void;
  payload?: FlightSearchStore;
}

function PassengerCountSelector({ showTitle, onChange, payload }: Props) {
    const {
        formData,
    } = useFlightSearchStore();
    const { isMobile } = useIsMobile();
    const dropdownContainerRef = useRef(null);

    const [openPassengerCount, setOpenPassengerCount] = useState(false);
  const [openFlightClass, setOpenFlightClass] = useState(false);

  const { numberOfAdults, numberOfChildren, numberOfInfants, FlightClass } = payload || formData;

  const numberOfPassengers = useMemo(() => {
    return numberOfAdults + (numberOfChildren || 0) + (numberOfInfants || 0);
  }, [numberOfAdults, numberOfChildren, numberOfInfants]);

  const closeDrawer = () => {
    setOpenPassengerCount(false);
    setOpenFlightClass(false);
  }
  const DrawerFooter = (
      <Button type="primary" block onClick={closeDrawer}>
        Confirm
      </Button>
  )

    const desktopContent = (<DropdownContentWrapper>
        <PassengerCount showTitle={showTitle} onChange={onChange} payload={payload} />
        <CabinOptions onChange={onChange} payload={payload} />
    </DropdownContentWrapper>);

    const getDropdownContainer = (e: HTMLElement) => dropdownContainerRef.current;

    return (
    <div ref={dropdownContainerRef}>
        {
            isMobile ? (
                <InputDisplay>
                    <InputTriggerItem onClick={() => setOpenPassengerCount(true)}>
                        <PassengerIcon />
                        <span>
                              {pluralizeText(numberOfPassengers, "Passenger", "Passengers")}
                        </span>
                    </InputTriggerItem>
                    <InputTriggerItem onClick={() => setOpenFlightClass(true)}>
                          <span>
                            {FlightClass}
                          </span>
                        <FlightClassIcon />
                    </InputTriggerItem>
                </InputDisplay>

            ) : (
                <Popover
                    placement="bottom"
                    trigger="click"
                    content={desktopContent}
                    getPopupContainer={(e) => getDropdownContainer(e)}
                >
                    <InputDisplay>
                        <HelperText>
                            Passenger and cabin class
                        </HelperText>
                        <InputTriggerItem>
                            <span>
                              {`${pluralizeText(numberOfPassengers, "Passenger", "Passengers")}, ${FlightClass}`}
                            </span>
                        </InputTriggerItem>
                    </InputDisplay>
                </Popover>
                )
        }


      <StyledDrawer
          open={openPassengerCount}
          onClose={() => setOpenPassengerCount(false)}
          footer={DrawerFooter}
      >
        <PassengerCount showTitle={showTitle} onChange={onChange} payload={payload} />
      </StyledDrawer>

      <StyledDrawer
          open={openFlightClass}
          onClose={() => setOpenFlightClass(false)}
          footer={DrawerFooter}
      >
        <CabinOptions onChange={onChange} payload={payload} />
      </StyledDrawer>
    </div>
  );
}

PassengerCountSelector.defaultProps = {
  showTitle: true,
};
export default PassengerCountSelector;
