export const numberOfFlightText = (total: number) => (total === 1 ? 'flight' : 'flights');

export const getAirlineLogo = (code: string) => `https://images.kiwi.com/airlines/64x64/${code}.png`;

export const calculateTicketTotal = (tickets: TicketingDetail[]) => {
    return tickets.reduce((acc, item) => (acc + item.Price), 0);
}

export const calculateFlightTotalFare = (selectedFlight: AirlineResultItem[] | null, isDomestic: boolean) => {
  if (!selectedFlight) {
    return { totalCharges: 0, totalTicketAmount: 0, totalAmount: 0 };
  }
  const totalAmount = selectedFlight.reduce((acc, item) => (acc + item.TotalFare), 0);
  const totalCharges = selectedFlight.reduce((acc, item) => (acc + item.TotalServiceCharge), 0);

  return { totalCharges, totalTicketAmount: totalAmount, totalAmount };
};

export const calculateFlightFareBreakdown = (selectedFlight: AirlineResultItem[] | null): FlightPassengerFare[]  => {
  if (!selectedFlight) {
    return [];
  }


  let departurePassengerFares  = selectedFlight[0].PassengerFares;
  let totalFares = [...departurePassengerFares]
  const returningPassengerFares  = selectedFlight[1] ? selectedFlight[1].PassengerFares : null;

  if(returningPassengerFares) {
    totalFares.push(...returningPassengerFares)
  }

  return totalFares;
};
