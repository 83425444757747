import React from 'react';
import { Row, Col } from 'antd';
import MobileHeader from "./MobileHeader";
import DesktopHeader from "./DesktopHeader";

function Header() {
  return (
      <Row>
        <Col xs={24} lg={0}>
          <MobileHeader />
        </Col>
          <Col xs={0} lg={24}>
          <DesktopHeader />
      </Col>
      </Row>
  )
}

Header.defaultProps = {
  hideContent: true,
};
export default Header;
