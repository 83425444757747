import React from 'react';
import {Form} from "antd"
import {PassengerDataWrapper} from "../styles";
import PassengerFormItem from "../../../components/PassengerFormItem";
import {generateRoadCategoryInputs, getPassengerData} from "../helper";
import {useRoadSearchStore} from "../../../store/roadSearch/roadSearchStore";

type Props = {
    form: any,
    errorFields: any,
    onSetErrorFields: (arg: any) => void
}
const RoadPassengerData = ({ form, errorFields, onSetErrorFields }: Props) => {
    const {
        formData: {
            numberOfAdults,
        }
    } = useRoadSearchStore();
    const [usedSavePaxData, setUsedSavePaxData] = React.useState(false);

    const savedPaxData = getPassengerData();
    const adultInputs = generateRoadCategoryInputs(numberOfAdults, savedPaxData);

    const adultsValue = Form.useWatch('adults', form) || [];

    const handleSetPaxData = (data: any) => {
        if(data) {
            setUsedSavePaxData(true);
            form.resetFields(['adults']);
            const adultsPayload = new Array(numberOfAdults).fill({}).map((value, index) => {
                return {
                    ...data,
                    id: index,
                }
            });

            form.setFieldValue('adults', adultsPayload);
        } else {
            const dataFields = form.getFieldValue('adults');
            const newFieldsData = dataFields.map((field: any, index) => {
                if(index === 0) {
                    return {
                        ...field,
                    }
                } else {
                    return {
                        id: index,
                        namePrefix: '',
                        gender: '',
                        firstName: '',
                        lastName: '',
                        email: '',
                        phoneNumber: '',
                        address: '',
                        nextOfKinName: '',
                        nextOfKinEmail: '',
                        nextOfKinPhoneNumber: '+234'
                    }
                }
            });

            form.setFieldValue('adults', newFieldsData);
        }
    }

    return (
            <PassengerDataWrapper>
                {/* Adults */}
                <Form.List name="adults" initialValue={adultInputs}>
                    {(fields) => (
                        <>
                            {fields.map((field, key) => (
                                <PassengerFormItem key={key} field={field} data={adultsValue[key]} form={form} fieldName="adults" errorFields={errorFields} onSetErrorFields={onSetErrorFields} setPaxData={handleSetPaxData} usedSavePaxData={usedSavePaxData} />
                            ))}
                        </>
                    )}
                </Form.List>
            </PassengerDataWrapper>
    );
};

export default RoadPassengerData;
