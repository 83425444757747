import React from 'react';
import {
  Button, Form, Row, Col,
} from 'antd';
import {
  AirportFieldsWrapper, DepartureWrapper,
  FormFieldsWrapper,
  FormWrapper, MobileFormContent, MobileFormContentWrapper, SwitcherWrapper,
} from './styles';
import PassengerCountSelector from '../PassegerSelector';
import FlightModes from './FlightModes';
import translations from '../../screens/Search/translations';
import { useI18nContext } from '../../utils/I18n';
import { getDisabledDateRange } from '../../utils/helpers';
import { MulticityFlightSearchForm } from './MultiCity';
import FlightSearchMobileNav from "./FlighSearchMobileNav";
import AirportSelector from "../RouteSelector";
import { ReactComponent as DirectionIcon } from "../../assets/images/direction.svg";
import StyledDatePicker from "../CustomAntdComponents/StyledDatePicker/DatePicker";

function FlightSearchMobileView(props) {
  const {
    onFormInputChange,
    isMultiCity,
    isRoundTrip,
    airportOptions,
    checkReturningDate,
    disabledReturnDate,
    validateDestination,
    formData,
    MultiCityMaxRoute,
    handleSwitchAirports,
    validateDeparture,
    form
  } = props;

  const {
    state: { t },
  } = useI18nContext();
  const {
    DepartureDate,
    ReturnDate,
    tripType,
    DestinationLocationCode,
    OriginLocationCode,
  } = formData;

  return (
    <MobileFormContentWrapper>
        <FormWrapper>
          {/* will display only smaller screen */}
          <FlightSearchMobileNav />
          <MobileFormContent>
            <FlightModes flightMode={tripType} onTripTypeChange={(value) => onFormInputChange(value, 'tripType')} />
            <FormFieldsWrapper>
              {isMultiCity ? (
                  <MulticityFlightSearchForm
                      airportOptions={airportOptions}
                      MultiCityMaxRoute={MultiCityMaxRoute}
                      form={form}
                  />
              ) : (
                  <Row gutter={8}>
                    <Col xs={24} lg={0}>
                      <AirportFieldsWrapper>
                        <Row gutter={[16, 0]}>
                          <Col xs={24}>
                            <DepartureWrapper>
                              <Form.Item
                                    name="OriginLocationCode"
                                    rules={[
                                      {
                                        validator: validateDeparture,
                                      },
                                    ]}
                                >
                                    <AirportSelector
                                        label={t(translations).inputs.fromLabel}
                                        placeholder={t(translations).inputs.fromPlaceholder}
                                        value={OriginLocationCode}
                                        options={airportOptions}
                                        icon="takeoff"
                                        tripType="flight"
                                        onChange={(value) => onFormInputChange(value, 'OriginLocationCode')}
                                    />
                                </Form.Item>
                              <SwitcherWrapper onClick={handleSwitchAirports}>
                                <DirectionIcon />
                              </SwitcherWrapper>
                            </DepartureWrapper>
                          </Col>

                          <Col span={24}>
                            <Form.Item
                                name="DestinationLocationCode"
                                rules={[
                                  {
                                    validator: validateDestination,
                                  },
                                ]}
                            >
                              <AirportSelector
                                  label={t(translations).inputs.toLabel}
                                  placeholder={t(translations).inputs.toPlaceholder}
                                  value={DestinationLocationCode}
                                  options={airportOptions}
                                  icon="land"
                                  tripType="flight"
                                  onChange={(value) => onFormInputChange(value, 'DestinationLocationCode')}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </AirportFieldsWrapper>

                      <Row gutter={8}>
                        <Col xs={24}>
                          <Form.Item
                              name="DepartureDate"
                              rules={[{ required: true, message: t(translations).validations.departureDate }]}
                          >
                            <StyledDatePicker
                                label={t(translations).inputs.departureDate}
                                value={DepartureDate}
                                disabledDate={getDisabledDateRange}
                                onChange={(value) => onFormInputChange(value, 'DepartureDate')}
                            />
                          </Form.Item>
                        </Col>

                        {
                          isRoundTrip && (
                                <Col xs={24}>
                                  <Form.Item
                                      name="ReturnDate"
                                      rules={[
                                        {
                                          validator: checkReturningDate,
                                        },
                                      ]}
                                  >
                                    <StyledDatePicker
                                        label={t(translations).inputs.arrivalDate}
                                        value={ReturnDate}
                                        disabledDate={disabledReturnDate}
                                        onChange={(value) => onFormInputChange(value, 'ReturnDate')}
                                    />
                                  </Form.Item>
                                </Col>
                            )
                        }
                      </Row>
                    </Col>
                  </Row>
              )}

              <Col xs={24} lg={0}>
                <Form.Item>
                  <PassengerCountSelector />
                </Form.Item>
              </Col>

              <Col xs={24} lg={0}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" size="large" block>
                    {t(translations).searchButton}
                  </Button>
                </Form.Item>
              </Col>
            </FormFieldsWrapper>
          </MobileFormContent>

        </FormWrapper>
    </MobileFormContentWrapper>
  );
}

export default FlightSearchMobileView;
