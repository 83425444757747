import styled, { css } from 'styled-components';
import {StyledText} from "../../CustomAntdComponents/Typography";
import {PRIMARY} from "../../../utils/colors";
import {SwitcherWrapper} from "../styles";

export const ItemWrapper = styled.div<{ active: boolean }>`
  ${({ active }) => active && css`
    padding-bottom: 20px;
    width:100%;
  `}
`;


export const AddItem = styled(StyledText)`
  cursor: pointer;
  color: ${PRIMARY};
  font-size: 16px;
`;

export const MultiCityFormWrapper = styled.div`
  ${SwitcherWrapper} {
    @media (max-width: 991px) {
      bottom: -16px;
    }
  }
`;
