import styled from "styled-components";
import device from '../../utils/mediaQuery';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  
  @media(${device.large}) {
    row-gap: 50px;
  }
`;

export const ReactionsWrapper = styled.div`
  display: flex;
  column-gap: 40px;
  justify-content: center;
`;

export const ReactionIcon= styled.img``;

export const ReactionItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5px;
  cursor: pointer;
`;

export const HeadingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 5px;

  @media(${device.large}) {
    margin-top: 50px;
  }
`;
