import React, { ReactNode, useState } from 'react';
import {Select, SelectProps} from 'antd';
import {PrefixIconWrapper, Wrapper} from './styles';

type Props = {
  onChange?: (value: any) => void;
  prefixIcon?: ReactNode,
  hideLabel?: boolean
  filled?: boolean
} & SelectProps

const { Option } = Select;
function StyledSelect({
  size,
  options,
  defaultValue,
  placeholder,
  onChange, prefixIcon,
  suffixIcon, showSearch, value,
  filled, hideLabel, allowClear
}:Props) {
  const [focus, setFocus] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string | number | undefined>(value);

  const hasValue = focus || (selectedValue) || (!selectedValue && selectedValue === 0) || defaultValue || (!defaultValue && defaultValue === 0);
  let labelClass = hasValue ? 'label as-label' : 'label as-placeholder';

  const handleChange = (input: string | number) => {
    setSelectedValue(input);
    if (onChange) { onChange(input); }
  };

  return (
    <Wrapper
      filled={filled}
      hideLabel={hideLabel}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
      hasPrefix={Boolean(prefixIcon)}
    >
      <Select
        showSearch={showSearch}
        size={size}
        defaultValue={defaultValue}
        style={{ width: '100%' }}
        onChange={handleChange}
        suffixIcon={suffixIcon}
        value={value}
        allowClear={allowClear}
        filterOption={(input, option) => {
          if (option && option.value) {
            return String(option.children)
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
              || String(option.value)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
              || String(option.tags)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0;
          }

          return false;
        }}
      >
        {
          options?.map((option) => (
            <Option
              key={option.value}
              tags={option.tags}
              value={option.value}
              disabled={Boolean(option.disabled)}
            >
              {option.label}
            </Option>
          ))
        }
      </Select>
      <span className={labelClass}>
        {hasValue ? placeholder : placeholder}
      </span>

      {
        prefixIcon && (
          <PrefixIconWrapper>
            {prefixIcon}
          </PrefixIconWrapper>
        )
      }
    </Wrapper>

  );
}

StyledSelect.defaultProps = {
  size: 'large',
  placeholder: undefined,
  defaultValue: undefined,
  suffixIcon: undefined,
  showSearch: false,
  value: undefined,
  onChange: undefined,
  filled: undefined,
};

export default StyledSelect;
