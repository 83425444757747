import React, { useState } from 'react';
import {
    Typography, Modal,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import {
    BannerWrapper, FormWrapper, Wrapper,
} from './styles';
import { StyledButton } from '../CustomAntdComponents/StyledButton';
import SearchBooking from '../SearchBooking';
import banner from '../../assets/images/bg/flight.png';
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";

const { Title } = Typography;
type Props = {
    hideContent?: boolean
}
function DesktopHeader({ hideContent }: Props) {
    const navigate = useNavigate();
    const [bookingModal, setBookingModal] = useState(false);

    // Language handler
    // const handleLangChange = (value: string | number) => {
    //     setLang(value);
    //
    //     dispatch({
    //         type: 'SET_LANGUAGE',
    //         payload: {
    //             langCode: value,
    //         },
    //     });
    // };

    return (
        <Wrapper>
            <BannerWrapper image={banner}>
                <div className="container">
                    <StyledTitle level={1}>
                        Unbeatable flight fares
                    </StyledTitle>
                    <StyledParagraph size="extra">
                        Get millions of cheap flights from just one search
                    </StyledParagraph>
                    {!hideContent && (
                        <StyledButton size="large" mode="primary" padded="large" onClick={() => setBookingModal(true)}>
                            Manage Booking
                        </StyledButton>
                    )}
                </div>
            </BannerWrapper>
            <Modal
            centered
            open={bookingModal}
            footer={null}
            onCancel={() => setBookingModal(false)}
            width={400}
            getContainer={document.getElementById('app-container') as HTMLElement}
             >
                <Title level={4}>Manage Booking</Title>
                <FormWrapper>
                    <SearchBooking filled handleNavigate={(tripType) => navigate(`/my-booking/${tripType}`)} />
                </FormWrapper>
            </Modal>
        </Wrapper>
    );
}

DesktopHeader.defaultProps = {
    hideContent: true,
};
export default DesktopHeader;
