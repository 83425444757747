import React from 'react';
import {FareRuleWrapper} from "../../screens/SearchListings/styles";
import {Empty, Space, Spin} from "antd";
import AlertMessage from "../AlertMessage";
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";

type Props = {
    loadingFareData: boolean,
    fareRulesError: string | null,
    fareRules: RoadFareRule[] | null
}
const FareContent = ({ loadingFareData, fareRules, fareRulesError }: Props) => {
    return (
        <FareRuleWrapper>
            <Spin spinning={loadingFareData}>
                {fareRulesError && <AlertMessage message={fareRulesError} />}

                {
                    !fareRulesError && !loadingFareData && fareRules?.length === 0 && (
                        <Empty description="This bus does not have a fare rule" />
                    )
                }
                <Space direction="vertical">
                    {fareRules && fareRules.map((fareRule, index) => (
                        <div key={index}>
                            <StyledTitle level={5}>
                                {fareRule.Code}
                            </StyledTitle>
                            <StyledParagraph size='normal'>
                                {fareRule.Text}
                            </StyledParagraph>
                        </div>
                    ))}
                </Space>
            </Spin>
        </FareRuleWrapper>
    );
};

export default FareContent;
