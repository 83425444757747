/* eslint-disable react/no-array-index-key */
import React, {useEffect, useState} from 'react';
import RoadCard from "./RoadCard";
import {Link, useNavigate} from "react-router-dom";
import RoadHeading from "./RoadHeading";
import {ContentWrapper, ActionButtonWrapper, ActionContentWrapper,ActionContentLink, ActionWrapper} from "./styles";
import {useRoadSearchStore} from "../../store/roadSearch/roadSearchStore";
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import {formatNumberAsCurrency} from "../../utils/helpers";
import {Button, Drawer} from "antd";
import FareRule from "../FareRule/RoadFareRule";
import {calculateTotalFare} from "../AvailableBuses/helper";
import { ReactComponent as InfoIcon} from "../../assets/images/info.svg";
import RoadFareBreakdown from "../RoadItineraryBreakdown/RoadFareBreakdown";

function RoadItineraryBreakdown() {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const {
    selectedBus,
      selectedResult,
    formData: {
      numberOfAdults,
      TripType,
    },
  } = useRoadSearchStore();

  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedResult) {
      navigate('/search/road');
    }
  }, [selectedResult]);

  const isRoundTrip = TripType === 'round_trip';

  const departureBus = selectedBus[0];
  const returningBus = selectedBus && selectedBus.length == 2 ? selectedBus[1] : null;
  const departureOperator = selectedResult[0];
  const returningOperator = selectedResult[1];

  const totalAmount = calculateTotalFare(selectedBus, numberOfAdults).totalAmount;

  return (
      <>
        <ContentWrapper>
          <div>
            {
                departureBus && (<>
                  <RoadHeading operator={departureOperator} bus={departureBus} label="Departure"  />
                  <RoadCard operator={departureOperator} bus={departureBus} />
                </>)
            }

            {
                isRoundTrip && returningBus && (<>
                  <RoadHeading operator={returningOperator} bus={returningBus} label="Return" />
                  <RoadCard operator={returningOperator} bus={returningBus} />
                </>)
            }
          </div>
        </ContentWrapper>

        <ActionWrapper>
          <ActionContentWrapper>
            <div>
              <ActionContentLink>
                <FareRule />
              </ActionContentLink>
              <StyledParagraph>
                <span>
                    Total for all passengers
                </span>
                &nbsp;<InfoIcon onClick={() => setOpenDrawer(true)} className="ant-empty-img-default" />
              </StyledParagraph>

              <StyledTitle level={3}>
                {formatNumberAsCurrency(totalAmount || 0, selectedResult[0]?.Currency)}
              </StyledTitle>
            </div>

            <ActionButtonWrapper>
              <Link to="/passenger-details/road">
                <Button block size="large" type="primary">
                  Continue
                </Button>
              </Link>
            </ActionButtonWrapper>
          </ActionContentWrapper>
        </ActionWrapper>

        <Drawer
            title="Price breakdown"
            placement="bottom"
            onClose={() => setOpenDrawer(false)}
            height="auto"
            open={openDrawer}
            getContainer={document.getElementById('app-container') as HTMLElement}
        >
          <RoadFareBreakdown hideBreakdown={false} />
        </Drawer>
      </>
  );
}
export default RoadItineraryBreakdown;
