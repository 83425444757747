import styled from 'styled-components';
import device from '../../utils/mediaQuery';
import {FONT_SIZE, GREY_10, GREY_2} from '../../utils/colors';

export const Wrapper = styled.div`
  @media ${device.large} {
    padding: 0 0 20px;
  }
`;

export const TitleWrapper = styled.div`
  .ant-typography {
    color: #C4C4C4;
    font-size: calc(12px *  ${FONT_SIZE});
    
  }
`;

export const FormWrapper = styled.div`
  ${device.large} {
    margin-bottom: 0;
  }

  .ant-select-selection-placeholder,
  .ant-select-selection-search {
    font-size: calc(12px *  ${FONT_SIZE});
  }
  
  .ant-select-selection-item {
    font-size: calc(12px *  ${FONT_SIZE});
    
  }
`;

export const TripSummaryWrapper = styled.div`
  padding: 0 10px;
  
  ${device.large} {
    padding: 0;
  }
`;

export const HeaderWrapper = styled.div`
  margin: 14px 0 28px;
`;

export const FareRuleTitleWrapper = styled.div`
  width: 70%;
  margin: 20px auto 10px;
`;

export const FareRuleWrapper = styled.div`
  padding: 20px;
  background: ${GREY_10};
  border-radius: 5px;
  margin: 0 0 20px;
  overflow-y: auto;

  @media(${device.large}) {
    padding-top: 0;
    margin-top: 0;
    max-height: 300px;
    background: none;
  }
`;

export const MobileContentWrapper = styled.div`
  padding: 24px 20px 20px;
  background: ${GREY_2};
`;


export const DesktopContentWrapper = styled.div`
  margin-top: 40px;
  
  .ant-drawer-title {
    font-size: 20px;
  }
  
  .ant-drawer-content-wrapper {
    width: 520px !important;
  }
  
  .ant-drawer-footer {
    padding: 30px;
  }
`;

export const ListingHeaderContentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
`;
