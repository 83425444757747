import React, {useState} from 'react';
import {
    ActionButtonWrapper,
    ActionContentLink,
    ActionContentWrapper,
    ActionWrapper,
} from "./styles";
import FareRule from "../FareRule";
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import {formatNumberAsCurrency} from "../../utils/helpers";
import {Link} from "react-router-dom";
import {Button, Drawer} from "antd";
import {calculateFlightFareBreakdown, calculateFlightTotalFare} from "../AvailableFlights/helper";
import {useFlightSearchStore} from "../../store/flightSearch/flightSearchStore";
import useIsMobile from "../../hooks/useIsMobile";
import {SpaceBetweenAlignContent} from "../CustomAntdComponents/Utitlities";
import { ReactComponent as InfoIcon} from "../../assets/images/info.svg";
import FlightFareBreakdown from "./FlightFareBreakdown";

const FlightPriceBreakdown = () => {
    const { isMobile } = useIsMobile();
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);

    const {
        selectedFlight,
        formData: {
            isDomestic,
        },
    } = useFlightSearchStore();
    const { totalTicketAmount } = calculateFlightTotalFare(selectedFlight, isDomestic);
    const paxFares = calculateFlightFareBreakdown(selectedFlight)

    return isMobile ? (
        <div>
            <ActionWrapper>
                <ActionContentWrapper>
                    <div>
                        <ActionContentLink>
                            <FareRule />
                        </ActionContentLink>
                        <StyledParagraph>
                            <span>
                                Total for all passengers
                            </span>&nbsp;
                            <InfoIcon onClick={() => setOpenDrawer(true)} className="ant-empty-img-default" />
                        </StyledParagraph>

                        <StyledTitle level={3}>
                            {formatNumberAsCurrency(totalTicketAmount || 0, selectedFlight[0]?.Currency)}
                        </StyledTitle>
                    </div>

                    <ActionButtonWrapper>
                        <Link to="/passenger-details/flight">
                            <Button block size="large" type="primary">
                                Continue
                            </Button>
                        </Link>
                    </ActionButtonWrapper>
                </ActionContentWrapper>
            </ActionWrapper>

            <Drawer
                title="Price breakdown"
                placement="bottom"
                onClose={() => setOpenDrawer(false)}
                height="auto"
                open={openDrawer}
                getContainer={document.getElementById('app-container') as HTMLElement}
            >
                <FlightFareBreakdown hideBreakdown={false} />
            </Drawer>
        </div>
    ) : (
        <>
            <SpaceBetweenAlignContent>
                <StyledTitle level={4}>
                    Price breakdown
                </StyledTitle>
                <ActionContentLink>
                    <FareRule />
                </ActionContentLink>
            </SpaceBetweenAlignContent>

            <div>
                <FlightFareBreakdown hideBreakdown={false} />
                <ActionButtonWrapper>
                    <Link to="/passenger-details/flight">
                        <Button block size="large" type="primary">
                            Continue
                        </Button>
                    </Link>
                </ActionButtonWrapper>
            </div>
        </>
    );
};

export default FlightPriceBreakdown;
