import { getFontSize } from './helpers';

const auth: UserAuth = JSON.parse(localStorage.getItem('trips_auth') || '{}');
export const PRIMARY = auth.ExtraData?.PrimaryColor || '#002E63';
export const LIGHT_PRIMARY = auth.ExtraData?.LightPrimaryColor || '#e9faff';
export const FONT_SIZE = getFontSize(auth?.ExtraData?.FontSize) || 1;
// export const FONT_SIZE = 1;
export const WHITE = '#FFFFFF';
export const GREY_1 = '#ccd5e0';
export const GREY_2 = '#FAFAFA';
export const GREY_3 = '#F3F3F3';
export const GREY_4 = '#F0F0F0';
export const GREY_5 = '#BABABA';
export const GREY_6 = '#e6e6e6';
export const GREY_7 = '#DDDCDC';
export const GREY_10 = '#f6f5f5';
export const GREY_12 = '#EFEFEF';
export const GREY_15 = '#F1F1F1';
export const LIGHT_GREY = '#fcfcfc';
export const GREY = '#72737F';
export const BLACK = '#080403';
export const BLACK_300 = '#000000d9';
export const BLACK_400 = '#15162B';
export const BLACK_200 = '#48484A';
export const BLACK_150 = '#72737F';
export const BLACK_100 = '#D0D0D4';
export const RED = '#FF3B30';
