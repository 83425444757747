
export const ADULT = "ADT";
export const CHILDREN = "CHD";
export const INFANT = "INF";

export const passengerCategoryLabel = (code: string) => {
  switch (code) {
      case ADULT:
        return "Adult"
      case CHILDREN:
          return "Child"
      case INFANT:
          return "Infant"
      default:
          break
  }
}
