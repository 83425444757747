import React from 'react';
import {CabinWrapper, DropDownTitle} from "./styles";
import StyledRadioGroup from "../CustomAntdComponents/StyledRadioGroup";
import { StyledParagraph } from "../CustomAntdComponents/Typography";
import { useFlightSearchStore } from "../../store/flightSearch/flightSearchStore";
import { flightClassOptions } from "../FlightSearch/constant";
import searchTranslations from "../../screens/Search/translations";
import { useI18nContext } from "../../utils/I18n";
import useIsMobile from "../../hooks/useIsMobile";
import {FlightSearchStore} from "../../store/flightSearch/types";

type Props = {
    onChange?: (obj) => void;
    payload?: FlightSearchStore;
}
const CabinOptions = ({ onChange, payload }: Props) => {
    const {
        state: { t },
    } = useI18nContext();
    const options = flightClassOptions(t(searchTranslations));
    const { isMobile } = useIsMobile();

    const {
        formData, updateFormData,
    } = useFlightSearchStore();
    const { FlightClass } = payload || formData;

    const handleChange = (e) => {
        if (onChange) {
            onChange({ FlightClass: e.target.value });
        } else {
            updateFormData({ FlightClass: e.target.value });
        }
    }

    return (
        <CabinWrapper>
            <DropDownTitle>
                <StyledParagraph align={isMobile ? "center" : "left"} size={isMobile ? "large" : "default"}>
                    Cabin class
                </StyledParagraph>
            </DropDownTitle>

            <StyledRadioGroup options={options} value={FlightClass} onChange={handleChange} block bordered={isMobile} />
        </CabinWrapper>
    );
};

export default CabinOptions;
