import styled, { css } from 'styled-components';
import {BLACK_100, BLACK_200, BLACK_300, GREY_10, GREY_12, GREY_4, WHITE} from '../../utils/colors';
import device from '../../utils/mediaQuery';

export const TitleWrapper = styled.div`
  margin-bottom: 20px;
  
  svg {
    path {
        fill: ${BLACK_300} !important;
    }
  }
`;

export const DetailsWrapper = styled.div`
  margin: 15px 0;
`;

export const HighlightDetailItem = styled.div`
  border-radius: 5px;
  background: ${GREY_10};
  padding: 10px;
  margin-bottom: 7px;
`;

export const HeaderTileWrapper = styled.div`
  padding: 0px 10px;
  margin-bottom: 7px;
  border-radius: 5px;
  border: 1px solid #D4C7A6;
  background: #F9F9F9;
  display: flex;
  justify-content: space-between;
  align-items: center;

`;

export const ProgressIcon = styled.div<{ flight?: boolean }>`
  svg {
    margin-left: -2px;
  }

  &:before {
    content: "";
    border: 1px solid ${GREY_12};
    position: absolute;
    left: 94px;
    bottom: 0;
    top: 25px;
  }
  
    ${({ flight }) => flight && css`
      &:before {
        top: 21px;
      }
      
      &:after {
        content: "";
        border: 1px solid ${GREY_12};
        position: absolute;
        left: 94px;
        top: 0;
        bottom: 39px;
      }
    `}
`;

export const RowItem = styled.div`
    display: flex;
    column-gap: 20px;
    padding-bottom: 15px;
    align-items: center;
    position: relative;
`;

export const CircleIcon = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${WHITE};
    border: solid 1.5px ${BLACK_200};
`

export const TimeWrapper = styled.div`
    width: 70px;
`;

export const FlightDetailsWrapper = styled.div`
    flex: 1;
`;

export const FlightLogoWrapper = styled.span`
     img {
       height: 15px;
       margin-right: 4px
     }
`;

export const FlightDetailsContainer = styled.div`
  margin-bottom: 15px;
`;

export const LayoverWrapper = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 133px;
  
  svg {
    display: block;
    margin-right: 20px;
    position: absolute;
    top: 23px;
    left: 100px;
    path {
        fill: ${BLACK_200} !important;
    }
  }
  
  .ant-typography {
    color: ${BLACK_200};
  }
  
   @media ${device.large} {
     height: 100px;
        svg {
          top: 43px;
        }
   }
`;
export const LayoverLine = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 107px;
  border: 1px dashed ${BLACK_100};
`;

export const FlightDetails = styled.div`
    position: relative;
    border: solid 1px ${GREY_4};
    padding: 12px;
    border-radius: 12px;
  
    ${RowItem} {
      &:last-of-type {
        margin-bottom: 0;
        
        ${ProgressIcon} {
            &:before {
              top: 0;
              bottom: 40px;
            }
        }
      }
    }
`;

export const ContentWrapper = styled.div<{ showPriceBreakDown: boolean }>`
  padding: 0 10px 20px;
  overflow: visible;
  
  ${({ showPriceBreakDown }) => showPriceBreakDown && css`
    padding-bottom: 150px;
  `};

  @media ${device.large} {
    padding-bottom: 20px;
  }
`;

export const ActionFareWrapper = styled.div`
    padding: 24px 16px;
    border-top: 1px solid #EEEEEE;
`;

export const ActionWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  padding: 24px 16px;
  border-top: 1px solid #EEEEEE;

  @media ${device.large} {
    position: unset;
    border: none;
    padding-top: 10px;
  }
`;

export const ActionContentLink = styled.div`
  margin-bottom: 30px;

  @media ${device.large} {
    margin-bottom: 0;
  }
`;

export const BreakdownWrapper = styled.div`
    padding: 10px 0;
    border-bottom: solid 1px ${GREY_12};
    margin-bottom: 10px;
`;

export const ActionButtonWrapper = styled.div`
  flex: 1;
  
  @media(${device.large}) {
    margin-top: 15px;
  }
`;

export const aWrapper = styled.div``;
export const ActionContentWrapper = styled.div`
  display: flex;
  column-gap: 40px;
  justify-content: space-between;
  align-items: flex-end;

  @media ${device.large} {
    display: block;
    margin: 30px 0 15px;
  }
`;
