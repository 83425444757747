import React from 'react';
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import FingersCrossed from "../../../src/assets/images/reactions/fingers-crossed.svg";
import styled from "styled-components";
import device from '../../utils/mediaQuery';


const Done = () => {
    return (
        <Wrapper>
            <img src={FingersCrossed} alt='fingers' />
            <StyledTitle level={3}>
                Thanks for your feedback!
            </StyledTitle>
            <StyledParagraph size="default">
                It means the world to us.
            </StyledParagraph>
        </Wrapper>
    );
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; 
    justify-content: center;
  height: 100%;
  row-gap: 10px;

  @media ${device.large} {
    padding: 30px 0;
  }
`;

export default Done;
