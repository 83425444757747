import styled, { css } from 'styled-components';
import {GREY_5, GREY, PRIMARY, WHITE, GREY_4} from '../../utils/colors';

const geSeatBackground = (type?: string) => {
  let result = '';

  switch (type) {
    case 'available':
      result = '#E9E9E9';
      break;
    case 'selected':
      result = PRIMARY;
      break;
    case 'booked':
      result = '#060618';
      break;
    case 'blocked':
      result = '#B90000';
      break;
    default:
      result = '#E9E9E9';
  }

  return result;
};
const geSeatTextColour = (type?: string) => {
  let result = '';

  switch (type) {
    case 'available':
      result = '#080403';
      break;
    default:
      result = '#fff';
  }

  return result;
};

const getCursorType = (type?: string) => {
  let result = '';

  switch (type) {
    case 'available':
      result = 'pointer';
      break;
    case 'selected':
      result = 'pointer';
      break;
    case 'blocked':
      result = 'not-allowed';
      break;
    case 'booked':
      result = 'not-allowed';
      break;
    default:
      result = 'auto';
  }

  return result;
};

export const Wrapper = styled.div``;

export const PadContentWrapper = styled.div`
  border: 1px solid ${GREY_4};
  background: ${WHITE};
  border-radius: 20px;
  width: 300px;
  margin: 20px 0;

`;

export const PadWrapper = styled.div<{ column?: number}>`
  padding: 10px;
  width:100%;
  box-sizing: border-box;
  display: grid;
  grid-gap: 5px;
  grid-template-rows: auto;
  justify-content: space-evenly;
  justify-items: center;
  align-items: center;
  ${({ column }) => column && css`
    grid-template-columns: repeat(${column},  minmax(50px, 1fr));

  `}

`;
export const DriverSeatWrapper = styled.div`
  display: inline-block;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #080403;
  margin-right: 64px;
  margin-left: 4px;
`;

export const SeatWrapper = styled.div<{ status?: string, isEmpty?: boolean, seatWidth?: string, marginalized?: boolean, isDriver?: boolean, doubleMarginalized?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  text-align: center;
  background: #E9E9E9;
  margin: 5px;
  min-width:45px;
  max-width:60px;
  box-sizing: border-box;
  color: #080403;
  border-radius: 15px 15px 4px 4px;
  border-bottom: solid 10px ${GREY};

  ${({ seatWidth }) => seatWidth && css`
    width: ${seatWidth}%;
  `}

  ${({ status }) => status && css`
    background: ${geSeatBackground(status)};
    color: ${geSeatTextColour(status)};
    cursor: ${getCursorType(status)};
  `}

  ${({ isDriver }) => isDriver && css`
    background:  transparent;
    color:  transaparent;
    border: none;
  `}

  ${({ isEmpty }) => isEmpty && css`
    background: transparent !mportant;
    color: transaparent;
    border: none;
    visibility: hidden;

  `}

  ${({ doubleMarginalized }) => doubleMarginalized && css`
    margin-right: 165px !important;
  `}
`;

export const IndicatorWrapper = styled.div`
  text-align: center;
  display: flex;
  column-gap: 20px;
  justify-content: center;
  ${SeatWrapper} {
    width: 40px;
  }
`;

export const IndicatorContent = styled.div`
  text-align: center;
`;

const largeBusContentWrapper = css`
  width: 350px;

  ${PadContentWrapper} {
    width: 350px;
  }
`;
const mediumBusContentWrapper = css`
  width: 300px;
  ${PadContentWrapper} {
    width: 300px;
  }
`;

const mediumSBusContentWrapper = css`
  width: 250px;

  ${PadContentWrapper} {
    width: 250px;
  }
`;

const smallBusContentWrapper = css`
  width: 230px;

  ${PadContentWrapper} {
    width: 230px;
  }
`;

export const ContentWrapper = styled.div<{ size: string}>`
  margin: 0 auto;
  width: 242px;

  ${({ size }) => size === 'small' && smallBusContentWrapper}
  ${({ size }) => size === 'medium' && mediumBusContentWrapper}
  ${({ size }) => size === 'medium-s' && mediumSBusContentWrapper}
  ${({ size }) => size === 'large' && largeBusContentWrapper}
`;
