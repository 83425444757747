import React from 'react';
import { Row, Col, Form } from 'antd';
import {
  DropDownSubTitle,
  DropDownTitle, PaxCountLabel,
  PaxCountItem, PaxCountSubTitle, PaxCountTitle,
  PaxCountWrapper,
  Wrapper,
} from './styles';
import { StyledParagraph } from '../CustomAntdComponents/Typography';
import {
  useFlightSearchStore,
} from '../../store/flightSearch/flightSearchStore';
import globalTranslations from '../../utils/globalTranslation';
import { useI18nContext } from '../../utils/I18n';
import NumberInput from "../NumberInput";
import useIsMobile from "../../hooks/useIsMobile";
import {FlightSearchStore} from "../../store/flightSearch/types";

type Props = {
  onChange?: (obj) => void;
  payload?: FlightSearchStore;
}

const PassengerCount = ({ onChange, payload }: Props) => {
  const {
    state: { t },
  } = useI18nContext();
  const {
    formData, updateFormData,
  } = useFlightSearchStore();
  const { isMobile } = useIsMobile();

  const {
    numberOfAdults, numberOfInfants, numberOfChildren,
  } = payload || formData;

  const onFormInputChange = (value: any, name: string) => {
    if(onChange) {
      onChange({
        [name]: value,
      });
    } else {
      updateFormData({ [name]: value });
    }
  };

  const checkNumberOfPassenger = (value, key) => {
    const data = {
        numberOfAdults,
        numberOfChildren: numberOfChildren || 0,
        numberOfInfants: numberOfInfants || 0,
        [key]: value,
    };

    const totalPAX = Object.values(data).reduce((acc, curr) => acc + curr, 0);

    if (totalPAX > 9) {
      return Promise.reject(new Error(
          t(globalTranslations).passengerSelector.maxValidationText,
      ));
    }

    return Promise.resolve();
  };

  const checkNumberOfInfants = (_, value) => {
    const totalToddlers = (value || 0);
    const totalPAX = numberOfAdults + (numberOfChildren || 0) + totalToddlers;

    if (totalPAX > 9) {
      return Promise.reject(new Error(
          t(globalTranslations).passengerSelector.maxValidationText,
      ));
    }

    if (numberOfAdults < totalToddlers) {
      return Promise.reject(new Error(
          t(globalTranslations).passengerSelector.unmatchValidationText,
      ));
    }

    return Promise.resolve();
  };

  return (
      <PaxCountWrapper>
        <DropDownTitle>
          <StyledParagraph align={isMobile ? "center" : "left"} size={isMobile ? "large" : "default"}>
            Passengers
          </StyledParagraph>
        </DropDownTitle>

        {isMobile && (
            <DropDownSubTitle>
              <StyledParagraph size='large'>
                Select the number of passengers
              </StyledParagraph>
            </DropDownSubTitle>
        )}

        <Wrapper>
          <Row>
            <Col span={24}>
              <PaxCountItem>
                <PaxCountLabel>
                  <PaxCountTitle>
                    <StyledParagraph size="default" style={{ margin: 0}}>
                      { t(globalTranslations).passengerSelector.adults}
                    </StyledParagraph>
                  </PaxCountTitle>
                  <PaxCountSubTitle>
                    <StyledParagraph size="small" style={{ margin: 0}}>
                      12+ years
                    </StyledParagraph>
                  </PaxCountSubTitle>
                </PaxCountLabel>

                <Form.Item
                    name="numberOfAdults"
                    rules={[
                      {
                        required: false,
                        message: t(globalTranslations).passengerSelector.adultValidationText,
                      },
                      {
                        validator: (_, value) => checkNumberOfPassenger(value, 'numberOfAdults'),
                      },
                    ]}
                    dependencies={['numberOfChildren', 'numberOfInfants']}
                >
                  <NumberInput min={1} value={numberOfAdults} onChange={(value) => onFormInputChange(value, 'numberOfAdults')} />
                </Form.Item>
              </PaxCountItem>

              <PaxCountItem>
                <PaxCountLabel>
                  <PaxCountTitle>
                    <StyledParagraph size="default" style={{ margin: 0}}>
                      { t(globalTranslations).passengerSelector.children}
                    </StyledParagraph>
                  </PaxCountTitle>
                  <PaxCountSubTitle>
                    <StyledParagraph size="small" style={{ margin: 0}}>
                      2 - 11 years
                    </StyledParagraph>
                  </PaxCountSubTitle>
                </PaxCountLabel>

                <Form.Item
                    name="numberOfChildren"
                    rules={[
                      {
                        validator: (_, value) => checkNumberOfPassenger(value, 'numberOfChildren'),
                      },
                    ]}
                    dependencies={['numberOfAdults', 'numberOfInfants']}
                >
                 <NumberInput value={numberOfChildren} onChange={(value) => onFormInputChange(value, 'numberOfChildren')} />
                </Form.Item>
              </PaxCountItem>

              <PaxCountItem>
                <PaxCountLabel>
                  <PaxCountTitle>
                    <StyledParagraph size="default" style={{ margin: 0}}>
                      { t(globalTranslations).passengerSelector.infants}
                    </StyledParagraph>
                  </PaxCountTitle>
                  <PaxCountSubTitle>
                    <StyledParagraph size="small" style={{ margin: 0}}>
                      Under 2 years
                    </StyledParagraph>
                  </PaxCountSubTitle>
                </PaxCountLabel>

                 <Form.Item
                      name="numberOfInfants"
                      rules={[
                        {
                          validator: checkNumberOfInfants,
                        },
                      ]}
                        dependencies={['numberOfAdults', 'numberOfChildren']}
                  >
                    <NumberInput value={numberOfInfants} onChange={(value) => onFormInputChange(value, 'numberOfInfants')} />
                  </Form.Item>
              </PaxCountItem>
            </Col>
          </Row>
        </Wrapper>
      </PaxCountWrapper>
  );
}

PassengerCount.defaultProps = {
  showTitle: true,
  onChange: undefined,
};
export default PassengerCount;
