import styled, { css } from 'styled-components';
import {PRIMARY, WHITE} from "../../utils/colors";
import { lighten } from 'polished'


export const StepCounter = styled.div`
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
  font-size: 16px;
  
  svg {
    width: 16px;
    height: 16px;
    
    path {
      fill: ${WHITE} !important;
    }
  }
`;


export const StepperItem = styled.div<{ active?: boolean, completed?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  color: ${WHITE};
  
   &:before {
     position: absolute;
     content: "";
     border-bottom: 2px solid #ccc;
     width: 100%;
     top: 20px;
     left: -50%;
     z-index: 2;
   }
  
    &:after {
      position: absolute;
      content: "";
      border-bottom: 2px solid #ccc;
      width: 100%;
      top: 20px;
      left: 50%;
      z-index: 2;
    }
  
    ${({ active }) => active && css`
          ${StepCounter} {
            background-color: ${PRIMARY};
            box-shadow: ${lighten(0.3, PRIMARY)} 0 0 3px 6px;
          }
    `}
  
    ${({ completed }) => completed && css`
        ${StepCounter} {
          background-color: ${PRIMARY};
        }

      &::after {
        position: absolute;
        content: "";
        border-bottom: 2px solid ${PRIMARY};
        width: 100%;
        top: 20px;
        left: 50%;
        z-index: 3;
      }
    `}
`;

export const StepperWrapper = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  
    ${StepperItem} {
      &:first-child::before {
        content: none;
      }
      
      &:last-child::after {
          content: none;
      }
    }
`;

export const Container = styled.div`
  width: 100%;
`;
