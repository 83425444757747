export const priceOptions = [{
  label: 'Cheapest',
  value: 'asc',
}, {
  label: 'Expensive',
  value: 'desc',
}];

export const stopOverOptions = [
  { label: 'All', value: -1 },
  {
    label: '0',
    value: 0,
  },
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
];
