import React, {useMemo} from 'react';
import StyledCheckboxGroup from "../../CustomAntdComponents/StyledCheckboxGroup";
import {ClearWrapper} from "../styles";
import {StyledText} from "../../CustomAntdComponents/Typography";

type Props = {
    operators: SelectOption[]
    onChange: (arg: string[]) => void,
    selectedOperators: string[]
}

function OperatorsCheckbox({ operators, selectedOperators, onChange }: Props) {
    const handleChange = (list) => {
        onChange(list);
    }

    return (
        <>
            <ClearWrapper>
                <StyledText onClick={() =>  handleChange([])} color="primary">
                    Clear all
                </StyledText>
            </ClearWrapper>
            <StyledCheckboxGroup options={operators} values={selectedOperators} onChange={handleChange} />
        </>
    );
}

export default OperatorsCheckbox;
