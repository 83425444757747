import React from 'react';
import {
  PillItemWrapper,
  PillWrapper,
} from '../../screens/Search/styles';

type Props = {
  options: DataOption[],
  filled?: boolean,
  itemFilled?: boolean,
  mode: string
  setOption: (arg: string) => void
}

function RoadModePill({
  setOption, filled, itemFilled, options, mode,
}: Props) {
  return (
    <PillWrapper filled={filled}>
      {options.map((option) => (
        <PillItemWrapper
          filled={itemFilled}
          active={mode === option.value}
          onClick={() => setOption(option.value)}
          key={option.id}
        >
          {option.name}
        </PillItemWrapper>
      ))}
    </PillWrapper>
  );
}

RoadModePill.defaultProps = {
  filled: false,
  itemFilled: false,
};

export default RoadModePill;
