import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
  ActionWrapper,
  Wrapper,
} from './styles';
import FlightReceipt from '../../components/ReceiptCard/FlightReceipt';
import RoadReceipt from '../../components/ReceiptCard/RoadReceipt';
import WaterReceipt from '../../components/ReceiptCard/WaterReceipt';
import TrainReceipt from '../../components/ReceiptCard/TrainReceipt';
import {Button, Drawer} from "antd";
import BookingFeedback from "../../components/BookingFeedback";
import FeedbackForm from "../../components/FeedbackForm";
import {StyledButton} from "../../components/CustomAntdComponents/StyledButton";
import {useFlightSearchStore} from "../../store/flightSearch/flightSearchStore";
import {useRoadSearchStore} from "../../store/roadSearch/roadSearchStore";
import {useBookingStore} from "../../store/booking/bookingStore";
import Done from "../../components/FeedbackForm/Done";

function PaymentReceipt() {
  const { tripType } = useParams();
  const navigate =  useNavigate();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [feedbackDrawer, setOpenFeedbackDrawer] = useState<boolean>(false);
    const [openFeedbackDone, setOpenFeedbackDone] = useState<boolean>(false);
    const [reaction, setReaction] = useState("");
    const {
        resetFormData,
    } = useFlightSearchStore();
    const {
        resetFormData: resetRoadSearchData
    } = useRoadSearchStore();

    const {
        resetBooking,
    } = useBookingStore();

  const redirectToSearch = () => {
      resetBooking();

      if(tripType === 'flight') {
          resetFormData();
      }

      if(tripType === 'road') {
          resetRoadSearchData();
      }
      navigate(`/search/${tripType}`);
  }

  const FeedbackFormFooter = (
      <StyledButton mode="primary" block onClick={() => {
          setOpenFeedbackDrawer(false);
          setOpenFeedbackDone(true);
      }}>
        Submit feedback
      </StyledButton>
  )

  const FeedbackDoneFooter = (
        <StyledButton mode="primary" block onClick={redirectToSearch}>
            Done
        </StyledButton>
  )

  return (
    <>
      <div className="container">
        <Wrapper>
          { tripType === 'flight' && <FlightReceipt />}
          { tripType === 'road' && <RoadReceipt />}
          { tripType === 'water' && <WaterReceipt />}
          { tripType === 'rail' && <TrainReceipt />}
        </Wrapper>

        <ActionWrapper>
          <Button type="primary" block onClick={() => setOpenDrawer(true)}>
            Done
          </Button>
        </ActionWrapper>

        <Drawer
            placement={"bottom"}
            onClose={() => setOpenDrawer(false)}
            open={openDrawer}
            height="auto"
            getContainer={document.getElementById('app-container') as HTMLElement}
        >
         <BookingFeedback openFeedbackForm={(reaction) => {
              setOpenDrawer(false);
              setOpenFeedbackDrawer(true);
              setReaction(reaction);
         }} />
        </Drawer>

        <Drawer
            title="Feedback"
            placement={"bottom"}
            onClose={() => {
                setOpenDrawer(true);
                setOpenFeedbackDrawer(false);
            }}
            open={feedbackDrawer}
            footer={FeedbackFormFooter}
            height="100%"
            getContainer={document.getElementById('app-container') as HTMLElement}
        >
          <FeedbackForm reaction={reaction} />
        </Drawer>

        <Drawer
            placement={"bottom"}
            height="auto"
            onClose={() => {
                setOpenDrawer(true);
                setOpenFeedbackDrawer(false);
            }}
            open={openFeedbackDone}
            footer={FeedbackDoneFooter}
            getContainer={document.getElementById('app-container') as HTMLElement}
        >
            <Done />
        </Drawer>
      </div>
    </>
  );
}

export default PaymentReceipt;
