import React from 'react';
import { Col, Row, Empty } from 'antd';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header';
import HeaderNavigation from '../../components/HeaderNavigation';
import SearchBooking from '../../components/SearchBooking';
import { ContentWrapper } from './styles';
import { useBookingStore } from '../../store/booking/bookingStore';
import ManageFlightBooking
  from '../../components/ManageBooking/ManageFlightBooking';
import ManageRoadBooking
  from '../../components/ManageBooking/ManageRoadBooking';

function Booking() {
  const { tripType } = useParams();

  const { flightReservation, roadReservation } = useBookingStore();

  const renderBookingType = () => {
    switch (tripType) {
      case 'flight':
        return <ManageFlightBooking />;
      case 'road': return <ManageRoadBooking />;
      default: return <Empty description="Unknown trip type" />;
    }
  };

  return (
    <>
      <Row>
        <Col xs={0} lg={24}>
          <Header />
        </Col>
      </Row>
      <div className="container">
        <ContentWrapper>
          <Row>
            <Col xs={24} lg={0}>
              <HeaderNavigation name="Manage Booking" />
            </Col>
            <Col xs={0} lg={24}>
              <HeaderNavigation />
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              {(flightReservation || roadReservation) ? renderBookingType() : <SearchBooking /> }
            </Col>
          </Row>
        </ContentWrapper>
      </div>
    </>

  );
}

export default Booking;
