import React, {useMemo} from 'react';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/arrow-left.svg';

import {
  BackIconWrapper,
  Wrapper,
  TimelineWrapper,
  TimelineContainer,
  Container,
} from './styles';
import { StyledText } from "../../CustomAntdComponents/Typography";
import Stepper from "../../Stepper";
import {useParams} from "react-router-dom";
import {roadTimelineSteps, timelineSteps} from "../index";

type Props = {
  navigateHandler: () => void
}
function PassengerDesktopHeader({ navigateHandler }: Props) {
  const { tripType } = useParams();

  const steps = useMemo(() => {
    if(tripType === 'road') {
      return roadTimelineSteps;
    }

    return timelineSteps;
  }, [tripType]);

  const currentStep = useMemo(() => {
    if(tripType === 'road') {
      return 3;
    }

    return 2;
  }, [tripType]);

  return (
      <Container>
        <Wrapper className="container">
          <BackIconWrapper onClick={navigateHandler}>
            <ArrowLeftIcon width={20} height={20} />
            <StyledText size="default">Back</StyledText>
          </BackIconWrapper>

          <TimelineContainer>
            <TimelineWrapper>
              <Stepper steps={steps} currentStep={currentStep} />
            </TimelineWrapper>
          </TimelineContainer>
        </Wrapper>
      </Container>
  );
}

export default PassengerDesktopHeader;
