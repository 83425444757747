import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled, { css } from "styled-components";
import { ReactComponent as FlightIcon } from '../../assets/images/flight-horizontal.svg';
import { ReactComponent as BusIcon } from '../../assets/images/bus-grey.svg';
import {GREY, WHITE} from "../../utils/colors";
import {StyledText} from "../CustomAntdComponents/Typography";
import { getExtraData } from '../../utils/helpers';

function FlightSearchDesktopNav() {
    const { tripType } = useParams();

    const extraData = getExtraData();
    const [visibleTripMode, setVisibleTripsMode] = useState({
      isAirVisible: false,
      isRoadVisible: false,
      isRailVisible: false,
      isWaterVisible: false,
    });


    const getVisibleTransportModes = useCallback(() => {
      const isAirVisible = extraData && extraData?.TransportMode?.find((item: TransportMode) => item.TransportModeName === 'Air')?.Status;
      const isRoadVisible = extraData && extraData?.TransportMode?.find((item: TransportMode) => item.TransportModeName === 'Road')?.Status;
      const isRailVisible = extraData && extraData?.TransportMode?.find((item: TransportMode) => item.TransportModeName === 'Rail')?.Status;
      const isWaterVisible = extraData && extraData?.TransportMode?.find((item: TransportMode) => item.TransportModeName === 'Water')?.Status;
  
     
      
      setVisibleTripsMode({
        ...visibleTripMode,
        isAirVisible,
        isRoadVisible,
        isRailVisible,
        isWaterVisible,
      });
  
    }, [setVisibleTripsMode]);

    useEffect(() => {
      getVisibleTransportModes();
    }, [getVisibleTransportModes]);

    return (
    <Wrapper>
      {visibleTripMode.isAirVisible && (
        <Link to="/search/flight">
          <IconWrapper active={tripType !== 'road'}>
              <FlightIcon />
              <StyledText size="large">
                  Book a flight
              </StyledText>
          </IconWrapper>
        </Link>
      )}
{visibleTripMode.isRoadVisible && (
        <Link to="/search/road">
            <IconWrapper active={tripType === 'road'}>
              <BusIcon />
              <StyledText size="large">
                  Book a bus
              </StyledText>
            </IconWrapper>
        </Link>
        )}
    </Wrapper>
  );
}

export default FlightSearchDesktopNav;

const Wrapper = styled.div`
  display: flex;
  column-gap: 10px;
  border-radius: 12px;
  position: relative;
  align-items: flex-start;
  
  .ant-typography {{
    color: ${GREY};
  }
`;

const IconWrapper = styled.div<{ active?: boolean }>`
  padding: 15px 30px;
  display: flex;
  column-gap: 7px;
  justify-content: center;
  align-items: center;
  background: #F3F3F3;
  border-radius: 20px;
  cursor: pointer;

  ${({ active }) => active && css`
    background: ${WHITE};
    border-radius: 20px 20px 0 0;
    padding-bottom: 25px;
    padding-top: 15px;  
  `}
  
  svg {
    path {
        fill: ${GREY} !important;
    }
  }
`;
