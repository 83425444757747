import React from 'react';
import {Row, Col, Form, Button} from 'antd';
import {useI18nContext} from "../../../utils/I18n";
import {useRoadSearchStore} from "../../../store/roadSearch/roadSearchStore";
import {useStore} from "../../../store/global/appStore";
import translations from "../../../screens/Search/translations";
import {getSelectOptions} from "../helper";
import {
    AirportFieldsWrapper, DepartureWrapper,
    FormFieldsWrapper,
    FormWrapper,
    MobileFormContent,
    MobileFormContentWrapper, SwitcherWrapper
} from "../../FlightSearch/styles";
import RoadModes from "../../RoadSearch/RoadModes";
import AirportSelector from "../../RouteSelector";
import {ReactComponent as DirectionIcon} from "../../../assets/images/direction.svg";
import {getDisabledDateRange, getStartOfDay} from "../../../utils/helpers";
import StyledDatePicker from "../../CustomAntdComponents/StyledDatePicker/DatePicker";
import {RangePickerProps} from "antd/es/date-picker";

type Props = {
    form: any;
}

const RoadSearchForm = ({ form }: Props) => {
    const {
        state: { t },
    } = useI18nContext();

    const {
        formData: {
            TripType, DepartureId, ArrivalId, DepartureDate, ArrivalDate,
        }, updateFormData,
    } = useRoadSearchStore();
    const {
        roadRoutes,
    } = useStore();

    const onFormInputChange = (value: any, name: string) => {
        updateFormData({ [name]: value });
        form.setFieldValue(name, value);
    };

    const handleSwitchLocation = () => {
        const temp = DepartureId;
        onFormInputChange(ArrivalId, 'DepartureId');
        onFormInputChange(temp, 'ArrivalId');

        updateFormData({
            DepartureId: ArrivalId,
            ArrivalId: temp,
        });

        form.setFieldsValue({
            DepartureId: ArrivalId,
            ArrivalId: DepartureId,
        });
    }

    const isRoundTrip = TripType === 'round_trip';
    const departureRouteOptions = getSelectOptions(roadRoutes.filter((route) => route.IsDeparture));
    const arrivalRouteOptions = getSelectOptions(roadRoutes.filter((route) => route.IsArrival));
    const departureDateWatch = Form.useWatch('DepartureDate', form);

    const validateDestination = (_: any, value: any) => {
        if (!value) {
            return Promise.reject(new Error(t(translations).validations.destination));
        }

        if (DepartureId === value) {
            return Promise.reject(new Error(t(translations).validations.invalidDestination));
        }

        return Promise.resolve();
    };

    const checkReturningDate = (_: any, value: any) => {
        const departureDate = form.getFieldValue('DepartureDate');
        if (!departureDate) {
            return Promise.resolve();
        }

        if (!value) {
            return Promise.reject(new Error(t(translations).validations.destinationDate));
        }

        if (value.isBefore(departureDate)) {
            return Promise.reject(new Error(t(translations).validations.invalidDestinationDate));
        }
        return Promise.resolve();
    };

    const disabledReturnDate: RangePickerProps['disabledDate'] = (current) => getDisabledDateRange(current, getStartOfDay(departureDateWatch));

    return (
        <MobileFormContentWrapper>
            <FormWrapper>
                <MobileFormContent bustrip>
                    <RoadModes mode={TripType} onTripTypeChange={(value) => onFormInputChange(value, 'TripType')} />

                    <FormFieldsWrapper>
                        <Row gutter={8}>
                            <Col xs={24} lg={0}>
                                <AirportFieldsWrapper>
                                    <Row gutter={[16, 0]}>
                                        <Col xs={24}>
                                            <Form.Item
                                                name="DepartureId"
                                                rules={[{ required: true, message: t(translations).validations.departure }]}
                                            >
                                                <DepartureWrapper>
                                                    <AirportSelector
                                                        label={t(translations).inputs.fromLabel}
                                                        placeholder={t(translations).inputs.fromPlaceholder}
                                                        value={DepartureId}
                                                        options={departureRouteOptions}
                                                        icon="circle"
                                                        onChange={(value) => onFormInputChange(value, 'DepartureId')}
                                                        tripType="road"
                                                    />
                                                    <SwitcherWrapper onClick={handleSwitchLocation}>
                                                        <DirectionIcon />
                                                    </SwitcherWrapper>
                                                </DepartureWrapper>
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item
                                                name="ArrivalId"
                                                rules={[
                                                    {
                                                        validator: validateDestination,
                                                    },
                                                ]}
                                            >
                                                <AirportSelector
                                                    label={t(translations).inputs.toLabel}
                                                    placeholder={t(translations).inputs.toPlaceholder}
                                                    value={ArrivalId}
                                                    options={arrivalRouteOptions}
                                                    icon="location"
                                                    onChange={(value) => onFormInputChange(value, 'ArrivalId')}
                                                    tripType="road"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </AirportFieldsWrapper>

                                <Row gutter={8}>
                                    <Col xs={24}>
                                        <Form.Item
                                            name="DepartureDate"
                                            rules={[{ required: true, message: t(translations).validations.departureDate }]}
                                        >
                                            <StyledDatePicker
                                                label={t(translations).inputs.departureDate}
                                                value={DepartureDate}
                                                disabledDate={getDisabledDateRange}
                                                onChange={(value) => onFormInputChange(value, 'DepartureDate')}
                                            />
                                        </Form.Item>
                                    </Col>

                                    {
                                        isRoundTrip && (
                                            <Col xs={24}>
                                                <Form.Item
                                                    name="ArrivalDate"
                                                    rules={[
                                                        {
                                                            validator: checkReturningDate,
                                                        },
                                                    ]}
                                                >
                                                    <StyledDatePicker
                                                        label={t(translations).inputs.arrivalDate}
                                                        value={ArrivalDate}
                                                        disabledDate={disabledReturnDate}
                                                        onChange={(value) => onFormInputChange(value, 'ArrivalDate')}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        )
                                    }
                                </Row>
                            </Col>
                        </Row>

                        <Col xs={24} lg={0}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" size="large" block>
                                    {t(translations).searchRoadButton}
                                </Button>
                            </Form.Item>
                        </Col>
                    </FormFieldsWrapper>
                </MobileFormContent>
            </FormWrapper>
        </MobileFormContentWrapper>
    );
};

export default RoadSearchForm;
