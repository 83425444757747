import styled, { css } from 'styled-components';
import device from '../../utils/mediaQuery';

export const Wrapper = styled.div<{ fullWidth?: boolean}>`
  margin: 0 auto 15px;

  @media ${device.large} {
    width: 70%
  }
  
  ${({ fullWidth }) => fullWidth && css`
    width: 100% !important;
  `}
`;
