import React, {useEffect, useMemo} from 'react';
import {
    Form, Row, Col, Button,
} from 'antd';
import {getAirportOptions, validateRoundTripDates} from "../../FlightSearch/helper";
import translations from "../../../screens/Search/translations";
import StyledDatePicker from "../../CustomAntdComponents/StyledDatePicker/DatePicker";
import {getDisabledDateRange, getExtraData} from "../../../utils/helpers";
import PassengerCountSelector from "../../PassegerSelector";
import {useI18nContext} from "../../../utils/I18n";
import {useStore} from "../../../store/global/appStore";
import {
    AirportFieldsWrapper,
    DepartureWrapper,
    SwitcherWrapper,
} from "../../FlightSearch/styles";
import StyledRadioGroup from "../../CustomAntdComponents/StyledRadioGroup";
import {MulticityFlightSearchForm} from "../../FlightSearch/MultiCity";
import AirportSelector from "../../RouteSelector";
import {ReactComponent as DirectionIcon} from "../../../assets/images/direction-hr.svg";
import StyledDateRangePicker from "../../CustomAntdComponents/StyledDateRangePicker/DateRangePicker";
import {tripModesMultiCity} from "../../../screens/Search/constant";
import {Moment} from "moment";
import {FlightSearchStore} from "../../../store/flightSearch/types";

type Props = {
    onSubmit: (arg?: any) => void;
    payload: FlightSearchStore;
    onChange: (arg) => void;
}
const FlightSearchDesktop = ({ onSubmit, onChange, payload }: Props) => {
    const [form] = Form.useForm();
    const {
        state: { t },
    } = useI18nContext();
    const { airports } = useStore();

    const {
        FlightClass,
        DepartureDate,
        ReturnDate,
        numberOfAdults,
        DestinationLocationCode,
        OriginLocationCode,
        numberOfInfants,
        numberOfChildren,
        tripType,
        multiCity,
    } = payload;

    const tripTypeWatch = Form.useWatch('tripType', form);
    const isRoundTrip = tripTypeWatch === 'round_trip';
    const isMultiCity = tripTypeWatch === 'multi_city';

    const formInitialValues = {
        FlightClass,
        DepartureDate,
        ReturnDate,
        FlightDateRange: [DepartureDate, ReturnDate],
        tripType,
        numberOfAdults,
        DestinationLocationCode,
        OriginLocationCode,
        numberOfInfants,
        numberOfChildren,
        multiCity,
    };

    const allAirports = [...airports]
    const airportOptions = useMemo(() => getAirportOptions(allAirports), [allAirports]);

    const validateDestination = (_: any, value: any) => {
        if (!value) {
            return Promise.reject(new Error(t(translations).validations.destination));
        }

        if (form.getFieldValue('OriginLocationCode') === value) {
            return Promise.reject(new Error(t(translations).validations.invalidDestination));
        }

        return Promise.resolve();
    };

    const onFormInputChange = (value: any, name: string) => {
        onChange({
            [name]: value,
        });

        form.setFieldsValue({
            [name]: value,
        });
    };

    const handleSwitchAirports = () => {
        const tempOrigin = form.getFieldValue('OriginLocationCode');
        const tempDestination = form.getFieldValue('DestinationLocationCode');

        onChange({
            OriginLocationCode: tempDestination,
            DestinationLocationCode: tempOrigin,
        });

        form.setFieldsValue({
            OriginLocationCode: tempDestination,
            DestinationLocationCode: tempOrigin,
        });
    }

    const onDateRangeChange = (values: [Moment, Moment]) => {
        let data = {};
        if(!values) {
            data = {DepartureDate: "", ReturnDate: "" }
        } else {
            data = { DepartureDate: values[0], ReturnDate: values[1] }
        }

        onChange(data);
        form.setFieldsValue(data);
    }

    const OriginLocationCodeWatch = Form.useWatch('OriginLocationCode', form);
    const DestinationLocationCodeWatch = Form.useWatch('DestinationLocationCode', form);

    useEffect(() => {
        const origin = allAirports.find((airport) => airport.Code === OriginLocationCodeWatch)
        const destination = allAirports.find((airport) => airport.Code === DestinationLocationCodeWatch)

        if(!origin || !destination) return;
        const isDomestic =  origin?.CountryCode === destination?.CountryCode;

        onChange({
            isDomestic,
        });

    }, [DestinationLocationCodeWatch, OriginLocationCodeWatch]);

    const onFinish = (values) => {
        onSubmit(values.trips);
    };

    const extraData = getExtraData();

    const flightModes = tripModesMultiCity(t(translations));
    const MultiCityMaxRoute = extraData.MultiCityMaxRoute ? extraData.MultiCityMaxRoute : 5;

    return (
        <Form
            wrapperCol={{ span: 24 }}
            layout="horizontal"
            initialValues={formInitialValues}
            form={form}
            size="large"
            onFinish={onFinish}
        >

            <Row gutter={[8, 0]}>
                <Col span={24}>
                    <Form.Item
                        name="tripType"
                        rules={[{ required: true, message: t(translations).validations.departure }]}
                    >
                        <StyledRadioGroup options={flightModes} value={tripType} onChange={(e) => onFormInputChange(e.target.value, 'tripType')} />
                    </Form.Item>
                </Col>

                {isMultiCity ? (
                    <Col span={18}>
                        <MulticityFlightSearchForm
                            airportOptions={airportOptions}
                            MultiCityMaxRoute={MultiCityMaxRoute}
                            form={form}
                            limitSuggestionHeight
                        />
                    </Col>)
                         :
                    (
                        <>
                            <Col span={12}>
                                <AirportFieldsWrapper>
                                    <Row gutter={[16, 0]}>
                                        <Col span={12}>
                                            <DepartureWrapper>
                                                <Form.Item
                                                    name="OriginLocationCode"
                                                    rules={[{ required: true, message: t(translations).validations.departure }]}
                                                >
                                                        <AirportSelector
                                                            label={t(translations).inputs.fromLabel}
                                                            placeholder={t(translations).inputs.fromPlaceholder}
                                                            value={OriginLocationCode}
                                                            options={airportOptions}
                                                            icon="takeoff"
                                                            tripType="flight"
                                                            limitSuggestionHeight
                                                            onChange={(value) => onFormInputChange(value, 'OriginLocationCode')}
                                                        />
                                                </Form.Item>
                                                <SwitcherWrapper onClick={handleSwitchAirports}>
                                                    <DirectionIcon />
                                                </SwitcherWrapper>
                                            </DepartureWrapper>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name="DestinationLocationCode"
                                                rules={[
                                                    {
                                                        validator: validateDestination,
                                                    },
                                                ]}
                                            >
                                                <AirportSelector
                                                    label={t(translations).inputs.toLabel}
                                                    placeholder={t(translations).inputs.toPlaceholder}
                                                    value={DestinationLocationCode}
                                                    options={airportOptions}
                                                    icon="land"
                                                    tripType="flight"
                                                    limitSuggestionHeight
                                                    onChange={(value) => onFormInputChange(value, 'DestinationLocationCode')}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </AirportFieldsWrapper>
                            </Col>
                            <Col span={6}>
                                {
                                    isRoundTrip ? (
                                        <Form.Item
                                            name="FlightDateRange"
                                            rules={[
                                                { validator: validateRoundTripDates }
                                            ]}
                                        >
                                            <StyledDateRangePicker
                                                value={[DepartureDate, ReturnDate]}
                                                disabledDate={getDisabledDateRange}
                                                onChange={onDateRangeChange}
                                                placeholder={[t(translations).inputs.departureDate, t(translations).inputs.arrivalDate]}
                                            />
                                        </Form.Item>
                                    ) : (
                                        <Form.Item
                                            name="DepartureDate"
                                            rules={[{ required: true, message: t(translations).validations.departureDate }]}
                                        >
                                            <StyledDatePicker
                                                label={t(translations).inputs.departureDate}
                                                value={DepartureDate}
                                                disabledDate={getDisabledDateRange}
                                                onChange={(value) => onFormInputChange(value, 'DepartureDate')}/>
                                        </Form.Item>
                                    )
                                }
                            </Col>
                        </>
                    )}
                <Col span={6}>
                    <PassengerCountSelector onChange={onChange} payload={payload} />
                </Col>
                <Col
                    span={6}
                    offset={18}
                >
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        block
                    >
                        Modify search
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default FlightSearchDesktop;
