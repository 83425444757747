import React from 'react';
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import {SelectInfoWrapper} from "./styles";

type Props = {
    isReturning: boolean
    count: number
}


const RoadSelectInfo = ({ isReturning, count }: Props) => {
    const title = isReturning ? 'Select your return' : 'Select your departure';
    return (
        <SelectInfoWrapper>
            <StyledTitle level={4}>
                {title} ({count})
            </StyledTitle>
            <StyledParagraph>
                Fares displayed are for all passengers
            </StyledParagraph>
        </SelectInfoWrapper>
    );
};

export default RoadSelectInfo;
