import React, { useState, useEffect, useCallback } from 'react';
import {
  Col, Row, Card, Modal, Space, Spin,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import HeaderNavigation from '../../components/HeaderNavigation';
import {
  HeaderWrapper,
  TripSummaryWrapper,
  Wrapper,
} from './styles';
import {
  FareRuleTitleWrapper,
  FareRuleWrapper,
} from '../SearchListings/styles';
import AvailableBoats from '../../components/AvailableBoats';
import Header from '../../components/Header';
import { buildSearchPayload } from './helpers';

import { isMobileScreen } from '../../utils/helpers';
import {
  StyledParagraph,
  StyledTitle,
} from '../../components/CustomAntdComponents/Typography';

import StyledDrawer from '../../components/CustomAntdComponents/StyledDrawer';
import AlertMessage from '../../components/AlertMessage';
import {
  StyledButton,
} from '../../components/CustomAntdComponents/StyledButton';
import { useWaterSearchStore } from '../../store/waterSearch/waterSearchStore';
import { boatResultsResponse, waterFareRules } from './mock';
import WaterListingFilter from '../../components/ListingFilter/WaterFilter';
import OperatorBoat from '../../components/AvailableBoats/OperatorBoat';
import WaterTripSummary from '../../components/TripSummary/WaterTripSummary';
import { useI18nContext } from '../../utils/I18n';
import translations from './translations';

function SearchWaterListings() {
  const {
    state: { t },
  } = useI18nContext();
  const navigate = useNavigate();
  const [boatResults, setBoatResults] = useState<WaterSearchResult>();
  const [filteredBusResults, setFilteredBusResults] = useState<WaterSearchResult>();
  const [selectedTransports, setSelectedTransports] = useState<number[]>([]);
  const [fareRules, setFareRules] = useState<WaterFareRule[] | null>(null);
  const [fareRulesError, setFareRulesError] = useState<string| null>(null);
  const [loadingFareData, setLoadingFareData] = useState<boolean>(false);

  const [error, setError] = useState<string | null>();
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const {
    formData,
    selectedResult,
    updateStoreData,
  } = useWaterSearchStore();

  const [modalVisible, setModalVisible] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);

  const isMobile = isMobileScreen();

  const getBusRules = useCallback(async (selectedBus: AvailableBoat) => {
    setLoadingFareData(true);
    setFareRulesError(null);
    setFareRules(null);
    if (selectedResult && selectedBus) {
      // const flightRules = await postAPI(GET_ROAD_FARE_RULES, {
      //   SessionId: selectedResult.TripsSessionId,
      //   CombinationID: selectedResult.CombinationId,
      //   RecommendationID: selectedBus.RecommendationId,
      // });

      setLoadingFareData(false);
      setFareRules(waterFareRules);

      // if (flightRules.status === 200) {
      //   setFareRules(waterFareRules);
      // } else {
      //   setFareRulesError(flightRules.data);
      // }
    }
  }, [selectedResult]);

  const searchBoats = useCallback(async () => {
    setLoadingData(true);
    setError(null);
    const payload = buildSearchPayload(formData);
    // eslint-disable-next-line no-console
    console.log(payload);

    const searchResults = boatResultsResponse;

    if (searchResults.status === 200) {
      setBoatResults(searchResults.data);
      setFilteredBusResults(searchResults.data);

      updateStoreData({ selectedResult: searchResults.data.ResultList[0] });

      setLoadingData(false);
    } else {
      setError('Error encountered');
      setLoadingData(false);
    }
  }, [formData, updateStoreData]);

  useEffect(() => {
    searchBoats();
  }, [searchBoats]);

  const handleNavigation = () => {
    if (selectedResult) {
      updateStoreData({ selectedResult: null });
    } else {
      navigate(-1);
    }
  };

  const handleBookingRedirect = (selectedBoat: AvailableBoat) => {
    updateStoreData({ selectedBoat });
    getBusRules(selectedBoat);

    if (isMobile) {
      setDialogVisible(true);
    } else {
      setModalVisible(true);
    }
  };

  const handleAccept = () => {
    setDialogVisible(false);
    setModalVisible(false);

    navigate('/passenger-details/water');
  };

  const renderAcceptButton = () => (
    <StyledButton mode="primary" size="large" block onClick={handleAccept} disabled={!fareRules}>
      {t(translations).fareRule.acceptButton}
    </StyledButton>
  );

  const handleTransportFiltering = (isChecked: boolean, transportId: number, singleMode?: boolean) => {
    if (transportId === -1) {
      setSelectedTransports([]);
      return;
    }

    if (singleMode) {
      setSelectedTransports([transportId]);
      return;
    }

    let newSelections = [...selectedTransports];

    if (isChecked) {
      newSelections.push(transportId);
    } else {
      newSelections = newSelections.filter((code) => transportId !== code);
    }
    setSelectedTransports(newSelections);
  };

  const renderRulesContent = () => (
    <>
      <FareRuleTitleWrapper>
        <StyledTitle level={3} color="primary" align="center">
          {t(translations).fareRule.title}
        </StyledTitle>
      </FareRuleTitleWrapper>
      <StyledParagraph align="center" strong>
        {t(translations).fareRule.text}
      </StyledParagraph>
      <FareRuleWrapper>
        <Spin spinning={loadingFareData}>
          {fareRulesError && <AlertMessage message={fareRulesError} />}
          <Space direction="vertical" size="small">
            {fareRules && fareRules.map((fareRule) => (
              <div key={fareRule.Code}>
                <StyledTitle level={5}>
                  {fareRule.Code}
                </StyledTitle>
                <StyledParagraph>
                  {fareRule.Text}
                </StyledParagraph>
              </div>
            ))}
          </Space>
        </Spin>
      </FareRuleWrapper>
    </>
  );

  useEffect(() => {
    if (boatResults) {
      if (selectedTransports.length === 0) {
        setFilteredBusResults(boatResults);
      } else {
        const filtered = boatResults.ResultList.filter((b) => selectedTransports.includes(b.AgentId));
        setFilteredBusResults({
          ...boatResults,
          ResultList: filtered,
        });
      }
    }
  }, [boatResults, selectedTransports]);

  const handleSortByPrice = (order: string| null) => {
    if (boatResults) {
      const sortedResultList = order === 'asc' ? boatResults.ResultList.sort(
        (a: BoatResultItem, b: BoatResultItem) => a.StartingPrice - b.StartingPrice,
      ) : boatResults.ResultList.sort(
        (a: BoatResultItem, b: BoatResultItem) => b.StartingPrice - a.StartingPrice,
      ) || [];

      setBoatResults({
        ...boatResults,
        ResultList: sortedResultList,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xs={0} lg={24}>
          <Header />
        </Col>
      </Row>

      <div className="container">
        <Wrapper>
          {/* Small Devices */}
          <Row>
            <Col xs={24} lg={0}>
              <HeaderNavigation name={t(translations).title} navigateHandler={handleNavigation} />
            </Col>

            <Col xs={24} lg={0}>
              <Card size="small">
                <TripSummaryWrapper>
                  <WaterTripSummary />
                </TripSummaryWrapper>
              </Card>
            </Col>
          </Row>

          {/*  >= LG Devices */}
          <Row>
            <Col xs={0} lg={24}>
              <HeaderWrapper>
                <Row gutter={32}>
                  <Col lg={8}>
                    <HeaderNavigation navigateHandler={handleNavigation} />
                  </Col>
                  <Col lg={16}>
                    <Card size="default">
                      <TripSummaryWrapper>
                        <WaterTripSummary />
                      </TripSummaryWrapper>
                    </Card>
                  </Col>
                </Row>
              </HeaderWrapper>

              <div style={{ marginTop: '30px' }}>
                <Row gutter={32}>
                  <Col lg={8}>
                    <Card>
                      <WaterListingFilter
                        results={boatResults}
                        handleTransportFiltering={handleTransportFiltering}
                        handleSortByPrice={handleSortByPrice}
                      />
                    </Card>
                  </Col>
                  <Col lg={16}>
                    {selectedResult
                      ? (
                        <OperatorBoat
                          onBookingRedirect={handleBookingRedirect}
                          listings={selectedResult}
                        />
                      )
                      : (
                        <AvailableBoats
                          error={error}
                          results={filteredBusResults}
                          loading={loadingData}
                          onSetActiveResultItem={(resultItem) => updateStoreData({ selectedResult: resultItem })}
                        />
                      )}
                  </Col>
                </Row>
              </div>

            </Col>
          </Row>

          {/*  <= XS Devices */}
          <Row>
            <Col xs={24} lg={0}>
              {selectedResult
                ? (
                  <OperatorBoat
                    onBookingRedirect={handleBookingRedirect}
                    listings={selectedResult}
                  />
                )
                : (
                  <>
                    <WaterListingFilter
                      results={boatResults}
                      handleTransportFiltering={handleTransportFiltering}
                      handleSortByPrice={handleSortByPrice}
                    />
                    <AvailableBoats
                      error={error}
                      results={filteredBusResults}
                      loading={loadingData}
                      onSetActiveResultItem={(result) => updateStoreData({ selectedResult: result })}
                    />
                  </>
                )}
            </Col>
          </Row>

          <Modal
            centered
            open={modalVisible}
            footer={null}
            onCancel={() => {
              setModalVisible(false);
              setFareRules(null);
            }}
            width={400}
            getContainer={document.getElementById('app-container') as HTMLElement}
          >
            {renderRulesContent()}
            {renderAcceptButton()}
          </Modal>

          <StyledDrawer open={dialogVisible} onClose={() => setDialogVisible(false)} footer={renderAcceptButton()} height={200}>
            {renderRulesContent()}
          </StyledDrawer>

        </Wrapper>
      </div>
    </>

  );
}

export default SearchWaterListings;
