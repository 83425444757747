import React, {useMemo} from 'react';
import {
  Col, Row,
} from 'antd';
import HeaderNavigation from '../../components/HeaderNavigation';
import {
  Container,
} from './styles';
import {useParams} from "react-router-dom";
import RoadItinerary from "../../components/RoadItinerary";
import FlightItinerary from '../../components/FlightItinerary';

function Itinerary() {
  const { tripType } = useParams();

  const renderItinerary = () => {
    switch (tripType) {
      case 'road':
        return <RoadItinerary />;
      case 'flight':
        return <FlightItinerary />;
      case 'water':
        return <></>;
      default:
        return <></>;
    }
  }

  const  headingName = useMemo(() => {
    switch (tripType) {
      case 'road':
        return 'Road Details';
      case 'flight':
        return 'Flight Details';
      case 'water':
        return 'Water Details';
      default:
        return '';
    }
  }, [tripType]);

  return (
    <Container>
      <div className="container">
        <Row>
          <Col span={24}>
            <HeaderNavigation name={headingName} />
          </Col>
        </Row>

        {renderItinerary()}
      </div>
    </Container>

  );
}

export default Itinerary;
