import styled, { css } from 'styled-components';
import {BLACK, BLACK_150, BLACK_300, GREY_10, GREY_12, GREY_2, GREY_5, PRIMARY, RED, WHITE} from '../../utils/colors';
import device from '../../utils/mediaQuery';

export const Wrapper = styled.div`
    @media ${device.mobile} {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 500;
    }
`;

export const Content = styled.div`
    @media ${device.mobile} {
        height: 100%;
        width: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
    }
`;

export const MobileContentWrapper = styled.div`
   height: 100%;
    overflow-y: auto;
`;

export const MobileContentBody = styled.div`
    flex: 1 1;
    min-width: 0;
    min-height: 0;
    overflow: auto;
    padding: 10px 10px;
`;

export const MobileContentFooter = styled.div`
`;

export const DesktopContentWrapper = styled.div`
  margin-top: 40px;
`;

export const ItemHeaderWrapper = styled.div`
  background: ${GREY_5};
  border: 1px solid ${PRIMARY};
  padding: 18px;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
`;

export const ItemBodyWrapper = styled.div`
  padding-top: 7px;
  overflow: hidden;
  height: 0;
  //max-height: 0;
  transition: all 0.2s ease-in-out;
`;

export const ItemWrapper = styled.div<{ active: boolean }>`
  ${({ active }) => active && css`
    padding-bottom: 20px;
    
    ${ItemHeaderWrapper} {
      background: none;
      border: none;
      margin-bottom: 0;
      padding-left: 0;
      cursor: auto;
    }
    
    ${ItemBodyWrapper} {
      height: auto;
      //max-height: 350px;
    }
  `}
`;

export const FormWrapper = styled.div`
  @media ${device.large} {
    background: #fff;
    padding: 30px;
    border-radius: 5px;


    .as-label {
      background: #fff !important;
    }
    
    .ant-picker,
    .ant-select-selector,
    .ant-input {
      background: ${GREY_10} !important;
    }
  }
`;

export const PriceModalContentWrapper = styled.div`
  width: 80%;
  margin: 0 auto 30px;
`;

export const InfoWrapper = styled.div`
  background: ${GREY_5};
  padding: 8px 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
`;

export const SectionCard = styled.div`
    background: ${WHITE};
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 30px;
`;

export const SectionCardHeading = styled.div<{ bordered?: boolean, clickable?: boolean }>`
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  svg {
    path {
        fill: ${BLACK} !important;
    }
  }
  
    ${({ clickable }) => clickable && css`
      cursor: pointer;
    `}
    
    ${({ bordered }) => bordered && css`
      border-bottom: 1px solid ${GREY_12};
      padding-bottom: 20px;
    `}
`;

export const SectionCardBody = styled.div`
  padding-top: 12px;
`;

export const PassengerDataWrapper = styled.div``;

export const ContactWrapper = styled.div<{ error?: boolean, hasContactDetails: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ hasContactDetails }) => hasContactDetails ? 'flex-start' : 'center'};
  flex: 1;
  
  ${({ error }) => error && css`
    .ant-typography {
        color: ${RED};
      span {
        color: ${RED} !important;
      }
    }
  `};
`;

export const FooterActionItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  
  svg {
     path {
        fill: ${BLACK_300} !important;
     }
  }
  
  .ant-typography {
    span {
        color: ${BLACK_150};
    }
  }
`;

export const FooterActionWrapper = styled.div`
  background: ${GREY_2};
  
  ${FooterActionItemWrapper} {
    &:first-of-type {
      border-bottom: 1px solid #DFDFDF;
    }
  }
`;

export const MobilePassengerDataWrapper = styled.div<{ offset?: number, topOffset?: number }>`
    padding-right: 10px;
`;
