import styled from 'styled-components';
import {
  FONT_SIZE, GREY_10, GREY_15, PRIMARY, WHITE,
} from '../../utils/colors';

export const Wrapper = styled.section` 
  .ant-typography {
    margin: 0;
  }
  div.ant-typography {
    color: #080403;
    font-size: calc(10px *  ${FONT_SIZE});
  }
  
`;

export const Badge = styled.div`
  text-align: center;
  
  div.ant-typography {
    background: ${PRIMARY};
    border-radius: 5px;
    color: ${WHITE};
    padding: 10px 20px;
    display: inline-block;
  }
`;
export const BadgeWrapper = styled.div`
  display; flex;
  justify-content: center;
  width: 100%;
`;

export const DetailItemsWrapper = styled.div`
  margin: 8px 0;
`;

export const DetailItem = styled.div`
  display: flex;
  div.ant-typography {
    font-size: calc(10px *  ${FONT_SIZE});
  }
`;

export const DetailItemIcon = styled.div`
  background: ${GREY_15};
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-right: 5px;
`;

export const StepItemWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const StepItemContent = styled.div`
  img {
    vertical-align: inherit;
    width: 20px;
    margin-top: 3px;
  }
`;
export const LayoverDurationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${PRIMARY};
   font-size: calc(10px *  ${FONT_SIZE});
`;
export const LayoverItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  p{
    margin: 0px;
    padding:0px;

  }
`;
export const RenderStopOverWrapper = styled.div`
  color: ${PRIMARY};
  font-size: calc(9px *  ${FONT_SIZE});
  text-align: center;
`;

export const StepProgressLine = styled.div`
  border-bottom: 1px dashed #bababa;
  flex: 1;
  margin: 0 10px;
  
  position: relative;
  
  &:before {
    content: ' ';
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: solid 2px ${PRIMARY};
    position: absolute;
    top: -7px;
  }
`;

export const StepsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  

  ${StepItemWrapper} {
    &:last-of-type {
      &:after {
        display: none;
      }
    }
  }
  
  ${StepProgressLine} {
    &:last-of-type {
      &:before {
        right: -15px;
      }
    }
    &:first-of-type {
      &:before {
        left: -15px;
      }
    }
  }

`;

export const DetailsWrapper = styled.div`
  margin: 15px 0px;
`;

export const HighlightDetailItem = styled.div`
  border-radius: 5px;
  background: ${GREY_10};
  padding: 10px;
  margin-bottom: 7px;
`;
