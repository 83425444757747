import React from 'react';
import {StyledParagraph, StyledTitle} from "../CustomAntdComponents/Typography";
import {HeadingWrapper, ReactionIcon, ReactionItem, ReactionsWrapper, Wrapper} from "./styles";
import {StyledButton} from "../CustomAntdComponents/StyledButton";

import NeutralFace from "../../../src/assets/images/reactions/neutral-face.svg";
import SmilingFace from "../../../src/assets/images/reactions/smiling-face.svg";
import GrinningFace from "../../../src/assets/images/reactions/grinning-face-with-smiling-eyes.svg";
import {EXCELLENT_REACTION, FAIR_REACTION, GOOD_REACTION} from "./constant";
import {useFlightSearchStore} from "../../store/flightSearch/flightSearchStore";
import {useNavigate, useParams} from "react-router-dom";
import {useRoadSearchStore} from "../../store/roadSearch/roadSearchStore";
import {useBookingStore} from "../../store/booking/bookingStore";

type Props = {
    hideAction?: boolean;
    openFeedbackForm: (reaction: string) => void;
}
const BookingFeedback = ({ hideAction, openFeedbackForm }: Props) => {
    const { tripType } = useParams();
    const navigate =  useNavigate();
    const { resetBooking, } = useBookingStore();
    const { resetFormData, } = useFlightSearchStore();

    const {
        resetFormData: resetRoadSearchData
    } = useRoadSearchStore();

    const redirectToSearch = () => {
        resetBooking();
        if(tripType === 'flight') {
            resetFormData();
        }

        if(tripType === 'road') {
            resetRoadSearchData();
        }
        navigate(`/search/${tripType}`);
    }

    return (
        <Wrapper>
            <HeadingWrapper>
                <StyledTitle level={4} align="center">
                    Rate your booking experience on Trips
                </StyledTitle>

                <StyledParagraph align="center" size="normal">
                    Your feedback means the world to us
                </StyledParagraph>
            </HeadingWrapper>

            <ReactionsWrapper>
                <ReactionItem onClick={() => openFeedbackForm(FAIR_REACTION)}>
                    <ReactionIcon src={NeutralFace} />
                    <StyledParagraph size="normal">
                        Fair
                    </StyledParagraph>
                </ReactionItem>
                <ReactionItem onClick={() => openFeedbackForm(GOOD_REACTION)}>
                    <ReactionIcon src={SmilingFace} />
                    <StyledParagraph size="normal">
                        Good
                    </StyledParagraph>
                </ReactionItem>
                <ReactionItem onClick={() => openFeedbackForm(EXCELLENT_REACTION)}>
                    <ReactionIcon src={GrinningFace} />
                    <StyledParagraph size="normal">
                        Excellent
                    </StyledParagraph>
                </ReactionItem>
            </ReactionsWrapper>

            {
                !hideAction && <StyledButton mode="default" block onClick={redirectToSearch}>
                    Not now
                </StyledButton>
            }
        </Wrapper>
    );
};

export default BookingFeedback;
