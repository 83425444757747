import React from 'react';
import { Checkbox } from 'antd';
import {CheckboxItem, Wrapper} from "./styles";


type Props = {
    options: SelectOption[],
    values: string[],
    onChange: (e: string[]) => void,
    block?: boolean,
}

const CheckboxGroup = Checkbox.Group;

const StyledCheckboxGroup = ({ options, values, onChange, block = true }: Props) => {
    return (
        <Wrapper block={block}>
            {block ? (<CheckboxGroup style={{ width: '100%' }} value={values} onChange={onChange}>
                    {options.map((option) => (
                        <CheckboxItem key={option.value}>
                            <Checkbox value={option.value}>{option.label}</Checkbox>
                        </CheckboxItem>
                    ))}
            </CheckboxGroup>) :(
                <CheckboxGroup options={options} value={values} onChange={onChange} />
            )}
        </Wrapper>
    );
};

export default StyledCheckboxGroup;
