import React, {ReactNode, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useFlightSearchStore } from '../store/flightSearch/flightSearchStore';
import AuthenticatedRoute from './AuthenticatedRoute';

type Props = {
  children: ReactNode
}
function FlightDataFilled({ children }: Props) {
  const [allow, setAllow] = useState(false)
  const navigate = useNavigate();
  const {
    formData: { OriginLocationCode, multiCity },
  } = useFlightSearchStore();

  const firstOriginLocationCodeInMultiCity = multiCity && multiCity?.length > 0 && multiCity[0]?.OriginLocationCode;
  useEffect(() => {
    if (!OriginLocationCode && !firstOriginLocationCodeInMultiCity) {
      navigate('/search/flight');
    } else {
      setAllow(true)
    }
  }, [OriginLocationCode, firstOriginLocationCodeInMultiCity, navigate]);
  return allow ? (
    <AuthenticatedRoute>
      {children}
    </AuthenticatedRoute>
  ) : (
        <></>
  );
}

export default FlightDataFilled;
