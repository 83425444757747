import styled from "styled-components";
import {Wrapper as SelectWrapper} from "../CustomAntdComponents/StyledSelect/styles";
import {BLACK_200, GREY_6, PRIMARY, WHITE} from "../../utils/colors";
import device from '../../utils/mediaQuery';

export const DrawerContent = styled.div`
  padding: 15px 0;
  height: 85vh;
  
  ${SelectWrapper} {
    .ant-select-selector {
      border: solid 1px ${PRIMARY} !important;
    }
  }
`;

export const PrefixIconWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 5px;
  
    svg {
      width: 20px;
      height: 20px;
    }
`;


export const InputWrapper = styled.div`
  background: ${WHITE};
  padding: 13.2px 11px;
  font-size: 14px;
  border-radius: 12px;
  overflow: hidden;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.4;
  min-height: 48px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  
  svg {
    path {
        fill: ${BLACK_200} !important;
    }
  }
`;

export const InputDisplay = styled.div`

  @media ${device.large} {
    border: solid 1px ${GREY_6};
  }
`;

export const SearchItem = styled.div`
  border-bottom: solid 1px #EFEFEF;
  padding: 16px 0 12px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;


export const NameWrapper = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;
  flex: 1;
  
  svg {
    width: 20px;
    height: 20px;
    
    path {
        fill: #434455 !important;
    }
  }
`;

export const NameContent = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 12px;
  max-height: 40px;
  overflow-y: hidden;
`;

export const SectionTitle = styled.div`
  margin: 12px 0 6px;
`;

export const ListContentWrapper = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 160px),
`;

export const DesktopPopoverWrapper = styled.div`
  position: relative;
  
  .ant-popover-inner-content {
      width: 100% !important;
      padding: 12px 0;
    }
`;
